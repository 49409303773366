import React, { FC } from 'react';
import {
  ChartTooltip as Tooltip,
  TooltipWithInsightsBounds,
  TooltipWithInsightsBoundsProps,
} from '@insights/tooltip-nwe';
import {
  MessageKeys,
  FormattedMessage,
  FormattedDate,
} from '@insights/i18n-nwe';
import GenerateCapacityMessage from './generateCapacityMessage';

import GenerateTeamCapacity from './generateTeamCapacity';

export interface OwnProps {
  date: Date;
  scheduledHours: number;
  availableHours: number;
}

export type Props = TooltipWithInsightsBoundsProps & OwnProps;

const TeamCapacityTooltip: FC<Props> = ({
  date,
  scheduledHours,
  availableHours,
  ...rest
}) => {
  return (
    <TooltipWithInsightsBounds {...rest}>
      <Tooltip.TooltipBaseComponent
        header={<Tooltip.Header label={<FormattedDate value={date} />} />}
        content={
          <>
            <Tooltip.DataItem
              labelText={
                <FormattedMessage id={MessageKeys.capacityPercentage} />
              }
              displayValue={
                <GenerateTeamCapacity
                  scheduledHours={scheduledHours}
                  availableHours={availableHours}
                  date={date}
                />
              }
            />
            <Tooltip.DataItem
              labelText={<FormattedMessage id={MessageKeys.capacityAmount} />}
              displayValue={
                <GenerateCapacityMessage
                  available={availableHours}
                  planned={scheduledHours}
                />
              }
            />
          </>
        }
      />
    </TooltipWithInsightsBounds>
  );
};

export default TeamCapacityTooltip;
