import { getStringWidth } from '@vx/text';
import { CSSProperties } from 'react';

export const ELLIPSIS = '\u2026';

export function truncate(text: string, startLimit = 15, endLimit = 10): string {
  if (text.length <= startLimit + endLimit) return text;

  const start = startLimit > 0 ? text.slice(0, startLimit) : '';
  const end = endLimit > 0 ? text.slice(-endLimit) : '';

  return `${start}${ELLIPSIS}${end}`;
}

export const calcLengthOfString = (text: string, style?: CSSProperties) =>
  Math.ceil(getStringWidth(text, style));

export const getTruncatedText = (
  text: string,
  width: number,
  style: CSSProperties,
  singleCharPixelValue = calcLengthOfString('a', style),
  ellipsisLength = calcLengthOfString(ELLIPSIS, style)
) => {
  const w = width - ellipsisLength;
  const charactersInWidth = w / singleCharPixelValue;
  return truncate(text, charactersInWidth, 0);
};
