import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  CompatRouter,
  Routes,
  Route,
} from 'react-router-dom-v5-compat';
import { Setup } from '@insights/clients-nwe';

import { useIsTreatmentEnabled } from '@wf-mfe/toggles';
import { AnalyticsPage } from './analytics-page';

export const Root = (): JSX.Element => {
  const isRRv6Enabled = useIsTreatmentEnabled('react-router-v6');
  return (
    <Setup>
      <Router>
        <CompatRouter>
          <Routes>
            <Route
              path={isRRv6Enabled ? '*' : '/analytics/*'}
              element={<AnalyticsPage />}
            />
          </Routes>
        </CompatRouter>
      </Router>
    </Setup>
  );
};
