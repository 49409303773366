import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  ProjectHoursSummaryDatum,
  Project,
  RawProject,
} from '@insights/models-nwe';
import { formatDate } from '@insights/shared-components-nwe';
import {
  useCustomerPreferences,
  CustomerPreferenceField,
  useProjectConditions,
  useProjects,
} from '@insights/clients-nwe';
import {
  processProjectMagnitudeData,
  validateDurationHoursPerDay,
} from './utils';

export const GET_PROJECT_HOURS = gql`
  query ProjectHours(
    $projectIds: [Guid!]!
    $startDate: Date!
    $endDate: Date!
  ) {
    projectHoursForDateRange(
      projectIDs: $projectIds
      queryStartDate: $startDate
      queryEndDate: $endDate
    ) {
      projectguid
      completedplannedhours
      completeddurationhours
    }
  }
`;

export type RawProjectFragment = Pick<
  RawProject,
  | 'guid'
  | 'name'
  | 'condition'
  | 'conditionlabel'
  | 'workrequired'
  | 'plannedcompletiondate'
  | 'plannedstartdate'
>;

export type ProjectFragment = Pick<
  Project,
  | 'guid'
  | 'name'
  | 'condition'
  | 'workrequired'
  | 'plannedcompletiondate'
  | 'plannedstartdate'
  | 'conditionlabel'
>;

/**
 * This is where the shape of the response is defined. It should match
 * the shape of your query
 */
export interface Response {
  projectHoursForDateRange: ProjectHoursSummaryDatum[];
}

/**
 * This is where the variables that are used in the actual query
 * are defined
 */
export interface Variables {
  projectIds: string[];
  startDate: string;
  endDate: string;
}

export const useProjectMagnitudeQuery = (startDate: Date, endDate: Date) => {
  const {
    data: { projects },
    loading: projectsLoading,
  } = useProjects();
  const {
    loading: preferencesLoading,
    error: errorLoadingPreferences,
    preferences,
  } = useCustomerPreferences();
  const {
    data: conditions,
    loading: conditionsLoading,
  } = useProjectConditions();
  const hoursPerDay = preferences?.[CustomerPreferenceField.hoursPerDay];
  const durationHoursPerDay = hoursPerDay ? parseFloat(hoursPerDay) : undefined;

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<Response, Variables>(GET_PROJECT_HOURS, {
    variables: {
      projectIds: Array.from(projects.keys()).sort(),
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
    skip: projectsLoading || projects.size === 0,
  });

  const data = useMemo(
    () =>
      processProjectMagnitudeData(
        queryData?.projectHoursForDateRange,
        projects,
        durationHoursPerDay,
        conditions
      ),
    [queryData, projects, durationHoursPerDay, conditions]
  );
  const loading =
    preferencesLoading || queryLoading || conditionsLoading || projectsLoading;
  const preferencesError =
    errorLoadingPreferences ||
    (preferencesLoading
      ? null
      : validateDurationHoursPerDay(durationHoursPerDay));

  return {
    data,
    loading,
    queryError,
    preferencesError,
  };
};
