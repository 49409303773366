import { css } from 'emotion';
import { CARD_MARGIN } from './constants';

const OFFSET_FOR_NEGATIVE_MARGIN = CARD_MARGIN * 2;

export const KPIWrapper = css`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  width: calc(100% + ${OFFSET_FOR_NEGATIVE_MARGIN}px);
  margin: 0 -${CARD_MARGIN}px;
  }
`;
