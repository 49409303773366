import React, { FC } from 'react';
import { DateAxis } from '@insights/chart-parts-nwe';
import { ScaleTime } from 'd3-scale';

interface OwnProps {
  xScale: ScaleTime<number, number>;
  yMax: number;
}

export const XAxis: FC<OwnProps> = ({ xScale, yMax }) => (
  <DateAxis scale={xScale} top={yMax} />
);
