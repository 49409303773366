import React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

import GroupIcon from 'phoenix-icons/dist/GroupIcon.js';
import ProjectIcon from 'phoenix-icons/dist/ProjectIcon.js';
import { getStyledNavItemIcon, SecondaryNavigation } from '@wf-mfe/navigation';
import { PageContentSlot, PageHeaderSlot } from '@wf-mfe/layout';
import { css } from 'react-emotion';
import { WorkContent } from '@insights/work-nwe';
import { PeopleContent } from '@insights/people-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

import { Header } from './header';

const navConfig = {
  WORK: {
    subPath: 'work',
    label: <FormattedMessage id={MessageKeys.work} />,
    icon: getStyledNavItemIcon(ProjectIcon, {}),
    getPageContent: () => null, // to handle bad types
  },
  PEOPLE: {
    subPath: 'people',
    label: <FormattedMessage id={MessageKeys.people} />,
    icon: getStyledNavItemIcon(GroupIcon, {}),
    getPageContent: () => null, // to handle bad types
  },
};

export const AnalyticsPage = (): JSX.Element => {
  return (
    <>
      <SecondaryNavigation
        navConfig={navConfig}
        isStatic
        disableSecondaryNavCustomization
      />
      <PageHeaderSlot>
        <Header />
      </PageHeaderSlot>
      <PageContentSlot mfeName="@wf-mfe/enhanced-analytics-ui">
        <Routes>
          <Route
            path="/"
            element={
              <div className={workContentWrapperStyles}>
                <WorkContent />
              </div>
            }
          />
          <Route
            path="work"
            element={
              <div className={workContentWrapperStyles}>
                <WorkContent />
              </div>
            }
          />
          <Route path="people" element={<PeopleContent />} />
        </Routes>
      </PageContentSlot>
    </>
  );
};

const workContentWrapperStyles = css`
  display: flex;
  flex-direction: row;
  max-height: 100%;
  flex-grow: 1;
`;
