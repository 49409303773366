import {
  Project as ProjectModel,
  Filter,
  isGraphQLFilter,
  isValueFilter,
  ValueFilter,
} from '@insights/models-nwe';
import {
  generateRedrockFilterBody,
  logError,
  parseDate,
} from '@insights/utils-nwe';

import { GraphqlFilterKey } from '@insights/constants-nwe';
import { ConditionsMap } from '../../project-conditions/models';
import { FilterBody, ProjectMap, RawProjectDatum } from '../models';

export function transformDate(input: null): null;
export function transformDate(input: string): Date;
export function transformDate(input: string | null): Date | null {
  if (input === null) return null;
  const date = convertToValidISO(input);
  return parseDate(date);
}

export const convertToValidISO = (input: string): string => {
  // Redrock returns invalid date 2020-05-20T09:00:00:000-0600 but 2020-05-20T09:00:00:000Z is required
  return `${input.slice(0, -5)}Z`;
};

export const getEquatedConditions = (
  conditionFilter: ValueFilter,
  customEnumns: ConditionsMap | undefined
): ValueFilter => {
  const targetValues = conditionFilter?.values.map(
    (condtion) => condtion.value
  );
  const allConditionsArray = Array.from(customEnumns?.values() ?? []);
  const foundConditions = allConditionsArray.filter((condition) =>
    targetValues?.includes(condition.equatesWith)
  );
  const mappedConditions = foundConditions.map(({ label, value }) => ({
    label,
    value,
    __typename: 'FilterOption',
  }));

  return { ...conditionFilter, values: mappedConditions };
};

export const processCondtionFilters = (
  filters: Filter[],
  customerConditons: ConditionsMap | undefined
): Filter[] => {
  return filters.reduce((allFilters: Filter[], filter: Filter): Filter[] => {
    if (isValueFilter(filter) && filter.column === GraphqlFilterKey.condition) {
      const conditionsFilters = getEquatedConditions(filter, customerConditons);
      allFilters.push(conditionsFilters);
    } else {
      allFilters.push(filter);
    }
    return allFilters;
  }, []);
};

export const transformProjectFilters = (
  filters: Filter[],
  conditionMap: ConditionsMap | undefined
): FilterBody => {
  const projectFilters = processCondtionFilters(filters, conditionMap).filter(
    isGraphQLFilter
  );
  let filterBody = {};

  try {
    filterBody = generateRedrockFilterBody(projectFilters);
  } catch (e) {
    logError(e);
  }

  return filterBody;
};

export const processProjectData = (projects: RawProjectDatum[]): ProjectMap =>
  projects.reduce((accumulator, project) => {
    accumulator.set(project.ID, transformProject(project));
    return accumulator;
  }, new Map<string, ProjectModel>());

export const transformProject = (project: RawProjectDatum): ProjectModel => ({
  name: project.name,
  guid: project.ID,
  status: project.status,
  condition: project.condition,
  workrequired: project.workRequired,
  plannedstartdate: transformDate(project.plannedStartDate),
  plannedcompletiondate: transformDate(project.plannedCompletionDate),
  actualstartdate: transformDate(project.actualStartDate),
  actualcompletiondate: transformDate(project.actualCompletionDate),
  __typename: 'Project',
  __id: project.ID,
});
