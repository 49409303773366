import React, { FC, SVGProps, CSSProperties, useMemo } from 'react';

// vx and d3
import { Text } from '@vx/text';
import { getTruncatedText } from '@insights/utils-nwe';

export interface OwnProps {
  width: number;
  children: string;
  labelStyle: CSSProperties;
}

type Props = OwnProps & SVGProps<Text>;

const TruncateText: FC<Props> = ({ width, children, labelStyle, ...props }) => {
  const text = useMemo(() => getTruncatedText(children, width, labelStyle), [
    width,
    children,
    labelStyle,
  ]);

  return (
    <Text {...props} style={labelStyle}>
      {text}
    </Text>
  );
};

export default TruncateText;
