import React from 'react';
import { css } from 'emotion';

/* This component is useful for when you have content you want a screen 
reader to be able to read, but you don't want it to be visible on the 
page */

type Props<T extends keyof JSX.IntrinsicElements> = {
  tag: T;
} & JSX.IntrinsicElements[T];

export const HiddenAriaElement = <T extends keyof JSX.IntrinsicElements>({
  tag,
  children,
  ...props
}: Props<T>) => {
  return React.createElement(tag, { className: styles, children, ...props });
};

const styles = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
