import React, { FC, ReactElement } from 'react';
import { MessageKeys, FormattedMessage, useIntl } from '@insights/i18n-nwe';

import { KpiCardWrapper, KPICardHeader, CardFooter } from '../../card-layout';
import { CardBody } from '../../card-layout/body';
import {
  getValue,
  TIMEFRAME_LABEL_TO_MESSAGEKEY,
  TIMEFRAME_ARIALABEL_FOOTER_TO_MESSAGEKEY,
  TIMEFRAME_ARIALABEL_TO_MESSAGEKEY,
} from '../../utils/index';
import { KPICardProps } from '../models';
import useAvgProjectDuration from '../../queries/use-avg-project-duration';

const TEST_ID = 'avg-proj-duration';

const CardHeader: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <KPICardHeader
      ariaLabel={formatMessage({
        id: MessageKeys.kpiAriaLabelAverageProjectDurationHeader,
      })}
      title={formatMessage({ id: MessageKeys.kpiAverageProjectDuration })}
      testID="average-project-duration-header"
      info={{
        header: <FormattedMessage id={MessageKeys.kpiAverageProjectDuration} />,
        body: (
          <>
            <FormattedMessage
              id={MessageKeys.kpiAverageProjectDurationInfo1}
              tagName="div"
            />
            <br />
            <FormattedMessage
              id={MessageKeys.kpiAverageProjectDurationInfo2}
              tagName="div"
            />
          </>
        ),
      }}
      buttonDescription={
        <FormattedMessage
          id={MessageKeys.tooltipButtonDescriptionAverageProjectDuration}
        />
      }
    />
  );
};

export const AverageProjectDuration: FC<KPICardProps> = ({
  filter,
  dateRange,
}) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useAvgProjectDuration(
    filter,
    dateRange.start,
    dateRange.end
  );

  if (loading) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.loading} />}
      />
    );
  }

  if (error || !data) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.error} />}
      />
    );
  }

  const bodyUnitObj = getValue(data.averageProjectDuration.actual);
  const footerUnitObj = getValue(data.averageProjectDuration.historical);

  return (
    <KpiCardWrapper
      testID={TEST_ID}
      header={<CardHeader />}
      body={
        <CardBody
          testID="average-project-duration-body"
          value={data.averageProjectDuration.actual}
          previousValue={data.averageProjectDuration.historical}
          label={(value) =>
            formatMessage(
              { id: TIMEFRAME_LABEL_TO_MESSAGEKEY[bodyUnitObj.unit] },
              {
                value: bodyUnitObj.value,
                [bodyUnitObj.unit]: (...chunks: ReactElement[]) => (
                  <span style={{ fontSize: '24px' }}>{chunks}</span>
                ),
              }
            )
          }
          bodyAriaLabel={formatMessage(
            { id: TIMEFRAME_ARIALABEL_TO_MESSAGEKEY[bodyUnitObj.unit] },
            {
              value: bodyUnitObj.value,
              startDate: dateRange.start,
              endDate: dateRange.end,
            }
          )}
          changeAriaLabel={
            MessageKeys.kpiAriaLabelAverageProjectDurationBodyChange
          }
          dateRange={{ start: dateRange.start, end: dateRange.end }}
        />
      }
      footer={
        <CardFooter
          testID="average-project-duration-footer"
          previousValue={data.averageProjectDuration.historical}
          startDate={dateRange.start}
          endDate={dateRange.end}
          label={(previousValue) =>
            formatMessage(
              { id: TIMEFRAME_LABEL_TO_MESSAGEKEY[footerUnitObj.unit] },
              {
                value: footerUnitObj.value,
                [footerUnitObj.unit]: (...chunks: ReactElement[]) => (
                  <span>{chunks}</span>
                ),
              }
            )
          }
          ariaLabel={(totalDays) =>
            formatMessage(
              {
                id:
                  TIMEFRAME_ARIALABEL_FOOTER_TO_MESSAGEKEY[footerUnitObj.unit],
              },
              { value: footerUnitObj.value, totalDays }
            )
          }
        />
      }
    />
  );
};
