import styled from 'react-emotion';

interface Props {
  color: string;
}

export default styled('div')(({ color }: Props) => ({
  height: '12px',
  width: '12px',
  borderRadius: '50%',
  display: 'inline-block',
  backgroundColor: color,
  lineHeight: '20px',
  marginRight: '12px',
}));
