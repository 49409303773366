import React, { FC } from 'react';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { Font } from '@phoenix/all';
import { css } from 'emotion';

interface Props {
  totalCount: number;
  pageOffset: number;
  testId: string;
  limit: number;
}

type WheelRange = {
  startRange: number;
  endRange: number;
};

const getRanges = (
  total: number,
  offSet: number,
  limit: number
): WheelRange => {
  const startRange = total === 0 ? 0 : offSet + 1;
  const endRange = total - startRange < limit ? total : offSet + limit;
  return { startRange, endRange };
};

export const PaginationText: FC<Props> = ({
  totalCount,
  pageOffset,
  testId,
  limit,
}) => {
  const { startRange, endRange } = getRanges(totalCount, pageOffset, limit);
  return (
    <Font
      type="text"
      className={paginationText}
      data-testid={`${testId}-pagination-wheel-txt`}
    >
      <FormattedMessage
        id={MessageKeys.paginationWheelText}
        values={{
          startOfRange: startRange,
          endOfRange: endRange,
          totalCount,
        }}
      />
    </Font>
  );
};

export const paginationText = css`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: primary.gray(700);
`;
