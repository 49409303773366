import React, { FC } from 'react';
import { TopLevelGroupedActivity } from '@insights/models-nwe';
import Chart, { OwnProps as ChartProps } from '../chart';
import { ActivityChartA11y, ActivityType } from '../chart-parts/chart-a11y';

export type Props = ChartProps & { activityType: ActivityType };

export const ActivityChartWithTables: FC<Props> = ({
  data,
  dateRange,
  zoomRange,
  activityType,
  ...rest
}) => {
  return (
    <>
      <Chart
        data={data}
        dateRange={dateRange}
        zoomRange={zoomRange}
        {...rest}
      />
      {data !== undefined && data.length > 0
        ? data.map((d) => (
            <ActivityChartA11y
              data={d as TopLevelGroupedActivity}
              dateRange={zoomRange || dateRange}
              activityType={activityType}
              key={d.guid}
            />
          ))
        : null}
    </>
  );
};
