import { createReactLifecycles } from 'single-spa-workfront';
import { css } from 'emotion';
import { Root } from './root';

const lifecycles = createReactLifecycles({
  rootComponent: Root,
  rootNode: {
    type: 'div',
    className: css`
      height: 100%;
      width: 100%;
    `,
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

export default Root;