import React, { FC, useMemo } from 'react';
import { ScaleBand } from 'd3-scale';
import { GroupedActivity, MaxActivitiesByType } from '@insights/models-nwe';
import ActivityGroup, {
  Props as ActivityGroupProps,
} from '../../activity/chart-parts/activity-group';
import { getGradientScaleByType } from '../../services/color-scales/activity-color-scale';

export interface Props {
  data: GroupedActivity[];
  maxActivities: MaxActivitiesByType;
  dateScale: ScaleBand<Date>;
  categoryScale: ScaleBand<string>;
  onSegmentMouseOver: ActivityGroupProps['onSegmentMouseOver'];
  onSegmentMouseOut: ActivityGroupProps['onSegmentMouseOut'];
}

const UserActivitySection: FC<Props> = ({
  maxActivities,
  data,
  dateScale,
  categoryScale,
  onSegmentMouseOut,
  onSegmentMouseOver,
}) => {
  const gradientScales = useMemo(() => getGradientScaleByType(maxActivities), [
    maxActivities,
  ]);

  return (
    <>
      {data.map((d) => (
        <ActivityGroup
          key={d.guid}
          data={d}
          dateScale={dateScale}
          gradientScales={gradientScales}
          top={categoryScale(d.guid) || 0}
          height={categoryScale.bandwidth()}
          onSegmentMouseOver={onSegmentMouseOver}
          onSegmentMouseOut={onSegmentMouseOut}
        />
      ))}
    </>
  );
};

export default UserActivitySection;
