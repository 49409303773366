import React, { FC } from 'react';
import { ScaleLinear } from 'd3-scale';

import TeamCapacityChart, { Props as ChartProps } from '../chart';
import { TeamCapacityChartA11y } from '../chart-a11y';

interface OwnProps {
  teamName: string | null;
  height: number;
  yMax: number;
  yScale: ScaleLinear<number, number>;
  gradientId: string;
}

export type Props = OwnProps & ChartProps;

export const TeamCapacityChartWithTable: FC<Props> = ({
  teamName,
  data,
  dateRange,
  width,
  height,
  yMax,
  yScale,
  gradientId,
  zoomRange,
  onZoom,
}) => {
  const workingDateRange = zoomRange || dateRange;

  return (
    <>
      <TeamCapacityChart
        data={data}
        width={width}
        height={height}
        yMax={yMax}
        yScale={yScale}
        gradientId={gradientId}
        dateRange={dateRange}
        zoomRange={zoomRange}
        onZoom={onZoom}
      />
      {data !== undefined && data.length > 0 ? (
        <TeamCapacityChartA11y
          data={data}
          dateRange={workingDateRange}
          teamName={teamName}
        />
      ) : null}
    </>
  );
};
