import React, { FC } from 'react';
import { staticPMColors, HourGrain } from '@insights/constants-nwe';
import { useIntl, FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { ScaleQuantile } from 'd3-scale';
import { Legend, LegendBarItem } from '@insights/chart-parts-nwe';

export interface Props {
  scale: ScaleQuantile<string>;
  hourGrain: HourGrain;
}

const ProjectMagnitudeLegend: FC<Props> = ({ scale, hourGrain }) => {
  const buckets = scale.quantiles();
  const { formatNumber } = useIntl();
  const options = { minimumFractionDigits: 0, maximumFractionDigits: 2 };
  const headerMessageKey =
    hourGrain === HourGrain.plannedDuration
      ? MessageKeys.daysWorked
      : MessageKeys.hoursCompleted;

  return (
    <Legend
      header={<FormattedMessage id={headerMessageKey} />}
      testID="project-magnitude-legend-header"
    >
      <LegendBarItem
        testID="project-magnitude-legend-item1"
        color={staticPMColors[4]}
        label={`> ${formatNumber(buckets[3], options)}`}
      />
      <LegendBarItem
        testID="project-magnitude-legend-item2"
        color={staticPMColors[3]}
        label={`${formatNumber(buckets[2], options)} - ${formatNumber(
          buckets[3],
          options
        )}`}
      />
      <LegendBarItem
        testID="project-magnitude-legend-item3"
        color={staticPMColors[2]}
        label={`${formatNumber(buckets[1], options)} - ${formatNumber(
          buckets[2],
          options
        )}`}
      />
      <LegendBarItem
        testID="project-magnitude-legend-item4"
        color={staticPMColors[1]}
        label={`${formatNumber(buckets[0], options)} - ${formatNumber(
          buckets[1],
          options
        )}`}
      />
      <LegendBarItem
        testID="project-magnitude-legend-item5"
        color={staticPMColors[0]}
        label={`0 - ${formatNumber(buckets[0], options)}`}
      />
    </Legend>
  );
};

export default ProjectMagnitudeLegend;
