import { wfetch, encodeAsFormData } from '@wf-mfe/api';
import { CustomerPreferences } from 'workfront-objcodes';
import { useCancelableEffect } from '@insights/utils-nwe';

import { CustomerPreferencesState, Preferences } from './models';

export const DEFAULT_STATE: CustomerPreferencesState = {
  loading: true,
  error: undefined,
  preferences: undefined,
};

export interface Preference {
  name: string;
  value: string;
}

export const useCustomerPreferencesQuery = (
  fields: string[]
): CustomerPreferencesState => {
  const { loading, error, data } = useCancelableEffect((): Promise<
    Preferences
  > => {
    return wfetch(`/attask/api-internal/${CustomerPreferences}/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeAsFormData({
        method: 'get',
        fields: ['name', 'value'],
        filters: {
          name: fields,
        },
      }),
    }).then(processPreferences);
  }, [fields]);

  return { loading, error, preferences: data };
};

const processPreferences = (preferences: Preference[]): Preferences => {
  return preferences.reduce<Preferences>((acc, preference) => {
    acc[preference.name] = preference.value;
    return acc;
  }, {});
};
