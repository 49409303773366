import React, { FC } from 'react';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import * as styles from './styles';
import DataItem from '../data-item';
import DurationDays from '../duration-days';
import PlannedTimeframe from '../planned-timeframe';

export interface Props {
  startDate: Date;
  endDate: Date;
}

const PlannedWithDurationDays: FC<Props> = ({ startDate, endDate }) => {
  return (
    <DataItem
      labelText={
        <FormattedMessage id={MessageKeys.flightPlanPlannedTimeframe} />
      }
      displayValue={
        <>
          <PlannedTimeframe
            className={styles.display}
            startDate={startDate}
            endDate={endDate}
          />
          <DurationDays
            className={styles.durationDaysDisplay}
            startDate={startDate}
            endDate={endDate}
          />
        </>
      }
    />
  );
};

export default PlannedWithDurationDays;
