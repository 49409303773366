import React, { FC } from 'react';
import {
  MessageKeys,
  FormattedNumber,
  FormattedMessage,
  FormattedDate,
} from '@insights/i18n-nwe';
import { Task } from '@insights/models-nwe';
import {
  ChartTooltip as Tooltip,
  TooltipWithInsightsBounds,
  TooltipWithInsightsBoundsProps,
} from '@insights/tooltip-nwe';

export interface OwnProps {
  task: Task;
}

type Props = OwnProps & TooltipWithInsightsBoundsProps;

const MINUTES_IN_HOURS = 60;

const TasksInFlightTooltip: FC<Props> = ({ task, ...rest }) => {
  if (!task) return null;

  return (
    <TooltipWithInsightsBounds {...rest}>
      <Tooltip.TooltipBaseComponent
        header={<Tooltip.Header label={task.name} />}
        content={
          <>
            <Tooltip.DataItem
              labelText={<FormattedMessage id={MessageKeys.plannedHours} />}
              displayValue={
                <FormattedNumber
                  value={task.workRequired / MINUTES_IN_HOURS}
                  maximumFractionDigits={2}
                  minimumFractionDigits={0}
                />
              }
            />
            <Tooltip.DataItem
              labelText={
                <FormattedMessage id={MessageKeys.plannedCompletionDate} />
              }
              displayValue={
                <FormattedDate value={task.plannedCompletionDate} />
              }
            />
            <Tooltip.DataItem
              labelText={<FormattedMessage id={MessageKeys.percentComplete} />}
              displayValue={`${Math.round(task.percentComplete)}%`}
            />
          </>
        }
      />
    </TooltipWithInsightsBounds>
  );
};
export default TasksInFlightTooltip;
