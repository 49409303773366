import React, { FunctionComponent, memo } from 'react';
import { ScaleBand } from 'd3-scale';
import { Group } from '@vx/group';
import { scaleBand } from '@vx/scale';

import { GroupedActivity } from '@insights/models-nwe';
import { ActivityType } from '@insights/constants-nwe';

import ActivityHeatMap from '../activity-heat-map';
import { GradientScaleByType } from '../../../services/color-scales/activity-color-scale';

export const TEST_ID = 'activity-group';

export interface Props {
  data: GroupedActivity;
  top: number;
  height: number;
  dateScale: ScaleBand<Date>;
  gradientScales: GradientScaleByType;
  onSegmentMouseOver(
    event: React.MouseEvent,
    id: string,
    activity: ActivityType,
    date: Date,
    count: number
  ): void;
  onSegmentMouseOut(
    event: React.MouseEvent,
    id: string,
    activity: ActivityType,
    date: Date,
    count: number
  ): void;
  onSegmentClick?(
    event: React.MouseEvent,
    id: string,
    activity: ActivityType,
    date: Date,
    count: number
  ): void;
}

const types = Object.values(ActivityType);

const ActivityGroup: FunctionComponent<Props> = ({
  top,
  height,
  data,
  dateScale,
  gradientScales,
  onSegmentMouseOver,
  onSegmentMouseOut,
  onSegmentClick = () => null,
}) => {
  const yScale: ScaleBand<ActivityType> = scaleBand({
    rangeRound: [0, height],
    domain: types,
    paddingOuter: 0.25,
    paddingInner: 0.05,
  });

  return (
    <Group top={top} data-testid={TEST_ID}>
      {yScale.domain().map((type) => (
        <ActivityHeatMap
          key={type}
          top={yScale(type) || 0}
          height={yScale.bandwidth()}
          data={data.activities[type]}
          type={type}
          dateScale={dateScale}
          colorScale={gradientScales[type]}
          onSegmentMouseOver={(event: React.MouseEvent, ...args) =>
            onSegmentMouseOver(event, data.guid, ...args)
          }
          onSegmentMouseOut={(event: React.MouseEvent, ...args) =>
            onSegmentMouseOut(event, data.guid, ...args)
          }
          onSegmentClick={(event: React.MouseEvent, ...args) =>
            onSegmentClick(event, data.guid, ...args)
          }
        />
      ))}
    </Group>
  );
};

export default memo(ActivityGroup);
