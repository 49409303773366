import { primary } from '@phoenix/all';
import {
  ConditionType,
  isConditionType,
  LegendColors,
} from '@insights/constants-nwe';

export const colorMap: Record<ConditionType, string> = {
  [ConditionType.onTarget]: LegendColors.green,
  [ConditionType.atRisk]: LegendColors.orange,
  [ConditionType.inTrouble]: LegendColors.red,
};

export default (condition: string) => {
  if (isConditionType(condition)) {
    return colorMap[condition];
  }
  return primary.gray(300);
};
