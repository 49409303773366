import React, { FC, SVGProps } from 'react';
import { Group } from '@vx/group';
import { Line } from '@vx/shape';

export interface Props extends SVGProps<SVGLineElement> {
  startPosition: { x: number; y: number };
  endPosition: { x: number; y: number };
  color: string;
  strokeWidth?: number;
  strokeDasharray?: string;
  content(x: number, y: number): JSX.Element;
}

const Marker: FC<Props> = ({
  startPosition,
  endPosition,
  color,
  strokeWidth = 1,
  strokeDasharray = '3',
  content,
  ...rest
}) => {
  return (
    <Group top={0} left={0}>
      <Line
        from={startPosition}
        to={endPosition}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        {...rest}
      />
      {content(startPosition.x, endPosition.y)}
    </Group>
  );
};

export default Marker;
