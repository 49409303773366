import { ConditionType, ActivityType } from '@insights/constants-nwe';
import { InsightsTheme, ThemeNames } from '../constants/theme';
import { SharedColors } from './sharedColors';

export const LightMode: InsightsTheme = {
  name: ThemeNames.light,
  condition: {
    [ConditionType.onTarget]: SharedColors.green.primary400,
    [ConditionType.atRisk]: SharedColors.yellow.primary400,
    [ConditionType.inTrouble]: SharedColors.red.primary400,
  },
  backgroundColors: {
    card: SharedColors.white.primary,
    background: SharedColors.grey.primary50,
    lineBreak: SharedColors.grey.primary500,
    infoIcon: SharedColors.white.primary,
  },
  text: {
    link: SharedColors.blue.primary400,
    chartText: SharedColors.blue.primary400,
    primary: SharedColors.grey.primary500,
    header: SharedColors.grey.primary500,
    todayMarker: SharedColors.white.primary,
  },
  flightPlanChart: {
    plan: SharedColors.blue.primary100,
  },
  burndown: {
    actual: SharedColors.blue.primary50,
  },
  activityChart: {
    [ActivityType.login]: [
      SharedColors.purple.primary100,
      SharedColors.purple.primary200,
      SharedColors.purple.primary400,
      SharedColors.purple.primary500,
    ],
    [ActivityType.taskStatusChange]: [
      SharedColors.pink.primary100,
      SharedColors.pink.primary200,
      SharedColors.pink.primary400,
      SharedColors.pink.primary500,
    ],
    [ActivityType.tasksCompleted]: [
      SharedColors.navy.primary100,
      SharedColors.navy.primary200,
      SharedColors.navy.primary400,
      SharedColors.navy.primary500,
    ],
  },
  treemapChart: {
    staticColors: [
      SharedColors.blue.primary50,
      SharedColors.blue.primary100,
      SharedColors.blue.primary400,
      SharedColors.blue.primary500,
      SharedColors.blue.primary600,
    ],
  },
};
