import React, { FC, MouseEvent } from 'react';
import styled from 'react-emotion';
import { alerts } from '@phoenix/all';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';

export interface Props {
  onClick(e: MouseEvent): void;
  disabled?: boolean;
}

export const TEST_ID_APPLY_FILTER_BTN = 'apply-filter-btn';

export const ApplyButton: FC<Props> = ({ onClick, disabled = false }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      data-testid={TEST_ID_APPLY_FILTER_BTN}
      onClick={disabled ? () => null : onClick}
      aria-disabled={disabled}
      isDisabled={disabled}
      aria-label={formatMessage({ id: MessageKeys.applyFilter })}
    >
      <FormattedMessage id={MessageKeys.applyFilter} />
    </Button>
  );
};

const Button = styled('button')(({ isDisabled }: { isDisabled?: boolean }) => ({
  width: '100%',
  height: '32px',
  backgroundColor: isDisabled ? '#ADAFB1' : alerts.info(),
  cursor: isDisabled ? 'not-allowed' : 'pointer',
  textAlign: 'center',
  margin: 'auto',
  color: 'white',
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  fontWeight: 'bold',
  border: 'none',
}));
