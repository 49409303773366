import { Dispatch, bindActionCreators } from 'redux';
import {
  connect,
  RootState,
  getPaginationResultsLimit,
  getPaginationOffset,
  getPaginationPageNumber,
  setOffsetByPageNumber,
} from '@insights/store-nwe';
import { PaginationFooter, Props } from './pagination-footer';

type StateProps = Pick<Props, 'pageOffset' | 'limit' | 'currentPageNumber'>;
type DispatchProps = Pick<Props, 'setPage'>;

export default connect<StateProps, DispatchProps, Props>(
  (state: RootState) => ({
    pageOffset: getPaginationOffset(state),
    limit: getPaginationResultsLimit(state),
    currentPageNumber: getPaginationPageNumber(state),
  }),
  (dispatch: Dispatch): DispatchProps =>
    bindActionCreators(
      {
        setPage: (value: number) => setOffsetByPageNumber(value),
      },
      dispatch
    )
)(PaginationFooter);
