import { wfetch } from '@wf-mfe/api';
import { CustomEnum } from 'workfront-objcodes';
import { useCancelableEffect } from '@insights/utils-nwe';

import {
  ProjectConditionsState,
  ConditionsMap,
  CustomEnumInfo,
} from '../models';

export const DEFAULT_STATE: ProjectConditionsState = {
  loading: true,
  error: undefined,
  data: undefined,
};

export interface Preference {
  name: string;
  value: string;
}

export const useProjectConditionsQuery = (): ProjectConditionsState => {
  return useCancelableEffect((): Promise<ConditionsMap> => {
    return wfetch(
      `/attask/api-internal/${CustomEnum}/projectConditions?includeHidden=true`
    ).then(processConditions);
  }, []);
};

export const processConditions = (
  conditions: CustomEnumInfo[]
): ConditionsMap =>
  conditions.reduce<ConditionsMap>((acc, customEnum) => {
    acc.set(customEnum.value, customEnum);
    return acc;
  }, new Map());
