import { MessageKeys } from '@insights/i18n-nwe';
import { ChartType } from '@insights/constants-nwe';

export const chartTitleToMesasgeKey: { [key in ChartType]: MessageKeys } = {
  [ChartType.Burndown]: MessageKeys.burndownExportPng,
  [ChartType.FlightPlan]: MessageKeys.flightPlanExportPng,
  [ChartType.PeopleActivity]: MessageKeys.peopleActivityExportPng,
  [ChartType.ProjectActivity]: MessageKeys.projectActivityExportPng,
  [ChartType.ProjectTreemap]: MessageKeys.projectTreemapExportPng,
  [ChartType.ResourceCapacity]: MessageKeys.resourceCapacityExportPng,
  [ChartType.TasksInFlight]: MessageKeys.tasksInFlightExportPng,
  [ChartType.TeamCapacity]: MessageKeys.teamCapacityExportPng,
};
