import React, { FC } from 'react';
import { Tooltip } from '@vx/tooltip';
import { useFormattedDate } from '@insights/i18n-nwe';
import * as styles from './styles';

export interface Props {
  top: number;
  left: number;
  date: Date;
}

const DateTooltip: FC<Props> = ({ top, left, date }) => {
  const formattedDate = useFormattedDate(date);

  return (
    <Tooltip
      key={Math.random()}
      top={top - styles.TOOLTIP_TOP}
      left={left - styles.TOOLTIP_WIDTH / 2}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        background: '#fff',
        height: '24px',
        width: styles.TOOLTIP_WIDTH,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className={styles.tooltip}>{formattedDate}</div>
    </Tooltip>
  );
};

export default DateTooltip;
