import React, { FC } from 'react';
import { ParentSize } from '@vx/responsive';
import { Omit } from 'utility-types';
import { TopLevelGroupedActivity } from '@insights/models-nwe';
import memoizeOne from 'memoize-one';
import {
  ActivityLabels,
  ACTIVITY_LABELS_TO_MESSAGE_KEY,
  ChartType,
} from '@insights/constants-nwe';
import { MessageKeys, FormattedMessage, useIntl } from '@insights/i18n-nwe';
import { CardLayout } from '@insights/shared-components-nwe';

import { orderedProjects } from '../../services/alphabetize-projects';
import { OwnProps as ChartProps } from '../../activity/chart';
import Header from '../header';
import Legend from '../../activity/legend';
import GetPeopleActivity, {
  Props as QueryProps,
} from '../queries/get-people-activity';
import { mapMaxActivities } from '../../services/activity';
import { shapeTeamActivityDataForXLSX } from '../../services/xlsx/team-activity';
import { ActivityChartWithTables } from '../../activity/chart-with-tables';

type Props = Omit<ChartProps, 'data' | 'maxActivities' | 'label'> &
  Omit<QueryProps, 'children' | 'startDate' | 'endDate'>;

const orderProjects = memoizeOne(orderedProjects);
const CHART_TYPE = ChartType.PeopleActivity;

export const RenderChart: FC<Props> = (props) => {
  const { projectIds, teamIds, dateRange, renderUsers } = props;
  const { formatMessage } = useIntl();

  return (
    <GetPeopleActivity
      // @TODO we should remove projectIds as a prop once the service no longer requires this field for this query. projectIds aren't techincally used anymore even though it's required.
      projectIds={projectIds}
      teamIds={teamIds}
      startDate={dateRange.start}
      endDate={dateRange.end}
    >
      {({ data, loading, error }) => {
        const teams = data.TEAMS;
        const maxActivitiesCount = mapMaxActivities(data);

        const getData = () =>
          shapeTeamActivityDataForXLSX(
            formatMessage(
              { id: MessageKeys.peopleActivityExportXlsx },
              { startDate: dateRange.start, endDate: dateRange.end }
            ),
            {
              date: formatMessage({ id: MessageKeys.date }),
              teamID: formatMessage({ id: MessageKeys.teamID }),
              teamName: formatMessage({ id: MessageKeys.teamName }),
              numberOfLogins: formatMessage({
                id: MessageKeys.activityHeaderLogins,
              }),
              numberOfTaskStatusChanges: formatMessage({
                id: MessageKeys.activityHeaderStatusChanges,
              }),
              numberOfTasksCompleted: formatMessage({
                id: MessageKeys.activityHeaderTasksCompleted,
              }),
            },
            data,
            dateRange
          );

        const chart = () => {
          if (loading) return <FormattedMessage id={MessageKeys.loading} />;
          if (error) return <FormattedMessage id={MessageKeys.error} />;

          return (
            <ParentSize>
              {({ width }: { width: number; height: number }) => (
                <ActivityChartWithTables
                  {...props}
                  activityType={CHART_TYPE}
                  data={orderProjects(teams)}
                  maxActivities={maxActivitiesCount}
                  dateRange={dateRange}
                  width={width}
                  label={
                    ACTIVITY_LABELS_TO_MESSAGE_KEY[
                      ActivityLabels.peopleActivityLabel
                    ]
                  }
                  getChildren={(d: TopLevelGroupedActivity) => d.users}
                  renderUsers={renderUsers}
                />
              )}
            </ParentSize>
          );
        };

        return (
          <CardLayout
            title={CHART_TYPE}
            header={<Header />}
            label={formatMessage({ id: MessageKeys.teamActivity })}
            legend={<Legend />}
            chart={chart()}
            getData={getData}
            disableExport={!(teams.length > 0) || loading || !!error}
          />
        );
      }}
    </GetPeopleActivity>
  );
};
