import React, { FC } from 'react';
import styled from 'react-emotion';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import {
  ContentHolderComponent,
  CardComponent,
} from '@insights/shared-components-nwe';
import { primary } from '@phoenix/all';
import AlertIcon from 'phoenix-icons/dist/AlertIcon.js';

const TeamsZeroState: FC = () => {
  return (
    <ContentHolderComponent>
      <CardComponent>
        <ZeroState
          role="dialog"
          aria-live="polite"
          aria-describedby="zero-state-msg"
          tabIndex={0}
        >
          <AlertIcon width={32} color={primary.gray(400)} />
          <ZeroStateText id="zero-state-msg" data-testid="people-zero-state">
            <FormattedMessage id={MessageKeys.TeamFilter} />
          </ZeroStateText>
        </ZeroState>
      </CardComponent>
    </ContentHolderComponent>
  );
};

const ZeroState = styled.section`
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ZeroStateText = styled.p`
  color: ${primary.gray(400)};
  margin-top: 11px;
  font-size: 16px;
`;

export default TeamsZeroState;
