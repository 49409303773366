import styled from 'react-emotion';

export const Header = styled.header`
  display: flex;
`;

export const Heading = styled.h4`
  font-size: 16px;
  font-weight: 400;
`;
