import {
  Project,
  ProjectHistoricalCondition as Condition,
} from '@insights/models-nwe';

export type ProjectFragment = Pick<Project, 'guid' | 'actualcompletiondate'> & {
  [key: string]: unknown;
};

export type ConditionFragment = Pick<
  Condition,
  'conditionchangetimestamp' | 'projectguid' | 'condition'
>;

export interface ConditionsByProject<T extends ConditionFragment> {
  [projectId: string]: (T & { conditionChangeEnd: Date })[];
}

const groupByProject = <T extends ConditionFragment>(
  data: T[]
): { [projectId: string]: T[] } =>
  data.reduce<{ [projectId: string]: T[] }>((acc, datum) => {
    const id = datum.projectguid;

    if (Object.prototype.hasOwnProperty.call(acc, id)) {
      acc[id] = [...acc[id], datum];
    } else {
      acc[id] = [datum];
    }

    return acc;
  }, {});

const addEndDate = <T extends ConditionFragment>(
  data: T[],
  project: ProjectFragment | undefined
): (T & { conditionChangeEnd: Date })[] =>
  data.map((datum, index) => {
    if (index < data.length - 1) {
      return {
        ...datum,
        conditionChangeEnd: data[index + 1].conditionchangetimestamp,
      };
    }
    return {
      ...datum,
      conditionChangeEnd:
        project && project.actualcompletiondate
          ? project.actualcompletiondate
          : new Date(),
    };
  });

export const getConditionTimeline = <T extends ConditionFragment>(
  data: T[],
  projects: ProjectFragment[]
): ConditionsByProject<T> => {
  const groupedByProject = groupByProject(data);

  return Object.entries(groupedByProject).reduce<ConditionsByProject<T>>(
    (acc, [id, conditions]) => {
      const project = projects.find((p) => p.guid === id);
      acc[id] = addEndDate(conditions, project);

      return acc;
    },
    {}
  );
};
