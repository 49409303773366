import { SpreadSheetData, DailyTeamCapacity } from '@insights/models-nwe';

interface Headers {
  date: string;
  availableHours: string;
  scheduledHours: string;
}

export const shapeData = (
  headers: Headers,
  teamCapacityForDateRange: DailyTeamCapacity[]
) => {
  return teamCapacityForDateRange.map((data: DailyTeamCapacity) => {
    return {
      [headers.date]: data.date,
      [headers.availableHours]: data.availableHours,
      [headers.scheduledHours]: data.scheduledHours,
    };
  });
};

export const shapeTeamCapacityDataForXLSX = (
  name: string,
  headers: Headers,
  chartData: DailyTeamCapacity[]
): SpreadSheetData => {
  const teamCapacityTemplate = shapeData(headers, chartData);
  return {
    data: {
      data: teamCapacityTemplate,
    },
    name,
  };
};
