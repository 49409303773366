import React, { Component, MouseEvent, ComponentClass } from 'react';
import memoize from 'memoize-one';
import { Group } from '@vx/group';
import { withBrush, getCoordsFromEvent, constrainToRegion } from '@vx/brush';
import { withTooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import { primary } from '@phoenix/all';
import { Overwrite } from 'utility-types';
import { ScaleBand } from 'd3-scale';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

import {
  DateRange,
  Extent,
  Margin,
  TooltipProps,
  HoverState,
  BrushProps,
  ProjectFragment,
} from '@insights/models-nwe';
import {
  ActivityLabels,
  Orientation,
  ACTIVITY_LABELS_TO_MESSAGE_KEY,
  ChartType,
} from '@insights/constants-nwe';
import { ChartMargins, LabelOffset } from '@insights/styles-nwe';
import {
  TodayMarker,
  DateMarker,
  ScrollYOverlay,
  GridRows,
  DateAxis,
  CategoryAxis,
  BrushHighlight,
  AxisLabel,
} from '@insights/chart-parts-nwe';
import {
  ZeroState,
  shouldZoom,
  getExtent,
  getDateScale,
  getCategoryScale,
  transformCoords,
} from '@insights/shared-components-nwe';

import { getConditionTimeline } from '../utils/timeline';

import ProjectBars, { GROUP_HEIGHT } from './chart-parts/project-bars';

import ProjectDetails from './chart-parts/project-details';
import { ConditionFragment } from '../queries/get-conditions/models';

enum TooltipType {
  chart = 'chart',
  label = 'label',
}

export interface OwnProps {
  width: number;
  height: number;
  margin: Margin;
  projects: ProjectFragment[];
  conditions: ConditionFragment[];
  zoomRange?: DateRange;
  dateRange: DateRange;
  selectedProject?: string;
  onProjectClick(guid: string, name: string): void;
  onZoom(range: DateRange): void;
}

type DefaultProps = Pick<Props, 'width' | 'height' | 'margin'>;

type Props = OwnProps &
  BrushProps &
  TooltipProps<LabelTooltipProps | ChartTooltipProps>;

export interface LabelTooltipProps {
  type: TooltipType.label;
}

export interface ChartTooltipProps {
  type: TooltipType.chart;
  date: Date;
  condition: string | null;
  conditionLabel: string | null;
  data?: ProjectFragment;
}

export interface State {
  activeProjectId?: string;
  activeProjectName?: string;
}

export class FlightPlanChart extends Component<Props, State> {
  state = {
    activeProjectId: undefined,
  };

  svg = React.createRef<SVGSVGElement>();

  static defaultProps: DefaultProps = {
    width: 800,
    height: 200,
    margin: ChartMargins,
  };

  getConditionTimeline = memoize(getConditionTimeline);

  getCategoryScale = memoize<
    (
      data: ProjectFragment[],
      key: keyof ProjectFragment,
      height: number
    ) => ScaleBand<string>
  >(getCategoryScale);

  getDateScale = memoize(getDateScale);

  getExtent = memoize(getExtent);

  componentDidUpdate(prevProps: Props): void {
    const { brush } = this.props;

    if (brush.domain && brush.domain !== prevProps.brush.domain)
      this.handleZoomChange(brush.domain);
  }

  private getHoverState(id: string): HoverState {
    const { activeProjectId } = this.state;
    const { selectedProject } = this.props;

    if (!activeProjectId && !selectedProject) return HoverState.default;

    return activeProjectId === id || (selectedProject && selectedProject === id)
      ? HoverState.active
      : HoverState.inactive;
  }

  private getXMax(): number {
    const { width, margin } = this.props;

    return width > 0 ? width - margin.left - margin.right : 0;
  }

  private getYMax(): number {
    const { height, margin } = this.props;

    return height - margin.top - margin.bottom;
  }

  private getConstrainedCoords(event: MouseEvent) {
    const { width, height, margin } = this.props;
    const region = this.getExtent(width, height, margin);
    const { x, y } = transformCoords(
      getCoordsFromEvent(this.svg.current, event),
      margin
    );

    return constrainToRegion({ region, x, y });
  }

  handleZoomChange = ({ x0, x1 }: Extent) => {
    const { onZoom, zoomRange } = this.props;
    const check = shouldZoom(x0, x1, zoomRange);

    if (check) {
      onZoom({
        start: this.invertX(x0),
        end: this.invertX(x1),
      });
    }
  };

  handleProjectHover = (projectId: string) => {
    this.setState(() => ({
      activeProjectId: projectId,
    }));
  };

  // type MouseEvent does not include getBoundingClientRect which is why event type is any.
  // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/12239
  handleAxisMouseMove = (event: any, data: ProjectFragment) => {
    if (!event.target.getBoundingClientRect) return;
    const { showTooltip } = this.props;
    const clientRect = event.target.getBoundingClientRect();
    showTooltip({
      tooltipData: { type: TooltipType.label },
      tooltipLeft: clientRect.width,
      tooltipTop: clientRect.top,
    });
  };

  handleProjectOffHover = () =>
    this.setState(() => ({
      activeProjectId: undefined,
    }));

  private handleOverlayMouseDown = (e: MouseEvent) => {
    const { onBrushStart } = this.props;
    onBrushStart(this.getConstrainedCoords(e));
  };

  private handleOverlayMouseMove = (e: MouseEvent) => {
    this.handleTooltip(e);
    this.updateBrush(e);
  };

  private handleOverlayMouseUp = (e: MouseEvent) => {
    const { onBrushEnd, onBrushReset, brush } = this.props;
    if (brush.end) onBrushEnd(this.getConstrainedCoords(e));
    else onBrushReset();
  };

  handleProjectClick = (id: string): void => {
    const { brush } = this.props;
    if (brush.end === undefined)
      this.props.onProjectClick(id, this.getProjectName(id));
  };

  private updateBrush = (e: MouseEvent) => {
    const { brush, onBrushDrag } = this.props;
    if (!brush.isBrushing) return;

    onBrushDrag(this.getConstrainedCoords(e));
  };

  getProjectName = (guid: string): string => {
    const selectedProject = this.props.projects.find(
      (p): boolean => p.guid === guid
    );
    return selectedProject ? selectedProject.name : 'Unknown';
  };

  hoverCondition = (data: ProjectFragment | undefined, hoverDate: Date) => {
    const { conditions, projects } = this.props;
    const conditionsByProject = this.getConditionTimeline(conditions, projects);
    const currentConditions = data && conditionsByProject[data.guid];

    const change =
      currentConditions &&
      currentConditions.find((i) => {
        return (
          hoverDate > i.conditionchangetimestamp &&
          hoverDate < i.conditionChangeEnd
        );
      });

    return {
      condition: change?.condition ?? null,
      conditionLabel: change?.conditionlabel ?? null,
    };
  };

  private handleTooltip = (
    event: React.MouseEvent<any>,
    data?: ProjectFragment
  ) => {
    const { showTooltip, margin, brush } = this.props;
    const { x, y } = localPoint(event);
    const hoverDate = this.invertX(x - margin.left);
    const condition = this.hoverCondition(data, hoverDate);

    showTooltip({
      tooltipData: {
        date: hoverDate,
        data,
        ...condition,
        type: 'chart' as TooltipType.chart,
      },
      tooltipLeft: x,
      tooltipTop: y,
    });
    if (data && !brush.isBrushing) {
      // Doing this because handleTooltip is called by overlay as well as project bars and could collide
      event.stopPropagation();
    }
  };

  private invertX(x: number): Date {
    const { dateRange, zoomRange } = this.props;
    const dateScale = this.getDateScale(this.getXMax(), dateRange, zoomRange);
    return dateScale.invert(x);
  }

  render() {
    const {
      conditions,
      projects,
      dateRange,
      zoomRange,
      width,
      height,
      margin,
      tooltipOpen,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      hideTooltip,
      brush,
      selectedProject,
    } = this.props;
    const { activeProjectId } = this.state;
    // @TODO remove when we start getting condition end date from Datalake
    const conditionData = this.getConditionTimeline(conditions, projects);
    const yMax = this.getYMax();
    const xMax = this.getXMax();
    const scrollHeight = projects.length * GROUP_HEIGHT;
    const categoryScale = this.getCategoryScale(projects, 'guid', scrollHeight);
    const dateScale = this.getDateScale(xMax, dateRange, zoomRange);
    const DateRangeWithin = projects.filter((i) => {
      return (
        (i.plannedstartdate >= dateRange.start &&
          i.plannedstartdate <= dateRange.end) ||
        (dateRange.start >= i.plannedstartdate &&
          dateRange.start <= i.plannedcompletiondate)
      );
    });

    if (DateRangeWithin.length === 0)
      return (
        <ZeroState testID="flightplan-chart-">
          <FormattedMessage id={MessageKeys.ZeroMessage} />
        </ZeroState>
      );

    return (
      <>
        <svg
          width={width}
          height={height}
          fill="white"
          ref={this.svg}
          id={ChartType.FlightPlan}
          fontFamily="Proxima Nova"
          overflow="hidden"
        >
          <Group
            top={margin.top}
            left={margin.left}
            data-testid="flightplan-chart"
            onMouseLeave={this.handleProjectOffHover}
          >
            <DateAxis top={yMax} scale={dateScale} />
            <AxisLabel
              label={
                ACTIVITY_LABELS_TO_MESSAGE_KEY[
                  ActivityLabels.projectActivityLabel
                ]
              }
              labelOffset={LabelOffset}
              orientation={Orientation.left}
              range={[0, yMax]}
            />
            <ScrollYOverlay
              width={xMax + margin.left}
              height={yMax}
              x={-LabelOffset}
              scrollHeight={scrollHeight}
            >
              <GridRows width={xMax} scale={categoryScale} />
              <Group
                onMouseOver={this.handleAxisMouseMove}
                onMouseLeave={hideTooltip}
              >
                <CategoryAxis
                  scale={categoryScale}
                  tickFormat={this.getProjectName}
                  onMouseOver={this.handleProjectHover}
                  onClick={this.handleProjectClick}
                  activeLabel={selectedProject || activeProjectId}
                />
              </Group>
              <Group
                onMouseDown={this.handleOverlayMouseDown}
                onMouseMove={this.handleOverlayMouseMove}
                onMouseUp={this.handleOverlayMouseUp}
                onMouseLeave={hideTooltip}
                data-testid="flightplan-overlay"
              >
                {projects.map((project) => {
                  return (
                    <ProjectBars
                      key={project.guid}
                      height={categoryScale.bandwidth()}
                      top={categoryScale(project.guid) || 0}
                      dateScale={dateScale}
                      project={project}
                      conditions={conditionData[project.guid]}
                      hoverState={this.getHoverState(project.guid)}
                      onMouseOver={this.handleProjectHover}
                      onMouseOut={this.handleProjectOffHover}
                      onClick={this.handleProjectClick}
                      handleTooltip={this.handleTooltip}
                      hideTooltip={hideTooltip}
                    />
                  );
                })}
              </Group>
            </ScrollYOverlay>
            <TodayMarker
              dateScale={dateScale}
              height={yMax}
              color={primary.blue()}
            />
            <BrushHighlight brush={brush} yMax={yMax} dateScale={dateScale} />
            {tooltipOpen &&
              tooltipData.type === TooltipType.chart &&
              tooltipData.date && (
                <DateMarker
                  dateScale={dateScale}
                  date={tooltipData.date}
                  height={yMax}
                />
              )}
          </Group>
        </svg>
        {tooltipOpen &&
          tooltipData.type === TooltipType.chart &&
          tooltipData.data && (
            <ProjectDetails
              top={tooltipTop}
              left={tooltipLeft}
              offsetLeft={6}
              project={tooltipData.data}
              condition={tooltipData.condition}
              conditionLabel={tooltipData.conditionLabel}
            />
          )}
      </>
    );
  }
}

const WrappedComponent: ComponentClass<Overwrite<
  OwnProps,
  Partial<DefaultProps>
>> = withBrush(withTooltip(FlightPlanChart));

export default WrappedComponent;
