import styled from 'react-emotion';

interface Props {
  color: string;
}

export default styled('div')(({ color }: Props) => ({
  backgroundColor: '#999999',
  height: '70%',
  width: '2px',
  marginRight: '18px',
  marginLeft: '4px',
}));
