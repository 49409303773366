import React, { useReducer, useRef } from 'react';
import { TOOLTIP_FADE_TIMEOUT } from '@phoenix/all';

import * as Actions from './actions';
import reducer from './reducer';

export const DELAY = 500;

type TimeoutRef = ReturnType<typeof setTimeout> | null;

export const useTooltip = () => {
  const [tooltipState, dispatch] = useReducer(reducer, {
    show: false,
    fade: false,
  });
  const delayCloseTimer = useRef<TimeoutRef>(null);
  const delayOpenTimer = useRef<TimeoutRef>(null);
  const fadeTimer = useRef<TimeoutRef>(null);

  const clearTimers = () => {
    if (delayCloseTimer.current) clearTimeout(delayCloseTimer.current);
    if (delayOpenTimer.current) clearTimeout(delayOpenTimer.current);
    if (fadeTimer.current) clearTimeout(fadeTimer.current);
  };

  const showTooltip = (e: React.MouseEvent | React.FocusEvent) => {
    clearTimers();
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch(Actions.showTooltip());
  };

  const hideTooltip = (e?: React.MouseEvent | React.FocusEvent) => {
    clearTimers();
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch(Actions.startFade());
    fadeTimer.current = setTimeout(
      () => dispatch(Actions.hideTooltip()),
      TOOLTIP_FADE_TIMEOUT
    );
  };

  const delayHideTooltip = (e: React.MouseEvent | React.FocusEvent) => {
    clearTimers();
    e.preventDefault();
    e.stopPropagation();
    delayCloseTimer.current = setTimeout(hideTooltip, DELAY);
  };

  const delayShowTooltip = (e: React.MouseEvent | React.FocusEvent) => {
    clearTimers();
    e.preventDefault();
    e.stopPropagation();
    delayOpenTimer.current = setTimeout(showTooltip, DELAY);
  };

  return {
    tooltipState,
    showTooltip,
    hideTooltip,
    delayHideTooltip,
    delayShowTooltip,
  };
};
