import {
  ProjectFragment,
  ProjectHistoricalCondition,
} from '@insights/models-nwe';
import { indexify } from '@insights/shared-components-nwe';
import { ConditionFragment } from '../../queries/get-conditions/models';

type FormattedHistoricalData = ConditionFragment & {
  name: string;
};

export const formatHistoricalProjectData = (
  historicalProjects: ConditionFragment[],
  projects: ProjectFragment[]
): FormattedHistoricalData[] => {
  const getProjectGuid = (d: ProjectFragment) => d.guid;
  const dataByProjectGuid = indexify(projects, getProjectGuid);

  return historicalProjects.map((project: ProjectHistoricalCondition) => {
    return {
      ...project,
      name: dataByProjectGuid[project.projectguid]?.name,
    };
  });
};
