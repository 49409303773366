import { ChartType } from '@insights/constants-nwe';

export const ZOOM_PENDO_EVENT: { [key: string]: string } = {
  [ChartType.FlightPlan]: 'Analytics > Flight Plan > Zoom',
  [ChartType.Burndown]: 'Analytics > Burndown > Zoom',
  [ChartType.TasksInFlight]: 'Analytics > Tasks in flight > Zoom',
  [ChartType.ProjectActivity]: 'Analytics > Project activity > Zoom',
  [ChartType.PeopleActivity]: 'Analytics > People activity > Zoom',
  [ChartType.TeamCapacity]: 'Analytics > Team capacity > Zoom',
};
