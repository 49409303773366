import React, { FC } from 'react';
import { cx } from 'emotion';

import * as styles from './styles';

export interface Props {
  maxHeight: number;
  expanded?: boolean;
  className?: string;
  duration?: number;
}

const Collapse: FC<Props> = ({
  expanded,
  maxHeight,
  className,
  children,
  duration = 800,
  ...props
}) => (
  <div
    className={cx(styles.collapsePanel, className, { expanded })}
    style={{
      maxHeight: expanded ? maxHeight : 0,
      transitionDuration: `${duration}ms`,
      transitionDelay: `${expanded ? '0' : duration * 0.1}ms`,
    }}
    data-testid="root"
    {...props}
  >
    {children}
  </div>
);

export default Collapse;

Collapse.defaultProps = {
  expanded: false,
};
