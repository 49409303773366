import React from 'react';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';
import { ChartType } from '@insights/constants-nwe';
import { CardLayout, ZeroState } from '@insights/shared-components-nwe';

import Header from '../header';
import Legend from '../../activity/legend';
import * as styles from '../../activity/check-range/styles';

export const ChartDateRangeWarning = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <CardLayout
      title={ChartType.ProjectActivity}
      header={<Header />}
      legend={<Legend />}
      label={formatMessage({ id: MessageKeys.projectActivity })}
      chart={
        <div className={styles.zero}>
          <ZeroState testID="range-">
            <FormattedMessage id={MessageKeys.ninetyTwoDayMax} />
          </ZeroState>
        </div>
      }
      disableExport
    />
  );
};
