import { FilterOption } from '@insights/models-nwe';

export const getLastTwo = (arr: FilterOption[]): string =>
  `${arr[0].label} and ${arr[1].label}`;
export const getLastTwoMore = (arr: FilterOption[]): string =>
  `${arr[0].label}, and ${arr[1].label}`;

export const defineFilterValues = (values: FilterOption[]): string => {
  const { length } = values;

  if (length === 1) return values[0].label;
  else if (length === 2) return getLastTwo(values);
  else if (length > 2) {
    const valuesCopy = [...values];
    const lastTwo = getLastTwoMore(valuesCopy.splice(-2, 2));
    return (
      valuesCopy.reduce((acc, value) => `${acc + value.label}, `, '') + lastTwo
    );
  }

  return '';
};
