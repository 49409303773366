import React, { FC, useMemo } from 'react';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import { FilterOption } from '@insights/models-nwe';
import { formatItemTestId } from '@insights/utils-nwe';
import styled from 'react-emotion';
import { LoadingSpinner } from '../loading-spinner';
import { ApplyButton } from '../apply-button';
import { MultiSelectOption } from '../multi-select-option';
import { AriaMenuProps, AriaOptionProps } from '../../hooks/useComboBox';
import { SELECT_ALL_FILTER_OPTION, hasValue } from '../../services';

const DIALOG_ID = 'alert-dialog';

export interface MultiSelectContainerProps {
  options: FilterOption[];
  loading: boolean;
  error: boolean;
  called?: boolean;
  requiresSearchInput?: boolean;
  selectedOptions: FilterOption[];
  ariaMenuProps?: AriaMenuProps | {};
  onApply: () => void;
  ariaOptionProps?(item: FilterOption): AriaOptionProps | null;
}

export type MultiSelectProps = Pick<
  MultiSelectContainerProps,
  'options' | 'selectedOptions' | 'ariaMenuProps' | 'ariaOptionProps'
>;

export const checkOptionsSelected = (
  options: FilterOption[],
  selectedOptions: FilterOption[]
): boolean => {
  const filterOptions = options.filter(
    (option) => option.value !== SELECT_ALL_FILTER_OPTION.value
  );
  const selectedOptionValues = selectedOptions.map((option) => option.value);
  return (
    filterOptions.find(
      (option) => !selectedOptionValues.includes(option.value)
    ) === undefined
  );
};

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  selectedOptions = [],
  ariaMenuProps = {},
  ariaOptionProps = () => null,
}) => {
  const selectAllChecked = useMemo(
    () => checkOptionsSelected(options, selectedOptions),
    [options, selectedOptions, checkOptionsSelected]
  );

  return (
    <OptionsContainer
      role="listbox"
      aria-multiselectable="true"
      {...ariaMenuProps}
    >
      {options.map((option) => {
        const isSelectAll = option.value === SELECT_ALL_FILTER_OPTION.value;
        const checked = isSelectAll
          ? selectAllChecked
          : hasValue(selectedOptions, option);
        const label = isSelectAll ? (
          <SelectAllLabel>
            <FormattedMessage id={MessageKeys.selectAll} />
          </SelectAllLabel>
        ) : (
          option.label
        );
        const testID = `select-item-${formatItemTestId(option.label)}`;

        return (
          <MultiSelectOption
            option={option}
            checked={checked}
            label={label}
            testID={testID}
            ariaOptionProps={ariaOptionProps}
            key={option.value}
          />
        );
      })}
    </OptionsContainer>
  );
};

export const MultiSelectContainer: FC<MultiSelectContainerProps> = ({
  options,
  loading,
  error,
  called = true,
  requiresSearchInput = false,
  selectedOptions,
  onApply,
  ariaMenuProps,
  ariaOptionProps = () => null,
}) => {
  let displayMessage = null;

  if (error) {
    displayMessage = (
      <Message id={DIALOG_ID}>
        <FormattedMessage id={MessageKeys.error} />
      </Message>
    );
  } else if (loading || (!called && !requiresSearchInput)) {
    displayMessage = <LoadingSpinner />;
  } else if (requiresSearchInput) {
    displayMessage = (
      <Message id={DIALOG_ID}>
        <FormattedMessage id={MessageKeys.filteringThreeCharLimit} />
      </Message>
    );
  } else if (options.length === 0) {
    displayMessage = (
      <Message id={DIALOG_ID}>
        <FormattedMessage id={MessageKeys.ZeroFilters} />
      </Message>
    );
  }

  return displayMessage ? (
    <MessageContainer
      role="dialog"
      aria-live="polite"
      aria-labelledby={DIALOG_ID}
    >
      {displayMessage}
    </MessageContainer>
  ) : (
    <>
      <MultiSelect
        options={options}
        selectedOptions={selectedOptions}
        ariaMenuProps={ariaMenuProps}
        ariaOptionProps={ariaOptionProps}
      />
      <ApplyButton onClick={onApply} disabled={selectedOptions.length === 0} />
    </>
  );
};

const OptionsContainer = styled.ul`
  width: 100%;
  padding: 10px 0px;
  height: 180px;
  overflow: scroll;
  position: relative;

  /* The "Licenses" feature of WF uses injectGlobal to pollute global styles
      with their own styling. One of the selectors is for ALL elements with role="listbox",
      AND it has a !important. To keep this selector from messing with our listbox, we need
      a more specific one (class + attribute selector) AND a !important of our own.
      Once this is fixed by the team that owns the "Licenses" feature, we can remove this
      See https://hub.workfront.com/issue/5fd25bde00028b4c79607abf958c1cf3/overview */
  &[role='listbox'] {
    top: 0 !important;
  }

  ::-webkit-scrollbar {
    height: 0px;
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #FOFOFO;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    height: 6px;
    background: #d8d8d8;
    width: 5px;
    border-radius: 7px;
  }
`;

const SelectAllLabel = styled.span`
  font-weight: bold;
`;

const Message = styled.div`
  font-size: 12px;
  font-family: Proxima Nova;
  color: #333;
  font-weight: bold;
`;

const MessageContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
