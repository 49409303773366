import { getType } from 'typesafe-actions';

import Actions from '../../actions';
import { setCurrentView } from '../../actions/app';

export type State = string | undefined;

export const INITIAL_STATE: State = '';

export default (state: State = INITIAL_STATE, action: Actions): State => {
  switch (action.type) {
    case getType(setCurrentView):
      return action.payload;
    default:
      return state;
  }
};

export const getCurrentView = (state: State) => state;
