import { css } from 'emotion';
import { primary } from '@phoenix/all';

export const tickLabel = css`
  fill: #999;
  font-size: 12px;
`;

export const activeTickLabel = css`
  fill: ${primary.blue()};
  font-weight: bold;
  letter-spacing: -0.2px;
`;
