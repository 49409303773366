import React, { FC, useMemo } from 'react';
// vx
import { ParentSize } from '@vx/responsive';
import { ApolloError } from 'apollo-client';

import { DateRange, ProjectMagnitudeChartDatum } from '@insights/models-nwe';
import {
  HourGrain,
  HOUR_GRAIN_TO_MESSAGE_KEY,
  ChartType,
} from '@insights/constants-nwe';
import { MessageKeys, FormattedMessage, useIntl } from '@insights/i18n-nwe';
import { CardLayout } from '@insights/shared-components-nwe';

import { accessors, shapeProjectMagnitudesDataForXLSX } from './services';
import { projectMagnitudeScale } from './components/chart/chart-parts/ProjectMagnitudeScale';
import ProjectMagnitudeChart from './components/chart';
import ProjectMagnitudeHeader from './components/header';
import ProjectMagnitudeLegend from './components/legend';
import { A11y } from './components/a11y';

export interface Props {
  data: ProjectMagnitudeChartDatum[];
  loading: boolean;
  queryError: ApolloError | undefined;
  preferencesError: Error | null;
  dateRange: DateRange;
  zoomRange?: DateRange;
  onHourGrainChange(grain: HourGrain): void;
  hourGrain: HourGrain;
  onProjectClick(guid: string, name: string): void;
  selectedProject?: string;
}

const BaseComp: FC<Props> = ({
  data,
  loading,
  queryError,
  preferencesError,
  dateRange,
  onHourGrainChange,
  hourGrain,
  onProjectClick,
  selectedProject,
}) => {
  const { formatMessage } = useIntl();

  const getData = () =>
    shapeProjectMagnitudesDataForXLSX(
      formatMessage(
        { id: MessageKeys.projectMagnitudeExportXlsx },
        { startDate: dateRange.start, endDate: dateRange.end }
      ),
      {
        projectID: formatMessage({ id: MessageKeys.projectID }),
        projectName: formatMessage({ id: MessageKeys.projectName }),
        condition: formatMessage({ id: MessageKeys.condition }),
        conditionLabel: formatMessage({ id: MessageKeys.customCondition }),
        plannedStartDate: formatMessage({ id: MessageKeys.plannedStartDate }),
        plannedCompletionDate: formatMessage({
          id: MessageKeys.plannedCompletionDate,
        }),
        workRequired: formatMessage({ id: MessageKeys.workRequired }),
        completedDurationDays: formatMessage({
          id: MessageKeys.daysWorked,
        }),
        completedPlannedHours: formatMessage({
          id: MessageKeys.completedPlannedHours,
        }),
      },
      data,
      preferencesError
    );

  const scale = useMemo(() => projectMagnitudeScale(data, hourGrain), [
    data,
    hourGrain,
  ]);

  const chart = (): JSX.Element => {
    if (loading) return <FormattedMessage id={MessageKeys.loading} />;
    if (queryError) return <FormattedMessage id={MessageKeys.error} />;
    if (preferencesError && hourGrain === HourGrain.plannedDuration)
      return (
        <FormattedMessage id={MessageKeys.customerDurationPreferenceError} />
      );
    return (
      <>
        {data.length > 0 && (
          <A11y
            data={data}
            startDate={dateRange.start}
            endDate={dateRange.end}
          />
        )}
        <ParentSize>
          {({ width }: { width: number; height: number }) => (
            <ProjectMagnitudeChart
              data={data}
              dateRange={dateRange}
              hourGrain={hourGrain}
              width={width}
              onProjectClick={onProjectClick}
              selectedProject={selectedProject}
              {...accessors[hourGrain]}
              scale={scale}
            />
          )}
        </ParentSize>
      </>
    );
  };

  return (
    <CardLayout
      title={ChartType.ProjectTreemap}
      header={
        <ProjectMagnitudeHeader
          onHourGrainChange={onHourGrainChange}
          hourGrain={hourGrain}
        />
      }
      legend={
        data && <ProjectMagnitudeLegend scale={scale} hourGrain={hourGrain} />
      }
      label={`${formatMessage({
        id: MessageKeys.projectMagnitudeHeader,
      })} ${formatMessage({ id: HOUR_GRAIN_TO_MESSAGE_KEY[hourGrain] })}`}
      chart={chart()}
      getData={getData}
      disableExport={!(data && data.length > 0) || loading || !!queryError}
    />
  );
};

export default BaseComp;
