import React, { Component, ReactNode } from 'react';

export interface OwnProps<T extends object> {
  duration: number;
  isMounted: boolean;
  children: ReactNode | ((props: T) => ReactNode);
}

export interface State<T extends object> {
  shouldRender: boolean;
  props: T | {};
}

export type Props<T extends object> = OwnProps<T> & T;

export default class DelayUnmount<T extends object> extends Component<
  Props<T>,
  State<T>
> {
  state = {
    shouldRender: this.props.isMounted,
    props: this.getPassthroughProps(),
  };

  componentDidUpdate(prevProps: Props<T>) {
    if (prevProps.isMounted && !this.props.isMounted) {
      setTimeout(
        () => this.setState(() => ({ shouldRender: false })),
        this.props.duration
      );
    } else if (!prevProps.isMounted && this.props.isMounted) {
      this.setState(() => ({
        shouldRender: true,
        props: this.getPassthroughProps(),
      }));
    } else if (prevProps !== this.props) {
      this.setState(() => ({ props: this.getPassthroughProps() }));
    }
  }

  private getPassthroughProps(): T {
    const { duration, isMounted, ...passthrough } = this.props;

    return passthrough as T;
  }

  render() {
    if (!this.state.shouldRender) return null;

    return typeof this.props.children === 'function'
      ? (this.props.children as (props: T) => ReactNode)(this.state.props)
      : this.props.children;
  }
}
