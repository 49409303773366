import React, { FC } from 'react';
import { DateRange } from '@insights/models-nwe';
import { HourGrain } from '@insights/constants-nwe';

import BaseComp from './baseComp';
import { accessors } from './services/accessors';
import { useProjectMagnitudeQuery } from './queries/use-project-magnitudes';

export interface Props {
  dateRange: DateRange;
  zoomRange?: DateRange;
  onHourGrainChange(grain: HourGrain): void;
  hourGrain: HourGrain;
  onProjectClick(guid: string, name: string): void;
  selectedProject?: string;
}

const ProjectMagnitude: FC<Props> = ({
  dateRange,
  zoomRange,
  onHourGrainChange,
  hourGrain,
  onProjectClick,
  selectedProject,
}) => {
  const chartDates = zoomRange || dateRange;
  const {
    data,
    loading,
    queryError,
    preferencesError,
  } = useProjectMagnitudeQuery(chartDates.start, chartDates.end);

  return (
    <BaseComp
      data={data}
      loading={loading}
      queryError={queryError}
      preferencesError={preferencesError}
      onHourGrainChange={onHourGrainChange}
      hourGrain={hourGrain}
      dateRange={chartDates}
      onProjectClick={onProjectClick}
      selectedProject={selectedProject}
      {...accessors[hourGrain]}
    />
  );
};

export default ProjectMagnitude;
