import { combineReducers, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Page } from '@insights/constants-nwe';
import { DateRange } from '@insights/models-nwe';

import dateRange, * as fromDateRange from './date-range';
import filters, * as fromFilters from './filters';
import filterHeader, * as fromFilterHeader from './filter-header';
import currentView, * as fromCurrentView from './current-view';
import burndown, * as fromBurndown from './burndown';
import projectMagnitude, * as fromProjectMagnitude from './project-magnitude';
import loading, * as fromLoading from './loading';
import pagination, * as fromPagination from './pagination';
import sortBy, * as fromSortBy from './sort-by';

export interface RootState {
  dateRange: fromDateRange.State;
  filters: fromFilters.State;
  currentView: fromCurrentView.State;
  burndown: fromBurndown.State;
  projectMagnitude: fromProjectMagnitude.State;
  filterHeader: fromFilterHeader.State;
  loading: fromLoading.State;
  pagination: fromPagination.State;
  sortBy: fromSortBy.State;
}
export type ThunkResult<R> = ThunkAction<R, RootState, null, Action>;

const rootReducer = combineReducers<RootState>({
  dateRange,
  filters,
  currentView,
  burndown,
  projectMagnitude,
  filterHeader,
  loading,
  pagination,
  sortBy,
});

export default rootReducer;

// Date Range
export const getDateRange = (state: RootState) =>
  fromDateRange.getDateRange(state.dateRange);
export const getDateRangeWithType = (state: RootState) =>
  fromDateRange.getDateRangeWithType(state.dateRange);

// Filters
export const getFilters = (state: RootState, page: Page) =>
  fromFilters.getFilters(state.filters, page);
export const getZoomDateRange = (state: RootState, page: Page) =>
  fromFilters.getZoomDateRange(state.filters, page);
export const getFilterHeader = (state: RootState) => state.filterHeader;
export const getTeamIds = (state: RootState) =>
  fromFilters.getTeamIds(state.filters);

// Current View
export const getCurrentView = (state: RootState) =>
  fromCurrentView.getCurrentView(state.currentView);

// Burndown
export const getBurndownHourGrain = (state: RootState) =>
  fromBurndown.getHourGrain(state.burndown);

// Project Magnitude
export const getProjectMagnitudeHourGrain = (state: RootState) =>
  fromProjectMagnitude.getHourGrain(state.projectMagnitude);

// Loading
export const isLoading = (state: RootState): boolean =>
  fromLoading.isLoading(state.loading);

export const getChartDateRange = (state: RootState, page: Page): DateRange => {
  const zoomDateRange = getZoomDateRange(state, page);

  if (zoomDateRange) return zoomDateRange;

  return getDateRange(state);
};

// Pagination
export const getPaginationResultsLimit = (state: RootState) =>
  fromPagination.getResultsLimit(state.pagination);

export const getPaginationOffset = (state: RootState) =>
  fromPagination.getOffset(state.pagination);

export const getPaginationPageNumber = (state: RootState) =>
  fromPagination.getPageNumber(state.pagination);

// SortBy
export const getSortByField = (state: RootState) =>
  fromSortBy.getSortByField(state.sortBy);

export const getSortByDirection = (state: RootState) =>
  fromSortBy.getSortByDirection(state.sortBy);

export const getSortBy = (state: RootState) => state.sortBy;
