import React, { FC, useContext } from 'react';
import styled from 'react-emotion';

import { StoreContext, getTeamIds } from '@insights/store-nwe';
import { Page } from '@insights/constants-nwe';
import { PeopleActivity } from '@insights/activity-nwe';
import {
  ContentHolderComponent,
  CardComponent,
} from '@insights/shared-components-nwe';
import { FilterHeader } from '@insights/filters-nwe';
import { Capacity } from '@insights/resource-capacity-nwe';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';
import TeamsZeroState from './zero-state';

const PeopleContent: FC = () => {
  const { state } = useContext(StoreContext);
  const teamIds = getTeamIds(state);

  const deprecateAnalyticsPeopleCapacity = useIsTreatmentEnabled('deprecate-analytics-people-capacity');

  return (
    <PageContent>
      <FiltersContainer>
        <FilterHeader page={Page.people} />
      </FiltersContainer>
      {(teamIds && teamIds.length === 0) || teamIds === undefined ? (
        <TeamsZeroState />
      ) : (
        <ContentHolderComponent>
          <CardComponent>
            <PeopleActivity teamIds={teamIds} />
          </CardComponent>

        { !deprecateAnalyticsPeopleCapacity &&
            <CardComponent>
              <Capacity />
            </CardComponent>
        }
        </ContentHolderComponent>
      )}
    </PageContent>
  );
};

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

export const FiltersContainer = styled.div`
  background-color: white;
  padding: 10px;
`;

export default PeopleContent;
