import { css } from 'emotion';

export const initialStyle = css`
  opacity: 1;
  cursor: pointer;
`;

export const opaqueStyle = css`
  opacity: 0.6;
  cursor: pointer;
`;
