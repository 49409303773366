import { MessageKeys } from '@insights/i18n-nwe';

enum CapacityLegendLabels {
  Balance = 'Balance',
  Burnout = 'Burnout',
  Unchallenged = 'Unchallenged',
}

export const CAPACITY_LEGEND_TO_MESSAGE_KEY: {
  [key in CapacityLegendLabels]: MessageKeys;
} = {
  [CapacityLegendLabels.Balance]: MessageKeys.balance,
  [CapacityLegendLabels.Burnout]: MessageKeys.burnout,
  [CapacityLegendLabels.Unchallenged]: MessageKeys.unchallenged,
};

export default CapacityLegendLabels;
