import React, { FC, KeyboardEvent } from 'react';
import { primary } from '@phoenix/all';
import CloseIcon from 'phoenix-icons/dist/CloseIcon.js';
import { DateRangeFormat } from '@insights/utils-nwe';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';
import { Filter, isDateRangeFilter, isValueFilter } from '@insights/models-nwe';
import { ZOOM_COLUMN } from '@insights/store-nwe';
import styled from 'react-emotion';
import { IntlFormatters } from 'react-intl';

import { defineFilterValues } from '../../../services/filter-values/defineFilterValues';
import { FilterSegment } from '../../filter-segment';
import {
  COLUMN_LABELS,
  OPERATOR_LABELS,
} from '../../../constants/filter-labels';

export interface Props {
  filter: Filter;
  filterNumber: number;
  totalFilters: number;
  onFilterRemove(
    column: string,
    e: KeyboardEvent | React.MouseEvent<HTMLButtonElement>
  ): void;
}

export const COLUMN_DISPLAY: { [key: string]: string } = {
  ...COLUMN_LABELS,
  [ZOOM_COLUMN]: MessageKeys.timeframe,
};

export const OPERATOR_DISPLAY: { [key: string]: string } = {
  ...OPERATOR_LABELS,
  between: MessageKeys.between,
};

export function getColumnLabel(column: string): string {
  return Object.prototype.hasOwnProperty.call(COLUMN_DISPLAY, column)
    ? COLUMN_DISPLAY[column]
    : column;
}

export function getOperatorLabel(operator: string): string {
  return Object.prototype.hasOwnProperty.call(OPERATOR_DISPLAY, operator)
    ? OPERATOR_DISPLAY[operator]
    : operator;
}

function getFilterSummary(filter: Partial<Filter>) {
  if (isDateRangeFilter(filter)) {
    return <DateRangeFormat>{filter.values}</DateRangeFormat>;
  }
  if (isValueFilter(filter)) {
    return defineFilterValues(filter.values);
  }

  return '';
}

const getReadableFilterOptions = (
  filter: Partial<Filter>,
  formatMessage: IntlFormatters['formatMessage']
): string => {
  if (isDateRangeFilter(filter)) {
    return formatMessage(
      { id: MessageKeys.dateRangeReadable },
      { startDate: filter.values.start, endDate: filter.values.end }
    );
  }
  if (isValueFilter(filter)) {
    return filter.values.map((value) => value.label).join(', ');
  }

  return '';
};

export const ActiveFilter: FC<Props> = ({
  filter,
  onFilterRemove,
  filterNumber,
  totalFilters,
}) => {
  const { formatMessage } = useIntl();
  const filterOptions = getReadableFilterOptions(filter, formatMessage);
  const column = formatMessage({ id: getColumnLabel(filter.column) });
  const operator = formatMessage({ id: getOperatorLabel(filter.operator) });

  const handleKeyDown = (e: KeyboardEvent): void => {
    const keyPress: KeyboardEvent['key'] = e.key;
    if (keyPress === 'Enter' || keyPress === ' ') {
      onFilterRemove(filter.column, e);
    }
  };

  return (
    <FilterComponentContainer
      tabIndex={0}
      aria-label={formatMessage(
        { id: MessageKeys.activeFilter },
        {
          column,
          operator,
          filterOptions,
          filterNumber,
          totalFilters,
        }
      )}
      data-testid={filter.column}
    >
      <FilterSegmentStyled data-testid="filter-segment">
        {column}
        &nbsp;
        <OperatorLabel>{operator}</OperatorLabel>
        &nbsp;
        {getFilterSummary(filter)}
      </FilterSegmentStyled>
      <CloseIconStyled
        data-testid="filter-remove"
        onKeyDown={handleKeyDown}
        onClick={(e) => onFilterRemove(filter.column, e)}
        aria-label={formatMessage(
          { id: MessageKeys.filterRemoveAriaLabel },
          { filterType: getColumnLabel(filter.column) }
        )}
      >
        <CloseIcon height={12} width={12} />
      </CloseIconStyled>
    </FilterComponentContainer>
  );
};

const CloseIconStyled = styled.button`
  background: ${primary.white()};
  cursor: pointer;
  align-self: flex-start;
  border: none;
  padding: 4px 0 0 0;
  opacity: 0;
  transition: opacity 100ms ease-in;
  &:focus {
    opacity: 1;
  }
`;

const FilterComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  &:hover ${CloseIconStyled} {
    opacity: 1;
  }
`;

const FilterSegmentStyled = styled(FilterSegment)`
  max-width: 100%;
`;

const OperatorLabel = styled.span`
  color: ${primary.gray(400)};
  text-transform: 'lowercase';
`;
