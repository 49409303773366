import React, { FC } from 'react';
import { Omit } from 'utility-types';
import UserActivitySection, {
  Props as ChartProps,
} from './user-activity-section';
import { QueryResult } from './queries/models';
import { mapMaxActivities } from '../services/activity';

type Props = QueryResult & Omit<ChartProps, 'data' | 'maxActivities'>;

const UserActivity: FC<Props> = ({ data, loading, error, ...rest }) => {
  if (loading) return <text>Loading...</text>;
  if (error) return <text>Error!</text>;

  const { USERS: userData, ...maxActivities } = data;

  return (
    <UserActivitySection
      {...rest}
      maxActivities={mapMaxActivities(maxActivities)}
      data={userData}
    />
  );
};

export default UserActivity;
