import React, { FC } from 'react';
import { Page, GraphqlFilterKey } from '@insights/constants-nwe';
import { FilterOption, Filter, FilterColumn } from '@insights/models-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import styled from 'react-emotion';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useSearch } from '../../hooks/useSearch';
import { useComboBox } from '../../hooks/useComboBox';
import { useGetColumnsQuery } from './useGetColumnsQuery';
import {
  Popover,
  SingleSelect,
  SearchInput,
  ComboBoxContainer,
} from '../../components';
import { FilterRoutes } from '../../constants/filter-routes';
import { useDeprecatedFiltersSideEffect } from './use-deprecated-filters-side-effect';
import { comboBoxWidth } from '../../constants/combo-box';

export interface Props {
  page: Page;
  filters: Filter[];
}

export const shouldExclude = (
  column: FilterColumn,
  currentSet: Set<string>
): boolean => {
  return (
    column.hierarchy.some((key) => currentSet.has(key)) ||
    currentSet.has(column.filterKey)
  );
};

export const filterColumnOptions = (
  availableColumns: FilterColumn[],
  currentFilters: { column: string }[]
): FilterOption<GraphqlFilterKey>[] => {
  const currentColumns = new Set(currentFilters.map((d) => d.column));

  return availableColumns
    .filter((d) => !shouldExclude(d, currentColumns))
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((d) => ({
      value: d.filterKey,
      label: d.translationKey,
    }));
};

export const FilterColumns: FC<Props> = ({ page, filters }) => {
  const { data, loading, error } = useGetColumnsQuery(page);
  const { searchText, filteredOptions, updateSearchText } = useSearch(
    '',
    filterColumnOptions(data, filters)
  );
  const navigate = useNavigate();

  useDeprecatedFiltersSideEffect(page, filters, data);

  const onSelection = (item: FilterOption<GraphqlFilterKey>): void => {
    const filterRoute =
      item.value === GraphqlFilterKey.customfields
        ? FilterRoutes.customForm
        : FilterRoutes.standard;
    navigate(filterRoute, { state: item });
  };

  const {
    ariaComboboxProps,
    ariaInputProps,
    ariaMenuProps,
    ariaOptionProps,
  } = useComboBox(filteredOptions, onSelection);

  return (
    <ComboBoxContainer ariaComboboxProps={ariaComboboxProps}>
      <SearchInput
        value={searchText}
        onChange={updateSearchText}
        searchInputLabel={
          <FormattedMessage id={MessageKeys.searchAvailableFilterOptions} />
        }
        ariaInputProps={ariaInputProps}
      >
        <Popover>
          <Container>
            <SingleSelect
              options={filteredOptions}
              loading={loading}
              error={!!error}
              ariaMenuProps={ariaMenuProps}
              ariaOptionProps={ariaOptionProps}
            />
          </Container>
        </Popover>
      </SearchInput>
    </ComboBoxContainer>
  );
};

const Container = styled.div`
  width: ${comboBoxWidth};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
