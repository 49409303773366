import { getType } from 'typesafe-actions';
import { isWithinInterval } from 'date-fns';
import { Filter, isDateRangeFilter } from '@insights/models-nwe';

import Actions from '../../actions';
import {
  addFilter,
  updateFilter,
  removeFilter,
  isZoomFilter,
} from '../../actions/filters';
import { setDateRange } from '../../actions/app';

export type State = Filter[];

export const INITIAL_STATE: State = [];

function getIndex(filters: State, column: string): number {
  return filters.findIndex((filter) => filter.column === column);
}

export default (state: State = INITIAL_STATE, action: Actions): State => {
  switch (action.type) {
    case getType(addFilter):
      return [...state, action.payload];
    case getType(updateFilter): {
      const index = getIndex(state, action.payload.column);
      const newState = [...state];
      newState.splice(index, 1, action.payload);

      return newState;
    }
    case getType(removeFilter): {
      const index = getIndex(state, action.payload);
      const newState = [...state];
      newState.splice(index, 1);

      return newState;
    }
    case getType(setDateRange): {
      const zoomIndex = state.findIndex(isZoomFilter);
      const zoomFilter = state[zoomIndex];
      const newDateRange = {
        start: action.payload.startDate,
        end: action.payload.endDate,
      };

      if (
        zoomIndex === -1 ||
        !isDateRangeFilter(zoomFilter) ||
        (isWithinInterval(zoomFilter.values.start, newDateRange) &&
          isWithinInterval(zoomFilter.values.end, newDateRange))
      )
        return state;

      const newState = [...state];
      newState.splice(zoomIndex, 1);
      return newState;
    }
    default:
      return state;
  }
};
