import React, { FC } from 'react';
import { primary } from '@phoenix/all';

import { Arc } from '@vx/shape';
import { LineDot } from '@insights/chart-parts-nwe';

export const TEST_ID = 'selected-dot';

const RADIUS = 3;
const PADDING = 3;
const STROKE_WIDTH = 0.5;

export interface Props {
  x: number;
  y: number;
  stroke?: string;
  dataStart: number;
  dataEnd: number;
}

const SelectedDot: FC<Props> = ({
  x,
  y,
  stroke = primary.blue(),
  dataStart,
  dataEnd,
}) => {
  if (x < dataStart || x > dataEnd) return null;

  return (
    <>
      <Arc
        data-testid={TEST_ID}
        transform={`translate(${x}, ${y})`}
        innerRadius={RADIUS + PADDING}
        outerRadius={RADIUS + PADDING + STROKE_WIDTH}
        startAngle={() => 0} // because of bug in vx
        endAngle={2 * Math.PI}
        stroke={stroke}
      />
      <LineDot
        x={x}
        y={y}
        fill={stroke}
        color={stroke}
        dotRadius={RADIUS}
        strokeWidth={0}
      />
    </>
  );
};

export default SelectedDot;
