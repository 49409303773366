import React, { FC, ReactNode } from 'react';
import { AriaComboboxProps } from '../../hooks/useComboBox';

interface Props {
  ariaComboboxProps: AriaComboboxProps;
  children: ReactNode;
}

export const ComboBoxContainer: FC<Props> = ({
  ariaComboboxProps,
  children,
}) => (
  <div
    role="combobox"
    aria-haspopup="listbox"
    aria-expanded="true"
    {...ariaComboboxProps}
  >
    {children}
  </div>
);
