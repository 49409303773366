import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  RawUserActivitySummary,
  UserActivitySummary,
} from '@insights/models-nwe';
import { formatDate } from '@insights/shared-components-nwe';

import { processGroupedActivities } from '../../../services/activity';
import { QueryResult } from '../../../user-activity/queries/models';

export const NO_DATA: UserActivitySummary = {
  MAX_LOGIN: 0,
  MAX_TASKS_CHANGES: 0,
  MAX_TASKS_COMPLETED: 0,
  USERS: [],
};

export const GET_PROJECT_USER_ACTIVITY = gql`
  query GetProjectUserActivity(
    $projectId: Guid!
    $startDate: Date!
    $endDate: Date!
  ) {
    userProjectActivityForDateRange(
      projectID: $projectId
      queryStartDate: $startDate
      queryEndDate: $endDate
    ) {
      MAX_LOGIN
      MAX_TASKS_COMPLETED
      MAX_TASKS_CHANGES
      USERS {
        guid
        activities {
          login {
            date
            count
          }
          taskStatusChange {
            date
            count
          }
          tasksCompleted {
            date
            count
          }
        }
      }
    }
  }
`;

/**
 * This is where the props feeding into the component
 * are defined
 * Note: leave the children prop there!!!
 */
export interface Props {
  projectId: string;
  startDate: Date;
  endDate: Date;
  children(result: QueryResult): JSX.Element | null;
}

/**
 * This is where the shape of the response is defined. It should match
 * the shape of your query
 */
export interface Response {
  userProjectActivityForDateRange: RawUserActivitySummary;
}

/**
 * This is where the variables that are used in the actual query
 * are defined
 */
export interface Variables {
  projectId: string;
  startDate: string;
  endDate: string;
}

const GetProjectUserActivity: FC<Props> = ({
  children,
  projectId,
  startDate,
  endDate,
}) => {
  const { data, loading, error } = useQuery<Response, Variables>(
    GET_PROJECT_USER_ACTIVITY,
    {
      variables: {
        projectId,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      },
    }
  );

  return children({
    data: data
      ? {
          ...data.userProjectActivityForDateRange,
          USERS: processGroupedActivities(
            data.userProjectActivityForDateRange.USERS
          ),
        }
      : NO_DATA,
    loading,
    error,
  });
};

export default GetProjectUserActivity;
