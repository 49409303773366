import React, { FC, ReactElement } from 'react';

import LegendHeader from './legend-header';
import LegendBarItem from './legend-bar-item';
import LegendLineItem from './legend-line-item';

export interface Props {
  header: React.ReactNode;
  children: ReactElement<any> | ReactElement<any>[];
  className?: string;
  testID?: string;
}

const Legend: FC<Props> = ({ header, children, className, testID }) => (
  <div className={className}>
    <LegendHeader data-testid={testID}>{header}</LegendHeader>
    {children}
  </div>
);

export default Legend;
export {
  LegendBarItem as BarItem,
  LegendHeader as Header,
  LegendLineItem as LineItem,
};
