import React, { FC } from 'react';

import { DateRange } from '@insights/models-nwe';
import { FormattedDate } from '@insights/i18n-nwe';

export interface Props {
  children: DateRange;
}

export const DateRangeFormat: FC<Props> = ({ children }) => (
  <>
    <FormattedDate value={children.start} /> -{' '}
    <FormattedDate value={children.end} />
  </>
);
