import React, { FC } from 'react';
import { FormattedMessage } from '@insights/i18n-nwe';
import styled from 'react-emotion';
import { css } from 'emotion';
import { primary } from '@phoenix/all';
import {
  BasicOperator,
  CustomFormOperator,
  FilterOption,
} from '@insights/models-nwe';
import {
  getColumnLabel,
  getOperatorLabel,
} from '../filter-header/active-filter';
import { defineFilterValues } from '../../services/filter-values/defineFilterValues';
import { FilterSegment } from '../filter-segment';

export interface Props {
  column: string;
  operator: BasicOperator | CustomFormOperator;
  values?: FilterOption[];
}

export const FilterInProgress: FC<Props> = ({
  column,
  operator,
  values = [],
}) => {
  return (
    <>
      <FilterSegment>
        <FormattedMessage id={getColumnLabel(column)} />
      </FilterSegment>
      <FilterSegment className={spacing}>
        <FilterOperator>
          <FormattedMessage id={getOperatorLabel(operator)} />
        </FilterOperator>
      </FilterSegment>
      {values.length > 0 && (
        <FilterSegment className={spacing}>
          {defineFilterValues(values)}
        </FilterSegment>
      )}
    </>
  );
};

export const FilterDetails = styled.span`
  color: #333333;
  margin-left: 6px;
  border-bottom: 1px solid ${primary.blue()};
  display: flex;
`;

const FilterOperator = styled.span`
  color: ${primary.gray(400)};
`;

const spacing = css`
  margin-left: 6px;
`;
