import React, { FC, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

const getContainer = () => document.getElementById('dialog-container');

const PhoenixPortal: FC = ({ children }) => {
  const node = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    const container = getContainer();
    if (container) container.appendChild(node);

    return () => {
      if (container) container.removeChild(node);
    };
  }, []);

  return createPortal(children, node);
};

export default PhoenixPortal;
