import React, { FC } from 'react';
import styled from 'react-emotion';
import { LabelDropdown, Option } from '@phoenix/all';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { formatItemTestId } from '@insights/utils-nwe';
import Trigger from './trigger';

export type OptionType = {
  value: string;
  messageKey: MessageKeys;
};

export interface Props {
  label?: React.ReactNode;
  testID?: string;
  open?: boolean;
  value: string;
  onChange(option: string): void;
  optionIsDisabled?(option: string): boolean;
  options: OptionType[];
}

const DropdownToggle: FC<Props> = ({
  value,
  label,
  options,
  onChange,
  optionIsDisabled,
  ...rest
}): JSX.Element => {
  const selectedOption = options.find((option) => {
    return option.value === value;
  });

  return (
    <LabelDropdown
      {...rest}
      onChange={(e: OptionType) => onChange(e.value)}
      trigger={<Trigger messageKey={selectedOption?.messageKey} />}
      value={value}
    >
      {options.map((option) => (
        <Option
          value={option}
          key={option.value}
          testID={`option-${formatItemTestId(option.value)}`}
          disabled={optionIsDisabled && optionIsDisabled(option.value)}
        >
          <TextOption>
            <FormattedMessage id={option.messageKey} />
          </TextOption>
        </Option>
      ))}
    </LabelDropdown>
  );
};

export default DropdownToggle;

const TextOption = styled.div`
  text-transform: lowercase;
`;
