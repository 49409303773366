import React, { FC } from 'react';
import { DailyTeamCapacity } from '@insights/models-nwe';
import {
  FormattedMessage,
  FormattedNumber,
  MessageKeys,
} from '@insights/i18n-nwe';
import { getY } from '../../utils/accessors';
import * as styles from './styles';

const GenerateTeamCapacity: FC<DailyTeamCapacity> = ({
  scheduledHours,
  availableHours,
  date,
}) => {
  const percentage = getY({
    scheduledHours,
    availableHours,
    date,
  });
  const hasCapacity = percentage !== Infinity;

  const calculatedCapacityString = (): string => {
    if (!hasCapacity) return '= ∞';
    return `= ${Math.round(percentage * 100)}%`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.fractionContainer}>
        <p className={styles.top}>
          (<FormattedMessage id={MessageKeys.scheduledHours} />
          )&nbsp;
          <styles.Bold>
            <FormattedNumber
              value={scheduledHours}
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          </styles.Bold>
        </p>
        <p>
          (<FormattedMessage id={MessageKeys.availableHours} />
          )&nbsp;
          <styles.Bold>
            <FormattedNumber
              value={availableHours}
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          </styles.Bold>
        </p>
      </div>
      <div className={styles.totalContainer}>
        <p className={styles.capacityPercentage}>
          <styles.Bold>{calculatedCapacityString()}</styles.Bold>
          {hasCapacity && (
            <>
              &nbsp;
              <FormattedMessage id={MessageKeys.capacity} />
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default GenerateTeamCapacity;
