import styled from 'react-emotion';
import { primary } from '@phoenix/all';

export const Value = styled.span`
  color: ${primary.gray(400)};
  font-size: 16px;
  font-weight: bold;
`;

export const PreviousPeriod = styled.span`
  color: ${primary.gray(400)};
  font-size: 14px;
`;
