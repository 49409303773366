import { MessageKeys } from '@insights/i18n-nwe';

enum ConditionType {
  onTarget = 'ON',
  atRisk = 'AR',
  inTrouble = 'IT',
}

export const PROJECT_CONDITION_TO_MESSAGE_KEY: {
  [key in ConditionType]: MessageKeys;
} = {
  [ConditionType.onTarget]: MessageKeys.projectConditionOnTarget,
  [ConditionType.atRisk]: MessageKeys.projectConditionAtRisk,
  [ConditionType.inTrouble]: MessageKeys.projectConditionInTrouble,
};

export default ConditionType;

export function isConditionType(arg: any): arg is ConditionType {
  return (
    typeof arg === 'string' &&
    Object.values<string>(ConditionType).includes(arg)
  );
}
