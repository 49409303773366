import styled from 'react-emotion';
import { primary } from '@phoenix/all';

export const ChangeArrowContainer = styled.div`
  display: flex;
  align-items: center;
  height: auto;
`;

export const Circle = styled.span`
  background-color: ${primary.blue(50)};
  border-radius: 50%;
  height: 24px;
  margin-right: 8px;
  padding: 4px;
  width: 24px;
`;

export const ChangeNumber = styled.span`
  color: ${primary.blue()};
  font-size: 16px;
  font-weight: bold;
`;
