import React, { FC } from 'react';

interface Props {
  color: string;
  height: number;
  width: number;
}

export const NoChange: FC<Props> = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest}>
    <g fill={color}>
      <path d="M17.5 12.5h-11a.5.5 0 010-1h11a.5.5 0 010 1z" />
    </g>
  </svg>
);
