import React, { FC, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import styled from 'react-emotion';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { BasicOperator } from '@insights/models-nwe';

import {
  Popover,
  Header,
  ApplyButton,
  FilterInProgress,
  FilterInput,
  ErrorMessage,
} from '../../components';
import { FieldOption } from '../custom-form-field-options';
import { comboBoxWidth } from '../../constants/combo-box';

export const UnsupportedFormField: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const backButtonRef = useRef<HTMLButtonElement | null>(null);
  const operator = BasicOperator.in;
  const fieldName = location.state.name;

  useEffect(() => {
    if (backButtonRef.current) {
      backButtonRef.current.focus();
    }
  }, [backButtonRef]);

  return (
    <Container>
      <FilterInProgress column={fieldName} operator={operator} />
      <InputContainer>
        <FilterInput disabled />
        <Popover>
          <PopoverContent>
            <Header
              onBack={() => navigate(-1)}
              header={fieldName}
              backButtonRef={backButtonRef}
            />
            <ErrorMessage
              errorMessage={
                <FormattedMessage id={MessageKeys.filterUnsupportedError} />
              }
              ariaLabelID="alert-dialog"
            />
            <ApplyButton onClick={() => null} disabled />
          </PopoverContent>
        </Popover>
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const InputContainer = styled.div`
  position: relative;
`;

const PopoverContent = styled.div`
  width: ${comboBoxWidth};
`;
