import React from 'react';

interface Props {
  height: number;
  gradientId: string;
}

export default ({ height, gradientId }: Props) => (
  <rect height={height} width={8} fill={`url('#${gradientId}')`} />
);
