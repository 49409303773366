import React from 'react';
import styled from 'react-emotion';
import { FilterOption } from '@insights/models-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

import { LoadingSpinner } from '../loading-spinner';
import { SingleSelectItem } from './single-select-item';
import { AriaMenuProps, AriaOptionProps } from '../../hooks/useComboBox';

const DIALOG_ID = 'alert-dialog';

export interface Props<T extends FilterOption> {
  loading?: boolean;
  error?: boolean;
  options: T[];
  onClick?(d: T): void;
  ariaMenuProps?: AriaMenuProps | {};
  ariaOptionProps?(item: FilterOption): AriaOptionProps | null;
}

export const SingleSelectOptions = <T extends FilterOption>({
  onClick,
  options,
  ariaOptionProps = () => null,
}: Pick<Props<T>, 'options' | 'onClick' | 'ariaOptionProps'>): JSX.Element => (
  <>
    {options.map((option) => (
      <SingleSelectItem<T>
        key={option.value}
        item={option}
        onClick={onClick}
        ariaOptionProps={ariaOptionProps}
      />
    ))}
  </>
);

export const SingleSelect = <T extends FilterOption>({
  loading,
  error,
  options,
  onClick,
  ariaMenuProps = {},
  ariaOptionProps = () => null,
}: Props<T>): JSX.Element => {
  let displayMessage = null;

  if (error) {
    displayMessage = (
      <Text id={DIALOG_ID}>
        <FormattedMessage id={MessageKeys.error} />
      </Text>
    );
  } else if (loading) {
    displayMessage = <LoadingSpinner />;
  } else if (options.length === 0) {
    displayMessage = (
      <Text id={DIALOG_ID}>
        <FormattedMessage id={MessageKeys.ZeroFilters} />
      </Text>
    );
  }

  return displayMessage ? (
    <Container
      role="dialog"
      aria-live="polite"
      aria-labelledby={DIALOG_ID}
      {...ariaMenuProps}
    >
      {displayMessage}
    </Container>
  ) : (
    <ListBox role="listbox" {...ariaMenuProps}>
      <SingleSelectOptions<T>
        options={options}
        onClick={onClick}
        ariaOptionProps={ariaOptionProps}
      />
    </ListBox>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 24px;
`;

const ListBox = styled.ul``;

const Text = styled.span`
  font-weight: 700;
  color: #333;
  font-size: 12px;
`;
