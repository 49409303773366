import { SpreadSheetData } from '@insights/models-nwe';
import { saveAs } from 'file-saver';

export const convertToBinary = (data: string): ArrayBuffer => {
  const buffer: ArrayBuffer = new ArrayBuffer(data.length);
  const view: Uint8Array = new Uint8Array(buffer);
  view.forEach((v: number, i: number): void => {
    view[i] = data.charCodeAt(i);
  });
  return buffer;
};

// NOTE: Excel has a hard 31 char limit for tab names: https://tinyurl.com/y4ozyye6
const WORKSHEET_NAME_LIMIT = 30;
export const truncateWorksheetName = (name: string): string => {
  if (name.length <= WORKSHEET_NAME_LIMIT) return name;

  return `${name.slice(0, WORKSHEET_NAME_LIMIT)}…`;
};

const generateXLSX = async (data: SpreadSheetData): Promise<void> => {
  const XLSX = await import('xlsx'); // eslint-disable-line import/no-unresolved
  const workbook = XLSX.utils.book_new();
  workbook.Props = {
    Title: 'Data Export',
    Author: 'Workfront',
  };

  const name = data.name || 'export';
  const contents = data.data;
  const worksheets = Object.keys(contents);

  worksheets.forEach((sheet: string): void => {
    const uniqueSheet = XLSX.utils.json_to_sheet(contents[sheet], {
      dateNF: 'yyyy-mm-ddThh:mm:ss.sssZ',
    });
    XLSX.utils.book_append_sheet(
      workbook,
      uniqueSheet,
      truncateWorksheetName(sheet)
    );
  });

  const output = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

  const blob = new Blob([convertToBinary(output)], {
    type: 'application/octet-stream',
  });
  saveAs(blob, `${name}.xlsx`);
};

export default generateXLSX;
