import React, { FC, ReactNode } from 'react';

import * as styles from './styles';

export interface Props {
  title: ReactNode;
  span?: number;
}

const AdditionalFieldsHeader: FC<Props> = ({ title, span = 12 }) => (
  <th colSpan={span} className={styles.root}>
    {title}
  </th>
);

export default AdditionalFieldsHeader;
