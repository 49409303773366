import React, { FC } from 'react';
import { FormattedNumber } from '@insights/i18n-nwe';
import { calcDaysBetweenDates } from '../../utilities/calculate-days-between-dates';
import { display } from '../styles';

export interface Props {
  startDate: Date;
  endDate: Date;
  hours: number;
  className?: string;
}

const AvgHoursPerDay: FC<Props> = ({
  startDate,
  endDate,
  hours,
  className = display,
}) => {
  const numDays = calcDaysBetweenDates(startDate, endDate);
  if (!numDays || !hours) return <>-</>;
  const avgHours = hours / numDays;

  return (
    <span className={className}>
      <FormattedNumber
        value={avgHours}
        maximumFractionDigits={2}
        minimumFractionDigits={0}
      />
    </span>
  );
};

export default AvgHoursPerDay;
