import styled from 'react-emotion';

interface Props {
  color: string;
}

export const ArrowDown = styled('div')(({ color }: Props) => ({
  width: 0,
  height: 0,
  borderLeft: '4px solid transparent',
  borderRight: '4px solid transparent',
  borderTopStyle: 'solid',
  borderTopWidth: '5px',
  borderTopColor: color,
}));
