import React, { ReactNode } from 'react';
import { InfoTooltip } from '@insights/shared-components-nwe';
import { QuicksilverHyperLinks } from '@insights/constants-nwe';
import { Header, Heading } from './styles';

// @TODO: Use InfoTooltip props as type for info when Babel supports it.
// https://github.com/babel/babel/issues/8361#issuecomment-578317317
export interface Props {
  title: string;
  testID?: string;
  buttonDescription?: ReactNode;
  info: {
    header?: React.ReactNode;
    body?: JSX.Element;
  };
  ariaLabel?: string;
}

export default function KPICardHeader({
  title,
  testID,
  buttonDescription,
  info,
  ariaLabel,
}: Props) {
  return (
    <Header aria-label={ariaLabel || title} tabIndex={0} data-testid={testID}>
      <Heading>{title}</Heading>
      <InfoTooltip
        dataId={testID}
        hyperlink={QuicksilverHyperLinks.KPI}
        buttonDescription={buttonDescription}
        {...info}
      />
    </Header>
  );
}
