import * as saveAs from '../save-as/saveAs';

const svgToImg = (ref: string, node: Document) => {
  const svg = node.querySelectorAll(`#${ref}`)[0];
  svg.setAttribute('style', 'font-family: Arial');
};

export const VisualExportService = async (
  ref: string,
  filename: string,
  node: HTMLCanvasElement
) => {
  const { default: html2canvas } = await import('html2canvas'); // eslint-disable-line import/no-unresolved

  return html2canvas(node, {
    backgroundColor: '#fff',
    onclone: svgToImg.bind(null, ref),
  }).then((canvas: HTMLCanvasElement) => {
    saveAs.saveAs(canvas.toDataURL(), `${filename}.png`);
  });
};
