import { alerts, primary, secondary } from '@phoenix/all';

export const SharedColors = {
  blue: {
    primary50: primary.blue(50),
    primary100: primary.blue(100),
    primary400: primary.blue(400),
    primary500: primary.blue(500),
    primary600: primary.blue(600),
    highContrast1: '#F4EEFB',
    highContrast2: '#C2E4FA',
    highContrast3: '#86C9F6',
    highContrast4: '#4BAEF1',
    highContrast5: '#0E94ED',
    highContrast6: '#75C1FF',
  },
  white: {
    primary: primary.gray(0),
    highContrast: '#21262f',
  },
  grey: {
    primary50: primary.gray(50),
    primary500: primary.gray(500),
    primary600: primary.gray(600),
    highContrast1: '#21262E',
    highContrast2: '#101A20',
    highContrast3: '#C1C3C7',
    highContrast4: '#B4B4B4',
    highContrast5: '#28278E',
  },
  green: {
    primary400: alerts.success(400),
    highContrast: '#00E4BF',
  },
  yellow: {
    primary400: alerts.warn(400),
    highContrast: '#FECA42',
  },
  red: {
    primary400: secondary.red(400),
    highContrast: '#FC368F',
  },
  pink: {
    primary100: secondary.pink(100),
    primary200: secondary.pink(200),
    primary400: secondary.pink(400),
    primary500: secondary.pink(500),
    highContrast1: '#F79B4A',
    highContrast2: '#F9B378',
    highContrast3: '#FEE6D2',
    highContrast4: '#FBCDA6',
  },
  purple: {
    primary100: secondary.purple(100),
    primary200: secondary.purple(200),
    primary400: secondary.purple(400),
    primary500: secondary.purple(500),
    highContrast1: '#B18AE5',
    highContrast2: '#D1B0FD',
    highContrast3: '#DECDF4',
    highContrast4: '#F4EEFB',
  },
  navy: {
    primary100: secondary.navy(100),
    primary200: secondary.navy(200),
    primary400: secondary.navy(400),
    primary500: secondary.navy(500),
  },
};
