import {
  TeamActivitySummaryDatum,
  SpreadSheetData,
  DateRange,
} from '@insights/models-nwe';
import { extractActivityData, ActivityHeaders } from '../utils';

interface ActivityObject {
  [key: string]: Date;
}

interface Headers extends ActivityHeaders {
  teamID: string;
  teamName: string;
}

export const shapeData = (
  headers: Headers,
  chartData: TeamActivitySummaryDatum,
  dateRange: DateRange
) => {
  const data = chartData.TEAMS.map((project) => {
    const projectLevelDetail = {
      [headers.teamID]: project.guid,
      [headers.teamName]: project.name,
    };

    return extractActivityData(
      headers,
      projectLevelDetail,
      project.activities,
      dateRange
    );
  });

  return data.reduce((accum, projectArr) => {
    return [...accum, ...projectArr];
  }, []);
};

export const shapeTeamActivityDataForXLSX = (
  name: string,
  headers: Headers,
  chartData: TeamActivitySummaryDatum,
  dateRange: DateRange
): SpreadSheetData => {
  const teamActivity = shapeData(headers, chartData, dateRange);

  return {
    data: {
      data: teamActivity,
    },
    name,
  };
};
