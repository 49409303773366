import React, { FC } from 'react';

import { DateRange } from '@insights/models-nwe';
import { ChartDateRangeWarning } from './cards/chartDateRangeWarning';
import { RenderChart } from './cards/renderChart';
import CheckRange from '../activity/check-range';

export interface Props {
  dateRange: DateRange;
  zoomRange?: DateRange;
  onZoom(zoomRange: DateRange): void;
}

const ProjectActivity: FC<Props> = (props) => {
  const { dateRange, zoomRange, onZoom } = props;

  return (
    <CheckRange
      dateRange={dateRange}
      renderChart={
        <RenderChart
          dateRange={dateRange}
          zoomRange={zoomRange}
          onZoom={onZoom}
        />
      }
      chartDateRangeWarning={<ChartDateRangeWarning />}
    />
  );
};

export default ProjectActivity;
