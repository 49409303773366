import { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  ProjectActivitySummaryDatum,
  RawProjectActivitySummaryDatum,
  TopLevelRawGroupedActivity,
  TopLevelGroupedActivity,
  Project,
} from '@insights/models-nwe';
import { formatDate, indexify } from '@insights/shared-components-nwe';
import { useProjects } from '@insights/clients-nwe';

import { processActivity } from '../../../services/activity';

export type ProjectFragment = Pick<Project, 'guid' | 'name'>;

export interface Props {
  startDate: Date;
  endDate: Date;
  children(result: {
    data: ProjectActivitySummaryDatum;
    loading: boolean;
    error: Error | undefined;
  }): JSX.Element | null;
}

export const NO_DATA: ProjectActivitySummaryDatum = {
  MAX_LOGIN: 0,
  MAX_TASKS_CHANGES: 0,
  MAX_TASKS_COMPLETED: 0,
  PROJECTS: [],
};

export const GET_PROJECT_ACTIVITY = gql`
  query ProjectActivity(
    $projectIds: [Guid!]!
    $startDate: Date!
    $endDate: Date!
  ) {
    projectActivityForDateRange(
      projectIDs: $projectIds
      queryStartDate: $startDate
      queryEndDate: $endDate
    ) {
      MAX_LOGIN
      MAX_TASKS_COMPLETED
      MAX_TASKS_CHANGES
      PROJECTS {
        guid
        users
        activities {
          login {
            date
            count
          }
          taskStatusChange {
            date
            count
          }
          tasksCompleted {
            date
            count
          }
        }
      }
    }
  }
`;

/**
 * This is where the shape of the response is defined. It should match
 * the shape of your query
 */
export interface Response {
  projectActivityForDateRange: RawProjectActivitySummaryDatum;
  projects: ProjectFragment[];
}

/**
 * This is where the variables that are used in the actual query
 * are defined
 */
export interface Variables {
  projectIds: string[];
  startDate: string;
  endDate: string;
}

const GetProjectActivity: FC<Props> = ({ startDate, endDate, children }) => {
  const {
    data: { projects },
    loading: projectsLoading,
  } = useProjects();
  const noProjectIds = projects.size === 0;
  const skipQuery = projectsLoading || noProjectIds;
  const { data, loading: queryLoading, error } = useQuery<Response, Variables>(
    GET_PROJECT_ACTIVITY,
    {
      variables: {
        projectIds: Array.from(projects.keys()).sort(),
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      },
      skip: skipQuery,
    }
  );

  return children({
    data: data
      ? processActivityDate(data.projectActivityForDateRange, projects)
      : NO_DATA,
    loading: queryLoading || projectsLoading,
    error,
  });
};

export default GetProjectActivity;

export const processProjectActivity = (
  data: TopLevelRawGroupedActivity[],
  projects: Map<string, ProjectFragment>
): TopLevelGroupedActivity[] => {
  const indexedData = indexify(data, ({ guid }) => guid);

  return Array.from(projects).map(([guid, group]) => {
    const { users, activities } = indexedData[guid] ?? {
      users: [],
      activities: [],
    };

    return {
      ...group,
      users,
      activities: processActivity(activities),
    };
  });
};

export const processActivityDate = (
  data: RawProjectActivitySummaryDatum,
  projects: Map<string, ProjectFragment>
): ProjectActivitySummaryDatum => {
  const processedProjects = processProjectActivity(data.PROJECTS, projects);

  return {
    ...data,
    PROJECTS: processedProjects,
  };
};
