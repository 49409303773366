import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { GraphqlFilterKey } from '@insights/constants-nwe';
import styled from 'react-emotion';
import {
  FilterOption,
  FilterType,
  BasicOperator,
  Filter,
} from '@insights/models-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { logError } from '@insights/utils-nwe';
import {
  Header,
  Popover,
  SearchInput,
  MultiSelectContainer,
  FilterInProgress,
  ComboBoxContainer,
} from '../../components';
import { useGetFilterOptionsQuery } from './useGetFilterOptionsQuery';
import { useComboBox, useMultiSelect } from '../../hooks';
import { COLUMN_LABELS } from '../../constants/filter-labels';
import { comboBoxWidth } from '../../constants/combo-box';

export interface Props {
  filters: Filter[];
  onAddFilter(d: Filter): void;
}

export const Standard: FC<Props> = ({ onAddFilter, filters }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const filterOption = location.state as FilterOption<GraphqlFilterKey>
  const filterKey = filterOption.value;
  const [searchText, updateSearchText] = useState('');
  const {
    data,
    loading,
    error,
    requiresSearchInput,
    called,
  } = useGetFilterOptionsQuery(searchText, filterKey, filters);
  const { options, selectedOptions, onSelectOption } = useMultiSelect(data);
  const {
    ariaComboboxProps,
    ariaInputProps,
    ariaMenuProps,
    ariaOptionProps,
  } = useComboBox(options, onSelectOption);
  const operator = BasicOperator.in;

  const onApply = (): void => {
    if (filterKey) {
      onAddFilter({
        filterType: FilterType.Standard,
        column: filterKey,
        operator,
        values: selectedOptions,
      });
    } else {
      logError(
        'There was not a column provided to the Standard Options picker'
      );
    }
  };

  return (
    <>
      <FilterInProgress
        column={filterKey}
        operator={operator}
        values={selectedOptions}
      />
      <ComboBoxContainer ariaComboboxProps={ariaComboboxProps}>
        <SearchInput
          value={searchText}
          onChange={updateSearchText}
          searchInputLabel={
            <FormattedMessage
              id={MessageKeys.searchFilterOptionsByFilterType}
              values={{
                filterColumn: (
                  <FormattedMessage id={COLUMN_LABELS[filterKey]} />
                ),
              }}
            />
          }
          ariaInputProps={ariaInputProps}
        />
        <Popover>
          <InnerPopoverContainer>
            <Header
              onBack={() => navigate(-1)}
              header={filterOption.label}
            />
            <MultiSelectContainer
              options={options}
              loading={loading}
              error={!!error}
              called={called}
              requiresSearchInput={requiresSearchInput}
              selectedOptions={selectedOptions}
              onApply={onApply}
              ariaMenuProps={ariaMenuProps}
              ariaOptionProps={ariaOptionProps}
            />
          </InnerPopoverContainer>
        </Popover>
      </ComboBoxContainer>
    </>
  );
};

const InnerPopoverContainer = styled.div`
  width: ${comboBoxWidth};
`;
