import { useState, useEffect } from 'react';
import { getStorageUtil } from '@workfront/storage';
import { InsightsTheme, ThemeNames } from '../constants/theme';
import { themes } from '../themes';

const storageUtil = getStorageUtil();
const storageKey = 'insights-theme';

export function useLocalStorageTheme(defaultTheme: InsightsTheme) {
  const [theme, setTheme] = useState(
    themes[storageUtil.get(storageKey) as keyof typeof themes] || defaultTheme
  );

  function setThemeFromName(themeName: ThemeNames) {
    setTheme(themes[themeName]);
  }

  useEffect(() => {
    storageUtil.set(storageKey, theme.name);
  }, [theme]);

  return { theme, setTheme: setThemeFromName };
}
