import { alerts, secondary, primary } from '@phoenix/all';

const LegendColors = {
  green: secondary.green(),
  orange: secondary.orange(),
  red: alerts.error(),
  blue: primary.blue(),
  blue100: primary.blue(100),
  gray: primary.gray(500),
};

export default LegendColors;
