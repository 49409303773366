import { MessageKeys } from './message-keys';

/**
 * This constant contains messages that will override anything coming back from Redrock.
 * This is useful to untie our deployments from Redrock's deployments. But with this comes
 * great responsibility to make sure we come back and clean up once the latest version of Redrock
 * has been deployment to production
 */
export const TEMP_MESSAGE_OVERRIDES: { [key in MessageKeys]?: string } = {
  [MessageKeys.noTasksInfo]: 'There are no tasks in flight for this project',
  [MessageKeys.noTasks]:
    'There are no tasks in flight for the selected date range.',
};
