import { useContext } from 'react';
import { Project } from '@insights/models-nwe';

import { ProjectsContext } from '../index';

export const useProject = (guid: string): Project | undefined => {
  const state = useContext(ProjectsContext);

  return state?.data?.projects.get(guid);
};
