import { css } from 'emotion';
import styled from 'react-emotion';

export interface Props {
  disableExport: boolean;
}

export const Container = styled('div')((props: Props) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: '6px',
  position: 'relative',
  cursor: props.disableExport ? 'not-allowed' : 'pointer',
}));

export const headerLink = css`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  position: relative;
`;

export const exportIcon = css`
  width: 20px;
  margin-right: 8px;
`;
