import React, { FC, memo } from 'react';
import { ChartA11y } from '@insights/shared-components-nwe';
import { ChartType } from '@insights/constants-nwe';
import { useIntl, MessageKeys } from '@insights/i18n-nwe';
import { TopLevelGroupedActivity, DateRange } from '@insights/models-nwe';
import {
  extractActivityData,
  ActivityHeaders,
} from '../../../services/xlsx/utils';

export type ActivityType = ChartType.ProjectActivity | ChartType.PeopleActivity;

export interface Props {
  data: TopLevelGroupedActivity;
  dateRange: DateRange;
  activityType: ActivityType;
}

const keys: ActivityHeaders = {
  date: 'date',
  numberOfLogins: 'numberOfLogins',
  numberOfTaskStatusChanges: 'numberOfTaskStatusChanges',
  numberOfTasksCompleted: 'numberOfTasksCompleted',
};

const Chart: FC<Props> = ({ data, dateRange, activityType }) => {
  const { formatDate, formatMessage, formatNumber } = useIntl();

  const messageKey =
    activityType === ChartType.ProjectActivity
      ? MessageKeys.projectActivityCaption
      : MessageKeys.peopleActivityCaption;

  return (
    <ChartA11y
      testID="activity"
      caption={formatMessage(
        { id: messageKey },
        {
          name: data.name,
          startDate: dateRange.start,
          endDate: dateRange.end,
        }
      )}
      headers={[
        {
          key: keys.date,
          label: formatMessage({ id: MessageKeys.date }),
          isRowHeader: true,
        },
        {
          key: keys.numberOfLogins,
          label: formatMessage({ id: MessageKeys.activityHeaderLogins }),
        },
        {
          key: keys.numberOfTaskStatusChanges,
          label: formatMessage({ id: MessageKeys.activityHeaderStatusChanges }),
        },
        {
          key: keys.numberOfTasksCompleted,
          label: formatMessage({
            id: MessageKeys.activityHeaderTasksCompleted,
          }),
        },
      ]}
      data={extractActivityData(
        keys,
        {},
        data.activities,
        dateRange,
        (date) => formatDate(date, { format: 'readable' }),
        (number) =>
          formatNumber(number, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
      )}
    />
  );
};

export const ActivityChartA11y = memo(Chart);
