import React, { FC } from 'react';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';
import { ParentSize } from '@vx/responsive';
import { ChartType } from '@insights/constants-nwe';
import { CardLayout } from '@insights/shared-components-nwe';
import { GetTeamCapacities } from '../chart/query';
import ResourceCapacityLegend from '../legend';
import ResourceHeader from '../header';
import { Props as ChartProps } from '../chart';
import { shapeResourceCapacityDataForXLSX } from '../utils';
import { ResourceCapacityChartWithTable } from './chart-with-table';

export interface OwnProps {
  teamIds: string[];
  startDate: Date;
  endDate: Date;
}

type PassthroughProps = Pick<ChartProps, 'selectedTeam' | 'setSelectedTeam'>;

export type Props = OwnProps & PassthroughProps;

const ResourceCapacity: FC<Props> = ({
  setSelectedTeam,
  selectedTeam,
  teamIds,
  startDate,
  endDate,
}) => {
  const { formatMessage } = useIntl();

  return (
    <GetTeamCapacities
      teamIds={teamIds}
      startDate={startDate}
      endDate={endDate}
    >
      {({ data, loading, error }) => {
        const chart = () => {
          if (loading) return <FormattedMessage id={MessageKeys.loading} />;
          if (error) return <FormattedMessage id={MessageKeys.error} />;

          const chartData = data?.capacityByTeamForDateRange ?? [];
          const teams = data?.teams ?? [];

          return (
            <ParentSize>
              {({ width }: { width: number; height: number }) => (
                <ResourceCapacityChartWithTable
                  startDate={startDate}
                  endDate={endDate}
                  setSelectedTeam={setSelectedTeam}
                  selectedTeam={selectedTeam}
                  data={chartData}
                  teams={teams}
                  width={width}
                />
              )}
            </ParentSize>
          );
        };

        const getData = () =>
          shapeResourceCapacityDataForXLSX(
            formatMessage(
              { id: MessageKeys.resourceCapacityExportXlsx },
              { startDate, endDate }
            ),
            {
              teamName: formatMessage({ id: MessageKeys.teamName }),
              availableHours: formatMessage({ id: MessageKeys.availableHours }),
              scheduledHours: formatMessage({ id: MessageKeys.scheduledHours }),
            },
            {
              capacityByTeamForDateRange: data.capacityByTeamForDateRange,
              teams: data.teams,
            }
          );

        return (
          <CardLayout
            title={ChartType.ResourceCapacity}
            header={<ResourceHeader />}
            legend={<ResourceCapacityLegend />}
            label={formatMessage({ id: MessageKeys.resourceCapacityLowercase })}
            chart={chart()}
            getData={getData}
            disableExport={
              data.teams.length === 0 ||
              data.capacityByTeamForDateRange.length === 0 ||
              loading ||
              !!error
            }
          />
        );
      }}
    </GetTeamCapacities>
  );
};

export default ResourceCapacity;
