export enum MessageEvents {
  extendSession = 'extend-session',
  success = 'extend-session-success',
  fail = 'extend-session-failure',
}

export const retryAuth = () => {
  return new Promise((resolve, reject) => {
    let validResponse = false;

    window.addEventListener('message', function handleMessage(e: MessageEvent) {
      if (e.origin === window.origin) {
        if (e.data === MessageEvents.success) {
          resolve();
          validResponse = true;
        } else if (e.data === MessageEvents.fail) {
          reject();
          validResponse = true;
        }

        if (validResponse) {
          window.removeEventListener('message', handleMessage);
        }
      }
    });

    window.parent.postMessage(MessageEvents.extendSession, window.origin);
  });
};
