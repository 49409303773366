import { FilterOption } from '@insights/models-nwe';
import { hasValue } from '../has-value';

export const selectAllOptions = (
  options: FilterOption[],
  selectedOptions: FilterOption[],
  onChange: (d: FilterOption[]) => void
): void => {
  const allSelected = options.every((option) =>
    hasValue(selectedOptions, option)
  );
  if (allSelected) {
    // We have already selected everything, so we should deselect everything
    onChange([]);
  } else {
    // We have not selected everything, so we should select everything that is not already selected
    const newOptions = options.filter(
      (option) => !hasValue(selectedOptions, option)
    );

    onChange([...selectedOptions, ...newOptions]);
  }
};
