import React, { FC } from 'react';
import { primary } from '@phoenix/all';
import ExportIcon from 'phoenix-icons/dist/ExportIcon.js';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { ArrowDown } from '../../arrow-down';
import { HiddenAriaElement } from '../../hidden-aria-element';
import * as styles from '../styles';

export interface Props {
  disableExport: boolean;
}

const exportIconColor = (disableExport: boolean): string =>
  disableExport ? primary.gray(300) : primary.gray(600);

const ExportUI: FC<Props> = ({ disableExport }) => {
  const { Container } = styles;
  const iconColor = exportIconColor(disableExport);

  return (
    <Container disableExport={disableExport}>
      <ExportIcon className={styles.exportIcon} color={iconColor} />
      {!disableExport && <ArrowDown color={iconColor} />}
      <HiddenAriaElement tag="span">
        <FormattedMessage id={MessageKeys.export} />
      </HiddenAriaElement>
    </Container>
  );
};

export default ExportUI;
