import { differenceInDays, addDays, isSameDay } from 'date-fns';
import {
  BurndownChartDatum,
  Project,
  ProjectBurndown,
  RebaseDatum,
  TaskHoursDatum,
} from '@insights/models-nwe';
import { HourGrain } from '@insights/constants-nwe';
import { checkIfDateBefore } from '@insights/shared-components-nwe';
import { getSlope } from '../line-slope/index';

const PROJECT_PERCENT = 0.2;
const DEFAULT_TASK_HOUR_OBJ: TaskHoursDatum = {
  tasksAdded: 0,
  tasksCompleted: 0,
  hoursAdded: 0,
  hoursCompleted: 0,
};

export const getVelocityPlannedHours = (
  data: BurndownChartDatum[],
  project: Pick<Project, 'plannedcompletiondate' | 'plannedstartdate'> & {
    [key: string]: unknown;
  }
) => {
  const daysDiff = differenceInDays(
    project.plannedcompletiondate,
    project.plannedstartdate
  );

  const rangeEndDate = addDays(
    project.plannedstartdate,
    daysDiff * PROJECT_PERCENT
  );

  let highestHours = 0;

  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    if (d.currentDate > rangeEndDate) break;
    if (d.plannedHours !== undefined && d.plannedHours > highestHours) {
      highestHours = d.plannedHours;
    }
  }

  return highestHours;
};

export const getRebaseData = (
  data: BurndownChartDatum[],
  project: Pick<Project, 'plannedcompletiondate' | 'plannedstartdate'> & {
    [key: string]: unknown;
  }
) => {
  if (data.length === 0) return [];

  const rebaseDataset: RebaseDatum[] = [];
  let plannedCompletion: Date = data[0].plannedCompletionDate;
  let slope = getSlope(
    data[0].plannedStartDate,
    plannedCompletion,
    data[0].plannedHours
  );

  const pushRebaseDataset = (date: Date, hoursRemaining: number) => {
    rebaseDataset.push({
      date,
      hoursRemaining,
    });
  };

  pushRebaseDataset(data[0].currentDate, slope(data[0].currentDate));
  pushRebaseDataset(plannedCompletion, 0);

  for (let i = 1; i < data.length; i++) {
    // plotting points for rebase line
    const d = data[i];
    if (
      d.plannedCompletionDate.getTime() !== plannedCompletion.getTime() &&
      d.hoursRemaining !== undefined
    ) {
      if (checkIfDateBefore(d.currentDate, plannedCompletion))
        rebaseDataset.pop();
      pushRebaseDataset(d.currentDate, slope(d.currentDate));
      pushRebaseDataset(d.currentDate, d.hoursRemaining);
      pushRebaseDataset(d.plannedCompletionDate, 0);
      plannedCompletion = d.plannedCompletionDate;
      slope = getSlope(
        d.currentDate,
        d.plannedCompletionDate,
        d.hoursRemaining
      );
    }
  }

  return rebaseDataset;
};

type TaskHoursBurndown = Pick<
  ProjectBurndown,
  | 'currentdate'
  | 'taskaddeddaily'
  | 'taskcompleteddaily'
  | 'plannedDurationHoursDaily'
  | 'plannedHoursDaily'
  | 'completedDurationHours'
  | 'completedPlannedHours'
>;

const pullOutTaskHours = (data: TaskHoursBurndown[], hourGrain: HourGrain) => {
  const d = data[data.length - 1];
  return {
    tasksAdded: d.taskaddeddaily,
    tasksCompleted: d.taskcompleteddaily,
    hoursAdded:
      hourGrain === HourGrain.plannedDuration
        ? d.plannedDurationHoursDaily
        : d.plannedHoursDaily,
    hoursCompleted:
      hourGrain === HourGrain.plannedDuration
        ? d.completedDurationHours
        : d.completedPlannedHours,
  };
};

export const getTaskHourData = (
  data: TaskHoursBurndown[],
  clickedDate: Date,
  hourGrain: HourGrain
) => {
  if (data.length === 0) return undefined;

  const dataStart = data[0].currentdate;
  const dataEnd = data[data.length - 1].currentdate;

  if (clickedDate < dataStart || clickedDate > dataEnd) return undefined;

  // @TODO: This may be an area of optimization at a later date
  const taskHourDataFiltered = data.filter((d) =>
    isSameDay(clickedDate, d.currentdate)
  );

  return taskHourDataFiltered.length > 0
    ? pullOutTaskHours(taskHourDataFiltered, hourGrain)
    : DEFAULT_TASK_HOUR_OBJ;
};

export const getBurndownChartData = <
  T extends Pick<
    ProjectBurndown,
    'currentdate' | 'plannedcompletiondate' | 'projectcond' | 'plannedstartdate'
  >
>(
  data: T[],
  hoursRemaining: (d: T) => number | undefined,
  plannedHours: (d: T) => number | undefined,
  date: (d: T) => Date = (d) => d.currentdate,
  plannedStartDate: (d: T) => Date = (d) => d.plannedstartdate,
  plannedCompletionDate: (d: T) => Date = (d) => d.plannedcompletiondate,
  condition: (d: T) => string = (d) => d.projectcond
): BurndownChartDatum[] =>
  data.map((d) => ({
    currentDate: date(d),
    plannedStartDate: plannedStartDate(d),
    plannedCompletionDate: plannedCompletionDate(d),
    hoursRemaining: hoursRemaining(d),
    plannedHours: plannedHours(d),
    condition: condition(d),
  }));
