import styled from 'react-emotion';

interface Props {
  color: string;
}

export default styled('div')(({ color }: Props) => ({
  backgroundColor: color,
  height: '100%',
  width: '8px',
  marginRight: '12px',
}));
