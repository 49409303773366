import { useIntl, FormatNumberOptions } from 'react-intl';

export function useFormattedNumber(
  value: number,
  options?: FormatNumberOptions
): string {
  const intl = useIntl();

  return intl.formatNumber(value, options);
}
