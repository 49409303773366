import { BasicOperator, CustomFormOperator } from '@insights/models-nwe';
import { MessageKeys } from '@insights/i18n-nwe';
import { GraphqlFilterKey } from '@insights/constants-nwe';

export const COLUMN_LABELS: { [key in GraphqlFilterKey]: string } = {
  [GraphqlFilterKey.projectguid]: MessageKeys.project,
  [GraphqlFilterKey.programguid]: MessageKeys.program,
  [GraphqlFilterKey.portfolioguid]: MessageKeys.portfolio,
  [GraphqlFilterKey.condition]: MessageKeys.condition,
  [GraphqlFilterKey.status]: MessageKeys.status,
  [GraphqlFilterKey.teamguid]: MessageKeys.team,
  [GraphqlFilterKey.sponsorguid]: MessageKeys.sponsor,
  [GraphqlFilterKey.ownerguid]: MessageKeys.projectowner,
  [GraphqlFilterKey.customfields]: MessageKeys.customForm,
};

export const OPERATOR_LABELS: {
  [key in BasicOperator | CustomFormOperator]: string;
} = {
  [BasicOperator.contains]: MessageKeys.contains,
  [BasicOperator.in]: MessageKeys.contains,
  [BasicOperator.notIn]: MessageKeys.doesNotEqual,
  [CustomFormOperator.allof]: MessageKeys.equals,
  [CustomFormOperator.cicontainsall]: MessageKeys.equals,
  [CustomFormOperator.cicontainsany]: MessageKeys.contains,
  [CustomFormOperator.in]: MessageKeys.contains,
};
