import { useState, useCallback } from 'react';
import { FilterOption } from '@insights/models-nwe';
import {
  selectOptionMultiSelect,
  selectAllOptions,
  includeSelectAllOption,
  SELECT_ALL_FILTER_OPTION,
} from '../services';

interface UseMultiSelectReturnValue {
  options: FilterOption[];
  selectedOptions: FilterOption[];
  onSelectOption(option: FilterOption): void;
}

export const useMultiSelect = (
  data: FilterOption[]
): UseMultiSelectReturnValue => {
  const options = includeSelectAllOption(data);
  const [selectedOptions, updateSelectedOptions] = useState<FilterOption[]>([]);

  const onSelectOption = useCallback(
    (option: FilterOption): void => {
      if (option.value === SELECT_ALL_FILTER_OPTION.value) {
        selectAllOptions(data, selectedOptions, updateSelectedOptions);
      } else {
        selectOptionMultiSelect(option, selectedOptions, updateSelectedOptions);
      }
    },
    [
      SELECT_ALL_FILTER_OPTION,
      selectAllOptions,
      selectOptionMultiSelect,
      data,
      selectedOptions,
      updateSelectedOptions,
    ]
  );

  return {
    options,
    selectedOptions,
    onSelectOption,
  };
};
