import { SpreadSheetData } from '@insights/models-nwe';
import { BurndownFragment } from '../../../../queries/get-burndown';

export interface Headers {
  date: string;
  projectID: string;
  projectName: string;
  plannedStartDate: string;
  plannedCompletionDate: string;
  projectCondition: string;
  conditionLabel: string;
  tasksAddedDaily: string;
  tasksCompletedDaily: string;
  plannedDurationDaily: string;
  completedPlannedDurationDaily: string;
  plannedHoursDaily: string;
  completedPlannedHoursDaily: string;
  plannedDuration: string;
  remainingDuration: string;
  plannedHours: string;
  remainingHours: string;
}

interface BurndownObject {
  burndownData: BurndownFragment[];
  projectName: string;
}

export const shapeData = (headers: Headers, burndownObject: BurndownObject) => {
  const { burndownData, projectName } = burndownObject;

  return burndownData.map((details) => {
    return {
      [headers.date]: details.currentdate,
      [headers.projectID]: details.projectguid,
      [headers.projectName]: projectName,
      [headers.plannedStartDate]: details.plannedstartdate,
      [headers.plannedCompletionDate]: details.plannedcompletiondate,
      [headers.projectCondition]: details.projectcond,
      [headers.conditionLabel]: details.conditionlabel,
      [headers.tasksAddedDaily]: details.taskaddeddaily,
      [headers.tasksCompletedDaily]: details.taskcompleteddaily,
      [headers.plannedDurationDaily]: details.plannedDurationDaysDaily,
      [headers.completedPlannedDurationDaily]: details.completedDurationDays,
      [headers.plannedHoursDaily]: details.plannedHoursDaily,
      [headers.completedPlannedHoursDaily]: details.completedPlannedHours,
      [headers.plannedDuration]: details.plannedDurationDays,
      [headers.remainingDuration]: details.durationRemaining,
      [headers.plannedHours]: details.plannedHours,
      [headers.remainingHours]: details.hoursRemaining,
    };
  });
};

export const shapeBurndownDataForXLSX = (
  name: string,
  headers: Headers,
  chartData: BurndownObject
): SpreadSheetData => {
  const burndownTemplate = shapeData(headers, chartData);
  return {
    data: {
      data: burndownTemplate,
    },
    name,
  };
};
