import { css } from 'emotion';

export const root = css`
  position: relative;
  width: 100%;
  height: 52px;
`;

export const zeroState = css`
  height: 100%;
  display: flex;
  position: absolute;
  width: 100%;
`;
