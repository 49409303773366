import React, { FC, useState, useMemo } from 'react';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';
import { SpreadSheetData, DateRange } from '@insights/models-nwe';
import { ParentSize } from '@vx/responsive';
import { CardLayout } from '@insights/shared-components-nwe';
import { TasksInFlightSortBy, ChartType } from '@insights/constants-nwe';
import GetTasks from './queries/get-tasks';
import {
  shapeTasksInFlightDataForXLSX,
  sortTasks,
  filterTasksOutsideDateRange,
} from './utils';
import TasksInFlightA11yCharts from './chart/chart-parts/a11y/index';
import { TasksInFlightChart } from './chart';
import TasksInFlightHeader from './header';
import TasksInFlightLegend from './legend';

export interface Props {
  projectId?: string;
  projectName?: string;
  dateRange: DateRange;
  zoomRange?: DateRange;
  onZoom(zoomRange: DateRange): void;
}

export const TasksInFlight: FC<Props> = ({
  projectId,
  projectName,
  dateRange,
  zoomRange,
  onZoom,
}) => {
  const [sortByValue, changeSortBy] = useState(
    TasksInFlightSortBy.completionDate
  );
  const { formatMessage } = useIntl();
  if (!projectId) return null;

  return (
    <GetTasks projectId={projectId}>
      {({ data, loading, error }) => {
        const chartProjectName =
          projectName || formatMessage({ id: MessageKeys.noName });
        const workingDateRange = zoomRange || dateRange;
        const sortedData = useMemo(
          () => (data ? sortTasks(data, sortByValue) : []),
          [data, sortByValue]
        );
        const filteredTasks = useMemo(
          () =>
            filterTasksOutsideDateRange(
              sortedData,
              workingDateRange.start,
              workingDateRange.end
            ),
          [sortedData, workingDateRange]
        );
        const chart = () => {
          if (loading) return <FormattedMessage id={MessageKeys.loading} />;
          if (error) return <FormattedMessage id={MessageKeys.error} />;

          return (
            <ParentSize>
              {({ width }: { width: number }) => (
                <>
                  {filteredTasks.length > 0 ? (
                    <TasksInFlightA11yCharts
                      data={filteredTasks}
                      projectName={chartProjectName}
                      dateRange={workingDateRange}
                    />
                  ) : null}
                  <TasksInFlightChart
                    tasks={filteredTasks}
                    totalTaskCount={sortedData.length}
                    width={width}
                    dateRange={dateRange}
                    zoomRange={zoomRange}
                    onZoom={onZoom}
                  />
                </>
              )}
            </ParentSize>
          );
        };

        const getData = (): SpreadSheetData =>
          shapeTasksInFlightDataForXLSX(
            formatMessage(
              { id: MessageKeys.tasksInFlightExportXlsx },
              { startDate: dateRange.start, endDate: dateRange.end }
            ),
            {
              ID: formatMessage({ id: MessageKeys.ID }),
              name: formatMessage({ id: MessageKeys.name }),
              plannedStartDate: formatMessage({
                id: MessageKeys.plannedStartDate,
              }),
              plannedCompletionDate: formatMessage({
                id: MessageKeys.plannedCompletionDate,
              }),
              actualStartDate: formatMessage({
                id: MessageKeys.actualStartDate,
              }),
              percentComplete: formatMessage({
                id: MessageKeys.percentComplete,
              }),
              wbs: formatMessage({ id: MessageKeys.wbs }),
              workRequired: formatMessage({ id: MessageKeys.workRequired }),
            },
            sortedData
          );

        return (
          <CardLayout
            title={ChartType.TasksInFlight}
            header={
              <TasksInFlightHeader
                projectName={chartProjectName}
                filteredTaskCount={filteredTasks.length}
                totalTaskCount={sortedData.length}
                sortBy={sortByValue}
                changeSortBy={changeSortBy}
              />
            }
            label={formatMessage(
              { id: MessageKeys.tasksInFlightHeader },
              { projectName: chartProjectName }
            )}
            legend={<TasksInFlightLegend />}
            chart={chart()}
            getData={getData}
            disableExport={!(filteredTasks.length > 0) || loading || !!error}
          />
        );
      }}
    </GetTasks>
  );
};
