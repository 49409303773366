import { isBefore } from 'date-fns';

export function checkIfDateBefore(
  // Checks to see if date one is before date 2.
  dateOne: Date | null,
  dateTwo: Date | null
): boolean | null {
  if (!dateOne || !dateTwo) return false;
  return isBefore(dateOne, dateTwo);
}
