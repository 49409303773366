import React, { FC, Ref } from 'react';
import { Text, primary } from '@phoenix/all';
import styled from 'react-emotion';

interface Props {
  className?: string;
  forwardedRef?: Ref<HTMLElement>;
  testID?: string;
}

export const FilterSegment: FC<Props> = ({ children, testID, ...props }) => (
  <Text {...props}>
    <TextStyling data-testid={testID}>{children}</TextStyling>
  </Text>
);

const TextStyling = styled.span`
  border-bottom: 1px solid ${primary.blue()};
`;
