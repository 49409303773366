import React, { FC } from 'react';
import { TooltipWithBounds } from '@vx/tooltip';

export interface TooltipWithInsightsBoundsProps {
  top: number;
  left: number;
  offsetLeft: number;
  testID?: string;
}

export const TooltipWithInsightsBounds: FC<TooltipWithInsightsBoundsProps> = ({
  top,
  left,
  offsetLeft,
  children,
  testID,
}) => (
  <TooltipWithBounds
    key={Math.random()}
    top={top}
    left={left}
    offsetLeft={offsetLeft}
    style={{
      pointerEvents: 'none',
      boxShadow: 'none',
      background: 'transparent',
      zIndex: 99,
    }}
    data-testid={testID}
  >
    {children}
  </TooltipWithBounds>
);
