import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  RawProjectBurndown,
  ProjectBurndown,
  HoursCalcs,
  DaysCalcs,
} from '@insights/models-nwe';
import { SortDirection } from '@insights/constants-nwe';

import { ApolloError } from 'apollo-client';
import { formatDate, convertToHours } from '@insights/shared-components-nwe';
import { parseDate } from '@insights/utils-nwe';

export const GET_BURNDOWN = gql`
  query ProjectBurndown($projectId: Guid!, $startDate: Date, $endDate: Date) {
    projectBurndown(
      projectID: $projectId
      queryRangeStart: $startDate
      queryRangeEnd: $endDate
      orderBy: { direction: ${SortDirection.asc}, key: currentdate }
    ) {
      projectguid
      currentdate
      projectcond
      conditionlabel
      plannedcompletiondate
      plannedstartdate
      plannedminutes
      planneddurationminutes
      completedplannedminutesdaily
      completedplanneddurationminutesdaily
      remainingminutes
      remainingdurationminutes
      taskaddeddaily
      taskcompleteddaily
      plannedminutesdaily
      planneddurationminutesdaily
    }
  }
`;

type BurndownProperties =
  | 'projectguid'
  | 'currentdate'
  | 'projectcond'
  | 'conditionlabel'
  | 'plannedcompletiondate'
  | 'plannedstartdate'
  | 'plannedminutes'
  | 'planneddurationminutes'
  | 'completedplannedminutesdaily'
  | 'completedplanneddurationminutesdaily'
  | 'remainingminutes'
  | 'remainingdurationminutes'
  | 'taskaddeddaily'
  | 'taskcompleteddaily'
  | 'plannedminutesdaily'
  | 'planneddurationminutesdaily';

export type RawBurndownFragment = Pick<RawProjectBurndown, BurndownProperties>;
export type BurndownFragment = Pick<ProjectBurndown, BurndownProperties> &
  HoursCalcs &
  DaysCalcs;

export interface Props {
  skip?: boolean;
  projectId: string;
  startDate: Date;
  endDate: Date;
  durationHoursPerDay?: number;
  children(result: {
    data: Data | undefined;
    loading: boolean;
    error: ApolloError | undefined;
  }): JSX.Element | null;
}

export interface Response {
  projectBurndown: RawBurndownFragment[];
}

export interface Data {
  projectBurndown: BurndownFragment[];
}

export interface Variables {
  projectId: string;
  startDate: string;
  endDate: string;
}

export const processBurndown = (
  data: RawBurndownFragment[],
  durationHoursPerDay?: number
): BurndownFragment[] =>
  data.map((d) => {
    let processedData: BurndownFragment = {
      ...d,
      plannedstartdate: parseDate(d.plannedstartdate),
      plannedcompletiondate: parseDate(d.plannedcompletiondate),
      currentdate: parseDate(d.currentdate),
      hoursRemaining: convertToHours(d.remainingminutes),
      remainingDurationHours: convertToHours(d.remainingdurationminutes),
      plannedHours: convertToHours(d.plannedminutes),
      plannedDurationHours: convertToHours(d.planneddurationminutes),
      plannedHoursDaily: convertToHours(d.plannedminutesdaily),
      plannedDurationHoursDaily: convertToHours(d.planneddurationminutesdaily),
      completedPlannedHours: convertToHours(d.completedplannedminutesdaily),
      completedDurationHours: convertToHours(
        d.completedplanneddurationminutesdaily
      ),
    };

    if (durationHoursPerDay) {
      processedData = {
        ...processedData,
        durationRemaining:
          processedData.remainingDurationHours / durationHoursPerDay,
        plannedDurationDays:
          processedData.plannedDurationHours / durationHoursPerDay,
        plannedDurationDaysDaily:
          processedData.plannedDurationHoursDaily / durationHoursPerDay,
        completedDurationDays:
          processedData.completedDurationHours / durationHoursPerDay,
      };
    }

    return processedData;
  });

const GetBurndown: FC<Props> = ({
  skip,
  projectId,
  startDate,
  endDate,
  durationHoursPerDay,
  children,
}) => {
  const { data, loading, error } = useQuery<Response, Variables>(GET_BURNDOWN, {
    skip,
    variables: {
      projectId,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
  });

  return children({
    data: data
      ? {
          projectBurndown: processBurndown(
            data.projectBurndown,
            durationHoursPerDay
          ),
        }
      : undefined,
    loading,
    error,
  });
};

export default GetBurndown;
