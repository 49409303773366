import React, { FC, useState } from 'react';
import { useIntl, MessageKeys } from '@insights/i18n-nwe';
import {
  FilterType,
  CustomFormOperator,
  CustomFilter,
} from '@insights/models-nwe';
import { logError } from '@insights/utils-nwe';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { formatISO } from 'date-fns';
import styled from 'react-emotion';

import {
  CalendarPopover,
  FilterSegment,
  FilterInProgress,
} from '../../components';
import { FieldOption } from '../custom-form-field-options';

export interface Props {
  onAddFilter(d: CustomFilter): void;
  initialDate?: Date; // mainly used for testing
}

export const TEST_ID = 'custom-form-date';
export const TEST_ID_INPUT = `${TEST_ID}-input`;
export const TEST_ID_POPOVER = `${TEST_ID}-popover`;

export const CalendarInput: FC<Props> = ({
  onAddFilter,
  initialDate = new Date(),
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage, formatDate } = useIntl();
  const formattedDate = formatDate(selectedDate);
  const fieldName = location.state.name;
  const operator = CustomFormOperator.allof;

  const applyFitler = (): void => {
    if (fieldName) {
      onAddFilter({
        filterType: FilterType.Custom,
        column: fieldName,
        operator,
        values: [
          {
            label: formattedDate,
            // format as YYYY-MM-DD for backend
            value: formatISO(selectedDate, { representation: 'date' }),
          },
        ],
      });
    } else {
      logError('A field name was not provided to the Custom Forms date input');
    }
  };

  return (
    <Container>
      <FilterInProgress column={fieldName} operator={operator} />
      <InputWrapper>
        <FilterSegment testID={TEST_ID_INPUT}>{formattedDate}</FilterSegment>
        <CalendarPopover
          onChange={setSelectedDate}
          onBack={() => navigate(-1)}
          date={selectedDate}
          onApply={applyFitler}
          testID={TEST_ID_POPOVER}
          header={formatMessage({ id: MessageKeys.date })}
          ariaLabel={formatMessage(
            { id: MessageKeys.customDateField },
            { customDateField: fieldName }
          )}
        />
      </InputWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-left: 6px;
`;
