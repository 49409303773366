import { useReducer, useMemo, Reducer, useEffect } from 'react';
import { ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { isStreamAPIError } from '@insights/models-nwe';

import { logError } from '../logging';
import { AsyncState, LoadingState } from './models';

export const DEFAULT_STATE: LoadingState<undefined> = {
  loading: true,
  error: undefined,
  data: undefined,
};

export const useCancelableEffect = <T>(
  promiseFn: () => Promise<T> | null,
  dependencies?: unknown[]
): AsyncState<T> => {
  const reducer = useMemo(() => getReducer<T>(), []);
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  useEffect(() => {
    let isCanceled = false;
    const promise = promiseFn();

    if (!promise) return () => null;
    if (!isCanceled) dispatch(fetchActions.request());

    promise
      .then((res) => {
        if (!isCanceled) dispatch(fetchActions.success(res));
      })
      .catch((e) => {
        if (!isCanceled) {
          dispatch(fetchActions.failure(e));

          if (isStreamAPIError(e)) {
            logError(
              `[StreamAPI Error]: Status: ${e.error.status} Message: ${e.error.message}`
            );
          } else {
            logError(e);
          }
        }
      });

    return () => {
      isCanceled = true;
    };
  }, dependencies);

  return state;
};

// using any because we do nothing with the data inside of this hook
const fetchActions = createAsyncAction(
  'FETCH_REQUEST',
  'FETCH_SUCCESS',
  'FETCH_ERROR'
)<undefined, any, Error>();

const getReducer = <T extends any>(): Reducer<
  AsyncState<T>,
  ActionType<typeof fetchActions>
> =>
  createReducer<AsyncState<T>, ActionType<typeof fetchActions>>(DEFAULT_STATE)
    .handleAction(fetchActions.request, (state) => ({
      ...state,
      loading: true,
      error: undefined,
      data: state.data,
    }))
    .handleAction(fetchActions.success, (state, action) => ({
      ...state,
      loading: false,
      error: undefined,
      data: action.payload,
    }))
    .handleAction(fetchActions.failure, (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: undefined,
    }));
