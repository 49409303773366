import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { formatDate, graphQLShape } from '@insights/shared-components-nwe';
import { Filter } from '@insights/models-nwe';
import { Data, Variables } from './models';

export const AVG_PROJECT_DURATION = gql`
  query averageProjectDuration(
    $filter: ProjectsFilterInput!
    $queryStartDate: Date!
    $queryEndDate: Date!
  ) {
    averageProjectDuration(
      filter: $filter
      queryStartDate: $queryStartDate
      queryEndDate: $queryEndDate
    ) {
      actual
      historical
    }
  }
`;

export interface Response {
  averageProjectDuration: Data;
}

const useAvgProjectDuration = (
  filter: Filter[],
  startDate: Date,
  endDate: Date
) =>
  useQuery<Response, Variables>(AVG_PROJECT_DURATION, {
    variables: {
      filter: graphQLShape(filter),
      queryStartDate: formatDate(startDate),
      queryEndDate: formatDate(endDate),
    },
  });

export default useAvgProjectDuration;
