export enum QuicksilverHyperLinks {
  FlightPlan = 'https://one.workfront.com/s/csh?context=1275&pubname=the-new-workfront-experience',
  Burndown = 'https://one.workfront.com/s/csh?context=1273&pubname=the-new-workfront-experience',
  ProjectActivity = 'https://one.workfront.com/s/csh?context=1276&pubname=the-new-workfront-experience',
  Treemap = 'https://one.workfront.com/s/csh?context=1277&pubname=the-new-workfront-experience',
  ActivityByTeam = 'https://one.workfront.com/s/csh?context=1272&pubname=the-new-workfront-experience',
  ResourceCapacity = 'https://one.workfront.com/s/csh?context=1278&pubname=the-new-workfront-experience',
  TeamCapacity = 'https://one.workfront.com/s/csh?context=1280&pubname=the-new-workfront-experience',
  Filtering = 'https://one.workfront.com/s/csh?context=1283&pubname=the-new-workfront-experience',
  TasksInFlight = 'https://one.workfront.com/s/csh?context=1279&pubname=the-new-workfront-experience',
  KPI = 'https://one.workfront.com/s/csh?context=1282&pubname=the-new-workfront-experience',
}
