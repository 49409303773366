import React, { FC } from 'react';
import { Text } from '@phoenix/all';

import LegendItem from '../legend-item';

import Bar from './bar';

export interface Props {
  color: string;
  label: React.ReactNode;
  testID?: string;
}

const LegendBarItem: FC<Props> = ({ color, label, testID }) => (
  <LegendItem>
    <Bar color={color} data-testid="bar" />
    <Text.Small data-testid={testID}>{label}</Text.Small>
  </LegendItem>
);

export default LegendBarItem;
