import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { formatDate, graphQLShape } from '@insights/shared-components-nwe';
import { Filter } from '@insights/models-nwe';
import { Data, Variables } from './models';

export const AVG_TASKS_PER_PROJECT = gql`
  query AvgTasksPerProject(
    $filter: ProjectsFilterInput!
    $queryStartDate: Date!
    $queryEndDate: Date!
  ) {
    averageTasksPerProject(
      filter: $filter
      queryStartDate: $queryStartDate
      queryEndDate: $queryEndDate
    ) {
      actual
      historical
    }
  }
`;

export interface Response {
  averageTasksPerProject: Data;
}

const useAvgTasksPerProject = (
  filter: Filter[],
  startDate: Date,
  endDate: Date
) =>
  useQuery<Response, Variables>(AVG_TASKS_PER_PROJECT, {
    variables: {
      filter: graphQLShape(filter),
      queryStartDate: formatDate(startDate),
      queryEndDate: formatDate(endDate),
    },
  });

export default useAvgTasksPerProject;
