import React, { FC } from 'react';
import { primary } from '@phoenix/all';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import styled from 'react-emotion';

import GradientBar from './gradientBar';

interface Props {
  height: number;
  legendLabels: { label: MessageKeys; offset: number }[];
  gradientId: string;
}

const SVG_WIDTH = 120;

const GradientLegend: FC<Props> = ({ legendLabels, height, gradientId }) => (
  <svg height={height} width={SVG_WIDTH} viewBox={`0 0 ${SVG_WIDTH} ${height}`}>
    <GradientBar height={height} gradientId={gradientId} />
    {legendLabels.map((i) => (
      <LegendText x={18} y={i.offset * height} key={i.label}>
        <FormattedMessage id={i.label} />
      </LegendText>
    ))}
  </svg>
);

export default GradientLegend;

const LegendText = styled.text`
  font-size: 0.75rem;
  fill: ${primary.gray(500)};
`;
