import { wfetch, encodeAsFormData } from '@wf-mfe/api';
import { Category } from 'workfront-objcodes';
import { useCancelableEffect } from '@insights/utils-nwe';

interface StreamAPIOptions {
  ID: string;
  name: string;
  ObjCode: string;
  categoryParameters: StreamAPIParameters[];
}

interface StreamAPIParameters {
  parameter: Option;
  objCode: string;
  displayOrder: number;
}

export interface Option {
  ID: string;
  name: string;
  displayType: string;
  dataType: string;
  refObjCode: string | null;
}

export interface Response {
  data: Option[] | undefined;
  loading: boolean;
  error: Error | undefined;
}

const useCustomFieldsForFormQuery = (formID: string): Response => {
  return useCancelableEffect((): Promise<Option[]> => {
    return wfetch(`/attask/api-internal/${Category}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeAsFormData({
        method: 'get',
        ID: formID,
        fields: [
          'categoryParameters:parameter:displayType',
          'categoryParameters:parameter:dataType',
          'categoryParameters:parameter:refObjCode',
          'categoryParameters:displayOrder',
        ],
      }),
    }).then(formOptions);
  }, [formID]);
};

export default useCustomFieldsForFormQuery;

const formOptions = (options: StreamAPIOptions): Option[] => {
  return options.categoryParameters
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((o) => o.parameter);
};
