import React, { FC } from 'react';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { calcDaysBetweenDates } from '../../utilities/calculate-days-between-dates';
import { display } from '../styles';

export interface Props {
  startDate: Date;
  endDate: Date;
  messageKey?: MessageKeys;
  className?: string;
}

const DurationDays: FC<Props> = ({
  startDate,
  endDate,
  className = display,
}) => {
  const duration = calcDaysBetweenDates(startDate, endDate);

  return (
    <span className={className}>
      <FormattedMessage
        id={MessageKeys.durationDays}
        values={{
          numberOfDays: duration,
        }}
      />
    </span>
  );
};

export default DurationDays;
