import { useState, useMemo } from 'react';
import { FilterOption } from '@insights/models-nwe';
import { filterInput } from '../services/filter-input';

export const useSearch = <T extends FilterOption>(
  initialSearchText: string,
  data?: T[]
) => {
  const [searchText, updateSearchText] = useState(initialSearchText);

  const filteredOptions = useMemo(
    () => (data ? filterInput(searchText, data) : []),
    [searchText, data]
  );

  return {
    searchText,
    filteredOptions,
    updateSearchText,
  };
};
