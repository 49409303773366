import {
  ProjectActivitySummaryDatum,
  SpreadSheetData,
  DateRange,
} from '@insights/models-nwe';
import { extractActivityData, ActivityHeaders } from '../utils/index';

interface ActivityObject {
  [key: string]: Date;
}

interface Headers extends ActivityHeaders {
  projectID: string;
  projectName: string;
}

export const shapeData = (
  headers: Headers,
  chartData: ProjectActivitySummaryDatum,
  dateRange: DateRange
) => {
  const data = chartData.PROJECTS.map((project) => {
    const projectLevelDetail = {
      [headers.projectID]: project.guid,
      [headers.projectName]: project.name,
    };

    return extractActivityData(
      headers,
      projectLevelDetail,
      project.activities,
      dateRange
    );
  });

  return data.reduce((accum, projectArr) => {
    return [...accum, ...projectArr];
  }, []);
};

export const shapeProjectActivityDataForXLSX = (
  name: string,
  headers: Headers,
  chartData: ProjectActivitySummaryDatum,
  dateRange: DateRange
): SpreadSheetData => {
  const projectActivity = shapeData(headers, chartData, dateRange);

  return {
    data: {
      data: projectActivity,
    },
    name,
  };
};
