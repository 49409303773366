/* Disabling coverage because most of the components used in this router will be covered and the rest will be through e2e tests */
/* istanbul ignore file */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { MemoryRouter } from 'react-router-dom';
import { CompatRouter, Routes, Route } from 'react-router-dom-v5-compat';
import { Setup } from '@insights/clients-nwe';
import { Page } from '@insights/constants-nwe';
import { Filter } from '@insights/models-nwe';
import styled from 'react-emotion';
import { css } from 'emotion';
import { HandleClickOutside } from '@phoenix/all';
import {
  FilterColumns,
  Standard,
  CustomFormOptions,
  CustomFormFieldOptions,
  CalendarInput,
  CustomFieldMultiSelect,
  CustomFieldNumberInput,
  CustomFieldTextInput,
  UnsupportedFormField,
} from '../views';
import { FilterRoutes } from '../constants/filter-routes';
import { comboBoxWidth } from '../constants/combo-box';

interface Props {
  page: Page;
  filters: Filter[];
  toggleFilter(): void;
  onAddFilter(d: Filter): void;
}

export const FilterRouter: FC<Props> = ({
  page,
  filters,
  toggleFilter,
  onAddFilter,
}) => {
  const addFilter = useCallback(
    (filter: Filter) => {
      onAddFilter(filter);
      toggleFilter();
    },
    [onAddFilter, toggleFilter]
  );

  return (
    <HandleClickOutside onOutsideClick={toggleFilter}>
      {() => (
        <RouterContainerNewContext>
          <MemoryRouter>
            <CompatRouter>
              <Routes>
                <Route
                  path={FilterRoutes.default}
                  element={<FilterColumns page={page} filters={filters} />}
                />
                <Route
                  path={FilterRoutes.standard}
                  element={<Standard onAddFilter={addFilter} filters={filters} />}
                />
                <Route
                  path={FilterRoutes.customForm}
                  element={<CustomFormOptions />}
                />
                <Route
                  path={FilterRoutes.customFields}
                  element={<CustomFormFieldOptions filters={filters} />}
                />
                <Route
                  path={FilterRoutes.date}
                  element={<CalendarInput onAddFilter={addFilter} />}
                />
                <Route
                  path={FilterRoutes.options}
                  element={
                    <CustomFieldMultiSelect
                      toggleFilter={toggleFilter}
                      onAddFilter={onAddFilter}
                    />
                  }
                />
                <Route
                  path={FilterRoutes.unsupported}
                  element={<UnsupportedFormField />}
                />
                <Route
                  path={FilterRoutes.text}
                  element={
                    <CustomFieldTextInput
                      toggleFilter={toggleFilter}
                      onAddFilter={onAddFilter}
                    />
                  }
                />
                <Route
                  path={FilterRoutes.number}
                  element={
                    <CustomFieldNumberInput
                      toggleFilter={toggleFilter}
                      onAddFilter={onAddFilter}
                    />
                  }
                />
              </Routes>
              </CompatRouter>
          </MemoryRouter>
        </RouterContainerNewContext>
      )}
    </HandleClickOutside>
  );
};

const RouterContainer = styled.div`
  display: flex;
  position: relative;
  min-width: ${comboBoxWidth};
`;

const containerStyle = css`
  display: flex;
  position: relative;
  min-width: ${comboBoxWidth};
`

// ** React Router v6 does not currently allow nested routers, so to get around this,
// we are rendering the filters component in a separate context
// See suggestion here: https://github.com/remix-run/react-router/pull/9112#issuecomment-1241077187
// Also feature proposal here: https://github.com/remix-run/react-router/discussions/9601
const RouterContainerNewContext = ({
  children,
}: { children: any }) => {

  const ref = useRef(null);
  useEffect(() => {
      ReactDOM.render(<Setup>{children}</Setup>, ref.current);
  }, []);

  return <div className={containerStyle} ref={ref}/>
}

