import {
  ProjectMagnitudeChartDatum,
  ProjectHoursSummaryDatum,
  ProjectMagnitudeChartDatumNoDurationDays,
} from '@insights/models-nwe';
import { logError } from '@insights/utils-nwe';
import { ConditionsMap } from '@insights/clients-nwe';
import { isConditionType } from '@insights/constants-nwe';

import { ProjectFragment } from '.';

export const durationHoursPerDayError =
  'The customer preference field hoursPerDay returned as undefined, null, or could not be converted to a number.';

export const mergeProjectMagnitudeDatasets = (
  projects: Map<string, ProjectFragment>,
  magnitudes: ProjectHoursSummaryDatum[],
  conditionsMap: ConditionsMap | undefined
): ProjectMagnitudeChartDatumNoDurationDays[] => {
  const filteredMagnitudes = magnitudes.filter(
    (d) =>
      (d.completedplannedhours > 0 || d.completeddurationhours > 0) &&
      projects.has(d.projectguid)
  );
  return filteredMagnitudes.map((d) => {
    const project = projects.get(d.projectguid);
    const conditionInfo = project?.condition
      ? conditionsMap?.get(project.condition)
      : undefined;
    return {
      ...d,
      ...(project as ProjectFragment),
      condition: conditionInfo?.equatesWith,
      // we should only set the custom condition label if we have a custom condition
      conditionlabel: isConditionType(project?.condition)
        ? undefined
        : conditionInfo?.label,
    };
  });
};

export const includeDurationDays = (
  data: ProjectMagnitudeChartDatumNoDurationDays[],
  hoursPerDay: number | undefined
): ProjectMagnitudeChartDatum[] => {
  return data.map((project) => ({
    ...project,
    completeddurationdays: hoursPerDay
      ? project.completeddurationhours / hoursPerDay
      : undefined,
  }));
};

export const processProjectMagnitudeData = (
  projectHoursForDateRange: ProjectHoursSummaryDatum[] | undefined,
  projects: Map<string, ProjectFragment>,
  durationHoursPerDay: number | undefined,
  conditionsMap: ConditionsMap | undefined
): ProjectMagnitudeChartDatum[] => {
  if (!projectHoursForDateRange) return [];

  const mergedDataSets = mergeProjectMagnitudeDatasets(
    projects,
    projectHoursForDateRange,
    conditionsMap
  );
  return includeDurationDays(mergedDataSets, durationHoursPerDay);
};

export const validateDurationHoursPerDay = (
  durationHoursPerDay: number | undefined
) => {
  if (!durationHoursPerDay) {
    logError(durationHoursPerDayError);
    return Error(durationHoursPerDayError);
  }

  return null;
};
