import { css } from 'emotion';

export const collapsePanel = css`
  margin-top: 10px;
  transition: max-height cubic-bezier(0, 1, 0, 1);

  &.expanded {
    transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
  }
`;
