import React, { FC } from 'react';
import { H4 } from '@phoenix/all';
import { QuicksilverHyperLinks } from '@insights/constants-nwe';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import { InfoTooltip } from '@insights/shared-components-nwe';

import * as styles from './styles';

const ActivityHeader: FC = () => {
  return (
    <div className={styles.root}>
      <H4 testID="people-activity-header">
        <FormattedMessage id={MessageKeys.teamActivity} />
      </H4>
      <InfoTooltip
        header={<FormattedMessage id={MessageKeys.peopleActivity} />}
        hyperlink={QuicksilverHyperLinks.ActivityByTeam}
        dataId="peopleActivity"
        body={
          <>
            <div>
              <FormattedMessage id={MessageKeys.teamActivityInfoTooltipOne} />
            </div>
            <br />
            <div>
              <FormattedMessage id={MessageKeys.teamActivityInfoTooltipTwo} />
            </div>
          </>
        }
        buttonDescription={
          <FormattedMessage
            id={MessageKeys.tooltipButtonDescriptionActivityByTeam}
          />
        }
      />
    </div>
  );
};

export default ActivityHeader;
