import { useEffect, useState } from 'react';
import { wfetch } from '@wf-mfe/api';

export type Messages = { [key: string]: string };

export const useFetchMessages = (keys: string[], overrides?: Messages) => {
  const [messages, setMessages] = useState<Messages | undefined>(overrides);
  const [done, setDone] = useState(keys.length === 0);

  useEffect(() => {
    if (!done) {
      wfetch('/messages', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ keys }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res: Response) => (res.ok ? res.json() : Promise.reject(res)))
        .then((messagesRes: Messages) => {
          setMessages(mergeMessages(messagesRes, overrides));
        })
        .catch(() => {
          console.warn('Unable to fetch message keys');
        })
        .finally(() => {
          setDone(true);
        });
    }
  }, []);

  return {
    done,
    messages,
  };
};

const mergeMessages = (messages: Messages, overrides?: Messages) => {
  return Object.keys(messages).reduce(
    (acc, messageKey) => {
      if (messages[messageKey] === `?${messageKey}?`) {
        if (overrides && overrides[messageKey]) {
          acc[messageKey] = overrides[messageKey];
        }
      }

      return acc;
    },
    {
      ...messages,
    }
  );
};
