import React, { FC } from 'react';
import styled from 'react-emotion';

const ContentHolderComponent: FC = ({ children }) => (
  <ContentHolderStyled>{children}</ContentHolderStyled>
);

const ContentHolderStyled = styled.div`
  top: 10px;
  background-color: #fafafa;
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
`;

export default ContentHolderComponent;
