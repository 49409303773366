import { Dispatch, bindActionCreators } from 'redux';
import { Page, ChartType } from '@insights/constants-nwe';
import { DateRange } from '@insights/models-nwe';
import {
  getZoomDateRange,
  getDateRange,
  getBurndownHourGrain,
  setHourGrain,
  setZoomFilter,
  connect,
} from '@insights/store-nwe';
import Burndown, { Props } from './burndown-chart';

type StateProps = Pick<Props, 'dateRange' | 'zoomRange' | 'hourGrain'>;
type DispatchProps = Pick<Props, 'onZoom' | 'onHourGrainChange'>;

export default connect<StateProps, DispatchProps, Props>(
  (state) => ({
    dateRange: getDateRange(state),
    zoomRange: getZoomDateRange(state, Page.work),
    hourGrain: getBurndownHourGrain(state),
  }),
  (dispatch: Dispatch): DispatchProps =>
    bindActionCreators(
      {
        onZoom: (dateRange: DateRange) =>
          setZoomFilter(dateRange, Page.work, ChartType.Burndown),
        onHourGrainChange: setHourGrain,
      },
      dispatch
    )
)(Burndown);
