import React, { FC, useMemo, useCallback, useState, ReactNode } from 'react';
import { Group } from '@vx/group';
import { scaleBand } from '@vx/scale';
import { eachDayOfInterval } from 'date-fns';
import { ScaleBand } from 'd3-scale';
import { primary } from '@phoenix/all';
import { localPoint } from '@vx/event';
import { withTooltip } from '@vx/tooltip';
import {
  DateRange,
  Extent,
  Margin,
  MaxActivitiesByType,
  GroupedActivity,
  TopLevelGroupedActivity,
  TooltipProps,
} from '@insights/models-nwe';
import {
  ActivityType,
  ActivityLabels,
  Orientation,
  ACTIVITY_LABELS_TO_MESSAGE_KEY,
  ChartType,
} from '@insights/constants-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { ChartMargins, LabelOffset } from '@insights/styles-nwe';
import {
  GridRows,
  CategoryAxis,
  DateAxis,
  TickProps,
  TodayMarker,
  AxisLabel,
  BrushHighlight,
  Overlay,
  ScrollYOverlay,
  AvatarTick,
  TooltipTick,
} from '@insights/chart-parts-nwe';
import {
  getDateScale,
  indexify,
  useZoom,
  shouldZoom,
  ZeroState,
} from '@insights/shared-components-nwe';

import ActivityGroup, {
  Props as ActivityGroupProps,
} from '../chart-parts/activity-group';
import { getGradientScaleByType } from '../../services/color-scales/activity-color-scale';
import ActivityTooltip, { ActivityTooltipProps } from '../chart-parts/tooltip';

export interface UserProps {
  guid: string;
  dateScale: ScaleBand<Date>;
  categoryScale: ScaleBand<string>;
  onSegmentMouseOver: ActivityGroupProps['onSegmentMouseOver'];
  onSegmentMouseOut: ActivityGroupProps['onSegmentMouseOut'];
}

export interface OwnProps {
  data: GroupedActivity[];
  maxActivities: MaxActivitiesByType;
  dateRange: DateRange;
  label: string;
  height?: number;
  width?: number;
  margin?: Margin;
  zoomRange?: DateRange;
  getName?(data: {}): string;
  getId?(data: {}): string;
  getChildren?(data: {}): string[];
  onZoom(range: DateRange): void;
  renderUsers(props: UserProps): ReactNode;
}

export const GROUP_HEIGHT = 45;
export const TEST_ID = 'activity-chart';

type Props = OwnProps & TooltipProps<{ data: ActivityTooltipProps }>;

export const getDateIntervalScale = (
  range: DateRange,
  width: number
): ScaleBand<Date> => {
  // @TODO add logic to change grain based on date range
  const days = eachDayOfInterval(range);
  return scaleBand({
    domain: days,
    range: [0, width],
    paddingInner: 2 / (width / days.length),
  });
};

export const getCategoryScale = (
  data: GroupedActivity[],
  height: number,
  selectedGroup: GroupedActivity | null,
  getChildren?: OwnProps['getChildren']
): ScaleBand<string> => {
  const ids = data.map((d) => d.guid);
  if (selectedGroup && getChildren) {
    const index = data.findIndex((d) => d.guid === selectedGroup.guid);
    if (index >= 0) ids.splice(index + 1, 0, ...getChildren(data[index]));
  }

  return scaleBand({
    domain: ids,
    range: [0, height],
  });
};

export const getScrollHeight = (
  data: GroupedActivity[],
  selectedGroup: GroupedActivity | null,
  getChildren?: OwnProps['getChildren']
): number =>
  (data.length +
    (selectedGroup && getChildren ? getChildren(selectedGroup).length : 0)) *
  GROUP_HEIGHT;

const Chart: FC<Props> = ({
  width = 800,
  height = 300,
  data,
  maxActivities,
  dateRange,
  zoomRange,
  label,
  hideTooltip,
  showTooltip,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  tooltipOpen,
  onZoom,
  getName = (d: TopLevelGroupedActivity): string => d.name,
  getId = (d: TopLevelGroupedActivity): string => d.guid,
  getChildren,
  renderUsers,
}) => {
  if (data.length === 0)
    return (
      <ZeroState testID="activity-chart-">
        <FormattedMessage id={MessageKeys.noActivity} />
      </ZeroState>
    );

  const xMax = width - ChartMargins.left - ChartMargins.right;
  const yMax = height - ChartMargins.top - ChartMargins.bottom;
  const scrollYOverlayWidth =
    xMax + ChartMargins.left <= 0 ? 0 : xMax + ChartMargins.left;
  const overlayWidth = xMax <= 0 ? 0 : xMax;
  const [selectedGroup, setSelectedGroup] = useState<GroupedActivity | null>(
    null
  );
  const groupMap = useMemo(() => indexify(data, (d) => d.guid), [data]);
  const scrollHeight = useMemo(
    () => getScrollHeight(data, selectedGroup, getChildren),
    [data, selectedGroup, getChildren]
  );
  const continuousDateScale = useMemo(
    () => getDateScale(xMax, dateRange, zoomRange, false),
    [xMax, dateRange, zoomRange]
  );
  const [brush, brushHandlers] = useZoom(
    ({ x0, x1 }: Extent) => {
      if (shouldZoom(x0, x1, zoomRange))
        onZoom({
          start: continuousDateScale.invert(x0),
          end: continuousDateScale.invert(x1),
        });
    },
    width,
    height,
    ChartMargins
  );
  const categoryScale = useMemo(
    () => getCategoryScale(data, scrollHeight, selectedGroup, getChildren),
    [data, scrollHeight, selectedGroup, getChildren]
  );
  const dateScale = useMemo(
    () => getDateIntervalScale(zoomRange || dateRange, xMax),
    [xMax, zoomRange, dateRange]
  );
  const gradientScales = useMemo(() => getGradientScaleByType(maxActivities), [
    maxActivities,
  ]);

  const handleTooltip = useCallback(
    (
      event: React.MouseEvent,
      id: string,
      activityType: ActivityType,
      date: Date,
      count: number
    ): void => {
      const { x, y } = localPoint(event);

      showTooltip({
        tooltipData: { data: { activityType, date, count } },
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip]
  );

  const handleGroupClick = useCallback(
    (id: string) => {
      if (selectedGroup && selectedGroup.guid === id) setSelectedGroup(null);
      else {
        const group = data.find((d) => d.guid === id);
        if (group) setSelectedGroup(group);
      }
    },
    [selectedGroup, setSelectedGroup, data]
  );

  return (
    <>
      <svg
        width={width}
        height={height}
        data-testid={TEST_ID}
        id={
          label ===
          ACTIVITY_LABELS_TO_MESSAGE_KEY[ActivityLabels.projectActivityLabel]
            ? ChartType.ProjectActivity
            : ChartType.PeopleActivity
        }
        fill="white"
        fontFamily="Proxima Nova"
        overflow="hidden"
      >
        <Group top={ChartMargins.top} left={ChartMargins.left}>
          <DateAxis top={yMax} scale={continuousDateScale} />
          <AxisLabel
            label={label}
            labelOffset={LabelOffset}
            orientation={Orientation.left}
            range={[0, yMax]}
          />
          <ScrollYOverlay
            width={scrollYOverlayWidth}
            height={yMax}
            x={-LabelOffset}
            scrollHeight={scrollHeight}
          >
            <CategoryAxis
              scale={categoryScale}
              tickComponent={({ formattedValue, ...rest }: TickProps) => {
                if (groupMap[formattedValue])
                  // is project/team
                  return (
                    <TooltipTick
                      formattedValue={getName(groupMap[formattedValue])}
                      {...rest}
                    />
                  );

                return <AvatarTick {...rest} formattedValue={formattedValue} />;
              }}
              activeLabel={selectedGroup && selectedGroup.guid}
              onClick={handleGroupClick}
            />
            <Overlay
              width={overlayWidth}
              height={scrollHeight}
              onMouseDown={brushHandlers.onBrushStart}
              onMouseMove={brushHandlers.onBrushDrag}
              onMouseUp={brushHandlers.onBrushEnd}
              testId={
                label ===
                ACTIVITY_LABELS_TO_MESSAGE_KEY[
                  ActivityLabels.projectActivityLabel
                ]
                  ? 'project-activity-overlay'
                  : 'people-activity-overlay'
              }
            >
              {selectedGroup &&
                renderUsers({
                  dateScale,
                  categoryScale,
                  guid: selectedGroup.guid,
                  onSegmentMouseOver: handleTooltip,
                  onSegmentMouseOut: hideTooltip,
                })}
              {data.map((d, i) => (
                <ActivityGroup
                  key={getId(d)}
                  data={d}
                  dateScale={dateScale}
                  gradientScales={gradientScales}
                  top={categoryScale(getId(d)) || 0}
                  height={categoryScale.bandwidth()}
                  onSegmentMouseOver={handleTooltip}
                  onSegmentMouseOut={hideTooltip}
                />
              ))}
            </Overlay>
            <GridRows width={xMax} scale={categoryScale} />
          </ScrollYOverlay>
          <TodayMarker
            dateScale={continuousDateScale}
            height={yMax}
            color={primary.blue()}
          />
          <BrushHighlight
            brush={brush}
            yMax={yMax}
            dateScale={continuousDateScale}
          />
        </Group>
      </svg>
      {tooltipOpen && tooltipData.data && !brush.isBrushing && (
        <ActivityTooltip
          data={tooltipData.data}
          top={tooltipTop}
          left={tooltipLeft}
          offsetLeft={6}
        />
      )}
    </>
  );
};

const WrappedComponent: FC<OwnProps> = withTooltip(Chart);
export default WrappedComponent;
