import React, { SVGProps, forwardRef, useMemo } from 'react';
import { RectClipPath } from '@vx/clip-path';
import uniqueId from 'lodash.uniqueid';

import * as styles from './styles';

export interface Props extends SVGProps<SVGGElement> {
  width: number;
  height: number;
  top?: number;
  left?: number;
  x?: number;
  y?: number;
  testId?: string;
}

const Overlay = forwardRef<SVGGElement, Props>(
  (
    { width, height, x, y, top = 0, left = 0, children, testId, ...rest },
    ref
  ) => {
    const clipPathId = useMemo(() => uniqueId('scroll-overlay'), []);

    return (
      <>
        <RectClipPath
          id={clipPathId}
          width={width}
          height={height}
          x={x}
          y={y}
        />
        <g
          data-testid={testId}
          clipPath={`url(#${clipPathId})`}
          transform={`translate(${left}, ${top})`}
          ref={ref}
          {...rest}
        >
          <rect
            height={height}
            width={width}
            className={styles.overlay}
            x={x}
          />
          {children}
        </g>
      </>
    );
  }
);

export default Overlay;
