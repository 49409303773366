import { GraphQLFilter, FilterType } from '@insights/models-nwe';
import { GraphqlFilterKey } from '@insights/constants-nwe';
import { RedrockProjectKeys } from './models';

type FilterBody = { [key: string]: string | string[] };

export const generateRedrockFilterBody = (
  filters: GraphQLFilter[]
): FilterBody =>
  filters.reduce((acc, filter) => {
    const { column, operator } = getFilterKeys(filter);
    return {
      ...acc,
      [column]: filter.values.map(({ value }) => value),
      [operator]: filter.operator,
    };
  }, {});

const getFilterKeys = (
  filter: GraphQLFilter
): { column: string; operator: string } => {
  const redrockColumn =
    filter.filterType === FilterType.Standard
      ? getRedrockProjectColumn(filter.column)
      : `DE:${filter.column}`;
  if (!redrockColumn)
    throw new Error(
      `The filter column ${filter.column} has no matching Redrock column defined`
    );

  return { column: redrockColumn, operator: `${redrockColumn}_Mod` };
};

export const DATALAKE_COLUMN_TO_REDROCK_PROJECT: {
  [key in GraphqlFilterKey]: RedrockProjectKeys | undefined;
} = {
  [GraphqlFilterKey.projectguid]: RedrockProjectKeys.ID,
  [GraphqlFilterKey.programguid]: RedrockProjectKeys.programID,
  [GraphqlFilterKey.portfolioguid]: RedrockProjectKeys.portfolioID,
  [GraphqlFilterKey.condition]: RedrockProjectKeys.condition,
  [GraphqlFilterKey.status]: RedrockProjectKeys.status,
  [GraphqlFilterKey.sponsorguid]: RedrockProjectKeys.sponsorID,
  [GraphqlFilterKey.ownerguid]: RedrockProjectKeys.ownerID,
  [GraphqlFilterKey.teamguid]: undefined,
  [GraphqlFilterKey.customfields]: undefined,
};

export const getRedrockProjectColumn = (
  column: GraphqlFilterKey
): string | undefined => DATALAKE_COLUMN_TO_REDROCK_PROJECT[column];
