import { css } from 'emotion';

export const container = css`
  display: flex;
  flex-direction: column;
`;

export const capacityType = css`
  margin-top: 20px;
`;
