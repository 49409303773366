import React, { FC } from 'react';
import {
  FormattedMessage,
  FormattedNumber,
  MessageKeys,
} from '@insights/i18n-nwe';
import { TaskHoursDatum } from '@insights/models-nwe';
import { HourGrain } from '@insights/constants-nwe';
import { ZeroState } from '@insights/shared-components-nwe';

import { AdditionalFieldsTable as AFTable } from '../additional-fields-table/index';

import * as styles from './styles';

const formatNumber = (value: number) => (
  <FormattedNumber
    value={value}
    minimumFractionDigits={1}
    maximumFractionDigits={1}
  />
);

export interface Props {
  data: TaskHoursDatum | undefined;
  hourGrain: HourGrain;
}

const TaskHourTable: FC<Props> = ({ data, hourGrain }) => {
  return (
    <div data-testid="task-hour-table" className={styles.root}>
      {data === undefined && (
        <div className={styles.zeroState}>
          <ZeroState testID="task-hour-table-">
            <FormattedMessage id={MessageKeys.burndownHourTableZeroState} />
          </ZeroState>
        </div>
      )}
      <AFTable
        headers={
          <>
            <AFTable.Header
              title={
                <FormattedMessage
                  id={MessageKeys.burndownHourTableTasksHeader}
                />
              }
            />
            <AFTable.Header
              title={
                <FormattedMessage
                  id={
                    hourGrain === HourGrain.plannedHours
                      ? MessageKeys.burndownHourTableHoursHeader
                      : MessageKeys.days
                  }
                />
              }
            />
          </>
        }
        cells={
          <>
            {/* Tasks */}
            <AFTable.Cell
              label={
                <FormattedMessage id={MessageKeys.burndownHourTableAdded} />
              }
              value={
                data !== undefined ? (
                  <FormattedNumber value={data.tasksAdded} />
                ) : (
                  '--'
                )
              }
            />
            <AFTable.Cell
              label={
                <FormattedMessage id={MessageKeys.burndownHourTableCompleted} />
              }
              value={
                data !== undefined ? (
                  <FormattedNumber value={data.tasksCompleted} />
                ) : (
                  '--'
                )
              }
            />
            <AFTable.Cell
              label={
                <FormattedMessage id={MessageKeys.burndownHourTableTotal} />
              }
              value={
                data !== undefined ? (
                  <FormattedNumber
                    value={data.tasksAdded - data.tasksCompleted}
                  />
                ) : (
                  '--'
                )
              }
            />
            {/* Hours */}
            <AFTable.Cell
              label={
                <FormattedMessage id={MessageKeys.burndownHourTableAdded} />
              }
              value={data !== undefined ? formatNumber(data.hoursAdded) : '--'}
            />
            <AFTable.Cell
              label={
                <FormattedMessage id={MessageKeys.burndownHourTableCompleted} />
              }
              value={
                data !== undefined ? formatNumber(data.hoursCompleted) : '--'
              }
            />
            <AFTable.Cell
              label={
                <FormattedMessage id={MessageKeys.burndownHourTableTotal} />
              }
              value={
                data !== undefined
                  ? formatNumber(data.hoursAdded - data.hoursCompleted)
                  : '--'
              }
            />
          </>
        }
      />
    </div>
  );
};

export default TaskHourTable;
