import styled from 'react-emotion';
import { primary } from '@phoenix/all';

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

interface SummaryContainerProps {
  width: number;
}

export const SummaryContainer = styled('div')(
  ({ width }: SummaryContainerProps) => ({
    minWidth: width,
    background: primary.white(),
    boxShadow: '0 3px 6px 0 rgba(0, 35, 64, 0.2)',
    maxHeight: '100%',
    overflow: 'auto',
    zIndex: 7,
  })
);
