import React, { FC } from 'react';
import { ScaleTime } from 'd3-scale';
import { RequireExactlyOne } from 'type-fest'; // eslint-disable-line import/no-unresolved
import { primary } from '@phoenix/all';
import { FormattedDate } from '@insights/i18n-nwe';

import LabelMarker from './label-marker';

export interface BaseProps {
  dateScale: ScaleTime<number, number>;
  height: number;
  date: Date;
  markerX: number;
}

export type Props = RequireExactlyOne<BaseProps, 'date' | 'markerX'>;

const DateMarker: FC<Props> = (props) => {
  const jsDate = props.date || props.dateScale.invert(props.markerX);
  return (
    <LabelMarker
      markerX={props.dateScale(jsDate)}
      height={props.height}
      color={primary.blue()}
      label={<FormattedDate day="numeric" value={jsDate} />}
    />
  );
};

export default DateMarker;
