import { useReducer, useCallback } from 'react';
import { createReducer, ActionType, createAction } from 'typesafe-actions';

export interface DisplayInitialState {
  display: false;
  setFocus: false;
}

export interface DisplayActive {
  display: true;
  setFocus: false;
}

export interface DisplayHide {
  display: false;
  setFocus: true;
}

export type DisplayState = DisplayInitialState | DisplayActive | DisplayHide;

export interface UseReducerHookResponse {
  display: boolean;
  setFocus: boolean;
  dispatchDisplay(): void;
  dispatchHideAndSetFocus(): void;
}

const DEFAULT_STATE: DisplayInitialState = {
  display: false,
  setFocus: false,
};

/* 
  This hook is used to manage a display toggle state (show/hide) while also managing a setFocus
  state. This is used when we need to know if we should set focus on an element when 
  a component is set from display to hidden.
*/

export const useToggleFocusReducer = (): UseReducerHookResponse => {
  const [{ display, setFocus }, dispatch] = useReducer(reducer, DEFAULT_STATE);
  const dispatchDisplay = useCallback(
    (): void => dispatch(toggleActions.display()),
    [dispatch, toggleActions]
  );
  const dispatchHideAndSetFocus = useCallback(
    (): void => dispatch(toggleActions.hide()),
    [dispatch, toggleActions]
  );

  return {
    display,
    setFocus,
    dispatchDisplay,
    dispatchHideAndSetFocus,
  };
};

const toggleActions = {
  display: createAction('DISPLAY')(),
  hide: createAction('HIDE')(),
};

const reducer = createReducer<DisplayState, ActionType<typeof toggleActions>>(
  DEFAULT_STATE
)
  .handleAction(toggleActions.display, () => ({
    display: true,
    setFocus: false,
  }))
  .handleAction(toggleActions.hide, () => ({
    display: false,
    setFocus: true,
  }));
