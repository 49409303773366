import React, { FC, SVGProps } from 'react';
import styled from 'react-emotion';

const LINE_HEIGHT = 24;

const Line: FC<SVGProps<SVGLineElement>> = ({
  strokeWidth = '1',
  strokeDasharray = '4 1',
  x1 = Number(strokeWidth) / 2,
  x2 = Number(strokeWidth) / 2,
  ...rest
}) => (
  <LineDiv>
    <svg width={strokeWidth} height={LINE_HEIGHT}>
      <line
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        y2={LINE_HEIGHT}
        x1={x1}
        x2={x2}
        {...rest}
      />
    </svg>
  </LineDiv>
);

export default Line;

const LineDiv = styled('div')`
  width: 8px;
  height: ${LINE_HEIGHT}px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
`;
