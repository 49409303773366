import React, { FC, useRef } from 'react';
import { H4 } from '@phoenix/all';
import { Filter } from '@insights/models-nwe';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import {
  QuicksilverHyperLinks,
  DateRangeType,
  Page,
} from '@insights/constants-nwe';
import { InfoTooltip } from '@insights/shared-components-nwe';
import styled from 'react-emotion';
import { css } from 'emotion';

import { ActiveFilter } from './active-filter';
import { FilterSegment } from '../filter-segment';
import { intersperse } from '../../services/intersperse';
import { AddFilter } from '../add-filter';

export interface Props {
  page: Page;
  filters: Filter[];
  onFilterRemove(column: string): void;
  onAddFilter(f: Filter): void;
  dateRange: { type: DateRangeType; endDate: Date; startDate: Date };
}

const renderFilterDetails = (
  filters: Filter[],
  removeFilter: (
    filterColumn: string,
    event: React.KeyboardEvent | React.MouseEvent<HTMLButtonElement>
  ) => void
) => {
  const filterItems = filters.map((filter, index) => (
    <ActiveFilter
      key={filter.column}
      filter={filter}
      onFilterRemove={removeFilter}
      filterNumber={index + 1}
      totalFilters={filters.length}
    />
  ));

  return [
    ...intersperse(filterItems, (index: number) => (
      <FilterSegment key={`filter-segment-${index}`} className={intersection}>
        <FormattedMessage id={MessageKeys.and} />
      </FilterSegment>
    )),
  ];
};

export const FilterHeader: FC<Props> = ({
  page,
  filters,
  onFilterRemove,
  onAddFilter,
  dateRange,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const removeFilter = (
    filterColumn: string,
    event:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    onFilterRemove(filterColumn);

    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  return (
    <FilterHeaderContainer>
      <Overview>
        <H4 testID="filters-count">
          <FormattedMessage
            id={MessageKeys.filtersLength}
            values={{ numberOfFilters: filters.length }}
          />
        </H4>
        <InfoTooltip
          header={<FormattedMessage id={MessageKeys.filtering} />}
          dataId="filtering"
          hyperlink={QuicksilverHyperLinks.Filtering}
          body={<FormattedMessage id={MessageKeys.filterHoverText} />}
          buttonDescription={
            <FormattedMessage
              id={MessageKeys.tooltipButtonDescriptionFilters}
            />
          }
        />
        <Filters>
          {renderFilterDetails(filters, removeFilter)}
          <AddFilter
            page={page}
            filters={filters}
            onAddFilter={onAddFilter}
            dateRange={dateRange}
            buttonRef={buttonRef}
          />
        </Filters>
      </Overview>
    </FilterHeaderContainer>
  );
};

const FilterHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  flex: 1;
`;

const Overview = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%:
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  line-height: 1.25;
`;

export const intersection = css`
  margin-right: 13px;
`;
