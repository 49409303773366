import { HourGrain } from '@insights/constants-nwe';
import { OwnProps } from './chart';

type AccessorProps = Pick<OwnProps, 'getRemaining' | 'getPlanned'>;

export const accessors: { [K in HourGrain]: AccessorProps } = {
  [HourGrain.plannedHours]: {
    getRemaining: (d) => d.hoursRemaining,
    getPlanned: (d) => d.plannedHours,
  },
  [HourGrain.plannedDuration]: {
    getRemaining: (d) => d.durationRemaining,
    getPlanned: (d) => d.plannedDurationDays,
  },
};
