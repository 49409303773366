import styled from 'react-emotion';
import { CARD_MARGIN } from '../constants';

/*
To have the columns align 4 across and 2 x 2 depending on the width of the KPIWrapper, we're 
using flex-basis to trigger the min-width and max-width styling in CardGutter. The value of 
flex-basis is overridden by min-width and max-width when flex-basis is greater than or less 
than the min- and max-width. We're doing this instead of @media as the KPIWrapper's width may
change due to mini-x being opened, which shrinks KPIWrapper.
*/

const FLEX_BASIS = 'calc((1250px - 100%) * 999);';

const CardGutter = styled.div`
  display: inline-block;
  margin: 0 ${CARD_MARGIN}px;
  min-width: calc(25% - ${CARD_MARGIN * 2}px);
  max-width: calc(50% - ${CARD_MARGIN * 2}px);
  flex-basis: ${FLEX_BASIS};
  -ms-flex-preferred-size: ${FLEX_BASIS};
`;

const KPILayout = styled.article`
  height: 140px;
  width: 100%;
  padding: 20px 24px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: 1fr 10px 2fr 10px 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 10px;

  & > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  & > *:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  & > *:nth-child(3) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
`;

const KPIHeader = styled.div`
  height: 100%;
  width: 100%;
`;

const KPIBody = styled.div`
  height: 100%;
  width: 100%;
`;

const KPIFooter = styled.div`
  height: 100%;
  width: 100%;
`;

export { CardGutter, KPILayout, KPIHeader, KPIBody, KPIFooter };
