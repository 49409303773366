import React from 'react';
import CalendarIcon from 'phoenix-icons/dist/CalendarIcon.js';
import { DateRangeFormat } from '@insights/utils-nwe';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';
import { DateRangeInputPicker } from './DateRangeInputPicker';
import { useCalendarHook } from './calendarHook';
import {
  DateRangeSpan,
  IconContainer,
  CalendarInputContainer,
  parentContainer,
} from './styles';

export interface Props {
  startDate: Date;
  endDate: Date;
  onSubmit: Function;
}

const TEST_ID = 'calendar-filter';
const TEST_ID_CALENDAR_SELECTOR = 'calendar-selector';

export const CalendarWidget = (props: Props) => {
  const { startDate, endDate, onSubmit } = props;
  const {
    display,
    calendarWidget,
    calendarButton,
    toggleCalendar,
    submitDateRangeChange,
  } = useCalendarHook(onSubmit);

  const { formatMessage } = useIntl();
  return (
    <div className={parentContainer} ref={calendarWidget}>
      <CalendarInputContainer
        innerRef={calendarButton}
        data-testid={TEST_ID}
        onClick={toggleCalendar}
        aria-label={formatMessage(
          { id: MessageKeys.calendarFilter },
          { startDate, endDate }
        )}
        aria-expanded={display ? 'true' : 'false'}
        aria-controls={TEST_ID_CALENDAR_SELECTOR}
        isFocused={display}
      >
        <IconContainer isFocused={display} data-testid="calendar-icon">
          <CalendarIcon
            color={display ? '#FFFFFF' : '#82878E'}
            height={19.64}
            width={17.45}
          />
        </IconContainer>
        <DateRangeSpan isFocused={display} data-testid="project-range-link">
          <DateRangeFormat children={{ start: startDate, end: endDate }} />
        </DateRangeSpan>
      </CalendarInputContainer>
      {display && (
        <DateRangeInputPicker
          startDate={startDate}
          endDate={endDate}
          onChange={submitDateRangeChange}
          id={TEST_ID_CALENDAR_SELECTOR}
        />
      )}
    </div>
  );
};
