import React, { FC } from 'react';
import { Store } from '@insights/store-nwe';
import { IntlProvider } from '@insights/i18n-nwe';
import { FeedbackButton } from './feedback-button';
import { Provider as GraphqlClient } from './graphql';
import { CustomerPreferencesProvider } from './customer-preferences';
import { ProjectConditionsProvider } from './project-conditions';
import { CustomerPreferenceField } from './customer-preferences/customer-preference-field';
// @TODO dynamically import intl.pluralrules polyfill only for IE 11
import '@formatjs/intl-pluralrules/dist-es6/polyfill-locales';

const CUSTOMER_PREFERENCES_FIELDS = Object.values(CustomerPreferenceField);

export const Setup: FC<{
  graphqlUri?: string;
}> = ({ graphqlUri, children }) => (
  <CustomerPreferencesProvider fields={CUSTOMER_PREFERENCES_FIELDS}>
    <ProjectConditionsProvider>
      <Store>
        <GraphqlClient url={graphqlUri}>
          <IntlProvider>
            {children}
            <FeedbackButton />
          </IntlProvider>
        </GraphqlClient>
      </Store>
    </ProjectConditionsProvider>
  </CustomerPreferencesProvider>
);
