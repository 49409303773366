import React, { FC } from 'react';
import styled from 'react-emotion';
import { H4 } from '@phoenix/all';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';

import { QuicksilverHyperLinks } from '@insights/constants-nwe';
import {
  InfoTooltip,
  ProjectSort,
  projectSortStyles,
} from '@insights/shared-components-nwe';

export interface Props {
  count: number;
}

const FlightPlanHeader: FC<Props> = ({ count }) => {
  return (
    <Header>
      <H4 testID="flightplan-header">
        <FormattedMessage id={MessageKeys.flightPlan} />
      </H4>
      <ProjectCount data-testid="flightplan-project-count">
        <FormattedMessage
          id={MessageKeys.projectCount}
          values={{
            projectCount: count,
          }}
        />
      </ProjectCount>
      <InfoTooltip
        header={<FormattedMessage id={MessageKeys.flightPlan} />}
        dataId="flightplan"
        hyperlink={QuicksilverHyperLinks.FlightPlan}
        body={
          <>
            <div>
              <FormattedMessage id={MessageKeys.flightPlanInfoTooltipOne} />
            </div>
            <br />
            <div>
              <FormattedMessage id={MessageKeys.flightPlanInfoTooltipTwo} />
            </div>
          </>
        }
        buttonDescription={
          <FormattedMessage
            id={MessageKeys.tooltipButtonDescriptionFlightPlan}
          />
        }
      />
      <ProjectSort className={projectSortStyles.header} />
    </Header>
  );
};

export default FlightPlanHeader;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
`;

const ProjectCount = styled.span`
  color: #767676;
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
`;
