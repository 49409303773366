import React, { FC } from 'react';
import { ScaleLinear } from 'd3-scale';
import { PercentAxis } from '@insights/chart-parts-nwe';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';

interface OwnProps {
  yScale: ScaleLinear<number, number>;
}

export const NUM_TICKS = 4;

export const YAxis: FC<OwnProps> = ({ yScale }) => {
  const { formatMessage } = useIntl();

  return (
    <PercentAxis
      scale={yScale}
      label={formatMessage({ id: MessageKeys.capacity })}
      tickLabelProps={() => ({
        textAnchor: 'end',
        dy: '0.25em',
        fill: '#999',
        fontSize: 12,
      })}
      numTicks={NUM_TICKS}
    />
  );
};
