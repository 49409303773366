import React, { FC } from 'react';
import { css, cx } from 'emotion';
import { primary } from '@phoenix/all';
import LeftCaratSmallIcon from 'phoenix-icons/dist/LeftCaratSmallIcon.js';
import RightCaratSmallIcon from 'phoenix-icons/dist/RightCaratSmallIcon.js';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';
import { PaginationButton } from '../pagination-button';

interface TruncationProps {
  value: string;
}

interface CaratProps {
  currentPageNumber: number;
  setPage(value: number): void;
  disabled: boolean;
  ariaDescribedById: string;
  testID?: string;
}

interface PageProps {
  value: number;
  pageSelected: boolean;
  ariaDescribedById: string;
  setPage(value: number): void;
  testID?: string;
}

export const TruncationDots: FC<TruncationProps> = ({ value }) => (
  <li>
    <span className={cx(defaultButton, buttonDisabled)}>{value}</span>
  </li>
);

export const LeftCarat: FC<CaratProps> = ({
  currentPageNumber,
  setPage,
  disabled,
  ariaDescribedById,
  testID,
}) => {
  const { formatMessage } = useIntl();

  return (
    <li>
      <PaginationButton
        value={
          <LeftCaratSmallIcon
            height={15}
            width={15}
            viewBox={caratSVGViewBox}
            color={getCaratColor(disabled)}
          />
        }
        className={cx(defaultButton, { [buttonDisabled]: disabled })}
        ariaLabel={formatMessage({
          id: MessageKeys.paginationPreviousPage,
        })}
        ariaDescribedBy={ariaDescribedById}
        onClick={() => {
          if (!disabled) {
            setPage(currentPageNumber - 1);
          }
        }}
        disabled={disabled}
        testID={`${testID}-pagination-left-carat`}
      />
    </li>
  );
};

export const RightCarat: FC<CaratProps> = ({
  currentPageNumber,
  setPage,
  disabled,
  ariaDescribedById,
  testID,
}) => {
  const { formatMessage } = useIntl();

  return (
    <li>
      <PaginationButton
        value={
          <RightCaratSmallIcon
            height={15}
            width={15}
            viewBox={caratSVGViewBox}
            color={getCaratColor(disabled)}
          />
        }
        className={cx(defaultButton, { [buttonDisabled]: disabled })}
        ariaLabel={formatMessage({
          id: MessageKeys.paginationNextPage,
        })}
        ariaDescribedBy={ariaDescribedById}
        onClick={() => {
          if (!disabled) {
            setPage(currentPageNumber + 1);
          }
        }}
        disabled={disabled}
        testID={`${testID}-pagination-right-carat`}
      />
    </li>
  );
};

export const Page: FC<PageProps> = ({
  value,
  pageSelected,
  setPage,
  ariaDescribedById,
  testID,
}) => {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <li>
      <PaginationButton
        value={formatNumber(value)}
        className={cx(defaultButton, { [selectedPage]: pageSelected })}
        ariaLabel={formatMessage(
          {
            id: pageSelected
              ? MessageKeys.paginationPageNumber
              : MessageKeys.paginationGoToPageNumber,
          },
          { pageNumber: value }
        )}
        ariaDescribedBy={ariaDescribedById}
        pageSelected={pageSelected}
        onClick={() => setPage(+value)}
        testID={`${testID}-pagination-page-${value}`}
      />
    </li>
  );
};

const getCaratColor = (disabled: boolean): string =>
  disabled ? primary.gray(400) : primary.gray(700);

const caratSVGViewBox = '1 1 14 14';

const defaultButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  min-width: 26px;
  border: none;
  margin: 0 1px;
  color: ${primary.gray(700)};
  background-color: ${primary.gray(0)};
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${primary.blue(50)};
  }
`;

const buttonDisabled = css`
  &:hover,
  &:focus {
    background-color: ${primary.gray(0)};
    cursor: default;
  }
`;

const selectedPage = css`
  color: ${primary.gray(0)};
  background-color: ${primary.doctorStrange()};
  font-weight: 600;
  &:hover,
  &:focus {
    background-color: ${primary.doctorStrange()};
  }
`;
