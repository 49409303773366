import { MessageKeys } from '@insights/i18n-nwe';

export enum ExportType {
  chart = 'chart',
  dataTable = 'dataTable',
}

export const EXPORT_TYPE_TO_MESSAGE_KEY: {
  [key in ExportType]: MessageKeys;
} = {
  [ExportType.chart]: MessageKeys.exportPng,
  [ExportType.dataTable]: MessageKeys.exportXlsx,
};
