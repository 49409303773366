import { HourGrain } from '@insights/constants-nwe';
import { OwnProps } from '../components/chart';

type AccessorProps = Pick<OwnProps, 'getCompleted'>;

export const accessors: { [K in HourGrain]: AccessorProps } = {
  [HourGrain.plannedHours]: {
    getCompleted: (d) => d.completedplannedhours,
  },
  [HourGrain.plannedDuration]: {
    getCompleted: (d) => d.completeddurationdays || 0,
  },
};
