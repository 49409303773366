import React, { FC } from 'react';
import { Button } from '@phoenix/all';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import styled from 'react-emotion';

export const FeedbackButton: FC = () => {
  return (
    <RoundButton
      id="insights-feedback-button"
      testID="insights-feedback-button"
    >
      <FormattedMessage tagName="p" id={MessageKeys.feedback} />
    </RoundButton>
  );
};

export const RoundButton = styled(Button)`
  border-radius: 16px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 35, 64, 0.2);
`;
