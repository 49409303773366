import React, { FC, ReactNode } from 'react';

import * as styles from './styles';

export interface Props {
  value: ReactNode;
  label: ReactNode;
  span?: number;
}

const AdditionalFieldsCell: FC<Props> = ({ value, span = 2, label }) => {
  return (
    <>
      <td colSpan={span} className={styles.textLeft}>
        {label}
      </td>
      <td colSpan={span} className={styles.textRight}>
        {value}
      </td>
    </>
  );
};

export default AdditionalFieldsCell;
