import { ScaleBand, ScaleTime } from 'd3-scale';
import { scaleBand, scaleTime } from '@vx/scale';
import { DateRange, Extent, Margin, Point } from '@insights/models-nwe';

export const indexify = <T extends object>(
  data: T[],
  key: (d: T) => string
): { [key: string]: T } =>
  data.reduce((acc, d) => {
    acc[key(d)] = d;
    return acc;
  }, {} as { [key: string]: T });

export const getCategoryScale = <T extends object>(
  data: T[],
  key: keyof T,
  height: number
): ScaleBand<string> =>
  scaleBand({
    domain: data.map((d) => d[key]),
    range: [0, height],
  });

export const getDateScale = (
  width: number,
  dateRange: DateRange,
  zoomRange?: DateRange,
  clamp = true
): ScaleTime<number, number> => {
  const { start, end } = zoomRange || dateRange;

  return scaleTime({ domain: [start, end], range: [0, width], clamp });
};

/**
 * Returns the region of a chart where the content of the chart lives
 * e.g. the bars or lines
 *
 * @param width
 * @param height
 * @param margin
 */
export const getExtent = (
  width: number,
  height: number,
  margin: Margin
): Extent => ({
  x0: 0,
  x1: width - margin.right - margin.left,
  y0: 0,
  y1: height - margin.bottom - margin.top,
});

export const transformCoords = (
  { x, y }: Point,
  { top, left }: Margin
): Point => ({
  x: x - left,
  y: y - top,
});
