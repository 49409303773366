import { getType } from 'typesafe-actions';

import Actions from '../../actions';
import { toggleFilterHeader } from '../../actions/filters';

export type State = boolean;

export const INITIAL_STATE: State = true;

export default (state: State = INITIAL_STATE, action: Actions): State => {
  switch (action.type) {
    case getType(toggleFilterHeader):
      return !state;
    default:
      return state;
  }
};
