import React, { FC } from 'react';
import { DateRangeFormat } from '@insights/utils-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { display } from '../styles';

export interface Props {
  startDate: Date | null;
  endDate: Date | null;
  className?: string;
}

const PlannedTimeframe: FC<Props> = ({
  startDate,
  endDate,
  className = display,
}) => {
  const displayValue =
    startDate && endDate ? (
      <DateRangeFormat children={{ start: startDate, end: endDate }} />
    ) : (
      <FormattedMessage id={MessageKeys.noDateText} />
    );

  return <span className={className}>{displayValue}</span>;
};

export default PlannedTimeframe;
