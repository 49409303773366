import React, { FC } from 'react';
import { Except, RequireExactlyOne } from 'type-fest'; // eslint-disable-line import/no-unresolved
import { NumberAxis, NumberAxisProps } from '../number-axis';

export type PercentAxisProps = RequireExactlyOne<
  Except<NumberAxisProps, 'formatOptions'>,
  'label' | 'labelKey'
>;

export const PercentAxis: FC<PercentAxisProps> = (props) => (
  <NumberAxis formatOptions={{ style: 'percent' }} {...props} />
);
