import React, { ComponentProps, FC } from 'react';
import styled from 'react-emotion';
import { primary } from '@phoenix/all';
import DownCaratSmallIcon from 'phoenix-icons/dist/DownCaratSmallIcon.js';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';

export type Props = {
  messageKey?: MessageKeys;
  valueId?: string;
} & ComponentProps<typeof Button>;

export const Trigger: FC<Props> = ({
  messageKey,
  valueId,
  css,
  ref,
  ...rest
}) => (
  <Button id={valueId} {...rest}>
    <FormattedMessage id={messageKey} />
    <IconWrapper>
      <DownCaratSmallIcon />
    </IconWrapper>
  </Button>
);

const Button = styled.button`
  background: ${primary.gray(50)};
  border-radius: 2px;
  height: 32px;
  padding: 0px 16px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${primary.gray(700)};
`;

const IconWrapper = styled.span`
  align-self: center;
  height: 16px;
  width: 16px;
  line-height: 100%;
  margin-left: 12px;
  vertical-align: middle;
`;
