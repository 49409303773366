import { createReducer, ActionType } from 'typesafe-actions';

import { setOffsetByPageNumber, RESULTS_LIMIT } from '../../actions/pagination';
import { addFilter, updateFilter, removeFilter } from '../../actions/filters';
import { setDateRange } from '../../actions/app';

export type State = Readonly<{
  limit: number;
  offset: number;
}>;

export const INITIAL_STATE = {
  limit: RESULTS_LIMIT,
  offset: 0,
};

const actions = {
  setOffsetByPageNumber,
  addFilter,
  updateFilter,
  removeFilter,
  setDateRange,
};

export default createReducer<State, ActionType<typeof actions>>(INITIAL_STATE)
  .handleAction(setOffsetByPageNumber, (state, action) => ({
    ...state,
    offset: action.payload,
  }))
  .handleAction(
    [addFilter, updateFilter, removeFilter, setDateRange],
    () => INITIAL_STATE
  );

export const getResultsLimit = (state: State): number => state.limit;

export const getOffset = (state: State): number => state.offset;

export const getPageNumber = (state: State): number =>
  state.offset / RESULTS_LIMIT + 1;
