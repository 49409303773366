import React from 'react';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { Bold } from './styles';

export interface Props {
  available: number;
  planned: number;
}

const GenerateCapacityMessage = ({
  available,
  planned,
}: Props): JSX.Element => {
  const difference = available - planned;

  if (difference === 0) {
    return <FormattedMessage id={MessageKeys.atCapacity} />;
  } else {
    return (
      <FormattedMessage
        id={
          difference > 0
            ? MessageKeys.resourceCapacityTooltipUnderCapacity
            : MessageKeys.resourceCapacityTooltipOverCapacity
        }
        values={{
          hours: Math.abs(difference),
          bold: (hours: string) => <Bold>{hours}</Bold>,
        }}
      />
    );
  }
};

export default GenerateCapacityMessage;
