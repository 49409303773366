import React, { FC, SVGProps, memo } from 'react';
import { isWithinInterval } from 'date-fns';
import { ScaleTime } from 'd3-scale';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

import DateMarker from './date-with-label-marker';

export interface Props extends SVGProps<SVGLineElement> {
  dateScale: ScaleTime<number, number>;
  height: number;
  color: string;
}

const TodayMarker: FC<Props> = ({ dateScale, color, height }) => {
  const today = new Date();
  const dateRange = dateScale.domain(); // 0: startDate 1: endDate
  if (!isWithinInterval(today, { start: dateRange[0], end: dateRange[1] }))
    return null;
  return (
    <DateMarker
      dateScale={dateScale}
      height={height}
      color={color}
      dateX={today}
      labelText={<FormattedMessage id={MessageKeys.today} />}
    />
  );
};

export default memo(TodayMarker);
