import { Page } from '@insights/constants-nwe';
import {
  RootState,
  getChartDateRange,
  connect,
  getTeamIds,
} from '@insights/store-nwe';

import ChartWithData, { Props as ChartProps } from './with-data';

export type StateProps = Pick<ChartProps, 'startDate' | 'endDate' | 'teamIds'>;

export default connect<StateProps, {}, ChartProps>(
  (state: RootState): StateProps => {
    const { start: startDate, end: endDate } = getChartDateRange(
      state,
      Page.people
    );

    return {
      startDate,
      endDate,
      teamIds: getTeamIds(state),
    };
  }
)(ChartWithData);
