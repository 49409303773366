import React, { FC } from 'react';
import {
  ActivityType,
  ACTIVITY_TYPE_TO_MESSAGE_KEY,
} from '@insights/constants-nwe';
import {
  FormattedDate,
  FormattedNumber,
  FormattedMessage,
} from '@insights/i18n-nwe';

import {
  ChartTooltip as Tooltip,
  TooltipWithInsightsBounds,
  TooltipWithInsightsBoundsProps,
} from '@insights/tooltip-nwe';

export interface OwnProps {
  data: ActivityTooltipProps;
}

export interface ActivityTooltipProps {
  date: Date;
  count: number;
  activityType: ActivityType;
}

export type Props = OwnProps & TooltipWithInsightsBoundsProps;

const ActivityTooltip: FC<Props> = ({ data, ...rest }) => {
  return (
    <TooltipWithInsightsBounds {...rest}>
      <Tooltip.TooltipBaseComponent
        header={<Tooltip.Header label={<FormattedDate value={data.date} />} />}
        content={
          <Tooltip.DataItem
            labelText={
              <FormattedMessage
                id={ACTIVITY_TYPE_TO_MESSAGE_KEY[data.activityType]}
              />
            }
            displayValue={<FormattedNumber value={data.count} />}
          />
        }
      />
    </TooltipWithInsightsBounds>
  );
};
export default ActivityTooltip;
