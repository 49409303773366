import React, { FC, MouseEvent, useCallback, memo } from 'react';
import { Group } from '@vx/group';
import { Bar } from '@vx/shape';
import { Task, HoverState } from '@insights/models-nwe';
import { ScaleTime } from 'd3-scale';
import { primary } from '@phoenix/all';
import { TaskCompletionBar } from '../completion-bar';

interface Props {
  task: Task;
  top: number;
  rowHeight: number;
  xScale: ScaleTime<number, number>;
  hoverState: HoverState;
  onClick?(id: string): void;
  onMouseOver(id: string): void;
  onMouseOut(): void;
  handleTooltip?(event: React.MouseEvent<DOMRect>, data?: Task): void;
  hideTooltip?(): void;
}

const TaskCompletionStatus: FC<Props> = ({
  task,
  top,
  rowHeight,
  xScale,
  hoverState,
  onMouseOver,
  onMouseOut,
  handleTooltip = () => null,
  hideTooltip = () => null,
}) => {
  const onMouseMove = useCallback(
    (e: MouseEvent<DOMRect>) => handleTooltip(e, task),
    [task]
  );

  const isActive = hoverState === HoverState.active;
  const isInactive = hoverState === HoverState.inactive;
  const barOpacity = isInactive ? 0.3 : 1;
  const rowWidth = xScale.range()[1];

  return (
    <Group
      top={top}
      onMouseOver={onMouseOver.bind(null, task.ID)}
      onMouseOut={onMouseOut}
    >
      <Bar
        height={rowHeight}
        width={rowWidth}
        opacity={isActive ? 0.2 : 0}
        fill={primary.blue()}
      />
      <TaskCompletionBar
        task={task}
        xScale={xScale}
        rowHeight={rowHeight}
        barOpacity={barOpacity}
        onMouseMove={onMouseMove}
        onMouseLeave={hideTooltip}
        rowWidth={rowWidth}
      />
    </Group>
  );
};

export default memo(TaskCompletionStatus);
