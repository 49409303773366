import React, { FC } from 'react';
import { ProjectMagnitude } from '@insights/project-magnitude-nwe';
import { Burndown } from '@insights/burndown-nwe';
import TasksInFlight from '../connected-charts/tasks-in-flight';

import { CollapsableContainer, useSelectedProject } from '../../collapsing';

export const ProjectMagnitudeCharts: FC = () => {
  const { selectedProject, setSelectedProject } = useSelectedProject();

  const projectId = selectedProject && selectedProject.guid;
  const projectName = selectedProject && selectedProject.name;

  return (
    <>
      <ProjectMagnitude
        onProjectClick={setSelectedProject}
        selectedProject={projectId}
      />
      <CollapsableContainer projectId={projectId}>
        <Burndown projectId={projectId} />
        <TasksInFlight projectId={projectId} projectName={projectName} />
      </CollapsableContainer>
    </>
  );
};
