export interface StreamAPIError {
  error: {
    status: number;
    message: string;
  };
}

export function isStreamAPIError(arg: any): arg is StreamAPIError {
  return (
    arg !== undefined &&
    arg !== null &&
    Object.prototype.hasOwnProperty.call(arg, 'error') &&
    Object.prototype.hasOwnProperty.call(arg.error, 'status') &&
    Object.prototype.hasOwnProperty.call(arg.error, 'message')
  );
}
