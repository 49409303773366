import { useEffect, useState } from 'react';
import { wfetch } from '@wf-mfe/api';
import { FilterOption } from '@insights/models-nwe';

export interface Response {
  data: FilterOption[];
  loading: boolean;
  error: boolean | null;
}

interface SpringControllerOptions {
  [key: string]: string;
}

export const useFieldOptionValuesQuery = (fieldName: string): Response => {
  const [data, setData] = useState<FilterOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean | null>(null);

  useEffect((): void => {
    // wfetch will correctly encode the fieldName string
    wfetch(
      `/internal/mappingService/getParameterOptions?parameterName=${fieldName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then((options: SpringControllerOptions) => {
        setData(formOptions(options));
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [fieldName]);

  return { data, loading, error };
};

const formOptions = (options: SpringControllerOptions): FilterOption[] =>
  Object.keys(options).map((o) => {
    return { value: o, label: options[o] };
  });
