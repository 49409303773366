import React, { FC, SVGProps } from 'react';
import { ScaleLinear } from 'd3-scale';
import { Axis } from '@vx/axis';
import { FormatNumberOptions } from 'react-intl';
import { Orientation } from '@insights/constants-nwe';
import { LabelOffset } from '@insights/styles-nwe';
import { useIntl, MessageKeys } from '@insights/i18n-nwe';
import { RequireExactlyOne } from 'type-fest'; // eslint-disable-line import/no-unresolved

import * as styles from '../styles';

export const NUM_TICKS = 7;

export interface BaseProps {
  scale: ScaleLinear<number, number>;
  label?: string;
  labelKey?: MessageKeys;
  orientation?: Orientation;
  top?: number;
  left?: number;
  numTicks?: number;
  labelOffset?: number;
  hideAxisLine?: boolean;
  tickLabelProps?: () => SVGProps<SVGTextElement>;
  formatOptions?: FormatNumberOptions;
}

export type NumberAxisProps = RequireExactlyOne<
  BaseProps,
  'label' | 'labelKey'
>;

export const NumberAxis: FC<NumberAxisProps> = ({
  formatOptions,
  label,
  labelKey,
  ...rest
}) => {
  const { formatNumber, formatMessage } = useIntl();
  const axisLabel = (labelKey && formatMessage({ id: labelKey })) || label;

  return (
    <Axis
      fill="#999"
      labelProps={{
        className: styles.tickLabel,
        textAnchor: 'middle',
        fill: '#999',
        fontSize: 12,
      }}
      tickFormat={(num: number): string => formatNumber(num, formatOptions)}
      hideTicks
      stroke="#B4B4B4"
      strokeWidth={2}
      label={axisLabel}
      {...rest}
    />
  );
};

NumberAxis.defaultProps = {
  orientation: Orientation.left,
  numTicks: NUM_TICKS,
  labelOffset: LabelOffset,
  hideAxisLine: true,
  tickLabelProps: () => ({
    className: styles.tickLabel,
    textAnchor: 'end',
    dy: '0.25em',
    fill: '#999',
    fontSize: 12,
  }),
};
