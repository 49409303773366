import React from 'react';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import { Bold, RedText } from './styles';

interface OwnProps {
  available: number;
  planned: number;
}

const GenerateCapacityMessage = ({
  available,
  planned,
}: OwnProps): JSX.Element => {
  const difference = available - planned;
  const noCapacity = planned > 0 && available === 0;
  let message;

  if (noCapacity) {
    message = MessageKeys.noCapacity;
  } else if (difference > 10) {
    message = MessageKeys.underCapacity;
  } else if (difference < -10) {
    message = MessageKeys.overCapcity;
  } else {
    message = MessageKeys.atCapacity;
  }

  return (
    <>
      <Bold data-testid="capacity-message">
        <FormattedMessage id={message} />
      </Bold>
      {noCapacity && (
        <RedText data-testid="capacity-message-error">
          <FormattedMessage id={MessageKeys.schedulingError} />
        </RedText>
      )}
    </>
  );
};

export default GenerateCapacityMessage;
