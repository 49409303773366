import React, { useState, useEffect } from 'react';
import { useCurrentUser } from '@wf-mfe/auth';
import { MinixInnerContainer as MiniX } from '@wf-mfe/minix';
import { SummaryPanelState } from './SummaryProvider';
import { SummaryContainer } from './styles';

const WIDTH_SMALL = 360;
const WIDTH_LARGE = 472;
const SCREEN_WIDTH_BREAKPOINT = 1280;

export function SummaryPanel({
  details,
  setSummaryDetails,
}: SummaryPanelState) {
  const currentUser = useCurrentUser();
  const [width, setWidth] = useState(WIDTH_LARGE);

  function updateWidth() {
    const newWidth =
      window.innerWidth > SCREEN_WIDTH_BREAKPOINT ? WIDTH_LARGE : WIDTH_SMALL;

    if (newWidth !== width) {
      setWidth(newWidth);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  if (details == null) {
    return null;
  }

  return (
    <SummaryContainer data-testid="summary-panel" width={width}>
      <MiniX
        ID={details.ID}
        objCode={details.objCode}
        width={window.innerWidth}
        currentUser={currentUser}
        onClose={() => setSummaryDetails(undefined)}
      />
    </SummaryContainer>
  );
}
