import React, { useState, FC } from 'react';
import { TeamCapacity } from '@insights/team-capacity-nwe';
import { TeamExtended } from '@insights/models-nwe';

import ResourceCapacity from './resource-capacity';

const Capacity: FC = () => {
  const [selectedTeam, setSelectedTeam] = useState<TeamExtended | null>(null);
  return (
    <>
      <ResourceCapacity
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam && selectedTeam.guid}
      />
      <TeamCapacity
        teamId={selectedTeam && selectedTeam.guid}
        teamName={selectedTeam && selectedTeam.name}
      />
    </>
  );
};

export default Capacity;
