import React, { FC } from 'react';
import { ScaleTime } from 'd3-scale';

import { BurndownChartDatum } from '@insights/models-nwe';

import { conditionColorScale as colorScale } from '@insights/utils-nwe';

export interface Props {
  data: BurndownChartDatum[];
  dateScale: ScaleTime<number, number>;
  id: string;
}

const getKey = (condition: string, offset: number) => `${condition}-${offset}`;

export const getOffset = (date: Date, dateScale: ScaleTime<number, number>) =>
  (dateScale(date) / dateScale.range()[1]) * 100;

const ConditionGradient: FC<Props> = ({ data, dateScale, id }) => {
  if (data.length === 0) return null;
  const range = dateScale.range();

  return (
    <defs>
      <linearGradient
        id={id}
        data-testid="condition-gradient"
        x1={range[0]}
        x2={range[1]}
        gradientUnits="userSpaceOnUse"
      >
        {data.reduce(
          (stops, datum, i) => {
            if (i === 0) return stops;

            const currCondition = datum.condition;
            const prevCondition = data[i - 1].condition;
            const hasChanged = currCondition !== prevCondition;

            if (hasChanged) {
              const offset = getOffset(datum.currentDate, dateScale);
              stops.push(
                ...[
                  <Stop
                    key={getKey(prevCondition, offset)}
                    condition={prevCondition}
                    offset={offset}
                  />,
                  <Stop
                    key={getKey(currCondition, offset)}
                    condition={currCondition}
                    offset={offset}
                  />,
                ]
              );
            }

            return stops;
          },
          [
            <Stop
              key={getKey(data[0].condition, 0)}
              condition={data[0].condition}
              offset={0}
            />,
          ]
        )}
      </linearGradient>
    </defs>
  );
};

const Stop = ({ condition, offset }: { condition: string; offset: number }) => (
  <stop
    data-testid="stop"
    offset={`${offset}%`}
    stopColor={colorScale(condition)}
  />
);

export default ConditionGradient;
