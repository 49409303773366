import styled from 'react-emotion';

export const FilterInput = styled.input`
  font-family: Proxima Nova;
  color: #333333;
  font-size: 14px;
  outline: none;
  border: none;
  border-bottom: 1px solid #0078d7;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  width: 67px;
  background-color: #fff;

  /* for disabled styles */
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current color for safari */
  opacity: 1; /* 2. correct opacity on iOS */

  &:focus {
    border-bottom: 1px solid #0078d7;
  }
`;
