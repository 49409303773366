import React, { FC } from 'react';

import { Project } from '@insights/models-nwe';
import {
  ChartTooltip as Tooltip,
  TooltipWithInsightsBounds,
  TooltipWithInsightsBoundsProps,
} from '@insights/tooltip-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

export interface OwnProps {
  project: Pick<
    Project,
    'condition' | 'name' | 'plannedcompletiondate' | 'plannedstartdate'
  > & { [key: string]: unknown };
  condition: string | null;
  conditionLabel: string | null;
}

type Props = OwnProps & TooltipWithInsightsBoundsProps;

export const conditionDotTestID = 'flight-plan-tooltip-condition-dot';

const ProjectDetails: FC<Props> = ({
  condition,
  conditionLabel,
  project,
  ...rest
}) => {
  const { name, plannedstartdate: start, plannedcompletiondate: end } = project;
  return (
    <TooltipWithInsightsBounds {...rest} testID="flight-plan-tooltip">
      <Tooltip.TooltipBaseComponent
        {...rest}
        header={<Tooltip.Header label={name} />}
        content={
          <>
            {condition && (
              <Tooltip.ConditionDot
                projectCondition={condition}
                testID={conditionDotTestID}
              />
            )}
            {conditionLabel && (
              <Tooltip.DataItem
                testID="custom-condition-label"
                labelText={
                  <FormattedMessage id={MessageKeys.customCondition} />
                }
                displayValue={conditionLabel}
              />
            )}
            <Tooltip.PlannedWithDurationDays startDate={start} endDate={end} />
          </>
        }
      />
    </TooltipWithInsightsBounds>
  );
};

export default ProjectDetails;
