import { parseISO, startOfDay } from 'date-fns';

export const stripTimezone = (input: string): string =>
  input[input.length - 1] === 'Z' ? input.slice(0, -5) : input;

export function parseDate(input: null): null;
export function parseDate(input: string): Date;
export function parseDate(input: string | null): Date | null;
export function parseDate(input: number): Date;
export function parseDate(input: Date): Date;
export function parseDate(input: string | number | Date | null): Date | null {
  if (input === null) return null;
  else {
    let date = input;
    if (typeof input === 'number') date = new Date(input);
    if (typeof input === 'string') date = parseISO(stripTimezone(input));

    // added type assertion because we should have a date at this point
    return startOfDay(date as Date);
  }
}
