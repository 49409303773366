import React, { FC, useMemo } from 'react';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { ScaleTime } from 'd3-scale';
import { primary } from '@phoenix/all';
import { Task } from '@insights/models-nwe';
import { BarText } from '../completion-bar-text';
import { getGradientXDate } from '../../../utils';

interface Props extends React.SVGProps<SVGRect> {
  task: Task;
  rowHeight: number;
  xScale: ScaleTime<number, number>;
  barOpacity: number;
  rowWidth: number;
}

const BAR_HEIGHT = 16;
const TEXT_PADDING = 3;

export const TaskCompletionBar: FC<Props> = ({
  task,
  rowHeight,
  xScale,
  barOpacity,
  rowWidth,
  onMouseMove,
  onMouseLeave,
}) => {
  const { ID, percentComplete, plannedStartDate, plannedCompletionDate } = task;

  const x1 = xScale(plannedStartDate);
  const x2 = xScale(plannedCompletionDate);
  const barWidth = x2 - x1;
  const barYPosition = (rowHeight - BAR_HEIGHT) / 2;
  const barTextXPosition = xScale(plannedCompletionDate) + TEXT_PADDING;
  const barTextYPosition = rowHeight - 12;
  const modifiedPercentComplete = percentComplete / 100;
  const gradientXPosition = useMemo(
    () =>
      xScale(
        getGradientXDate(
          modifiedPercentComplete,
          plannedStartDate,
          plannedCompletionDate
        )
      ),
    [xScale, modifiedPercentComplete, plannedStartDate, plannedCompletionDate]
  );
  const plannedStartDateXPosition = xScale(plannedStartDate);

  return (
    <Group>
      <defs>
        <linearGradient id={ID}>
          <stop offset="0" stopColor={primary.blue()} />
          <stop offset={modifiedPercentComplete} stopColor={primary.blue()} />
          <stop offset={modifiedPercentComplete} stopColor={primary.blue(50)} />
          <stop offset="1" stopColor={primary.blue(50)} />
        </linearGradient>
      </defs>
      <Bar
        x={x1}
        y={barYPosition}
        height={BAR_HEIGHT}
        width={barWidth}
        rx={8}
        fill={`url(#${ID})`}
        opacity={barOpacity}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        data-testid={`${ID}-progress-bar`}
      />
      <BarText
        xPosition={barTextXPosition}
        yPosition={barTextYPosition}
        value={Math.round(percentComplete)}
        rowWidth={rowWidth}
        gradientXPosition={gradientXPosition}
        plannedStartDateXPosition={plannedStartDateXPosition}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    </Group>
  );
};
