import React, { FC } from 'react';
import { BrushProps } from '@insights/models-nwe';
import { primary } from '@phoenix/all';
import { ScaleTime } from 'd3-scale';
import DateMarker from '../marker/date-marker';

export interface Props {
  brush: BrushProps['brush'];
  yMax: number;
  dateScale: ScaleTime<number, number>;
}

const BrushHighlight: FC<Props> = ({ brush, yMax, dateScale }) => {
  if (!brush.isBrushing) return null;
  if (!brush.start) return null;
  if (!brush.end) return null;

  // get the X value for the brush start to handle l2r or r2l brushing
  const brushX = brush.end.x > brush.start.x ? brush.start.x : brush.end.x;

  // Get the width of the highlight box
  const brushBoxWidth = Math.abs(brush.end.x - brush.start.x);

  return (
    <>
      {
        // brush start marker
      }
      <DateMarker
        dateScale={dateScale}
        date={dateScale.invert(brush.start.x)}
        height={yMax}
      />

      {
        // brush box
      }

      <rect
        fill={primary.blue()}
        stroke="#999"
        strokeWidth={2}
        x={brushX}
        y={0}
        height={yMax}
        width={brushBoxWidth}
        opacity={0.1}
        style={{ pointerEvents: 'none' }}
      />

      <DateMarker
        dateScale={dateScale}
        date={dateScale.invert(brush.end.x)}
        height={yMax}
      />
    </>
  );
};

export default BrushHighlight;
