import React, { ReactNode, FC } from 'react';

import { H4 } from '@phoenix/all';
import styled from 'react-emotion';
import * as styles from '../styles';

export interface OwnProps {
  header: ReactNode;
  content: ReactNode;
}

export type Props = OwnProps;

const TooltipBaseComponent: FC<Props> = ({ header, content }) => {
  return (
    <TooltipStyleWrapper>
      {header && <H4>{header}</H4>}
      {content && <div>{content}</div>}
    </TooltipStyleWrapper>
  );
};

const TooltipStyleWrapper = styled.div`
  ${styles.tooltipBox}
`;

export default TooltipBaseComponent;
