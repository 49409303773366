import React, { FC } from 'react';

import { DateRange } from '@insights/models-nwe';
import { ChartDateRangeWarning } from './cards/ChartDateRangeWarning';
import { RenderChart } from './cards/renderChart';
import CheckRange from '../activity/check-range';
import GetTeamUserActivity from './queries/get-team-user-activity';
import UserActivity from '../user-activity';

export interface Props {
  teamIds: string[];
  dateRange: DateRange;
  zoomRange?: DateRange;
  onZoom(zoomRange: DateRange): void;
}

const PeopleActivity: FC<Props> = (props) => {
  const { teamIds, dateRange, zoomRange, onZoom } = props;

  return (
    <CheckRange
      dateRange={dateRange}
      renderChart={
        <RenderChart
          projectIds={[]}
          teamIds={teamIds}
          dateRange={dateRange}
          zoomRange={zoomRange}
          onZoom={onZoom}
          renderUsers={({ guid, ...chartProps }) => (
            <GetTeamUserActivity
              // @TODO we should remove projectIds as a prop once the service no longer requires this field for this query. projectIds aren't techincally used anymore even though it's required.
              projectIds={[]}
              teamId={guid}
              startDate={props.dateRange.start}
              endDate={props.dateRange.end}
            >
              {(result) => <UserActivity {...result} {...chartProps} />}
            </GetTeamUserActivity>
          )}
        />
      }
      chartDateRangeWarning={<ChartDateRangeWarning />}
    />
  );
};

export default PeopleActivity;
