import { useContext, useMemo } from 'react';
import { Overwrite } from 'utility-types';

import { ProjectsForDateRangeState, ProjectState } from '../models';
import { ProjectsContext } from '..';

export const useProjects = (): Overwrite<
  ProjectsForDateRangeState,
  { data: ProjectState }
> => {
  const state = useContext(ProjectsContext);
  const data = useMemo(() => {
    return {
      projects: state.data?.projects ?? new Map(),
      totalCount: state.data?.totalCount ?? 0,
    };
  }, [state.data]);

  return {
    ...state,
    data,
  };
};
