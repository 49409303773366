import React, { FC, memo, useState } from 'react';
import { ScaleBand, ScaleQuantize } from 'd3-scale';
import { Bar } from '@vx/shape';
import { primary } from '@phoenix/all';
import { Activity } from '@insights/models-nwe';
import { ActivityType } from '@insights/constants-nwe';

export interface Props {
  height: number;
  datum: Activity;
  type: ActivityType;
  dateScale: ScaleBand<Date>;
  colorScale: ScaleQuantize<string>;
  onSegmentMouseOver(
    event: React.MouseEvent,
    type: ActivityType,
    date: Date,
    count: number
  ): void;
  onSegmentMouseOut(
    event: React.MouseEvent,
    type: ActivityType,
    date: Date,
    count: number
  ): void;
  onSegmentClick(
    event: React.MouseEvent,
    type: ActivityType,
    date: Date,
    count: number
  ): void;
}

export const DATA_BAR = 'data-bar';

const ActivityBar: FC<Props> = ({
  height,
  datum,
  type,
  dateScale,
  colorScale,
  onSegmentMouseOver,
  onSegmentMouseOut,
  onSegmentClick,
}) => {
  const [isHovered, setHover] = useState<boolean>(false);

  if (datum.count > 0) {
    return (
      <Bar
        data-testid={DATA_BAR}
        key={datum.date}
        height={height}
        x={dateScale(datum.date)}
        width={dateScale.bandwidth()}
        fill={datum.count === 0 ? '#fff' : colorScale(datum.count)}
        {...(isHovered && { stroke: primary.gray(800), strokeWidth: '1px' })}
        onMouseOver={(event: React.MouseEvent) => {
          setHover(true);
          onSegmentMouseOver(event, type, datum.date, datum.count);
        }}
        onMouseOut={(event: React.MouseEvent) => {
          setHover(false);
          onSegmentMouseOut(event, type, datum.date, datum.count);
        }}
        onClick={(event: React.MouseEvent) =>
          onSegmentClick(event, type, datum.date, datum.count)
        }
      />
    );
  }
  return null;
};

export default memo(ActivityBar);
