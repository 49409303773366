import React, { Component, ReactNode } from 'react';

import * as styles from './styles';

import AdditionalFieldsCell from './cell';
import AdditionalFieldsHeader from './header';

export interface Props {
  headers: ReactNode;
  cells: ReactNode;
}

export class AdditionalFieldsTable extends Component<Props> {
  static Header = AdditionalFieldsHeader;

  static Cell = AdditionalFieldsCell;

  render() {
    const { headers, cells } = this.props;

    return (
      <table className={styles.root} data-testid="additional-fields-table">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          <tr>{cells}</tr>
        </tbody>
      </table>
    );
  }
}
