import { css } from 'emotion';

export const root = css`
  padding-left: 15px;
  vertical-align: baseline;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #545454;
  border-left: 1px solid #b4b4b4;
  width: 143px;
  height: 32px;
`;
