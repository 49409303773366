import { useEffect, useRef } from 'react';
import { DateRangeType } from '@insights/constants-nwe';
import {
  useSetFocus,
  useToggleFocusReducer,
} from '@insights/shared-components-nwe';

export const useCalendarHook = (onSubmit: Function) => {
  const {
    display,
    setFocus,
    dispatchDisplay,
    dispatchHideAndSetFocus,
  } = useToggleFocusReducer();
  const calendarWidget = useRef<null | HTMLDivElement>(null);

  const calendarButton = useRef<null | HTMLButtonElement>(null);
  useSetFocus(calendarButton, setFocus);

  const toggleCalendar = () => {
    if (display) {
      dispatchHideAndSetFocus();
    } else {
      dispatchDisplay();
    }
  };

  const submitDateRangeChange = (start: Date, end: Date) => {
    onSubmit({
      startDate: start,
      endDate: end,
      type: DateRangeType.during,
    });
    dispatchHideAndSetFocus();
  };

  const detectClickOutsideCalendarWidget = (e: MouseEvent) => {
    if (
      display &&
      calendarWidget.current &&
      !calendarWidget.current.contains(e.target as Node)
    ) {
      dispatchHideAndSetFocus();
    }
  };

  useEffect(() => {
    if (display) {
      window.addEventListener('mousedown', detectClickOutsideCalendarWidget);
      return () =>
        window.removeEventListener(
          'mousedown',
          detectClickOutsideCalendarWidget
        );
    }
    return () => {};
  }, [display]);

  return {
    display,
    calendarWidget,
    calendarButton,
    toggleCalendar,
    setFocus,
    submitDateRangeChange,
  };
};
