import { combineReducers } from 'redux';
import {
  Filter,
  DateRange,
  isDateRangeFilter,
  isGraphQLFilter,
  FilterOption,
  FilterType,
} from '@insights/models-nwe';
import { Page, GraphqlFilterKey } from '@insights/constants-nwe';

import reducer, { State as BaseState } from './reducer';
import { isFilterAction } from '../../actions/filters';
import { ZOOM_COLUMN } from '../../constants';
import { createFilteredReducer } from '../../utils';

export interface State {
  [Page.work]: BaseState;
  [Page.people]: BaseState;
}

const combinedFiltersReducer = combineReducers<State>({
  [Page.work]: createFilteredReducer(reducer, (action): boolean =>
    isFilterAction(action) ? action.meta === Page.work : true
  ),
  [Page.people]: createFilteredReducer(reducer, (action): boolean =>
    isFilterAction(action) ? action.meta === Page.people : true
  ),
});

export default combinedFiltersReducer;

export const getFilters = (state: State, page: Page): Filter[] => state[page];

export const getZoomDateRange = (
  state: State,
  page: Page
): DateRange | undefined => {
  const zoomFilter = state[page].find(
    (filter) => filter.column === ZOOM_COLUMN
  );
  if (isDateRangeFilter(zoomFilter)) return zoomFilter.values;

  return undefined;
};

export const getCustomFilters = (state: State, page: Page): Filter[] => {
  const filters = getFilters(state, page);
  return filters.filter(
    (filter) =>
      isGraphQLFilter(filter) && filter.filterType === FilterType.Custom
  );
};

export const getTeamIds = (state: State): string[] => {
  const teamIds = state[Page.people].find(
    (filter) => filter.column === GraphqlFilterKey.teamguid
  );
  return isGraphQLFilter(teamIds)
    ? teamIds.values.map((team: FilterOption) => team.value)
    : [];
};
