/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, ReactNode } from 'react';
import { wfetch, encodeAsFormData } from '@wf-mfe/api';
import { Task } from 'workfront-objcodes';
import { RawTask, Task as TaskModel } from '@insights/models-nwe';
import { endOfDay, parseJSON } from 'date-fns';
import { useCancelableEffect } from '@insights/utils-nwe';

export interface Props {
  projectId: string;
  children(result: {
    data: TaskModel[] | undefined;
    loading: boolean;
    error: Error | undefined;
  }): ReactNode;
}

const GetTasks: FC<Props> = ({ projectId, children }) => {
  const endOfDayToday = endOfDay(new Date());
  const state = useCancelableEffect((): Promise<TaskModel[]> => {
    return wfetch(`/attask/api-internal/${Task}/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeAsFormData({
        method: 'get',
        fields: [
          'ID',
          'name',
          'plannedStartDate',
          'plannedCompletionDate',
          'actualStartDate',
          'percentComplete',
          'wbs',
          'workRequired',
        ],
        filters: {
          'AND:a:projectID': projectId,
          'AND:a:actualCompletionDate_Mod': 'isnull',
          'AND:a:numberOfChildren': 0,
          'AND:a:numberOfChildren_Mod': 'eq',
          'OR:b:plannedStartDate': endOfDayToday,
          'OR:b:plannedStartDate_Mod': 'lte',
          'OR:c:actualStartDate': endOfDayToday,
          'OR:c:actualStartDate_Mod': 'lte',
        },
      }),
    }).then(processTasks);
  }, [projectId]);

  return <>{children(state)}</>;
};

export default GetTasks;

export const processTasks = (tasks: RawTask[]): TaskModel[] => {
  return tasks.map(
    (task: RawTask): TaskModel => ({
      ...task,
      plannedStartDate: parseJSON(task.plannedStartDate),
      plannedCompletionDate: parseJSON(task.plannedCompletionDate),
      actualStartDate: task.actualStartDate
        ? parseJSON(task.actualStartDate)
        : null,
    })
  );
};
