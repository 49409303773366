import React, { FC } from 'react';

import { LineDot, LineDotProps } from '@insights/chart-parts-nwe';

export interface OwnProps {
  dataStart: number;
  dataEnd: number;
}

export type Props = OwnProps & LineDotProps;

const BurndownLineDot: FC<Props> = ({ x, y, dataStart, dataEnd, ...rest }) => {
  if (x < dataStart || x > dataEnd) return null;

  return <LineDot x={x} y={y} {...rest} />;
};

export default BurndownLineDot;
