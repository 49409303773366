import React, { FC, useMemo } from 'react';
import { Text } from '@vx/text';
import { primary } from '@phoenix/all';
import * as styles from './styles';

type MouseEventProps = Pick<
  React.SVGProps<SVGRect>,
  'onMouseMove' | 'onMouseLeave'
>;

interface Props {
  xPosition: number;
  yPosition: number;
  value: number;
  rowWidth: number;
  gradientXPosition: number;
  plannedStartDateXPosition: number;
}

const bufferOfPixelsFromSVGEdge = 15;
const pixelsToAdjustText = 30;
const pointOfOverlapToChangeColor = -10;
const nearestAcceptablePlacementOfTextToSVGEdge = 30;

const determineXPositionOfText = (
  xPosition: number,
  rowWidth: number
): number =>
  xPosition + bufferOfPixelsFromSVGEdge >= rowWidth
    ? rowWidth - pixelsToAdjustText
    : xPosition;

const determineTextColor = (
  textXPosition: number,
  gradientXPosition: number,
  rowWidth: number,
  xPosition: number,
  plannedStartDateXPosition: number
): string =>
  textXPosition - gradientXPosition <= pointOfOverlapToChangeColor &&
  rowWidth - xPosition <= nearestAcceptablePlacementOfTextToSVGEdge &&
  rowWidth - plannedStartDateXPosition >=
    nearestAcceptablePlacementOfTextToSVGEdge
    ? primary.white()
    : primary.gray(800);

export const BarText: FC<Props & MouseEventProps> = ({
  value,
  xPosition,
  yPosition,
  rowWidth,
  gradientXPosition,
  plannedStartDateXPosition,
  onMouseMove,
  onMouseLeave,
}) => {
  const textXPosition = useMemo(
    () => determineXPositionOfText(xPosition, rowWidth),
    [xPosition, rowWidth]
  );
  const textFillColor = useMemo(
    () =>
      determineTextColor(
        textXPosition,
        gradientXPosition,
        rowWidth,
        xPosition,
        plannedStartDateXPosition
      ),
    [
      textXPosition,
      gradientXPosition,
      rowWidth,
      xPosition,
      plannedStartDateXPosition,
    ]
  );

  return (
    <Text
      x={textXPosition}
      y={yPosition}
      fill={textFillColor}
      className={styles.text}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {`${Math.round(value)}%`}
    </Text>
  );
};
