import React, { FC } from 'react';
import { useIntl, MessageKeys } from '@insights/i18n-nwe';
import { primary } from '@phoenix/all';
import DownArrowIcon from 'phoenix-icons/dist/DownArrowIcon.js';
import UpArrowIcon from 'phoenix-icons/dist/UpArrowIcon.js';
import { DateRange } from '@insights/models-nwe';
import { Circle, ChangeNumber, ChangeArrowContainer } from './styles';
import { NoChange } from './NoChangeSVG';

export interface Props {
  currentValue: number;
  previousValue: number;
  ariaLabel: MessageKeys;
  dateRange: DateRange;
}

export const ChangeArrow: FC<Props> = ({
  currentValue,
  previousValue,
  ariaLabel,
  dateRange,
}) => {
  const { formatNumber, formatMessage } = useIntl();

  const changeAmount = currentValue - previousValue;
  const changePercentageValue =
    previousValue === 0 ? 0 : changeAmount / previousValue;

  let Arrow;
  let ariaLabelChangeValue;

  if (changeAmount === 0 || changePercentageValue === 0) {
    Arrow = NoChange;
    ariaLabelChangeValue = 'stayed the same';
  } else if (changeAmount > 0) {
    Arrow = UpArrowIcon;
    ariaLabelChangeValue = 'increased';
  } else {
    Arrow = DownArrowIcon;
    ariaLabelChangeValue = 'decreased';
  }

  const showChangeArrowContainer =
    (currentValue === 0 && previousValue === 0) || previousValue !== 0;

  const formattedNumberForFooter = formatNumber(changePercentageValue, {
    format: 'percent',
  });

  return showChangeArrowContainer ? (
    <ChangeArrowContainer>
      <Circle>
        <Arrow color={primary.blue()} height={16} width={16} />
      </Circle>
      <ChangeNumber
        data-testid={ariaLabelChangeValue}
        tabIndex={0}
        aria-label={formatMessage(
          { id: ariaLabel },
          {
            ariaLabelChangeValue,
            value: changePercentageValue,
            startDate: dateRange.start,
            endDate: dateRange.end,
          }
        )}
      >
        {changePercentageValue > 0 && '+'}
        {formattedNumberForFooter}
      </ChangeNumber>
    </ChangeArrowContainer>
  ) : null;
};
