import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import RightCaratIcon from 'phoenix-icons/dist/RightCaratIcon.js';
import { FilterOption } from '@insights/models-nwe';
import { formatItemTestId } from '@insights/utils-nwe';
import { AriaOptionProps } from '../../hooks/useComboBox';

export interface SingleSelectItemProps<T extends FilterOption> {
  item: T;
  onClick?(d: T): void;
  ariaOptionProps?(item: FilterOption): AriaOptionProps | null;
}

export const SingleSelectItem = <T extends FilterOption>({
  item,
  onClick = () => null,
  ariaOptionProps = () => null,
}: SingleSelectItemProps<T>): JSX.Element => (
  <SingleItem
    onClick={() => onClick(item)}
    data-testid={`select-item-${formatItemTestId(item.label)}`}
    role="option"
    {...ariaOptionProps(item)}
  >
    <Label>{item.label}</Label>
    <RightCaratIcon width={15} height={15} className={arrow} />
  </SingleItem>
);

export const arrow = css`
  visibility: hidden;
  margin-right: 20px;
`;

const SingleItem = styled.li`
  cursor: pointer;
  height: 32px;
  width: 317px;
  font-family: 'Proxima Nova';
  font-size: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover, &[aria-current="true"] {
    background-color: #f4f4f4;

    .${arrow} {
      right: -10px;
      top: 1px;
      visibility: visible;
    }}
  }
`;

const Label = styled.span`
  margin-left: 10px;
  color: #21262f;
`;
