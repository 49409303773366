import { FilterOption } from '@insights/models-nwe';

export const filterInput = <T extends FilterOption>(
  searchInput: string,
  options: T[]
): T[] => {
  return options.filter((d) => {
    if (searchInput === '' || searchInput === null) {
      return options;
    }
    return (
      d?.label &&
      searchInput &&
      d.label.toUpperCase().includes(searchInput.toUpperCase())
    );
  });
};
