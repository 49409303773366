import React, { FC, memo, useMemo, MouseEvent, useCallback } from 'react';
import { Group } from '@vx/group';
import { Bar } from '@vx/shape';
import { scaleBand } from '@vx/scale';
import { ScaleTime } from 'd3-scale';
import { primary } from '@phoenix/all';
import { HoverState, ProjectFragment } from '@insights/models-nwe';

import PlannedTimeline from '../planned-timeline';
import ConditionTimeline from '../condition-timeline';
import { ConditionFragment } from '../../../queries/get-conditions/models';
import { pointer } from './styles';

export const GROUP_HEIGHT = 35;

enum BarType {
  planned = 'planned',
  condition = 'condition',
}

export interface Props {
  dateScale: ScaleTime<number, number>;
  project: ProjectFragment;
  conditions: (ConditionFragment & { conditionChangeEnd: Date })[];
  top: number;
  height: number;
  hoverState?: HoverState;
  onMouseOver(id: string): void;
  onMouseOut(id: string): void;
  onClick(val: string): void;
  handleTooltip?(event: React.MouseEvent<DOMRect>, data: ProjectFragment): void;
  hideTooltip?(): void;
}

const ProjectBars: FC<Props> = ({
  height = GROUP_HEIGHT,
  top,
  dateScale,
  project,
  conditions = [],
  hoverState = HoverState.default,
  onMouseOver,
  onMouseOut,
  onClick,
  handleTooltip = () => null,
  hideTooltip = () => null,
}) => {
  const yScale = useMemo(
    () =>
      scaleBand({
        rangeRound: [0, height],
        padding: 0.25,
        domain: Object.values(BarType),
      }),
    [height]
  );

  const onMouseMove = useCallback(
    (e: MouseEvent<DOMRect>) => handleTooltip(e, project),
    [project]
  );
  const isActive = hoverState === HoverState.active;
  const isInactive = hoverState === HoverState.inactive;
  const barOpacity = isInactive ? 0.3 : 1;

  return (
    <Group
      top={top}
      onMouseOver={onMouseOver.bind(null, project.guid)}
      onMouseOut={onMouseOut.bind(null, project.guid)}
      onClick={onClick.bind(null, project.guid)}
      className={pointer}
    >
      <Bar
        height={GROUP_HEIGHT}
        width={dateScale.range()[1]}
        opacity={isActive ? 0.1 : 0}
        fill={isActive ? primary.blue() : undefined}
      />
      <PlannedTimeline
        project={project}
        dateScale={dateScale}
        height={yScale.bandwidth()}
        top={yScale(BarType.planned)}
        opacity={barOpacity}
        onMouseMove={onMouseMove}
        onMouseLeave={hideTooltip}
      />
      <ConditionTimeline
        conditions={conditions}
        dateScale={dateScale}
        height={yScale.bandwidth()}
        y={yScale(BarType.condition)}
        opacity={barOpacity}
        onMouseMove={onMouseMove}
        onMouseLeave={hideTooltip}
      />
    </Group>
  );
};

export default memo(ProjectBars);
