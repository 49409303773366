import { MessageKeys } from '@insights/i18n-nwe';

enum ActivityType {
  login = 'login',
  taskStatusChange = 'taskStatusChange',
  tasksCompleted = 'tasksCompleted',
}

export const ACTIVITY_TYPE_TO_MESSAGE_KEY: {
  [key in ActivityType]: MessageKeys;
} = {
  [ActivityType.login]: MessageKeys.activityLoginText,
  [ActivityType.taskStatusChange]: MessageKeys.activityTasksChanges,
  [ActivityType.tasksCompleted]: MessageKeys.activityTasksCompleted,
};

export enum ActivityLabels {
  projectActivityLabel = 'Project Names',
  peopleActivityLabel = 'Team Names',
}

export const ACTIVITY_LABELS_TO_MESSAGE_KEY: {
  [key in ActivityLabels]: MessageKeys;
} = {
  [ActivityLabels.projectActivityLabel]: MessageKeys.projectNames,
  [ActivityLabels.peopleActivityLabel]: MessageKeys.teamNames,
};

export default ActivityType;

export function isActivityType(arg: any): arg is ActivityType {
  return (
    typeof arg === 'string' && Object.values<string>(ActivityType).includes(arg)
  );
}
