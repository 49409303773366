import React, { FC } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import SearchIcon from 'phoenix-icons/dist/SearchIcon.js';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';
import { HiddenAriaElement } from '@insights/shared-components-nwe';
import { FilterInput } from '../filtler-input';
import { AriaInputProps } from '../../hooks/useComboBox';

interface Props {
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  searchInputLabel?: React.ReactNode;
  disable?: boolean;
  ariaInputProps?: AriaInputProps | {};
}

export const TEST_ID_SEARCH_INPUT = 'filter-search-input';

export const SearchInput: FC<Props> = ({
  value,
  onChange,
  disable = false,
  searchInputLabel,
  children,
  ariaInputProps = {},
}) => {
  const { formatMessage } = useIntl();

  return (
    <InputContainer>
      <SearchIcon className={icon} />
      <FilterInput
        autoFocus
        type="text"
        className={search}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={formatMessage({ id: MessageKeys.search })}
        data-testid={TEST_ID_SEARCH_INPUT}
        disabled={disable}
        id={TEST_ID_SEARCH_INPUT}
        role="searchbox"
        aria-autocomplete="list"
        autoComplete="off"
        {...ariaInputProps}
      />
      {searchInputLabel && (
        <HiddenAriaElement tag="label" htmlFor={TEST_ID_SEARCH_INPUT}>
          {searchInputLabel}
        </HiddenAriaElement>
      )}
      {children}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
`;

const icon = css`
  position: absolute;
  width: 13px;
  margin-left: 5px;
  padding-bottom: 3px;
`;

const search = css`
  padding-left: 15px;
`;
