import { DateRange, GroupedActivity } from '@insights/models-nwe';
import { ActivityType } from '@insights/constants-nwe';
import { eachDayOfInterval, format } from 'date-fns';

interface ByDate {
  [date: string]: number;
}

interface ByType {
  [key: string]: ByDate;
}

interface Template {
  [key: string]: string;
}

export interface ActivityHeaders {
  date: string;
  numberOfLogins: string;
  numberOfTaskStatusChanges: string;
  numberOfTasksCompleted: string;
}

const getDateKey = (date: Date): string => format(date, 'yyyy-MM-dd');

export const extractActivityData = <T extends Template>(
  headers: ActivityHeaders,
  template: T,
  activities: GroupedActivity['activities'],
  dateRange: DateRange,
  formatDate: (d: Date) => Date | string = (date) => date,
  formatNumber: (n: number) => number | string = (number) => number
): (T & { [key: string]: Date | number | string })[] => {
  const countsIndexedByTypeAndDate = Object.values(ActivityType).reduce<ByType>(
    (map, type) => ({
      ...map,
      [type]: activities[type].reduce<ByDate>(
        (accum, activity) => ({
          ...accum,
          [getDateKey(activity.date)]: activity.count,
        }),
        {}
      ),
    }),
    {} as ByType
  );

  return eachDayOfInterval(dateRange).map((date) => {
    const key = getDateKey(date);

    return {
      ...template,
      [headers.date]: formatDate(date),
      [headers.numberOfLogins]: formatNumber(
        countsIndexedByTypeAndDate[ActivityType.login][key] ?? 0
      ),
      [headers.numberOfTaskStatusChanges]: formatNumber(
        countsIndexedByTypeAndDate[ActivityType.taskStatusChange][key] ?? 0
      ),
      [headers.numberOfTasksCompleted]: formatNumber(
        countsIndexedByTypeAndDate[ActivityType.tasksCompleted][key] ?? 0
      ),
    };
  });
};
