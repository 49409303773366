import { css } from 'emotion';
import { primary } from '@phoenix/all';

export const display = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
`;

export const durationDaysDisplay = css`
  color: ${primary.gray(400)};
  margin-left: 65px;
  text-align: right;
`;
