import { getType, ActionType } from 'typesafe-actions';
import * as Actions from './actions';
import { InitialState, ZoomState } from './index';

export const INITIAL_STATE: InitialState = {
  start: undefined,
  end: undefined,
  isBrushing: false,
};

export default (
  state: ZoomState,
  action: ActionType<typeof Actions>
): ZoomState => {
  switch (action.type) {
    case getType(Actions.startBrush): {
      return {
        ...INITIAL_STATE,
        isBrushing: true,
        start: action.payload,
      };
    }
    case getType(Actions.dragBrush): {
      if (!state.isBrushing) return state;

      return {
        ...state,
        isBrushing: true,
        end: action.payload,
      };
    }
    case getType(Actions.resetBrush): {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
