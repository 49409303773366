import { MessageKeys } from '@insights/i18n-nwe';

export enum TasksInFlightSortBy {
  completionDate = 'completionDate',
  alphabetically = 'alphabetically',
  workBreakdownStructure = 'workBreakdownStructure',
}

export const TASKS_IN_FLIGHT_SORT_BY_TO_MESSAGE_KEY: {
  [key in TasksInFlightSortBy]: MessageKeys;
} = {
  [TasksInFlightSortBy.completionDate]: MessageKeys.completionDate,
  [TasksInFlightSortBy.alphabetically]: MessageKeys.alphabetically,
  [TasksInFlightSortBy.workBreakdownStructure]:
    MessageKeys.workBreakdownStucture,
};
