import React, { FC } from 'react';
import { HourGrain, HOUR_GRAIN_TO_MESSAGE_KEY } from '@insights/constants-nwe';
import {
  useCustomerPreferences,
  CustomerPreferenceField,
} from '@insights/clients-nwe';
import Dropdown from '../dropdown';

export interface Props {
  value: HourGrain;
  onChange(grain: HourGrain): void;
  testID: string;
}

export const dropdownOptions = Object.values(HourGrain).map((option) => {
  return { value: option, messageKey: HOUR_GRAIN_TO_MESSAGE_KEY[option] };
});

const HourGrainSelect: FC<Props> = ({ value, onChange, testID }) => {
  const { preferences } = useCustomerPreferences();
  const shouldEnableDuration = !!preferences?.[
    CustomerPreferenceField.hoursPerDay
  ];

  return (
    <Dropdown
      value={value}
      onChange={onChange}
      testID={testID}
      options={dropdownOptions}
      optionIsDisabled={(option) =>
        option === HourGrain.plannedDuration && !shouldEnableDuration
      }
    />
  );
};

export default HourGrainSelect;
