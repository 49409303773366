import React, { FC } from 'react';
import { ScaleLinear } from 'd3-scale';
import { NumberAxis } from '@insights/chart-parts-nwe';
import { Orientation } from '@insights/constants-nwe';
import { MessageKeys } from '@insights/i18n-nwe';

export interface Props {
  scale: ScaleLinear<number, number>;
  top: number;
}

const BottomAxis: FC<Props> = ({ scale, top }) => {
  return (
    <NumberAxis
      labelKey={MessageKeys.hours}
      scale={scale}
      top={top}
      hideAxisLine={false}
      labelOffset={12}
      orientation={Orientation.bottom}
    />
  );
};

export default BottomAxis;
