import React, { FC } from 'react';
import { primary } from '@phoenix/all';
import CheckmarkIcon from 'phoenix-icons/dist/CheckmarkIcon.js';
import { FilterOption } from '@insights/models-nwe';
import styled from 'react-emotion';
import { AriaOptionProps } from '../../hooks/useComboBox';

interface MultiSelectOptionProps {
  option: FilterOption;
  checked: boolean;
  label: string | JSX.Element;
  testID?: string;
  ariaOptionProps?(item: FilterOption): AriaOptionProps | null;
}

export const MultiSelectOption: FC<MultiSelectOptionProps> = ({
  option,
  checked,
  label,
  testID,
  ariaOptionProps = () => null,
}) => (
  <Option
    data-testid={testID}
    role="option"
    aria-selected={checked}
    {...ariaOptionProps(option)}
  >
    <Checkbox checked={checked}>
      {checked && (
        <CheckmarkIcon color={primary.white()} height={15} title="" />
      )}
    </Checkbox>
    <Label>{label}</Label>
  </Option>
);

const Option = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 22px;
  padding: 7px 14px;

  &[aria-current='true'],
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

const Checkbox = styled.span`
  display: flex;
  flex: 0 0 auto;
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? primary.blue() : primary.white()};
  margin-right: 12px;
  height: 15px;
  width: 15px;
  border: ${(props: { checked: boolean }) =>
    props.checked ? 'none' : `1px solid ${primary.gray(300)}`}};
  border-radius: 4px;
  opacity: 1;
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 1.25;
`;
