import React, { FC } from 'react';
import styled from 'react-emotion';
import { Spinner } from '@phoenix/all';

export const TEST_ID = 'filter-spinner';

export const LoadingSpinner: FC = () => (
  <SpinnerWrapper>
    <Spinner testId={TEST_ID} />
  </SpinnerWrapper>
);

const SpinnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
