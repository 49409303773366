import { FC } from 'react';
import { ProjectFragment } from '@insights/models-nwe';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { SortDirection } from '@insights/constants-nwe';
import { parseDate } from '@insights/utils-nwe';
import { useProjects } from '@insights/clients-nwe';

import {
  RawConditionFragment,
  RawProjectFragment,
  ConditionFragment,
} from './models';

export interface Data {
  projects: ProjectFragment[];
  projectHistoricalConditions: ConditionFragment[];
}

export interface Props {
  children(result: {
    data: Data | undefined;
    loading: boolean;
    error: Error | undefined;
  }): JSX.Element | null;
}

export const GET_PROJECT_CONDITIONS = gql`
  query ProjectConditions($projectIds: [Guid!]!) {
    projectHistoricalConditions(
      projectIDs: $projectIds, 
      orderBy: [{ 
        key: conditionchangetimestamp, 
        direction: ${SortDirection.asc} 
      }]
    ) {
      projectguid
      conditionchangetimestamp
      condition
      conditionlabel
    }
  }
`;

export interface Response {
  projects: RawProjectFragment[];
  projectHistoricalConditions: RawConditionFragment[];
}

export interface Variables {
  projectIds: string[];
}

export const processConditions = (
  data: RawConditionFragment[]
): ConditionFragment[] =>
  data.map((d) => ({
    ...d,
    conditionchangetimestamp: parseDate(d.conditionchangetimestamp),
  }));

const GetConditions: FC<Props> = ({ children }) => {
  const {
    data: { projects },
    loading: projectsLoading,
  } = useProjects();
  const hasNoProjects = projects.size === 0;
  const skipQuery = projectsLoading || hasNoProjects;
  const { data, loading, error } = useQuery<Response, Variables>(
    GET_PROJECT_CONDITIONS,
    {
      variables: { projectIds: Array.from(projects.keys()).sort() },
      skip: skipQuery,
    }
  );

  if (skipQuery)
    return children({
      data: { projects: [], projectHistoricalConditions: [] },
      loading: projectsLoading,
      error: undefined,
    });
  else if (!data) return children({ data, loading, error });

  return children({
    loading,
    error,
    data: {
      projects: Array.from(projects.values()),
      projectHistoricalConditions: processConditions(
        data.projectHistoricalConditions
      ),
    },
  });
};

export default GetConditions;
