import styled from 'react-emotion';

export interface Props {
  size?: 'medium' | 'large';
}

export default styled('div')`
  height: ${(props: Props) => (props.size === 'large' ? '32px' : '24px')};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
