import { MessageKeys } from './message-keys';

// PLEASE KEEP THESE IN ALPHABETICAL ORDER
export const TEST_MESSAGES = {
  [MessageKeys.A_Z]: 'A - Z',
  [MessageKeys.activeFilter]:
    'Active filter {column} {operator} {filterOptions} ({filterNumber} of {totalFilters})',
  [MessageKeys.activityHeaderLogins]: 'Number of Logins',
  [MessageKeys.activityHeaderStatusChanges]: 'Number of Task Status Changes',
  [MessageKeys.activityHeaderTasksCompleted]: 'Number of Tasks Completed',
  [MessageKeys.activityLoginText]: 'Users logged in',
  [MessageKeys.activityTasksChanges]: 'Task status changes',
  [MessageKeys.activityTasksCompleted]: 'Tasks completed',
  [MessageKeys.activityType]: 'Activity type',
  [MessageKeys.actualCapacity]: 'Actual capacity',
  [MessageKeys.actualCompletionDate]: 'Actual Completion Date',
  [MessageKeys.actualStartDate]: 'Actual Start Date',
  [MessageKeys.analyticsNoAccess]:
    'You do not have sufficient rights. Please contact your administrator to upgrade your license in order to get access.',
  [MessageKeys.applyFilter]: 'Apply filter',
  [MessageKeys.atCapacity]: 'At capacity',
  [MessageKeys.availableHours]: 'Available Hours',
  [MessageKeys.averageHours]: 'avg. hours per day',
  [MessageKeys.back]: 'Back',
  [MessageKeys.burndown]: 'Burndown',
  [MessageKeys.calendarFilter]:
    'Calendar Filter showing {startDate, date, readable} through {endDate, date, readable}',
  [MessageKeys.calendarSelector]: 'calendar-selector',
  [MessageKeys.capacity]: 'Capacity',
  [MessageKeys.export]: 'export',
  [MessageKeys.between]: 'between',
  [MessageKeys.burndownBy]: 'Burndown by',
  [MessageKeys.burndownHourTableAdded]: 'Added',
  [MessageKeys.burndownHourTableCompleted]: 'Completed',
  [MessageKeys.burndownHourTableHoursHeader]: 'Hours',
  [MessageKeys.burndownHourTableTasksHeader]: 'Tasks',
  [MessageKeys.burndownHourTableTotal]: 'Total',
  [MessageKeys.burndownHourTableZeroState]:
    'Click a point on the graph to view how tasks and hours were changed on a given day.',
  [MessageKeys.burndownPlannedVelocity]: 'Planned',
  [MessageKeys.burndownProjectLink]: 'Go to project page',
  [MessageKeys.capacityType]: 'Capacity type',
  [MessageKeys.completedHours]: 'completed hours',
  [MessageKeys.completedPlannedHours]: 'Completed Planned Hours',
  [MessageKeys.condition]: 'Condition',
  [MessageKeys.contains]: 'contains',
  [MessageKeys.currentProjectConditions]: 'Current Project Conditions',
  [MessageKeys.currentProjectConditionsCaption]:
    'Current Project Conditions for {startDate, date, readable} - {endDate, date, readable}',
  [MessageKeys.customCondition]: 'Custom Condition',
  [MessageKeys.customerDurationPreferenceError]:
    'No information is available for Duration. Select Planned hours to load project data.',
  [MessageKeys.customFormNumberFieldInputLabel]:
    'Enter number to filter on the field {fieldName}.',
  [MessageKeys.customForms]: 'Custom Forms',
  [MessageKeys.customFormTextFieldInputLabel]:
    'Enter text to filter on the field {fieldName}.',
  [MessageKeys.date]: 'Date',
  [MessageKeys.dateRangeReadable]:
    '{startDate, date, readable} and {endDate, date, readable}',
  [MessageKeys.days]: 'Days',
  [MessageKeys.daysWorked]: 'Days Worked',
  [MessageKeys.due]: 'Due',
  [MessageKeys.durationAdded]: 'Duration Added',
  [MessageKeys.durationBurndownA11yCaption]:
    'Duration Burndown for { projectName } between {startDate, date, readable} and {endDate, date, readable}',
  [MessageKeys.durationCompleted]: 'Duration Completed',
  [MessageKeys.durationDays]:
    '{numberOfDays, number, twoDec} {numberOfDays, plural, =0 {Days} =1 {Day} other {Days}}',
  [MessageKeys.conditionChangeTimeStamp]: 'Condition Time Stamp',
  [MessageKeys.enterNumber]: 'Enter number',
  [MessageKeys.enterText]: 'Enter text',
  [MessageKeys.equals]: 'equals',
  [MessageKeys.error]: 'Error!',
  [MessageKeys.exportPng]: 'Chart (PNG)',
  [MessageKeys.exportXlsx]: 'Data Table (XLSX)',
  [MessageKeys.feedback]: 'Feedback',
  [MessageKeys.filterAdd]: 'Add filter',
  [MessageKeys.filtersLength]:
    '{numberOfFilters, plural, zero {0 Filters} one {1 Filter} other {{numberOfFilters, number} Filters}}',
  [MessageKeys.flightPlan]: 'Flight plan',
  [MessageKeys.flightPlanLegendPlan]: 'Planned',
  [MessageKeys.flightPlanPlannedTimeframe]: 'Planned Timeframe',
  [MessageKeys.filteringThreeCharLimit]: 'Type at least 3 characters to search',
  [MessageKeys.filterUnsupportedError]:
    'This field type is not supported. Select another custom field.',
  [MessageKeys.header]: 'Enhanced analytics',
  [MessageKeys.hours]: 'Hours',
  [MessageKeys.hoursCompleted]: 'Hours completed',
  [MessageKeys.historicalProjectConditions]: 'Historical project conditions',
  [MessageKeys.kpiAverageProjectDuration]: 'Avg. project duration',
  [MessageKeys.kpiAverageProjectDurationDays]:
    '{value, number, oneDec} <days>{value, plural, =0 {days} =1 {day} other {days}}</days>',
  [MessageKeys.kpiAverageProjectDurationWeeks]:
    '{value, number, oneDec} <weeks>{value, plural, =0 {weeks} =1 {week} other {weeks}}</weeks>',
  [MessageKeys.kpiAverageProjectDurationYears]:
    '{value, number, oneDec} <years>{value, plural, =0 {years} =1 {year} other {years}}</years>',
  [MessageKeys.kpiPreviousDays]:
    'prev: {totalDays, number, oneDec} {totalDays, plural, =0 {days} one {day} other {days}}',
  [MessageKeys.kpiAverageTasksPerProject]: 'Avg. tasks per project',
  [MessageKeys.kpiCharts]: 'KPI Charts',
  [MessageKeys.kpiProjectsCompleted]: 'Projects completed',
  [MessageKeys.kpiProjectsCompletedOnTime]: 'Projects completed on time',
  [MessageKeys.kpiAriaLabelAverageProjectDurationHeader]:
    'Average project duration',
  [MessageKeys.kpiAriaLabelAverageProjectDurationBodyDays]:
    'Average project duration is { value, number, oneDec } {value, plural, =0 {days} =1 {day} other {days}} between {startDate, date, readable} and {endDate, date, readable }',
  [MessageKeys.kpiAriaLabelAverageProjectDurationBodyWeeks]:
    'Average project duration is { value, number, oneDec } {value, plural, =0 {weeks} =1 {week} other {weeks}} between {startDate, date, readable} and {endDate, date, readable }',
  [MessageKeys.kpiAriaLabelAverageProjectDurationBodyYears]:
    'Average project duration is { value, number, oneDec } {value, plural, =0 {years} =1 {year} other {years}} between {startDate, date, readable} and {endDate, date, readable }',
  [MessageKeys.kpiAriaLabelAverageProjectDurationFooterDays]:
    'Average project duration is { value, number, oneDec } {value, plural, =0 {days} =1 {day} other {days}} for the previous { totalDays, number, oneDec  } { totalDays, plural, =0 {days} =1 {day} other {days} }',
  [MessageKeys.kpiAriaLabelAverageProjectDurationFooterWeeks]:
    'Average project duration is { value, number, oneDec } {value, plural, =0 {weeks} =1 {week} other {weeks}} for the previous { totalDays, number, oneDec  } { totalDays, plural, =0 {days} =1 {day} other {days} }',
  [MessageKeys.kpiAriaLabelAverageProjectDurationFooterYears]:
    'Average project duration is { value, number, oneDec } {value, plural, =0 {years} =1 {year} other {years}} for the previous { totalDays, number, oneDec  } { totalDays, plural, =0 {days} =1 {day} other {days} }',
  [MessageKeys.kpiAriaLabelAverageProjectDurationBodyChange]:
    'Average project duration has { ariaLabelChangeValue, select, decreased {decreased by} increased {increased by} other { stayed the same at } } {value, number, percent} between { startDate, date, readable } and { endDate, date, readable }',
  [MessageKeys.kpiAriaLabelAverageProjectDurationFooter]:
    '{ value } Average project duration for the previous { totalDays, number, oneDec } { totalDays, plural, =0 {days} =1 {day} other {days} }',
  [MessageKeys.kpiAriaLabelAverageTasksPerProjectHeader]:
    'Average tasks per project',
  [MessageKeys.kpiAriaLabelAverageTasksPerProjectBody]:
    '{ value, number, oneDec } Average tasks per project between {startDate, date, readable} and {endDate, date, readable }',
  [MessageKeys.kpiAriaLabelAverageTasksPerProjectBodyChange]:
    'Average tasks per project has { ariaLabelChangeValue, select, decreased {decreased by} increased {increased by} other { stayed the same at }} {value, number, percent} between { startDate, date, readable } and { endDate, date, readable }',
  [MessageKeys.kpiAriaLabelAverageTasksPerProjectFooter]:
    '{ value, number, oneDec } Average tasks per project for the previous { totalDays, number, oneDec } { totalDays, plural, =0 {days} =1 {day} other {days} }',
  [MessageKeys.kpiAriaLabelProjectsCompletedBody]:
    '{ value, number, oneDec } Projects completed between {startDate, date, readable} and {endDate, date, readable}',
  [MessageKeys.kpiAriaLabelProjectsCompletedBodyChange]:
    'Projects completed has { ariaLabelChangeValue, select, decreased {decreased by} increased {increased by} other { stayed the same at }} {value, number, percent} between { startDate, date, readable } and { endDate, date, readable }',
  [MessageKeys.kpiAriaLabelProjectsCompletedFooter]:
    '{ value, number, oneDec } Projects completed for the previous { totalDays, number, oneDec } { totalDays, plural, =0 {days} =1 {day} other {days} }',
  [MessageKeys.kpiAriaLabelProjectsCompletedOnTimeBody]:
    '{ value, number, percent } Projects completed on time between {startDate, date, readable} and {endDate, date, readable}',
  [MessageKeys.kpiAriaLabelProjectsCompletedOnTimeBodyChange]:
    'Projects completed on time has { ariaLabelChangeValue, select, decreased {decreased by} increased {increased by} other { stayed the same at }} {value, number, percent} between { startDate, date, readable } and { endDate, date, readable }',
  [MessageKeys.kpiAriaLabelProjectsCompletedOnTimeFooter]:
    '{ value, number, percent } Projects completed on time for the previous { totalDays, number, oneDec } { totalDays, plural, =0 {days} =1 {day} other {days} }',
  [MessageKeys.loading]: 'Loading...',
  [MessageKeys.ninetyTwoDayMax]:
    "You can’t view more than a quarters' worth of data at a time.",
  [MessageKeys.noActivity]: 'No activity available for date range',
  [MessageKeys.noInfo]:
    'No information is available for this project. Please adjust the date sub filter to populate with relevant data.',
  [MessageKeys.none]: 'None',
  [MessageKeys.notAvailable]: 'Not Available',
  [MessageKeys.notRetrieved]: 'This project could not be retrieved',
  [MessageKeys.paginationGoToPageNumber]: 'Go to page {pageNumber, number}',
  [MessageKeys.paginationNextPage]: 'Go to next page',
  [MessageKeys.paginationPageNumber]: 'Page {pageNumber, number}',
  [MessageKeys.paginationCurrentPageOfTotal]:
    'You are on page {currentPageNumber, number} of {totalPages, number}',
  [MessageKeys.paginationPreviousPage]: 'Go to previous page',
  [MessageKeys.paginationText]:
    '{projectCount, number} of {totalProjects, number} projects',
  [MessageKeys.paginationWheelText]:
    '{startOfRange, number} - {endOfRange, number} of {totalCount, number} projects',
  [MessageKeys.peopleActivityCaption]:
    'People Activity for {name} for {startDate, date, readable} - {endDate, date, readable}',
  [MessageKeys.plannedCapacity]: 'Planned capacity',
  [MessageKeys.plannedCompletionDate]: 'Planned Completion Date',
  [MessageKeys.plannedDuration]: 'duration',
  [MessageKeys.plannedHours]: 'planned hours',
  [MessageKeys.plannedHoursBurndownA11yCaption]:
    'Planned Hours for { projectName } between {startDate, date, readable} and {endDate, date, readable}',
  [MessageKeys.plannedHoursAdded]: 'Planned Hours Added',
  [MessageKeys.plannedHoursCompleted]: 'Planned Hours Completed',
  [MessageKeys.plannedHoursRemaining]: 'Planned Hours Remaining',
  [MessageKeys.plannedStartDate]: 'Planned Start Date',
  [MessageKeys.plannedTimeframe]: 'planned timeframe',
  [MessageKeys.portfolio]: 'Portfolio',
  [MessageKeys.program]: 'Program',
  [MessageKeys.project]: 'Project',
  [MessageKeys.projectActivity]: 'Project activity',
  [MessageKeys.projectActivityCaption]:
    'Project Activity for {name} for {startDate, date, readable} - {endDate, date, readable}',
  [MessageKeys.projectConditionAtRisk]: 'At Risk',
  [MessageKeys.projectConditionInTrouble]: 'In Trouble',
  [MessageKeys.projectConditionOnTarget]: 'On Target',
  [MessageKeys.projectCount]:
    '{projectCount, plural, zero {(0 projects)} one {(1 project)} other {({projectCount, number} projects)}}',
  [MessageKeys.projectMagnitudeHeader]: 'Project treemap by',
  [MessageKeys.projectMagnitudeTableCaption]:
    'Project Magnitudes for {startDate, date, readable} - {endDate, date, readable}',
  [MessageKeys.projectName]: 'Project Name',
  [MessageKeys.resourceCapacityCaption]:
    'Resource Capacity from {startDate, date, long} to {endDate, date, long}',
  [MessageKeys.resourceCapacityLowercase]: 'Resource capacity',
  [MessageKeys.resourceCapacityToolTipAvailableCapacity]: 'Available Capacity',
  [MessageKeys.resourceCapacityTooltipHours]:
    '{hours, plural, zero {0 hours} one {1 hour} other {{hours, number} hours}}',
  [MessageKeys.resourceCapacityTooltipOverCapacity]:
    'Over capacity <bold>{hours, plural, one {1 hour} other {{hours, number} hours}}</bold>',
  [MessageKeys.resourceCapacityToolTipPlannedCapacity]: 'Planned Capacity',
  [MessageKeys.resourceCapacityTooltipUnderCapacity]:
    'Under capacity <bold>{hours, plural, one {1 hour} other {{hours, number} hours}}</bold>',
  [MessageKeys.scheduledHours]: 'Scheduled Hours',
  [MessageKeys.remainingDuration]: 'Remaining Duration',
  [MessageKeys.search]: 'Search...',
  [MessageKeys.status]: 'Status',
  [MessageKeys.searchAvailableFilterOptions]: 'Search available filter options',
  [MessageKeys.searchAvailableCustomFieldOptions]:
    'Search available custom field options',
  [MessageKeys.searchAvailableCustomFormOptions]:
    'Search available custom form options',
  [MessageKeys.searchFilterOptionsByFilterType]:
    'Search available {filterColumn} options',
  [MessageKeys.selectAll]: 'Select All',
  [MessageKeys.sortBy]: 'Sort by',
  [MessageKeys.sortByActualEndDate]: 'Actual end date',
  [MessageKeys.sortByActualStartDate]: 'Actual start date',
  [MessageKeys.sortByPlannedCompletionDate]: 'Planned completion date',
  [MessageKeys.sortByPlannedStartDate]: 'Planned start date',
  [MessageKeys.noTasks]:
    'There are no tasks in flight for the selected date range.',
  [MessageKeys.noTasksInfo]: 'There are no tasks in flight for this project.',
  [MessageKeys.projectPagination]: 'Project Pagination',
  [MessageKeys.teamCapacityCaption]:
    '{teamName} Capacity between { startDate, date, readable} and { endDate, date, readable}',
  [MessageKeys.tasksInFlightCaption]:
    'Tasks in Flight for {projectName} {startDate, date, readable} to {endDate, date, readable}',
  [MessageKeys.TeamFilter]: 'Select teams in the filter to view data',
  [MessageKeys.teamName]: 'Team Name',
  [MessageKeys.timeframe]: 'Timeframe',
  [MessageKeys.tooltip]: 'Tooltip',
  [MessageKeys.tooltipButtonDescriptionActivityByTeam]:
    'Activity by team info tooltip',
  [MessageKeys.tooltipButtonDescriptionAverageProjectDuration]:
    'Average project duration info tooltip',
  [MessageKeys.tooltipButtonDescriptionAverageTasksPerProject]:
    'Average tasks per project info tooltip',
  [MessageKeys.tooltipButtonDescriptionBurndown]: 'Burndown info tooltip',
  [MessageKeys.tooltipButtonDescriptionFilters]: 'Filtering info tooltip',
  [MessageKeys.tooltipButtonDescriptionFlightPlan]: 'Flight plan info tooltip',
  [MessageKeys.tooltipButtonDescriptionProjectActivity]:
    'Project activity info tooltip',
  [MessageKeys.tooltipButtonDescriptionProjectsCompleted]:
    'Projects completed info tooltip',
  [MessageKeys.tooltipButtonDescriptionProjectsCompletedOnTime]:
    'Projects completed on time info tooltip',
  [MessageKeys.tooltipButtonDescriptionProjectTreemap]:
    'Project treemap info tooltip',
  [MessageKeys.tooltipButtonDescriptionResourceCapacity]:
    'Resource capacity info tooltip',
  [MessageKeys.tooltipButtonDescriptionTasksInFlight]:
    'Tasks in flight info tooltip',
  [MessageKeys.tooltipButtonDescriptionTeamCapacity]:
    'Team capacity info tooltip',
  [MessageKeys.total]: 'Total',
  [MessageKeys.totalProjectCount]: '{projectCount, number} projects',
  [MessageKeys.tasksAddedDaily]: 'Tasks Added Daily',
  [MessageKeys.taskBurndownA11yCaption]:
    'Task Burndown for { projectName } between {startDate, date, readable} and {endDate, date, readable}',
  [MessageKeys.tasksCompletedDaily]: 'Tasks Completed',
  [MessageKeys.velocity]: 'Velocity',
  [MessageKeys.workRequired]: 'Work Required',
  [MessageKeys.ZeroFilters]: '0 Filter options remain.',
  [MessageKeys.ZeroMessage]:
    'No projects match your filter criteria. Adjust your filters to view relevant data.',
  [MessageKeys.Z_A]: 'Z - A',
};
