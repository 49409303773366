import React, { FC } from 'react';
import { LegendColors } from '@insights/constants-nwe';

interface Props {
  gradientId: string;
  yMax: number;
  gradientOffsetTop: number;
  gradientOffsetMiddle: number;
  gradientOffsetBottom: number;
}

const convertToPercentage = 100;

const STOP_OPACITY = 0.7;

export const TeamCapacityGradient: FC<Props> = ({
  gradientId,
  yMax,
  gradientOffsetTop,
  gradientOffsetMiddle,
  gradientOffsetBottom,
}) => (
  <svg width={1} height={1}>
    <defs>
      <linearGradient
        id={gradientId}
        x1="0"
        x2="0"
        y1="0"
        y2={yMax}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={`${gradientOffsetTop * convertToPercentage}%`}
          stopColor={LegendColors.blue}
          stopOpacity={STOP_OPACITY}
        />
        <stop
          offset={`${gradientOffsetMiddle * convertToPercentage}%`}
          stopColor={LegendColors.blue100}
          stopOpacity={STOP_OPACITY}
        />
        <stop
          offset={`${gradientOffsetBottom * convertToPercentage}%`}
          stopColor={LegendColors.blue}
          stopOpacity={STOP_OPACITY}
        />
      </linearGradient>
    </defs>
  </svg>
);
