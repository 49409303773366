import React, { FC } from 'react';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import { MouseEventProps } from '../models';

export interface Props {
  xScale: ScaleLinear<number, number>;
  color: string;
  height: number;
  availableHours: number;
  scheduledHours: number;
  opacity: number;
}

const PADDING = 10;

const CapacityLine: FC<Props & MouseEventProps> = ({
  xScale,
  color,
  height,
  availableHours,
  scheduledHours,
  opacity,
  onMouseMove,
  onMouseLeave,
}) => {
  return (
    <>
      <Line
        from={{ x: xScale(availableHours), y: height - PADDING }}
        to={{ x: xScale(availableHours), y: PADDING }}
        stroke={color}
        strokeWidth={2}
        opacity={opacity}
        strokeLinecap="round"
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <Line
        from={{ x: xScale(scheduledHours), y: height / 2 }}
        to={{ x: xScale(availableHours), y: height / 2 }}
        stroke={color}
        strokeWidth={1}
        opacity={opacity}
        strokeLinecap="round"
      />
      <Line
        from={{ x: xScale(scheduledHours), y: height / 2 }}
        to={{ x: xScale(availableHours), y: height / 2 }}
        stroke="transparent"
        strokeWidth={10}
        strokeLinecap="round"
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    </>
  );
};

export default CapacityLine;
