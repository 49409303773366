import React, { FC } from 'react';
import { HeaderWrapper } from './styles';

export interface Props {
  label: React.ReactNode;
}

const Header: FC<Props> = ({ label }) => {
  return <HeaderWrapper>{label}</HeaderWrapper>;
};

export default Header;
