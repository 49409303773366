import { GraphqlFilterKey } from '@insights/constants-nwe';
import {
  BasicOperator,
  CustomFormOperator,
} from '../graphql/project-filter-input';

export enum FilterType {
  Standard,
  Custom,
}

export interface BaseFilter {
  column: string;
  operator: string; // switch to enum
  values: any;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export interface FilterOption<T extends string = string> {
  label: string;
  value: T;
}

export interface DateRangeFilter extends BaseFilter {
  values: DateRange;
}

export interface ValueFilter extends BaseFilter {
  filterType: FilterType;
  values: FilterOption[];
}

export type Filter = DateRangeFilter | ValueFilter;

export type GraphQLFilter = StandardFilter | CustomFilter;

export interface StandardFilter extends ValueFilter {
  filterType: FilterType.Standard;
  column: GraphqlFilterKey;
  operator: BasicOperator;
}

export interface CustomFilter extends ValueFilter {
  filterType: FilterType.Custom;
  operator: CustomFormOperator;
}

export function isFilter(arg: any): arg is BaseFilter {
  return (
    arg !== undefined &&
    arg !== null &&
    Object.prototype.hasOwnProperty.call(arg, 'column') &&
    Object.prototype.hasOwnProperty.call(arg, 'operator') &&
    Object.prototype.hasOwnProperty.call(arg, 'values')
  );
}

export function isDateRangeFilter(arg: any): arg is DateRangeFilter {
  return (
    isFilter(arg) &&
    arg.values &&
    Object.prototype.hasOwnProperty.call(arg.values, 'start') &&
    arg.values.start instanceof Date &&
    Object.prototype.hasOwnProperty.call(arg.values, 'end') &&
    arg.values.end instanceof Date
  );
}

export function isValueFilter(arg: any): arg is ValueFilter {
  return isFilter(arg) && Array.isArray(arg.values);
}

export function isStandardFilter(arg: any): arg is StandardFilter {
  return (
    isValueFilter(arg) &&
    // check for undefined for backwards-compat
    (typeof arg.filterType === 'undefined' ||
      arg.filterType === FilterType.Standard) &&
    Object.values<string>(GraphqlFilterKey).includes(arg.column) &&
    Object.values<string>(BasicOperator).includes(arg.operator)
  );
}

export function isCustomFilter(arg: any): arg is CustomFilter {
  return (
    isValueFilter(arg) &&
    Object.prototype.hasOwnProperty.call(arg, 'filterType') &&
    arg.filterType === FilterType.Custom &&
    Object.values<string>(CustomFormOperator).includes(arg.operator)
  );
}

export function isGraphQLFilter(arg: any): arg is GraphQLFilter {
  return isStandardFilter(arg) || isCustomFilter(arg);
}
