import React, { FC, memo } from 'react';
import { primary } from '@phoenix/all';

// vx
import { LinePath } from '@vx/shape';
import { ScaleTime, ScaleLinear } from 'd3-scale';

import { RebaseDatum } from '@insights/models-nwe';
import { LineDot } from '@insights/chart-parts-nwe';

export interface Props {
  data: RebaseDatum[];
  dateScale: ScaleTime<number, number>;
  hoursScale: ScaleLinear<number, number>;
  strokeWidth?: number;
  color?: string;
}

export const COLOR = primary.blue(400);

// Burndown Line
const RebaseLine: FC<Props> = ({
  data,
  dateScale,
  hoursScale,
  strokeWidth = 1,
  color = COLOR,
}) => {
  const getX = (d: RebaseDatum) => d.date;
  const getY = (d: RebaseDatum) => d.hoursRemaining;

  return (
    <>
      <LinePath
        data={data}
        x={(d: RebaseDatum) => dateScale(getX(d))}
        y={(d: RebaseDatum) => hoursScale(getY(d))}
        strokeWidth={strokeWidth}
        stroke={color}
      />

      {data.map((d: RebaseDatum, i) => (
        <LineDot
          key={`${d.date}${d.hoursRemaining}${i}`} // eslint-disable-line
          // There are no unique values in the data array, so we're including the index to make it unique
          x={dateScale(d.date)}
          y={hoursScale(d.hoursRemaining)}
        />
      ))}
    </>
  );
};

export default memo(RebaseLine);
