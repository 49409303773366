import { css } from 'emotion';
import styled from 'react-emotion';
import { primary } from '@phoenix/all';

export const container = css`
  width: 100%;
  display: flex;
  color: ${primary.gray(800)};
  font-family: 'Proxima Nova';
  font-size: 12px;
  line-height: 16px;
`;

export const fractionContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const top = css`
  border-bottom: 1px solid ${primary.gray(900)};
`;

export const totalContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px;
`;

export const Header = styled.div`
  color: ${primary.gray(300)};
  font-family: 'Proxima Nova';
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
`;

export const Bold = styled.span`
  color: ${primary.gray(800)};
  font-family: 'Proxima Nova';
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
`;

export const RedText = styled.span`
  color: #de3737;
  display: block;
  max-width: 220px;
`;

export const capacityPercentage = css`
  text-transform: uppercase;
`;
