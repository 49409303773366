import React, { FC, ReactNode } from 'react';
import { CardComponent } from '@insights/shared-components-nwe';

import { CardGutter, KPILayout, KPIHeader, KPIBody, KPIFooter } from './styles';

export interface Props {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  testID?: string;
}

export const KpiCardWrapper: FC<Props> = ({ testID, header, body, footer }) => {
  return (
    <CardGutter>
      <CardComponent>
        <KPILayout data-testid={`kpi-${testID}`}>
          <KPIHeader data-testid={`kpi-header-${testID}`}>{header}</KPIHeader>
          <KPIBody data-testid={`kpi-body-${testID}`}>{body}</KPIBody>
          <KPIFooter data-testid={`kpi-footer-${testID}`}>{footer}</KPIFooter>
        </KPILayout>
      </CardComponent>
    </CardGutter>
  );
};
