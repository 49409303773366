// @TODO move to work package when card refactor happens
import React, { FC, useRef, useMemo } from 'react';
import { Dropdown, Option, primary } from '@phoenix/all';
import {
  SortByDirection,
  SortByFields,
  sortByOptions,
  SortByOption,
} from '@insights/constants-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import styled from 'react-emotion';
import uniqueId from 'lodash.uniqueid';

import { Trigger } from './trigger';

export type SelectedSort = {
  sortBy: SortByFields;
  sortDirection: SortByDirection;
};

export type Props = {
  selectedSort: SelectedSort;
  onChange(selectedSort: SelectedSort): void;
  className?: string;
};

export const ProjectSort: FC<Props> = ({
  selectedSort,
  onChange,
  className,
}) => {
  const selectedOption = getSelectedOption(selectedSort);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const { labelId, valueId } = useMemo(
    () => ({
      labelId: uniqueId('sort-by-label'),
      valueId: uniqueId('sort-by-value'),
    }),
    []
  );

  return (
    <Container className={className}>
      <Label id={labelId}>
        <FormattedMessage id={MessageKeys.sortBy} />:
      </Label>
      <Dropdown
        selectedOptions={[selectedOption]}
        triggerRef={triggerRef}
        optionsChildren={optionsChildren}
        onChange={({ direction, value }: SortByOption) => {
          onChange({
            sortBy: value,
            sortDirection: direction,
          });
        }}
      >
        <Trigger
          messageKey={selectedOption?.messageKey}
          innerRef={triggerRef}
          valueId={valueId}
          aria-labelledby={`${labelId} ${valueId}`}
        />
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Label = styled.span`
  color: ${primary.gray(700)};
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-right: 8px;
`;

const optionsChildren = sortByOptions.map((option) => (
  <Option value={option} key={option.messageKey} testID={option.messageKey}>
    <FormattedMessage id={option.messageKey} />
  </Option>
));

const getSelectedOption = ({
  sortBy,
  sortDirection,
}: SelectedSort): SortByOption | undefined =>
  sortByOptions.find(
    (o) => o.value === sortBy && o.direction === sortDirection
  );
