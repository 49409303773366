import React, { FC } from 'react';
import { DailyTeamCapacity } from '@insights/models-nwe';
import { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { formatDate } from '@insights/shared-components-nwe';
import { parseDate } from '@insights/utils-nwe';
import gql from 'graphql-tag';

interface TeamCapacity {
  teamCapacityForDateRange: DailyTeamCapacity[];
}

const NO_DATA: [] = [];

export const GET_TEAM_CAPACITY = gql`
  query GetTeamCapacity(
    $teamID: Guid!
    $queryStartDate: Date!
    $queryEndDate: Date!
  ) {
    teamCapacityForDateRange(
      teamID: $teamID
      queryStartDate: $queryStartDate
      queryEndDate: $queryEndDate
    ) {
      date
      scheduledHours
      availableHours
    }
  }
`;

export interface Props {
  teamId: string | null;
  startDate: Date;
  endDate: Date;
  children(result: {
    data: DailyTeamCapacity[];
    loading: boolean;
    error: ApolloError | undefined;
  }): JSX.Element;
}

export interface Variables {
  teamID: string;
  queryStartDate: string;
  queryEndDate: string;
}

export const processCapacityData = (
  data: DailyTeamCapacity[]
): DailyTeamCapacity[] =>
  data.map(
    (c: DailyTeamCapacity): DailyTeamCapacity => {
      return {
        ...c,
        date: parseDate(c.date),
      };
    }
  );

export const GetTeamCapacity: FC<Props> = ({
  children,
  teamId,
  startDate,
  endDate,
}) => {
  const { data, loading, error } = useQuery<TeamCapacity, Variables>(
    GET_TEAM_CAPACITY,
    {
      variables: {
        teamID: teamId ?? '',
        queryStartDate: formatDate(startDate),
        queryEndDate: formatDate(endDate),
      },
      skip: !teamId,
    }
  );

  return children({
    data: data ? processCapacityData(data.teamCapacityForDateRange) : NO_DATA,
    loading,
    error,
  });
};
