import React, { FC, useRef, KeyboardEvent, ReactNode, useMemo } from 'react';
import styled from 'react-emotion';
import uniqueId from 'lodash.uniqueid';
import { A, H4, TooltipStyled, primary } from '@phoenix/all';
import InfoSmallIcon from 'phoenix-icons/dist/InfoSmallIcon.js';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import { HiddenAriaElement } from '../hidden-aria-element';

import { useTooltip } from './use-tooltip';

export interface Props {
  header?: React.ReactNode;
  body?: JSX.Element;
  hyperlink?: string;
  dataId?: string;
  buttonDescription?: ReactNode;
}

const InfoTooltip: FC<Props> = ({
  body,
  hyperlink,
  header,
  dataId,
  buttonDescription,
}) => {
  const iconRef = useRef(null);
  const {
    tooltipState,
    showTooltip,
    hideTooltip,
    delayHideTooltip,
    delayShowTooltip,
  } = useTooltip();
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      hideTooltip();
    }
  };

  const ariaDescribedById = useMemo(
    () => uniqueId('tooltip-description-id-'),
    []
  );

  return (
    <>
      <IconWrapper
        innerRef={iconRef}
        onMouseDown={(e) => e.preventDefault()}
        onMouseOver={delayShowTooltip}
        onMouseOut={delayHideTooltip}
        onBlur={hideTooltip}
        onFocus={showTooltip}
        onKeyDown={handleKeyDown}
        data-testid={`info-tooltip-${dataId}`}
      >
        <ClickableIcon
          type="button"
          aria-describedby={ariaDescribedById}
          data-testid="info-icon-button"
        >
          <InfoSmallIcon height={12} width={12} aria-hidden="true" />
          <HiddenAriaElement tag="span">{buttonDescription}</HiddenAriaElement>
        </ClickableIcon>
      </IconWrapper>
      {tooltipState.show && (
        <div onMouseDown={(e) => e.preventDefault()}>
          <TooltipStyled
            // set actionBar={true} to get action bar styling
            actionBar
            light
            positionElement={iconRef.current}
            fade={tooltipState.fade}
            onMouseLeaveTooltip={hideTooltip}
            onMouseEnterTooltip={showTooltip}
          >
            <div role="tooltip" aria-hidden={!tooltipState.show}>
              <Header>
                <H4>{header}</H4>
              </Header>
              <Text>
                <div id={ariaDescribedById}>{body}</div>
                {hyperlink && (
                  <LinkWrapper>
                    <A
                      href={hyperlink}
                      target="_blank"
                      data-testid={`learn-more-${dataId}`}
                    >
                      <FormattedMessage id={MessageKeys.learnMore} />
                    </A>
                  </LinkWrapper>
                )}
              </Text>
            </div>
          </TooltipStyled>
        </div>
      )}
    </>
  );
};

export default InfoTooltip;

const ClickableIcon = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const Header = styled.div`
  margin-bottom: 13px;
`;

export const Text = styled.div`
  font-size: 12px;
`;

export const IconWrapper = styled.span`
  margin: 0px 6px;
  cursor: pointer;
  color: ${primary.gray(600)};
  display: inline-block;
  vertical-align: middle;
`;

export const LinkWrapper = styled.div`
  margin-top: 16px;
`;
