import { useEffect, RefObject } from 'react';

export const useSetFocus = (
  ref: RefObject<HTMLElement>,
  setFocus: boolean,
  onSetFocus?: () => void
): void => {
  useEffect(() => {
    if (ref.current && setFocus) {
      ref.current.focus();
      if (onSetFocus) onSetFocus();
    }
  }, [ref, setFocus, onSetFocus]);
};
