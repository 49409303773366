import { createAction, ActionType, isActionOf } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { Filter, DateRange } from '@insights/models-nwe';
import { Page, ChartType } from '@insights/constants-nwe';
import { trackZoom } from '@insights/utils-nwe';

import { ThunkResult, RootState } from '../../reducers';
import { ZOOM_COLUMN } from '../../constants';

export const toggleFilterHeader = createAction('Filters/TOOGGLE_HEADER')();
export const addFilter = createAction('Filters/ADD_FILTER')<Filter, Page>();
export const updateFilter = createAction('Filters/UPDATE_FILTER')<
  Filter,
  Page
>();
export const removeFilter = createAction('Filters/REMOVE_FILTER')<
  string,
  Page
>();

export const isZoomFilter = (filter: Filter): boolean => {
  return filter.column === ZOOM_COLUMN;
};

export const removeZoomFilter = (column: string): boolean => {
  return column === ZOOM_COLUMN;
};

export const hasZoomFilter = (
  state: RootState['filters'],
  page: Page
): boolean => state[page].some(isZoomFilter);

export const setZoomFilter = (
  dateRange: DateRange,
  page: Page,
  chartType: ChartType
): ThunkResult<void> => {
  return (dispatch, getState) => {
    const actionCreator = hasZoomFilter(getState().filters, page)
      ? updateFilter
      : addFilter;

    dispatch(
      actionCreator(
        {
          column: ZOOM_COLUMN,
          operator: 'between',
          values: dateRange,
        },
        page
      )
    );

    trackZoom(chartType);
  };
};

type FilterAction =
  | ActionType<typeof addFilter>
  | ActionType<typeof removeFilter>
  | ActionType<typeof updateFilter>;
export const isFilterAction = (action: AnyAction): action is FilterAction =>
  isActionOf([addFilter, updateFilter, removeFilter], action);
