import { Dispatch, bindActionCreators } from 'redux';
import { Page, ChartType } from '@insights/constants-nwe';
import { DateRange } from '@insights/models-nwe';
import {
  RootState,
  getZoomDateRange,
  getDateRange,
  setZoomFilter,
  connect,
} from '@insights/store-nwe';
import { TasksInFlight, Props } from '@insights/tasks-in-flight-nwe';

type StateProps = Pick<Props, 'dateRange' | 'zoomRange'>;
type DispatchProps = Pick<Props, 'onZoom'>;

export default connect<StateProps, DispatchProps, Props>(
  (state: RootState): StateProps => ({
    zoomRange: getZoomDateRange(state, Page.work),
    dateRange: getDateRange(state),
  }),
  (dispatch: Dispatch): DispatchProps =>
    bindActionCreators(
      {
        onZoom: (dateRange: DateRange) =>
          setZoomFilter(dateRange, Page.work, ChartType.TasksInFlight),
      },
      dispatch
    )
)(TasksInFlight);
