import React from 'react';

import { tw } from 'workfront-twind';
import { GlobalPageTitle } from '@phoenix/all';
import AnalyticsExtraLargeHeroIcon from 'phoenix-icons/dist/AnalyticsExtraLargeHeroIcon.js';
import { ConnectedCalendar } from '@insights/calendar-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

export const Header = (): JSX.Element => {
  return (
    <div className={tw`flex items-center justify-between p-4`}>
      <div className={tw`flex items-center`}>
        <GlobalPageTitle IconComponent={AnalyticsExtraLargeHeroIcon}>
          <FormattedMessage id={MessageKeys.header} />
        </GlobalPageTitle>
      </div>
      <ConnectedCalendar />
    </div>
  );
};
