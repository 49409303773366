import React, { FC, MouseEvent } from 'react';
import { DatePicker } from '@phoenix/all';
import moment, { Moment } from 'moment';
import styled from 'react-emotion';

import { ApplyButton } from '../apply-button';
import { Popover } from '../popover';
import { Header } from '../header';

export interface Props {
  // Popover props
  onBack(): void;
  header: string;

  // DatePicker props
  date: Date;
  onChange(date: Date): void;
  onApply(e: MouseEvent): void;

  // Own Props
  testID?: string;
  ariaLabel?: string;
}

const Spacer = styled.div`
  margin-top: 10px;
`;

export const CalendarPopover: FC<Props> = ({
  date,
  onChange,
  onApply,
  onBack,
  testID,
  header,
  ariaLabel,
}) => (
  <Popover data-testid={testID} tabIndex={0} aria-label={ariaLabel}>
    <Header onBack={onBack} header={header} />
    <DatePicker
      date={moment(date)}
      onChange={(momentDate: Moment) => onChange(momentDate.toDate())}
    />
    <Spacer>
      <ApplyButton onClick={onApply} />
    </Spacer>
  </Popover>
);
