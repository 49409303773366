export enum DisplayType {
  calc = 'CALC',
  text = 'TEXT',
  checkbox = 'CHCK',
  displayText = 'DTXT',
  radio = 'RDIO',
  typeahead = 'TYAH',
  select = 'SLCT',
}

export enum DataType {
  number = 'NMBR',
  currency = 'CURC',
  date = 'DATE',
  text = 'TEXT',
}
