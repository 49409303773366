import React, { FC, ReactNode } from 'react';
import styled from 'react-emotion';

import * as styles from './styles';
import Marker from './index';

export interface Props {
  markerX: number;
  height: number;
  offset?: number;
  color: string;
  label: ReactNode;
  strokeWidth?: number;
}

const LabelMarker: FC<Props> = ({
  markerX,
  height,
  offset = 5,
  color,
  label,
  strokeWidth = 1,
}) => {
  return (
    <Marker
      startPosition={{ x: markerX, y: 0 }}
      endPosition={{ x: markerX, y: height + offset }}
      color={color}
      strokeWidth={strokeWidth}
      strokeDasharray=""
      style={{ pointerEvents: 'none' }}
      content={(x: number, y: number) => (
        <LabelText fill={color} x={x} y={y} dx={0} dy={13} textAnchor="middle">
          {label}
        </LabelText>
      )}
    />
  );
};

export default LabelMarker;

const LabelText = styled.text`
  ${styles.label};
`;
