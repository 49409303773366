import React, { FC, SVGProps } from 'react';
import { isBefore } from 'date-fns';
import { ScaleTime } from 'd3-scale';
import { Motion, spring } from 'react-motion';

export interface Props extends SVGProps<SVGPolygonElement> {
  startDate: Date;
  dateScale: ScaleTime<number, number>;
  height: number;
}

const StartMarker: FC<Props> = ({ startDate, dateScale, height, ...rest }) => {
  const rangeStartDate = dateScale.domain()[0];
  const rangeEndDate = dateScale.domain()[1];
  if (isBefore(startDate, rangeStartDate) || isBefore(rangeEndDate, startDate))
    return null;
  const x = dateScale(startDate);

  return (
    <Motion
      defaultStyle={{ x }}
      style={{
        x: spring(x),
      }}
    >
      {({ x: newX }) => (
        <polygon
          {...rest}
          transform={`translate(${newX} 0)`}
          points={`0,0 0,${height} ${height},0`}
          data-testid="planned-timeline-start-marker"
        />
      )}
    </Motion>
  );
};

export default StartMarker;
