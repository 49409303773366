import { css } from 'emotion';

export const TOOLTIP_TOP = 20;
export const TOOLTIP_WIDTH = 65;

export const tooltip = css`
  color: #595f69;
  font-size: 10px;

  &:before {
    position: absolute;
    display: inline-block;
    border-top: 7px solid transparent;
    border-right: 7px solid orange;
    border-bottom: 7px solid transparent;
    border-right-color: #fff;
    left: 30px;
    top: ${TOOLTIP_TOP}px;
    content: '';
    transform: rotate(-90deg);
  }
`;
