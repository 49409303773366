import React, { FC } from 'react';
import { ActivityType } from '@insights/constants-nwe';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import { Legend, LegendDotTypeItem } from '@insights/chart-parts-nwe';
import { activityColorScale } from '../../services/color-scales';

const ActivityLegend: FC = () => (
  <Legend
    header={<FormattedMessage id={MessageKeys.activityType} />}
    testID="activity-legend-header"
  >
    <LegendDotTypeItem
      color={activityColorScale(ActivityType.login)}
      testID="activity-legend-item1"
      label={<FormattedMessage id={MessageKeys.activityLoginText} />}
    />
    <LegendDotTypeItem
      color={activityColorScale(ActivityType.taskStatusChange)}
      testID="activity-legend-item2"
      label={<FormattedMessage id={MessageKeys.activityTasksChanges} />}
    />
    <LegendDotTypeItem
      color={activityColorScale(ActivityType.tasksCompleted)}
      testID="activity-legend-item3"
      label={<FormattedMessage id={MessageKeys.activityTasksCompleted} />}
    />
  </Legend>
);

export default ActivityLegend;
