import { GraphqlFilterKey } from '@insights/constants-nwe';

export interface ProjectsFilterInput {
  [GraphqlFilterKey.projectguid]?: GuidFilterInput;
  [GraphqlFilterKey.programguid]?: GuidFilterInput;
  [GraphqlFilterKey.portfolioguid]?: GuidFilterInput;
  [GraphqlFilterKey.condition]?: ConditionFilterInput;
  [GraphqlFilterKey.status]?: StringFilterInput;
  [GraphqlFilterKey.customfields]?: StringFilterInput;
}

export interface GuidFilterInput {
  operator: BasicOperator;
  value: string[];
}

export interface ConditionFilterInput {
  operator: BasicOperator;
  value: string[];
}

export interface StringFilterInput {
  operator: BasicOperator;
  value: string[];
}

export enum BasicOperator {
  contains = 'contains',
  in = 'in',
  notIn = 'notIn',
}

export enum CustomFormOperator {
  allof = 'allof',
  in = 'in',
  cicontainsall = 'cicontainsall',
  cicontainsany = 'cicontainsany',
}
