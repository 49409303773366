import { DailyTeamCapacity } from '@insights/models-nwe';

export const MINIMUM_MAX_VALUE = 2;

export const getY = (d: DailyTeamCapacity): number => {
  const scheduled = d.scheduledHours;
  const available = d.availableHours;

  if (scheduled === 0 && available === 0) {
    return 1;
  }
  return d.scheduledHours / d.availableHours;
};

export const getX = (d: DailyTeamCapacity): Date => d.date;

export const getMax = (
  data: DailyTeamCapacity[],
  selector: (d: DailyTeamCapacity) => number
) => {
  return data.reduce((maxValue, d) => {
    const value = selector(d);
    if (value !== Infinity && value > maxValue) return value;

    return maxValue;
  }, MINIMUM_MAX_VALUE);
};

export const calculateYDomain = (
  data: DailyTeamCapacity[],
  ySelector: (d: DailyTeamCapacity) => number
): [number, number] => {
  const maxValue = getMax(data, ySelector);
  const minValue = 0;

  return [minValue, maxValue];
};
