import React, { FC } from 'react';
import { ParentSize } from '@vx/responsive';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';

import { DateRange } from '@insights/models-nwe';
import { CardLayout } from '@insights/shared-components-nwe';
import { ChartType } from '@insights/constants-nwe';

import Legend from './legend';
import Chart from './chart';
import FlightPlanHeader from './header';
import { MARGIN, HEIGHT } from './constants';
import GetConditions from './queries/get-conditions';
import { shapeFlightPlanDataForXLSX } from './utils/shape-data-for-xlsx';
import FlightPlanA11yChart from './chart/chart-parts/chart-a11y';

export interface Props {
  projectIds: string[];
  dateRange: DateRange; // unknown because it isn't accessed
  zoomRange?: DateRange;
  selectedProject: string | undefined;
  onProjectClick(guid: string, name: string): void;
  onZoom(zoomRange: DateRange): void;
}

const FlightPlan: FC<Props> = ({
  projectIds,
  zoomRange,
  dateRange,
  onProjectClick,
  onZoom,
  selectedProject,
}) => {
  const { formatMessage } = useIntl();
  const workingDateRange = zoomRange || dateRange;

  return (
    <GetConditions>
      {({ data, loading, error }) => {
        const getData = () =>
          shapeFlightPlanDataForXLSX(
            formatMessage(
              { id: MessageKeys.flightPlanExportXlsx },
              { startDate: dateRange.start, endDate: dateRange.end }
            ),
            {
              projectID: formatMessage({ id: MessageKeys.projectID }),
              projectName: formatMessage({ id: MessageKeys.projectName }),
              plannedStartDate: formatMessage({
                id: MessageKeys.plannedStartDate,
              }),
              plannedCompletionDate: formatMessage({
                id: MessageKeys.plannedCompletionDate,
              }),
              actualStartDate: formatMessage({
                id: MessageKeys.actualStartDate,
              }),
              actualCompletionDate: formatMessage({
                id: MessageKeys.actualCompletionDate,
              }),
              condition: formatMessage({ id: MessageKeys.condition }),
              conditionLabel: formatMessage({
                id: MessageKeys.customCondition,
              }),
              conditionChangeTimeStamp: formatMessage({
                id: MessageKeys.conditionChangeTimeStamp,
              }),
              currentProjectConditions: formatMessage({
                id: MessageKeys.currentProjectConditions,
              }),
              historicalProjectConditions: formatMessage({
                id: MessageKeys.historicalProjectConditions,
              }),
            },
            data
          );

        const chart = () => {
          if (loading) return <FormattedMessage id={MessageKeys.loading} />;
          if (error) return <FormattedMessage id={MessageKeys.error} />;
          return (
            <ParentSize>
              {({ width }: { width: number; height: number }) => (
                <>
                  {data?.projects && data.projects.length > 0 && (
                    <FlightPlanA11yChart
                      projects={data?.projects}
                      historicalProjects={data?.projectHistoricalConditions}
                      dateRange={workingDateRange}
                    />
                  )}
                  <Chart
                    projects={data && data.projects ? data.projects : []}
                    conditions={
                      data && data.projectHistoricalConditions
                        ? data.projectHistoricalConditions
                        : []
                    }
                    onProjectClick={onProjectClick}
                    onZoom={onZoom}
                    zoomRange={zoomRange}
                    dateRange={dateRange}
                    margin={MARGIN}
                    height={HEIGHT}
                    width={width}
                    selectedProject={selectedProject}
                  />
                </>
              )}
            </ParentSize>
          );
        };

        return (
          <CardLayout
            title={ChartType.FlightPlan}
            header={<FlightPlanHeader count={projectIds.length} />}
            legend={<Legend />}
            label={formatMessage({ id: MessageKeys.flightPlan })}
            chart={chart()}
            getData={getData}
            disableExport={
              !(data && data.projects && data.projects.length > 0) ||
              loading ||
              !!error
            }
          />
        );
      }}
    </GetConditions>
  );
};

export default FlightPlan;
