import { SpreadSheetData } from '@insights/models-nwe';
import { Data } from '../../queries/get-conditions';
import { ConditionFragment } from '../../queries/get-conditions/models';

interface ProjectsTemplate {
  projectConditions: object[];
  projectConditionsMap: {
    [key: string]: object;
  };
}

interface Headers {
  projectID: string;
  projectName: string;
  plannedStartDate: string;
  plannedCompletionDate: string;
  actualStartDate: string;
  actualCompletionDate: string;
  condition: string;
  conditionLabel: string;
  conditionChangeTimeStamp: string;
  currentProjectConditions: string;
  historicalProjectConditions: string;
}

export const generateProjectsTemplate = (
  headers: Headers,
  projects: Data['projects']
): ProjectsTemplate => {
  return projects.reduce(
    (accum, project) => {
      const projectTemplate = {
        [headers.projectID]: project.guid,
        [headers.projectName]: project.name,
        [headers.plannedStartDate]: project.plannedstartdate,
        [headers.plannedCompletionDate]: project.plannedcompletiondate,
        [headers.actualStartDate]: project.actualstartdate,
        [headers.actualCompletionDate]: project.actualcompletiondate,
        [headers.condition]: project.condition,
        [headers.conditionLabel]: project.conditionlabel,
      };

      const projectConditions = [...accum.projectConditions, projectTemplate];
      const projectConditionsMap = {
        ...accum.projectConditionsMap,
        [project.guid]: {
          [headers.projectID]: project.guid,
          [headers.projectName]: project.name,
        },
      };
      return { projectConditions, projectConditionsMap };
    },
    {
      projectConditions: [],
      projectConditionsMap: {},
    }
  );
};

export const shapeFlightPlanDataForXLSX = (
  chartTitle: string,
  headers: Headers,
  chartData: Data | undefined
): SpreadSheetData => {
  const name = chartTitle;

  if (chartData !== undefined) {
    const { projects, projectHistoricalConditions } = chartData;
    const {
      projectConditions,
      projectConditionsMap,
    } = generateProjectsTemplate(headers, projects);

    const historicalProjectConditions = projectHistoricalConditions.map(
      (project: ConditionFragment) => {
        return {
          ...projectConditionsMap[project.projectguid],
          [headers.condition]: project.condition,
          [headers.conditionChangeTimeStamp]: project.conditionchangetimestamp,
          [headers.conditionLabel]: project.conditionlabel,
        };
      }
    );
    return {
      data: {
        [headers.currentProjectConditions]: projectConditions,
        [headers.historicalProjectConditions]: historicalProjectConditions,
      },
      name,
    };
  }

  return {
    data: {
      data: [],
    },
    name,
  };
};
