import React, { FC } from 'react';
import { TickProps } from '../models';
import Avatar, { AvatarSize } from '../../avatar';

const AvatarTick: FC<TickProps> = ({ formattedValue, x, y }: TickProps) => (
  <Avatar
    id={formattedValue}
    size={AvatarSize.small}
    y={Number(y) - AvatarSize.small / 2}
    x={-AvatarSize.small + Number(x)}
  />
);

export default AvatarTick;
