import { bindActionCreators, Dispatch } from 'redux';

import {
  RootState,
  getDateRangeWithType,
  setDateRange,
  connect,
} from '@insights/store-nwe';
import { CalendarWidget } from '../calendar';

const mapStateToProps = (state: RootState) => {
  const { startDate, endDate, type } = getDateRangeWithType(state);

  return { startDate, endDate, type };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onSubmit: setDateRange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CalendarWidget);
