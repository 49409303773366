import React, { FC, ReactElement } from 'react';
import { ParentSize } from '@vx/responsive';
import {
  DateRange,
  TopLevelGroupedActivity,
  SpreadSheetData,
} from '@insights/models-nwe';
import {
  ActivityLabels,
  ACTIVITY_LABELS_TO_MESSAGE_KEY,
  ChartType,
} from '@insights/constants-nwe';
import { MessageKeys, FormattedMessage, useIntl } from '@insights/i18n-nwe';
import { CardLayout } from '@insights/shared-components-nwe';

import GetProjectActivity from '../queries/get-project-activity';
import { mapMaxActivities } from '../../services/activity';
import GetProjectUserActivity from '../queries/get-project-user-activity';
import Header from '../header';
import Legend from '../../activity/legend';
import UserActivity from '../../user-activity';
import { shapeProjectActivityDataForXLSX } from '../../services/xlsx/project-activity';
import { ActivityChartWithTables } from '../../activity/chart-with-tables';

interface Props {
  dateRange: DateRange;
  zoomRange?: DateRange;
  onZoom(zoomRange: DateRange): void;
}

const CHART_TYPE = ChartType.ProjectActivity;

export const RenderChart: FC<Props> = (props) => {
  const { dateRange } = props;
  const { formatMessage } = useIntl();

  return (
    <GetProjectActivity startDate={dateRange.start} endDate={dateRange.end}>
      {({ data, loading, error }) => {
        const projects = data.PROJECTS;
        const maxActivitiesCount = mapMaxActivities(data);

        const getData = (): SpreadSheetData =>
          shapeProjectActivityDataForXLSX(
            formatMessage(
              { id: MessageKeys.projectActivityExportXlsx },
              { startDate: dateRange.start, endDate: dateRange.end }
            ),
            {
              date: formatMessage({ id: MessageKeys.date }),
              projectID: formatMessage({ id: MessageKeys.projectID }),
              projectName: formatMessage({ id: MessageKeys.projectName }),
              numberOfLogins: formatMessage({
                id: MessageKeys.activityHeaderLogins,
              }),
              numberOfTaskStatusChanges: formatMessage({
                id: MessageKeys.activityHeaderStatusChanges,
              }),
              numberOfTasksCompleted: formatMessage({
                id: MessageKeys.activityHeaderTasksCompleted,
              }),
            },
            data,
            dateRange
          );

        const chart = (): ReactElement => {
          if (loading) return <FormattedMessage id={MessageKeys.loading} />;
          if (error) return <FormattedMessage id={MessageKeys.error} />;

          return (
            <ParentSize>
              {({ width }: { width: number; height: number }) => (
                <ActivityChartWithTables
                  {...props}
                  activityType={CHART_TYPE}
                  data={projects}
                  maxActivities={maxActivitiesCount}
                  dateRange={dateRange}
                  width={width}
                  label={
                    ACTIVITY_LABELS_TO_MESSAGE_KEY[
                      ActivityLabels.projectActivityLabel
                    ]
                  }
                  getChildren={(d: TopLevelGroupedActivity) => d.users}
                  renderUsers={({ guid, ...chartProps }) => (
                    <GetProjectUserActivity
                      projectId={guid}
                      startDate={props.dateRange.start}
                      endDate={props.dateRange.end}
                    >
                      {(result) => <UserActivity {...result} {...chartProps} />}
                    </GetProjectUserActivity>
                  )}
                />
              )}
            </ParentSize>
          );
        };

        return (
          <CardLayout
            title={CHART_TYPE}
            header={<Header />}
            legend={<Legend />}
            label={formatMessage({ id: MessageKeys.projectActivity })}
            chart={chart()}
            getData={getData}
            disableExport={!(projects.length > 0) || loading || !!error}
          />
        );
      }}
    </GetProjectActivity>
  );
};
