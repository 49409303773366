import { css } from 'emotion';
import { primary } from '@phoenix/all';

export const todayMarker = css`
  fill: ${primary.white()};
  font-size: 12px;
`;

export const label = (props: { fill: string }) => css`
  fill: ${props.fill};
  font-size: 12px;
  font-weight: 600;
`;

export const lineMarker = css`
  fill: ${primary.white()};
  font-size: 12px;
`;
