import React, { FC, RefObject } from 'react';
import { ClickableIcon, primary } from '@phoenix/all';
import LeftCaratIcon from 'phoenix-icons/dist/LeftCaratIcon.js';
import styled from 'react-emotion';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';

export interface Props {
  header?: string;
  backButtonRef?: RefObject<HTMLButtonElement>;
  onBack(): void;
}

export const TEST_ID_BACK_BTN = 'filter-back-btn';
export const TEST_ID_HEADER = 'filter-header';

const ARROW_WIDTH = 35;

export const Header: FC<Props> = ({ header, onBack, backButtonRef }) => {
  const { formatMessage } = useIntl();

  return (
    <HeaderWrapper>
      <IconWrapper>
        <ClickableIcon
          onClick={onBack}
          testID={TEST_ID_BACK_BTN}
          icon={LeftCaratIcon}
          title={<FormattedMessage id={MessageKeys.back} />}
          ref={backButtonRef}
          aria-label={formatMessage({ id: MessageKeys.back })}
        />
      </IconWrapper>
      <Truncate data-testid={TEST_ID_HEADER} title={header}>
        {header}
      </Truncate>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  width: 100%;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: #e0e1e3 solid 1px;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  height: 100%;
  width: ${ARROW_WIDTH}px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${primary.gray(600)}
  cursor: pointer;
`;

const Truncate = styled.span`
  max-width: calc(100% - ${ARROW_WIDTH * 2}px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
