import { useContext, useEffect } from 'react';
import { removeFilter, StoreContext, ZOOM_COLUMN } from '@insights/store-nwe';
import { Page } from '@insights/constants-nwe';
import { Filter, isCustomFilter } from '@insights/models-nwe';

import { ProcessColumns } from './useGetColumnsQuery';

export const useDeprecatedFiltersSideEffect = (
  pageType: Page,
  filters: Filter[],
  columns: ProcessColumns[] | undefined
): void => {
  const { dispatch } = useContext(StoreContext);

  useEffect(() => {
    if (columns && columns.length !== 0) {
      const supportedColumns = columns.reduce((acc, column) => {
        acc.add(column.filterKey);
        return acc;
      }, new Set());

      filters.forEach((filter) => {
        if (
          !supportedColumns.has(filter.column) &&
          filter.column !== ZOOM_COLUMN &&
          !isCustomFilter(filter)
        ) {
          dispatch(removeFilter(filter.column, pageType));
        }
      });
    }
  }, [dispatch, pageType, filters, columns]);
};
