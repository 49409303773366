import React, { FC } from 'react';
import { Text } from '@phoenix/all';

import LegendItem from '../legend-item';
import Bar from './bar';

interface Props {
  color: string;
  label: React.ReactNode;
  testID?: string;
}

export const CapacityBar: FC<Props> = ({ color, label, testID }) => {
  return (
    <LegendItem>
      <Bar color={color} data-testid="bar" />
      <Text.Small data-testid={testID}>{label}</Text.Small>
    </LegendItem>
  );
};

export default CapacityBar;
