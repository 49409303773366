import { useCallback, useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GraphqlFilterKey } from '@insights/constants-nwe';
import {
  FilterOption,
  ProjectsFilterInput,
  Filter,
} from '@insights/models-nwe';
import { graphQLShape } from '@insights/shared-components-nwe';
import { ApolloError } from 'apollo-client';
import debounce from 'lodash.debounce';
import { needSearchInput } from './utils';

const LIMIT = 50;
const OFFSET = 0;

export const GET_FILTER_OPTIONS = gql`
  query GetFilterOptions($filterKey: FilterKey!, $filter: ProjectsFilterInput, $searchText: String) {
    filterOptions(
      filterKey: $filterKey,
      filter: $filter, 
      searchText: $searchText, 
      limit: ${LIMIT}, 
      offset: ${OFFSET}, 
      orderBy: {
        key: label,
        direction: ASC
      }
    ) {
      label
      value
    }
  }
`;

interface HookReturnValue {
  data: FilterOption[];
  loading: boolean;
  error: ApolloError | undefined;
  requiresSearchInput: boolean;
  called: boolean;
}

/**
 * This is where the shape of the response is defined. It should match
 * the shape of your query
 */
export interface Response {
  filterOptions: FilterOption[];
}

/**
 * This is where the variables that are used in the actual query
 * are defined
 */
export interface Variables {
  filterKey: GraphqlFilterKey;
  filter: ProjectsFilterInput;
  searchText?: string;
}

export const useGetFilterOptionsQuery = (
  searchText: string,
  filterKey: GraphqlFilterKey,
  filters: Filter[]
): HookReturnValue => {
  const [queryOptions, { data, loading, error, called }] = useLazyQuery<
    Response,
    Variables
  >(GET_FILTER_OPTIONS);
  const debouncedQueryOptions = useCallback(debounce(queryOptions, 500), []);
  const requiresSearchInput = useMemo(
    () => needSearchInput(searchText, filterKey),
    [searchText, filterKey, filters]
  );

  useEffect(() => {
    const variables = {
      searchText,
      filterKey,
      filter: graphQLShape(filters),
    };

    if (!requiresSearchInput) {
      debouncedQueryOptions({ variables });
    }
  }, [
    searchText,
    filterKey,
    filters,
    debouncedQueryOptions,
    requiresSearchInput,
    called,
  ]);

  return {
    data: data ? data.filterOptions : [],
    loading,
    error,
    requiresSearchInput,
    called,
  };
};
