import React, { FC, memo } from 'react';
import { ChartA11y, ChartA11yHeader } from '@insights/shared-components-nwe';
import { ProjectMagnitudeChartDatum } from '@insights/models-nwe';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';

export interface Props {
  data: ProjectMagnitudeChartDatum[];
  startDate: Date;
  endDate: Date;
  show?: boolean; // for testing
}

export const A11y: FC<Props> = ({ data, startDate, endDate, show = false }) => {
  const { formatDate, formatMessage, formatNumber } = useIntl();

  const getDatum = (
    row: ProjectMagnitudeChartDatum,
    key: keyof ProjectMagnitudeChartDatum
  ): string | number | undefined | null => {
    const value = row[key];
    if (value instanceof Date) return formatDate(value, { format: 'readable' });
    else if (typeof value === 'number') {
      if (Number.isNaN(value)) return undefined;
      return formatNumber(value, { format: 'a11y' });
    } else if (key === 'completeddurationdays' && value === undefined) {
      return formatMessage({ id: MessageKeys.notAvailable });
    }

    return value;
  };

  return (
    <ChartA11y
      caption={
        <FormattedMessage
          id={MessageKeys.projectMagnitudeTableCaption}
          values={{ startDate, endDate }}
        />
      }
      headers={headers}
      data={data}
      getDatum={getDatum}
      show={show}
    />
  );
};

export const Memoized = memo(A11y);

A11y.displayName = 'ProjectMagnitudeA11y';

export const headers: ChartA11yHeader<keyof ProjectMagnitudeChartDatum>[] = [
  {
    key: 'name',
    label: <FormattedMessage id={MessageKeys.projectName} />,
    isRowHeader: true,
  },
  {
    key: 'completedplannedhours',
    label: <FormattedMessage id={MessageKeys.completedPlannedHours} />,
  },
  {
    key: 'completeddurationdays',
    label: <FormattedMessage id={MessageKeys.daysWorked} />,
  },
  {
    key: 'workrequired',
    label: <FormattedMessage id={MessageKeys.workRequired} />,
  },
  {
    key: 'plannedstartdate',
    label: <FormattedMessage id={MessageKeys.plannedStartDate} />,
  },
  {
    key: 'plannedcompletiondate',
    label: <FormattedMessage id={MessageKeys.plannedCompletionDate} />,
  },
  {
    key: 'condition',
    label: <FormattedMessage id={MessageKeys.condition} />,
  },
  {
    key: 'conditionlabel',
    label: <FormattedMessage id={MessageKeys.customCondition} />,
  },
];
