import React, { FC } from 'react';
import { FormattedMessage } from '@insights/i18n-nwe';
import { LabelDropdown, Option } from '@phoenix/all';
import {
  TasksInFlightSortBy,
  TASKS_IN_FLIGHT_SORT_BY_TO_MESSAGE_KEY,
} from '@insights/constants-nwe';
import Trigger from './trigger';

export interface Props {
  sortBy: TasksInFlightSortBy;
  changeSortBy(sortBy: TasksInFlightSortBy): void;
}

const getOptions = () =>
  Object.values(TasksInFlightSortBy).map((sortByOption) => (
    <Option value={sortByOption} key={sortByOption}>
      <FormattedMessage
        id={TASKS_IN_FLIGHT_SORT_BY_TO_MESSAGE_KEY[sortByOption]}
      />
    </Option>
  ));

const SortBy: FC<Props> = ({ sortBy, changeSortBy }) => {
  return (
    <LabelDropdown
      testID="tasks-in-flight-sort-by"
      trigger={<Trigger sortBy={sortBy} />}
      value={sortBy}
      onChange={changeSortBy}
    >
      {getOptions()}
    </LabelDropdown>
  );
};

export default SortBy;
