import { bindActionCreators, Dispatch } from 'redux';
import { connect, getSortBy, setSortByAndDirection } from '@insights/store-nwe';

import { ProjectSort, Props } from './base';

type StateProps = Pick<Props, 'selectedSort'>;
type DispatchProps = Pick<Props, 'onChange'>;

export const ConnectedProjectSort = connect<StateProps, DispatchProps, Props>(
  (state) => ({
    selectedSort: getSortBy(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        onChange: setSortByAndDirection,
      },
      dispatch
    )
)(ProjectSort);
