import { useQuery } from '@apollo/react-hooks';
import { useIntl } from '@insights/i18n-nwe';
import gql from 'graphql-tag';
import { Page, GraphqlFilterKey } from '@insights/constants-nwe';
import { FilterColumn } from '@insights/models-nwe';
import { ApolloError } from 'apollo-client';

export const GET_COLUMNS = gql`
  query GetColumns($pageType: PageType!) {
    page(pageType: $pageType) {
      filterColumns {
        hierarchy
        filterKey
        displayOrder
        translationKey
      }
    }
  }
`;

interface Data {
  filterColumns: FilterColumn[];
}

export interface ProcessColumns {
  translationKey: string;
  hierarchy: GraphqlFilterKey[];
  filterKey: GraphqlFilterKey;
  displayOrder: number;
}

interface HookReturnValue {
  data: ProcessColumns[];
  loading: boolean;
  error: ApolloError | undefined;
}

/**
 * This is where the shape of the response is defined. It should match
 * the shape of your query
 */
export interface Response {
  page: {
    filterColumns: FilterColumn[];
  };
}

/**
 * This is where the variables that are used in the actual query
 * are defined
 */
export interface Variables {
  pageType: Page;
}

export const useGetColumnsQuery = (pageType: Page): HookReturnValue => {
  const { formatMessage } = useIntl();
  const processColumns = (data: Data): ProcessColumns[] => {
    return data.filterColumns.map((d: FilterColumn) => ({
      ...d,
      translationKey: formatMessage({ id: d.translationKey }),
    }));
  };

  const { data, loading, error } = useQuery<Response, Variables>(GET_COLUMNS, {
    variables: { pageType },
  });

  return {
    data: data ? processColumns(data.page) : [],
    loading,
    error,
  };
};
