import { GraphqlFilterKey } from '@insights/constants-nwe';

const searchFreeFilterKeys = [
  GraphqlFilterKey.condition,
  GraphqlFilterKey.status,
  GraphqlFilterKey.teamguid,
];

const MINIMUM_SEARCH_TEXT_LENGTH = 3;

export const needSearchInput = (
  searchText: string,
  filterKey: GraphqlFilterKey
): boolean => {
  const searchValid = searchText.length >= MINIMUM_SEARCH_TEXT_LENGTH;
  const searchFreeFilterKey = searchFreeFilterKeys.includes(filterKey);

  if (searchFreeFilterKey || searchValid) {
    return false;
  }

  return true;
};
