import React, { createContext, FC, useContext } from 'react';
import { ProjectConditionsState, CustomEnumInfo } from './models';
import {
  DEFAULT_STATE,
  useProjectConditionsQuery,
} from './use-project-conditions-query';

export const ProjectConditionsContext = createContext<ProjectConditionsState>(
  DEFAULT_STATE
);

export const ProjectConditionsProvider: FC = ({ children }) => {
  const state = useProjectConditionsQuery();

  return (
    <ProjectConditionsContext.Provider value={state}>
      {children}
    </ProjectConditionsContext.Provider>
  );
};

export const useProjectConditions = (): ProjectConditionsState => {
  return useContext(ProjectConditionsContext);
};

export const useConditionInfo = (
  conditionKey: string
): CustomEnumInfo | undefined => {
  const { data } = useContext(ProjectConditionsContext);

  return data?.get(conditionKey);
};
