import React, { FC, MouseEvent, memo, ReactNode } from 'react';
import { Axis } from '@vx/axis';
import { ScaleBand } from 'd3-scale';
import { cx } from 'emotion';
import { Orientation } from '@insights/constants-nwe';

import { TickProps } from '../models';

import * as styles from '../styles';
import TooltipTick from '../tooltip-tick';

const noop = () => null;

export interface Props {
  scale: ScaleBand<string>;
  orientation?: Orientation;
  top?: number;
  left?: number;
  activeLabel?: string | null;
  onMouseOver?(val: string, index: number, e: MouseEvent): void;
  onMouseOut?(val: string, index: number, e: MouseEvent): void;
  onClick?(val: string, e: MouseEvent): void;
  tickFormat?(val: string, index: number): string;
  tickComponent?(val: TickProps, index: number): ReactNode;
  label?: string;
  labelOffset?: number;
}

const CategoryAxis: FC<Props> = ({
  scale,
  activeLabel,
  onMouseOver = noop,
  onMouseOut = noop,
  onClick = noop,
  orientation = Orientation.left,
  ...rest
}) => (
  <Axis
    orientation={orientation}
    scale={scale}
    tickValues={scale.domain()}
    tickClassName={styles.tickLabel}
    tickLabelProps={(val: string, i: number) => ({
      fill: '#82878E',
      fontSize: '12px',
      textAnchor: 'end',
      cursor: 'pointer',
      onMouseOver: onMouseOver.bind(null, val, i),
      onMouseOut: onMouseOut.bind(null, val, i),
      onClick: onClick.bind(null, val),
      className: cx(styles.tickLabel, {
        [styles.activeTickLabel]: activeLabel === val,
      }),
    })}
    tickComponent={(props: TickProps) => <TooltipTick {...props} />}
    labelClassName={styles.tickLabel}
    hideTicks
    hideAxisLine
    {...rest}
  />
);

CategoryAxis.defaultProps = {
  orientation: Orientation.left,
  onMouseOver: () => null,
  onMouseOut: () => null,
  onClick: () => null,
};

export default memo(CategoryAxis);
