import React, { FC } from 'react';
import {
  FormattedMessage,
  MessageKeys,
  useIntl,
  useFormattedNumber,
} from '@insights/i18n-nwe';
import {
  KpiCardWrapper,
  KPICardHeader,
  CardFooter,
  CardBody,
} from '../../card-layout';
import useProjectsCompletedOnTime from '../../queries/use-projects-completed-on-time';
import { KPICardProps } from '../models';

export const TEST_ID = 'projects-completed-onTime';

const formatNumberAsPercent = (value: number) =>
  useFormattedNumber(value, { format: 'percent' });

const CardHeader: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <KPICardHeader
      testID={`${TEST_ID}-header`}
      title={formatMessage({ id: MessageKeys.kpiProjectsCompletedOnTime })}
      info={{
        header: (
          <FormattedMessage id={MessageKeys.kpiProjectsCompletedOnTime} />
        ),
        body: (
          <>
            <div>
              <FormattedMessage
                id={MessageKeys.kpiProjectsCompletedOnTimeInfo1}
              />
            </div>
            <br />
            <div>
              <FormattedMessage
                id={MessageKeys.kpiProjectsCompletedOnTimeInfo2}
              />
            </div>
          </>
        ),
      }}
      buttonDescription={
        <FormattedMessage
          id={MessageKeys.tooltipButtonDescriptionProjectsCompletedOnTime}
        />
      }
    />
  );
};

export const ProjectsCompletedOnTime: FC<KPICardProps> = ({
  filter,
  dateRange,
}) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useProjectsCompletedOnTime(
    filter,
    dateRange.start,
    dateRange.end
  );

  if (loading) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.loading} />}
      />
    );
  }

  if (error || !data) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.error} />}
      />
    );
  }

  return (
    <KpiCardWrapper
      testID={TEST_ID}
      header={<CardHeader />}
      body={
        <CardBody
          testID={`${TEST_ID}-body`}
          label={formatNumberAsPercent}
          value={data.projectsCompletedOnTime.actual}
          previousValue={data.projectsCompletedOnTime.historical}
          bodyAriaLabel={formatMessage(
            { id: MessageKeys.kpiAriaLabelProjectsCompletedOnTimeBody },
            {
              value: data.projectsCompletedOnTime.actual,
              startDate: dateRange.start,
              endDate: dateRange.end,
            }
          )}
          changeAriaLabel={
            MessageKeys.kpiAriaLabelProjectsCompletedOnTimeBodyChange
          }
          dateRange={{ start: dateRange.start, end: dateRange.end }}
        />
      }
      footer={
        <CardFooter
          testID={`${TEST_ID}-footer`}
          label={formatNumberAsPercent}
          previousValue={data.projectsCompletedOnTime.historical}
          startDate={dateRange.start}
          endDate={dateRange.end}
          ariaLabel={(totalDays) =>
            formatMessage(
              { id: MessageKeys.kpiAriaLabelProjectsCompletedOnTimeFooter },
              { value: data.projectsCompletedOnTime.historical, totalDays }
            )
          }
        />
      }
    />
  );
};
