import { wfetch, encodeAsFormData } from '@wf-mfe/api';
import { Project } from 'workfront-objcodes';
import { SortByFields, SortByDirection } from '@insights/constants-nwe';
import { RawProjectDatum } from '../../models';

type FilterBody = {
  [key: string]: string | number | Date | string[];
};

export const getProjectsPage = (
  filterBody: FilterBody,
  limit: number,
  offset: number,
  sortByField: SortByFields,
  sortByDirection: SortByDirection
): Promise<RawProjectDatum[]> =>
  wfetch(
    `/attask/api-internal/${Project}/search?${sortByField}_Sort=${sortByDirection}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeAsFormData({
        method: 'get',
        fields: [
          'ID',
          'name',
          'actualStartDate',
          'actualCompletionDate',
          'plannedStartDate',
          'plannedCompletionDate',
          'workRequired',
          'status',
          'statusLabel',
          'condition',
        ],
        filters: {
          $$LIMIT: limit,
          $$FIRST: offset,
          ...filterBody,
        },
      }),
    }
  );
