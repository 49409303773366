import React, { FC } from 'react';
import styled from 'react-emotion';
import { H4 } from '@phoenix/all';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import { QuicksilverHyperLinks } from '@insights/constants-nwe';
import {
  InfoTooltip,
  ProjectSort,
  projectSortStyles,
} from '@insights/shared-components-nwe';

const ActivityHeader: FC = () => {
  return (
    <Header>
      <H4 testID="project-activity-header">
        <FormattedMessage id={MessageKeys.projectActivity} />
      </H4>
      <InfoTooltip
        header={<FormattedMessage id={MessageKeys.projectActivity} />}
        hyperlink={QuicksilverHyperLinks.ProjectActivity}
        dataId="projectActivity"
        body={
          <>
            <div>
              <FormattedMessage
                id={MessageKeys.projectActivityInfoTooltipOne}
              />
            </div>
            <br />
            <div>
              <FormattedMessage
                id={MessageKeys.projectActivityInfoTooltipTwo}
              />
            </div>
          </>
        }
        buttonDescription={
          <FormattedMessage
            id={MessageKeys.tooltipButtonDescriptionProjectActivity}
          />
        }
      />
      <ProjectSort className={projectSortStyles.header} />
    </Header>
  );
};

export default ActivityHeader;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`;
