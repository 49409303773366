import { Dispatch, bindActionCreators } from 'redux';
import { Filter } from '@insights/models-nwe';
import {
  getFilters,
  getDateRangeWithType,
  removeFilter,
  addFilter,
  connect,
} from '@insights/store-nwe';

import { FilterHeader, Props } from './FilterHeader';

type StateProps = Pick<Props, 'filters' | 'dateRange'>;
type DispatchProps = Pick<Props, 'onFilterRemove' | 'onAddFilter'>;

export default connect<StateProps, DispatchProps, Props>(
  (state, props) => ({
    page: props.page,
    filters: getFilters(state, props.page),
    dateRange: getDateRangeWithType(state),
  }),
  (dispatch: Dispatch, props): DispatchProps =>
    bindActionCreators(
      {
        onFilterRemove: (column: string) => removeFilter(column, props.page),
        onAddFilter: (filter: Filter) => addFilter(filter, props.page),
      },
      dispatch
    )
)(FilterHeader);
