import React, { FC, SVGProps, memo, useCallback } from 'react';
import { LinePath } from '@vx/shape';
import { curveStepAfter } from '@vx/curve';
import { ScaleTime, ScaleLinear } from 'd3-scale';
import { BurndownChartDatum } from '@insights/models-nwe';

export interface Props extends SVGProps<SVGPathElement> {
  data: BurndownChartDatum[];
  dateScale: ScaleTime<number, number>;
  hoursScale: ScaleLinear<number, number>;
  getX(data: BurndownChartDatum): Date;
  getY(data: BurndownChartDatum): number | undefined;
}

const BurndownLine: FC<Props> = ({
  data,
  dateScale,
  hoursScale,
  getX,
  getY,
  ...rest
}) => {
  const x = useCallback((datum: BurndownChartDatum) => dateScale(getX(datum)), [
    dateScale,
    getX,
  ]);
  const y = useCallback(
    (datum: BurndownChartDatum) => hoursScale(getY(datum) || 0),
    [hoursScale, getY]
  );

  return (
    <LinePath
      data={data}
      x={x}
      y={y}
      curve={curveStepAfter}
      strokeWidth={2}
      {...rest}
    />
  );
};

export default memo(BurndownLine);
