import { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';

type HookReturnValue = {
  searchText: string;
  debouncedSearchText: string;
  updateSearchText: (text: string) => void;
};

export const useDebounceSearchText = (
  waitTime = 500,
  initialState = ''
): HookReturnValue => {
  const [searchText, setSearchText] = useState(initialState);
  const [debouncedSearchText, setDebouncedSearchText] = useState(initialState);
  const debouncedSetDebouncedSearchText = useCallback(
    debounce(setDebouncedSearchText, waitTime),
    []
  );

  const updateSearchText = (text: string): void => {
    setSearchText(text);
    debouncedSetDebouncedSearchText(text);
  };

  return {
    searchText,
    debouncedSearchText,
    updateSearchText,
  };
};
