import { css } from 'emotion';
import styled from 'react-emotion';

export const root = css`
  color: red;
`;

export const HeaderWrapper = styled.div`
  word-break: break-all;
`;
