import React, { FC } from 'react';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { primary } from '@phoenix/all';
import { ArrowDown } from '@insights/shared-components-nwe';
import {
  TasksInFlightSortBy,
  TASKS_IN_FLIGHT_SORT_BY_TO_MESSAGE_KEY,
} from '@insights/constants-nwe';

import {
  Trigger as TriggerStyled,
  TriggerLabel,
  TriggerLabelBold,
} from '../styles';

interface Props {
  sortBy: TasksInFlightSortBy;
}

const Trigger: FC<Props> = ({ sortBy }) => (
  <TriggerStyled>
    <TriggerLabel>
      <FormattedMessage id={MessageKeys.sortBy} />
      :&nbsp;
      <TriggerLabelBold>
        <FormattedMessage id={TASKS_IN_FLIGHT_SORT_BY_TO_MESSAGE_KEY[sortBy]} />
      </TriggerLabelBold>
    </TriggerLabel>
    <ArrowDown color={primary.gray(400)} />
  </TriggerStyled>
);

export default Trigger;
