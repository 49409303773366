import {
  ProjectMagnitudeChartDatum,
  SpreadSheetData,
} from '@insights/models-nwe';

interface Headers {
  projectID: string;
  projectName: string;
  condition: string;
  conditionLabel: string;
  plannedStartDate: string;
  plannedCompletionDate: string;
  workRequired: string;
  completedDurationDays: string;
  completedPlannedHours: string;
}

export const shapeData = (
  headers: Headers,
  chartData: ProjectMagnitudeChartDatum[] = [],
  preferencesError: Error | null
) => {
  return chartData.map((project: ProjectMagnitudeChartDatum) => {
    const baseColumns = {
      [headers.projectID]: project.projectguid,
      [headers.projectName]: project.name,
      [headers.condition]: project.condition,
      [headers.conditionLabel]: project.conditionlabel,
      [headers.plannedStartDate]: project.plannedstartdate,
      [headers.plannedCompletionDate]: project.plannedcompletiondate,
      [headers.workRequired]: project.workrequired,
      [headers.completedPlannedHours]: project.completedplannedhours,
    };

    if (preferencesError) return baseColumns;

    return {
      ...baseColumns,
      [headers.completedDurationDays]: project.completeddurationdays,
    };
  });
};

export const shapeProjectMagnitudesDataForXLSX = (
  name: string,
  headers: Headers,
  chartData: ProjectMagnitudeChartDatum[] | undefined,
  preferencesError: Error | null
): SpreadSheetData => {
  const projectMagnitudes = shapeData(headers, chartData, preferencesError);
  return {
    data: {
      data: projectMagnitudes,
    },
    name,
  };
};
