import { FilterOption } from '@insights/models-nwe';
import { wfetch, encodeAsFormData } from '@wf-mfe/api';
import { Project, Category } from 'workfront-objcodes';
import { useCancelableEffect } from '@insights/utils-nwe';

const LIMIT = 50;

export interface CustomForm {
  name: string;
  ID: string;
}

export interface Response {
  data: FilterOption[] | undefined;
  loading: boolean;
  error: Error | undefined;
}

const useCustomFormsQuery = (searchText: string): Response => {
  return useCancelableEffect((): Promise<FilterOption[]> => {
    return getCustomForms(searchText);
  }, [searchText]);
};

export default useCustomFormsQuery;

const getCustomForms = (searchText: string) => {
  return wfetch(`/attask/api-internal/${Category}/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: encodeAsFormData({
      method: 'get',
      fields: ['*'],
      filters: {
        'AND:a:catObjCode': Project,
        'AND:a:name_Sort': 'asc',
        'AND:a:name': searchText,
        'AND:a:name_Mod': 'cicontains',
        $$LIMIT: LIMIT,
      },
    }),
  }).then(processCustomForms);
};

const processCustomForms = (customData: CustomForm[]): FilterOption[] => {
  return customData.map((customForm: CustomForm) => {
    return {
      label: customForm.name,
      value: customForm.ID,
    };
  });
};
