import React, { FC } from 'react';
import { LabelDropdown, Option } from '@phoenix/all';
import { detect } from 'detect-browser';
import { logError } from '@insights/utils-nwe';
import { SpreadSheetData } from '@insights/models-nwe';
import {
  ExportType,
  EXPORT_TYPE_TO_MESSAGE_KEY,
  ChartType,
} from '@insights/constants-nwe';
import { FormattedMessage, useIntl } from '@insights/i18n-nwe';

import * as styles from './styles';
import ExportUI from './export-ui/index';
import { VisualExportService } from '../../services/export-visual/index';
import generateXLSX from '../../services/generate-xlsx';
import { chartTitleToMesasgeKey } from './utils/chartTitleToMessageKey';

export interface Props {
  getNode: any;
  disableExport: boolean;
  title: ChartType;
  getData?: () => SpreadSheetData;
}

const browser = detect();

const options = Object.keys(ExportType).map((type: ExportType) => {
  return (
    <Option
      value={type}
      key={type}
      disabled={browser?.name === 'ie' && type === ExportType.chart}
    >
      <FormattedMessage id={EXPORT_TYPE_TO_MESSAGE_KEY[type]} />
    </Option>
  );
});

const exportData = (
  type: ExportType,
  getNode: any,
  title: string,
  filename: string,
  getData?: () => SpreadSheetData
): void => {
  if (type === ExportType.chart) {
    const node = getNode();
    VisualExportService(title, filename, node);
  } else if (type === ExportType.dataTable && getData !== undefined) {
    try {
      const shapedData = getData();
      generateXLSX(shapedData);
    } catch (err) {
      logError(err);
    }
  }
};

const VisualExport: FC<Props> = ({
  getNode,
  getData,
  title,
  disableExport,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.headerLink} data-html2canvas-ignore>
      <LabelDropdown
        testID={`${title}-export`}
        onChange={(value: ExportType): void => {
          exportData(
            value,
            getNode,
            title,
            formatMessage(
              { id: chartTitleToMesasgeKey[title] },
              { today: new Date() }
            ),
            getData
          );
        }}
        trigger={<ExportUI disableExport={disableExport} />}
        open={false}
      >
        {disableExport ? null : options}
      </LabelDropdown>
    </div>
  );
};

export default VisualExport;
