import React, { FC } from 'react';
import AddIcon from 'phoenix-icons/dist/AddIcon.js';
import { MessageKeys, FormattedMessage, useIntl } from '@insights/i18n-nwe';
import { Page } from '@insights/constants-nwe';
import { Filter } from '@insights/models-nwe';
import {
  useSetFocus,
  useToggleFocusReducer,
} from '@insights/shared-components-nwe';
import styled from 'react-emotion';
import { css } from 'emotion';
import { FilterRouter } from '../../router';

export interface Props {
  page: Page;
  onAddFilter(d: Filter): void;
  dateRange: { type: string; endDate: Date; startDate: Date };
  filters: Filter[];
  buttonRef: React.RefObject<HTMLButtonElement>;
}

export const ADD_ICON_TEST_ID = 'add-filter-btn';

export const AddFilter: FC<Props> = ({
  filters,
  onAddFilter,
  page,
  buttonRef,
}) => {
  const {
    display: displayFilterRouter,
    setFocus,
    dispatchDisplay,
    dispatchHideAndSetFocus,
  } = useToggleFocusReducer();
  useSetFocus(buttonRef, setFocus);
  const { formatMessage } = useIntl();

  return displayFilterRouter ? (
    <FilterRouter
      page={page}
      filters={filters}
      toggleFilter={dispatchHideAndSetFocus}
      onAddFilter={onAddFilter}
    />
  ) : (
    <AddFilterContainer onClick={dispatchDisplay} innerRef={buttonRef}>
      <AddIcon
        className={icon}
        color="#0078d7"
        data-testid={ADD_ICON_TEST_ID}
        title=""
        aria-label={formatMessage({ id: MessageKeys.filterAdd })}
      />
      {filters.length === 0 && (
        <span aria-hidden="true">
          <FormattedMessage id={MessageKeys.filterAdd} />
        </span>
      )}
    </AddFilterContainer>
  );
};

const AddFilterContainer = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0078d7;
  cursor: pointer;
  height: 21px;
  border: none;
  background: none;
  font-size: 14px;
  padding: 0px;
`;

const icon = css`
  width: 16px;
  height: 20px;
  margin-right: 10px;
`;
