import React, { FunctionComponent } from 'react';
import { ScaleBand, ScaleQuantize } from 'd3-scale';
import { Group } from '@vx/group';

import { Activity } from '@insights/models-nwe';
import { ActivityType } from '@insights/constants-nwe';

import ActivityBar from '../activity-bar';

export interface Props {
  data: Activity[];
  top: number;
  height: number;
  dateScale: ScaleBand<Date>;
  colorScale: ScaleQuantize<string>;
  type: ActivityType;
  onSegmentMouseOver(
    event: React.MouseEvent,
    type: ActivityType,
    date: Date,
    count: number
  ): void;
  onSegmentMouseOut(
    event: React.MouseEvent,
    type: ActivityType,
    date: Date,
    count: number
  ): void;
  onSegmentClick(
    event: React.MouseEvent,
    type: ActivityType,
    date: Date,
    count: number
  ): void;
}

const ActivityHeatMap: FunctionComponent<Props> = ({ data, top, ...rest }) => (
  <Group top={top}>
    {data.map((d) => (
      <ActivityBar datum={d} {...rest} key={+d.date} />
    ))}
  </Group>
);

export default ActivityHeatMap;
