import React, { FC } from 'react';
import styled from 'react-emotion';

import { useProjects } from '@insights/clients-nwe';
import { PaginationWheel } from '@insights/shared-components-nwe';
import { PaginationText } from '../pagination-text/PaginationText';

export interface Props {
  pageOffset: number;
  limit: number;
  currentPageNumber: number;
  testID: string;
  setPage(value: number): void;
}

export const PaginationFooter: FC<Props> = ({
  pageOffset,
  limit,
  currentPageNumber,
  testID,
  setPage,
}) => {
  const {
    data: { totalCount },
  } = useProjects();

  return (
    <PaginationContainer data-testid={`${testID}-card-footer`}>
      <PaginationText
        pageOffset={pageOffset}
        limit={limit}
        totalCount={totalCount}
        testId={testID}
      />
      <PaginationWheelContainer>
        <PaginationWheel
          currentPageNumber={currentPageNumber}
          totalPages={Math.ceil(totalCount / limit)}
          setPage={setPage}
          testID={testID}
        />
      </PaginationWheelContainer>
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
`;

const PaginationWheelContainer = styled('div')`
  margin-left: 13px;
`;
