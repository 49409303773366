import { ActionType, getType } from 'typesafe-actions';

import * as Actions from './actions';

type TooltipState =
  | { fade: false; show: false }
  | { fade: true; show: true }
  | { fade: false; show: true };

export default function tooltipReducer(
  state: TooltipState,
  action: ActionType<typeof Actions>
): TooltipState {
  switch (action.type) {
    case getType(Actions.showTooltip): {
      return { show: true, fade: false };
    }
    case getType(Actions.hideTooltip): {
      return { show: false, fade: false };
    }
    case getType(Actions.startFade): {
      return { show: true, fade: true };
    }
    default:
      return state;
  }
}
