import React, { ReactElement } from 'react';
import { css, cx } from 'emotion';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

export type Datum = string | number | null | undefined;
export type Row = { [key: string]: unknown };
export type Header<T extends string | number | symbol> = {
  key: T;
  label: string | ReactElement;
  isRowHeader?: boolean;
};

export interface Props<T extends Row> {
  caption: string | ReactElement;
  headers: Header<keyof T>[];
  data: T[];
  getDatum?(row: T, key: keyof T): Datum;
  noDataMessage?: string | ReactElement;
  show?: boolean;
  testID?: string;
}

export const ChartA11y = <T extends Row>({
  show,
  data,
  headers,
  caption,
  getDatum = defaultGetter,
  noDataMessage = <FormattedMessage id={MessageKeys.none} />,
  testID,
}: Props<T>): JSX.Element => {
  const classes = cx({
    [hidden]: !show,
  });

  function getCell(row: T, key: keyof T): string | number | ReactElement {
    const cell = getDatum(row, key);
    return cell ?? noDataMessage;
  }

  function buildRow(row: T, index: number): JSX.Element {
    return (
      <tr
        className={classes}
        key={index}
        role="row"
        data-testid={`row-${index}`}
      >
        {headers.map(({ key, isRowHeader }) => {
          const dataPoint = getCell(row, key);
          return React.createElement(
            isRowHeader ? 'th' : 'td',
            {
              className: classes,
              key: `${key}-${index}`,
              scope: isRowHeader ? 'row' : undefined,
              role: isRowHeader ? 'rowheader' : 'gridcell',
              'data-testid': `${key}-${index}`,
            },
            dataPoint
          );
        })}
      </tr>
    );
  }

  function buildHeaders(): JSX.Element {
    return (
      <tr role="row">
        {headers.map(({ label, key }) => {
          return (
            <th
              className={classes}
              key={key.toString()}
              scope="col"
              role="columnheader"
            >
              {label}
            </th>
          );
        })}
      </tr>
    );
  }

  return (
    <table
      className={classes}
      tabIndex={0}
      data-testid={`${testID}-chart-a11y`}
    >
      <caption className={classes}>{caption}</caption>
      <thead>{buildHeaders()}</thead>
      <tbody>{data.map(buildRow)}</tbody>
    </table>
  );
};

export const defaultGetter = (row: Row, key: string): Datum => {
  const value = row?.[key];
  if (isDatum(value)) return value;
  else
    throw new Error(
      'Only cells that are strings, numbers, undefined or null are supported'
    );
};

const isDatum = (arg: unknown): arg is Datum => {
  return ['string', 'number', 'undefined'].includes(typeof arg) || arg === null;
};

const hidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
