import { createAction } from 'typesafe-actions';
import { DateRangeType } from '@insights/constants-nwe';
import { startOfDay, endOfDay } from 'date-fns';

type DateRangePayload = {
  startDate: Date;
  endDate: Date;
  type: DateRangeType;
};

export const setDateRange = createAction(
  'App/SET_DATE_RANGE',
  ({ startDate, endDate, type }: DateRangePayload) => ({
    startDate: startOfDay(startDate),
    endDate: endOfDay(endDate),
    type,
  })
)<DateRangePayload>();

// @TODO change payload to view enum
export const setCurrentView = createAction('App/SET_CURRENT_VIEW')<string>();

export const notifySplitLoading = createAction('App/NOTIFY_SPLIT_LOADING')();

export const notifySplitReady = createAction('App/NOTIFY_SPLIT_READY')();

export const notifySplitTimeout = createAction('App/NOTIFY_SPLIT_TIMEOUT')();
