import React, { FC, SVGProps, ReactNode } from 'react';
import { Text } from '@phoenix/all';

import LegendItem from '../legend-item';

import Line from './line';

export interface OwnProps {
  label: ReactNode;
  testID?: string;
}

export type Props = OwnProps & SVGProps<SVGLineElement>;

const LegendLineItem: FC<Props> = ({ label, testID, ...lineProps }) => (
  <LegendItem size="large">
    <Line {...lineProps} />
    <Text.Small data-testid={testID}>{label}</Text.Small>
  </LegendItem>
);

export default LegendLineItem;
