import React, { FC } from 'react';

import { KPIContainer } from '@insights/kpi-nwe';
import { ProjectActivity } from '@insights/activity-nwe';
import {
  ContentHolderComponent,
  CardComponent,
  SummaryPanelProvider,
} from '@insights/shared-components-nwe';
import { Page } from '@insights/constants-nwe';
import { FilterHeader } from '@insights/filters-nwe';
import { ProjectsProvider } from '@insights/clients-nwe';
import { pageStyles, FilterWrapper } from './styles';
import { FlightPlanCharts } from './containers/flight-plan';
import { ProjectMagnitudeCharts } from './containers/project-magnitude';
import { WorkProjectCount, PaginationFooter } from './pagination/index';

const WorkContent: FC = () => {
  const cardFooter = (testID: string) => <PaginationFooter testID={testID} />;
  return (
    <ProjectsProvider>
      <SummaryPanelProvider>
        <div className={pageStyles}>
          <FilterWrapper>
            <FilterHeader page={Page.work} />
            <WorkProjectCount />
          </FilterWrapper>
          <ContentHolderComponent>
            <KPIContainer />

            <CardComponent footer={cardFooter('flight-plan')}>
              <FlightPlanCharts />
            </CardComponent>

            <CardComponent footer={cardFooter('project-activity')}>
              <ProjectActivity />
            </CardComponent>

            <CardComponent footer={cardFooter('project-magnitude')}>
              <ProjectMagnitudeCharts />
            </CardComponent>
          </ContentHolderComponent>
        </div>
      </SummaryPanelProvider>
    </ProjectsProvider>
  );
};

export default WorkContent;
