import React, { FC } from 'react';
import { CustomEnum } from 'workfront-objcodes';
import { ConditionType } from '@insights/constants-nwe';

import { processConditions } from './use-project-conditions-query';
import { CustomEnumInfo } from './models';
import { ProjectConditionsContext } from './index';

export const TEST_CUSTOM_ENUMS: CustomEnumInfo[] = [
  {
    color: 'AAEA3A',
    equatesWith: ConditionType.onTarget,
    groupID: '',
    label: 'On Target',
    objCode: CustomEnum,
    value: 'ON',
  },
  {
    color: 'FFC000',
    equatesWith: ConditionType.atRisk,
    groupID: '',
    label: 'At Risk',
    objCode: CustomEnum,
    value: 'AR',
  },
  {
    color: 'E00000',
    equatesWith: ConditionType.inTrouble,
    groupID: '',
    label: 'In Trouble',
    objCode: CustomEnum,
    value: 'IT',
  },
];

export const MockProjectConditionsProvider: FC = ({ children }) => (
  <ProjectConditionsContext.Provider
    children={children}
    value={{
      loading: false,
      error: undefined,
      data: processConditions(TEST_CUSTOM_ENUMS),
    }}
  />
);
