import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'react-emotion';
import { MessageKeys, useIntl, FormattedMessage } from '@insights/i18n-nwe';

import { FilterOption } from '@insights/models-nwe';

import { useComboBox, useDebounceSearchText } from '../../hooks';

import {
  Popover,
  SearchInput,
  Header,
  SingleSelect,
  ComboBoxContainer,
} from '../../components';
import { FilterRoutes } from '../../constants/filter-routes';

import useCustomFormsQuery from './use-custom-forms-query';
import { FormState } from '../custom-form-field-options';
import { comboBoxWidth } from '../../constants/combo-box';

export const CustomFormOptions: FC = () => {
  const navigate = useNavigate();
  const {
    searchText,
    debouncedSearchText,
    updateSearchText,
  } = useDebounceSearchText();
  const { data, loading, error } = useCustomFormsQuery(debouncedSearchText);
  const filteredOptions = data || [];
  const { formatMessage } = useIntl();

  const onClick = ({ label, value }: FilterOption): void => {
    navigate(FilterRoutes.customFields, { state: {
      label,
      formId: value,
    }});
  };

  const {
    ariaComboboxProps,
    ariaInputProps,
    ariaMenuProps,
    ariaOptionProps,
  } = useComboBox(filteredOptions, onClick);

  return (
    <ComboBoxContainer ariaComboboxProps={ariaComboboxProps}>
      <SearchInput
        value={searchText}
        onChange={updateSearchText}
        searchInputLabel={
          <FormattedMessage id={MessageKeys.searchAvailableCustomFormOptions} />
        }
        ariaInputProps={ariaInputProps}
      />
      <Popover>
        <Container>
          <Header
            onBack={() => navigate(-1)}
            header={formatMessage({ id: MessageKeys.customForms })}
          />
          <OptionsContainer>
            <SingleSelect
              options={filteredOptions}
              loading={loading}
              error={!!error}
              ariaMenuProps={ariaMenuProps}
              ariaOptionProps={ariaOptionProps}
            />
          </OptionsContainer>
        </Container>
      </Popover>
    </ComboBoxContainer>
  );
};

const Container = styled.div`
  width: ${comboBoxWidth};
`;

const OptionsContainer = styled.div`
  height: auto;
  min-height: 32px;
  max-height: 193px;
  overflow-y: auto;
`;
