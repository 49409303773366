import styled from 'react-emotion';

export const Popover = styled.div`
  position: absolute;
  left: 1px;
  top: 20px;
  height: auto;
  border: 1px solid #adafb1;
  border-radius: 3px;
  margin-top: 5px;
  background-color: #fff;
`;
