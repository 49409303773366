import { FC } from 'react';
import { differenceInDays } from 'date-fns';

import { DateRange } from '@insights/models-nwe';

const ACTIVITY_DAY_LIMIT = 92;

export interface Props {
  dateRange: DateRange;
  renderChart: JSX.Element;
  chartDateRangeWarning: JSX.Element;
}

const CheckRange: FC<Props> = ({
  dateRange,
  renderChart,
  chartDateRangeWarning,
}) => {
  if (differenceInDays(dateRange.end, dateRange.start) >= ACTIVITY_DAY_LIMIT) {
    return chartDateRangeWarning;
  }

  return renderChart;
};

export default CheckRange;
