import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { getFeatureString, logError } from '@insights/utils-nwe';

import { retryAuth } from '../services/retry-auth';

import { cache } from './cache';

interface ResponseError {
  statusCode: number;
  bodyText: string;
}

// try refreshing token
const tokenRefreshLink = new RetryLink({
  delay: {
    initial: 0,
  },
  attempts: {
    max: 2,
    retryIf: (error: ResponseError) => {
      if (error.statusCode === 403) {
        return retryAuth().then(
          () => true,
          () => false
        );
      }
      return false;
    },
  },
});

interface ApolloConfig {
  url?: string;
}

interface GraphQLError {
  message: string;
  // locations is array of unknown?
  locations?: readonly ({ line: number; column: number } | undefined)[];
  // same for path?
  path?: readonly (string | number)[];
}

interface NetworkError {
  bodyText?: string;
}

interface OnErrorArgs {
  graphQLErrors?: readonly GraphQLError[];
  networkError?: Error & NetworkError;
}

function handleError({ graphQLErrors, networkError }: OnErrorArgs): void {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      logError(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    if (typeof networkError.bodyText !== 'undefined') {
      logError(
        `[Network error]: ${networkError.bodyText}: ${JSON.stringify(
          networkError
        )}`
      );
    } else {
      logError(`[Network error]: ${networkError}`);
    }
  }
}

export default ({ url = '/insights/graphql' }: ApolloConfig) =>
  new ApolloClient({
    cache,
    resolvers: {},
    link: ApolloLink.from([
      tokenRefreshLink,
      onError(handleError),
      new HttpLink({
        uri: `${url}${getFeatureString()}`,
        credentials: 'same-origin',
      }),
    ]),
  });
