import React, { FC, useMemo } from 'react';
import styled from 'react-emotion';
import uniqueId from 'lodash.uniqueid';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';
import { LeftCarat, RightCarat, Page, TruncationDots } from './wheel-elements';
import { generatePaginationOptions } from '../../utils';
import { HiddenAriaElement } from '../../../..';

export interface Props {
  currentPageNumber: number;
  totalPages: number;
  setPage(value: number): void;
  testID?: string;
}

export const PaginationWheel: FC<Props> = ({
  currentPageNumber,
  totalPages,
  setPage,
  testID,
}) => {
  const { formatMessage } = useIntl();
  const truncationDotsKey = useMemo(() => uniqueId('dots'), []);
  const currentPageNumberId = useMemo(
    () => uniqueId('current-page-number'),
    []
  );
  const paginationOptions = generatePaginationOptions(
    currentPageNumber,
    totalPages
  );

  return (
    <PaginationWheelContainer
      aria-label={formatMessage({ id: MessageKeys.projectPagination })}
      role="navigation"
    >
      <UnorderedList>
        <LeftCarat
          currentPageNumber={currentPageNumber}
          setPage={setPage}
          disabled={currentPageNumber <= 1}
          testID={testID}
          ariaDescribedById={currentPageNumberId}
        />

        {paginationOptions.map((value, i) => {
          const pageSelected = value === currentPageNumber;

          return typeof value === 'string' ? (
            <TruncationDots key={truncationDotsKey + i} value={value} /> // eslint-disable-line react/no-array-index-key
          ) : (
            <Page
              key={value}
              value={value}
              pageSelected={pageSelected}
              setPage={setPage}
              testID={testID}
              ariaDescribedById={currentPageNumberId}
            />
          );
        })}

        <RightCarat
          currentPageNumber={currentPageNumber}
          setPage={setPage}
          disabled={currentPageNumber >= totalPages}
          testID={testID}
          ariaDescribedById={currentPageNumberId}
        />
      </UnorderedList>
      <HiddenAriaElement tag="label" id={currentPageNumberId}>
        {formatMessage(
          { id: MessageKeys.paginationCurrentPageOfTotal },
          { currentPageNumber, totalPages }
        )}
      </HiddenAriaElement>
    </PaginationWheelContainer>
  );
};

const PaginationWheelContainer = styled.nav`
  display: flex;
  align-items: center;
  height: 24px;
`;

const UnorderedList = styled.ul`
  display: flex;
`;
