import { primary, secondary } from '@phoenix/all';
import { scaleQuantize } from '@vx/scale';
import { ScaleQuantize } from 'd3-scale';

import { ActivityType, isActivityType } from '@insights/constants-nwe';
import { MaxActivitiesByType } from '@insights/models-nwe';

export const colorMap: Record<ActivityType, string> = {
  [ActivityType.login]: secondary.purple(),
  [ActivityType.taskStatusChange]: secondary.pink(),
  [ActivityType.tasksCompleted]: primary.blue(500),
};

export default (type: string) => {
  if (isActivityType(type)) {
    return colorMap[type];
  }
  return primary.gray(300);
};

export const gradientMap: Record<ActivityType, string[]> = {
  [ActivityType.login]: ['#EBE1F8', '#C3A5EB', '#9C6ADE', '#7250A3'],
  [ActivityType.taskStatusChange]: ['#FDDAE6', '#F990B4', '#F54683', '#B13764'],
  [ActivityType.tasksCompleted]: ['#CFD8EF', '#708ACF', '#123DAF', '#123183'],
};

export const getActivityGradientScale = (
  type: string,
  max: number
): ScaleQuantize<string> => {
  const range = isActivityType(type) ? gradientMap[type] : [primary.gray(300)];

  return scaleQuantize({
    range,
    domain: [0, max],
  });
};

export type GradientScaleByType = {
  [key in ActivityType]: ScaleQuantize<string>;
};

export const getGradientScaleByType = (
  maxActivities: MaxActivitiesByType
): GradientScaleByType => {
  return Object.values(ActivityType).reduce((acc, type: ActivityType) => {
    const maxCount = maxActivities[type] || 0;
    acc[type] = getActivityGradientScale(type, maxCount);

    return acc;
  }, {} as GradientScaleByType);
};
