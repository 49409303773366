import React, {
  FC,
  ReactNode,
  createElement,
  HTMLProps,
  useState,
  useRef,
  forwardRef,
  ReactElement,
} from 'react';
import { TooltipStyled } from '@phoenix/all';

import Portal from '../phoenix-portal';

export const WRAPPER_TEST_ID = 'tooltip-wrapper';

export interface TooltipProps {
  content: ReactNode;
  wrapperTag?: keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap;
  position?: 'top' | 'bottom';
}

type WrapperProps = {
  tag: keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap;
} & HTMLProps<HTMLElement>;

const Wrapper = forwardRef<HTMLElement, WrapperProps>(
  ({ tag, children, ...props }, ref): ReactElement =>
    createElement(tag, { ...props, ref }, children)
);

export const Tooltip: FC<TooltipProps> = ({
  wrapperTag = 'span' as keyof HTMLElementTagNameMap,
  position = 'top',
  content,
  children,
}) => {
  const [isTooltipOpen, toggleTooltip] = useState(false);
  const wrapperRef = useRef(null);

  return (
    <>
      <Wrapper
        tag={wrapperTag}
        onMouseOver={() => toggleTooltip(true)}
        onMouseOut={() => toggleTooltip(false)}
        ref={wrapperRef}
        data-testid={WRAPPER_TEST_ID}
      >
        {children}
      </Wrapper>
      {isTooltipOpen && (
        <Portal>
          <TooltipStyled
            positionElement={wrapperRef.current}
            position={position}
          >
            {content}
          </TooltipStyled>
        </Portal>
      )}
    </>
  );
};
