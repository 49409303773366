import React, { FC, memo } from 'react';
import { eachDayOfInterval, format } from 'date-fns';
import { ChartA11y } from '@insights/shared-components-nwe';
import { DailyTeamCapacity, DateRange } from '@insights/models-nwe';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';

export interface Props {
  data: DailyTeamCapacity[];
  dateRange: DateRange;
  teamName: string | null;
}

enum keys {
  date,
  availableHours,
  scheduledHours,
}

const getDateKey = (date: Date): string => format(date, 'yyyy-MM-dd');

const Chart: FC<Props> = ({ data, dateRange, teamName }) => {
  const { formatDate, formatMessage, formatNumber } = useIntl();
  const caption = formatMessage(
    {
      id: MessageKeys.teamCapacityCaption,
    },
    {
      teamName,
      startDate: dateRange.start,
      endDate: dateRange.end,
    }
  );
  const capacityByDate = data.reduce((acc, capacity) => {
    return {
      ...acc,
      [getDateKey(capacity.date)]: capacity,
    };
  }, {} as { [key: string]: DailyTeamCapacity });

  return (
    <ChartA11y
      testID="team-capacity"
      caption={caption}
      headers={[
        {
          key: keys.date,
          label: formatMessage({ id: MessageKeys.date }),
          isRowHeader: true,
        },
        {
          key: keys.availableHours,
          label: formatMessage({ id: MessageKeys.availableHours }),
        },
        {
          key: keys.scheduledHours,
          label: formatMessage({ id: MessageKeys.scheduledHours }),
        },
      ]}
      data={eachDayOfInterval(dateRange).map((date) => {
        const capacity = capacityByDate[getDateKey(date)];

        return {
          [keys.date]: formatDate(date, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }),
          [keys.availableHours]:
            capacity &&
            formatNumber(capacity.availableHours, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }),
          [keys.scheduledHours]:
            capacity &&
            formatNumber(capacity.scheduledHours, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }),
        };
      })}
    />
  );
};

export const TeamCapacityChartA11y = memo(Chart);
