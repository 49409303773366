import { ActivityType, ConditionType } from '@insights/constants-nwe';

export enum ThemeNames {
  light = 'light',
  dark = 'dark',
}

export interface InsightsTheme {
  name: string;
  condition: {
    [ConditionType.onTarget]: string;
    [ConditionType.atRisk]: string;
    [ConditionType.inTrouble]: string;
  };
  backgroundColors: {
    card: string;
    background: string;
    lineBreak: string;
    infoIcon: string;
  };
  text: {
    link: string;
    chartText: string;
    primary: string;
    header: string;
    todayMarker: string;
  };
  flightPlanChart: {
    plan: string;
  };
  burndown: {
    actual: string;
  };
  activityChart: {
    [ActivityType.login]: string[];
    [ActivityType.taskStatusChange]: string[];
    [ActivityType.tasksCompleted]: string[];
  };
  treemapChart: {
    staticColors: string[];
  };
}
