import React, { FC } from 'react';
import { primary } from '@phoenix/all';

import { GlyphDot } from '@vx/glyph';

export interface LineDotProps {
  x: number;
  y: number;
  color?: string;
  strokeWidth?: number;
  fill?: string;
  dotRadius?: number;
}

export const LineDot: FC<LineDotProps> = ({
  x,
  y,
  color = primary.blue(),
  strokeWidth = 1,
  fill = primary.blue(),
  dotRadius = strokeWidth + 1,
}) => {
  return (
    <GlyphDot
      cx={x}
      cy={y}
      r={dotRadius}
      fill={fill}
      stroke={color}
      strokeWidth={strokeWidth}
      style={{ pointerEvents: 'none' }}
    />
  );
};
