import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { formatDate, graphQLShape } from '@insights/shared-components-nwe';
import { Filter } from '@insights/models-nwe';
import { Data, Variables } from './models';

export const PROJ_COMPLETED_ON_TIME = gql`
  query ProjectsCompletedOnTime(
    $filter: ProjectsFilterInput!
    $queryStartDate: Date!
    $queryEndDate: Date!
  ) {
    projectsCompletedOnTime(
      filter: $filter
      queryStartDate: $queryStartDate
      queryEndDate: $queryEndDate
    ) {
      actual
      historical
    }
  }
`;

export interface Response {
  projectsCompletedOnTime: Data;
}

const useProjectsCompletedOnTime = (
  filter: Filter[],
  startDate: Date,
  endDate: Date
) =>
  useQuery<Response, Variables>(PROJ_COMPLETED_ON_TIME, {
    variables: {
      filter: graphQLShape(filter),
      queryStartDate: formatDate(startDate),
      queryEndDate: formatDate(endDate),
    },
  });

export default useProjectsCompletedOnTime;
