import React, { FC } from 'react';
import styled from 'react-emotion';
import { alerts } from '@phoenix/all';
import ErrorIcon from 'phoenix-icons/dist/ErrorIcon.js';

export interface Props {
  errorMessage: React.ReactNode;
  ariaLabelID?: string;
}

export const ErrorMessage: FC<Props> = ({ errorMessage, ariaLabelID }) => {
  return (
    <Container
      data-testid="custom-filter-unsupported-msg"
      role="dialog"
      aria-live="polite"
      aria-labelledby={ariaLabelID}
    >
      <ContentWrapper>
        <IconContainer>
          <ErrorIcon color={alerts.error()} />
        </IconContainer>
        <Text id={ariaLabelID}>{errorMessage}</Text>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  min-height: 64px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
`;

const Text = styled.div`
  color: ${alerts.error()};
  margin-left: 17px;
  font-size: 14px;
  line-height: 20px;
`;
