import { css } from 'emotion';

export const text = css`
  font-size: 12px;
`;

export const root = css`
  display: flex;
  align-items: baseline;
`;
