import React, { FC, useState } from 'react';
import styled from 'react-emotion';
import { DateRange } from '@insights/models-nwe';
import { HourGrain, ChartType } from '@insights/constants-nwe';
import { MessageKeys, FormattedMessage, useIntl } from '@insights/i18n-nwe';
import {
  useCustomerPreferences,
  CustomerPreferenceField,
  useProject,
} from '@insights/clients-nwe';
import { CardLayout, ZeroState } from '@insights/shared-components-nwe';
import { shapeBurndownDataForXLSX } from './services/burndown/generate-xlsx/burndown/index';
import BurndownHeader from './header';

import Legend from './legend';
import ConnectedChart from './connected-chart';

import GetBurndown from './queries/get-burndown';
import BurndownA11yCharts from './chart/chart-parts/a11y';

export const ANIMATION_DURATION = 500;

export interface Props {
  projectId?: string;
  dateRange: DateRange;
  hourGrain: HourGrain;
  zoomRange?: DateRange;
  onZoom(zoomRange: DateRange): void;
  onHourGrainChange(grain: HourGrain): void;
}

export interface State {
  clickedDate: Date | undefined;
}

const Burndown: FC<Props> = ({
  projectId,
  hourGrain,
  onHourGrainChange,
  dateRange,
  zoomRange,
  ...rest
}) => {
  const project = useProject(projectId ?? '');
  const { formatMessage } = useIntl();
  const [clickedDate, setClickedDate] = useState<Date | undefined>(undefined);
  const {
    preferences,
    loading: preferencesLoading,
    error: preferencesError,
  } = useCustomerPreferences();
  const parsedHoursPerDay = parseFloat(
    preferences?.[CustomerPreferenceField.hoursPerDay] ?? ''
  );
  const durationHoursPerDay = !Number.isNaN(parsedHoursPerDay)
    ? parsedHoursPerDay
    : undefined;

  const range = zoomRange || dateRange;

  const projectGuid = project?.guid ?? '';
  const projectName = project?.name ?? '';

  return (
    <GetBurndown
      skip={!project}
      projectId={projectGuid}
      startDate={range.start}
      endDate={range.end}
      durationHoursPerDay={durationHoursPerDay}
    >
      {({ data, loading, error }) => {
        const burndownData =
          data && data.projectBurndown ? data.projectBurndown : [];

        const chart = () => {
          if (!project) {
            return (
              <ZeroState>
                <FormattedMessage id={MessageKeys.notRetrieved} />
              </ZeroState>
            );
          }
          if (
            loading ||
            (hourGrain === HourGrain.plannedDuration && preferencesLoading)
          ) {
            return <FormattedMessage id={MessageKeys.loading} />;
          }
          if (
            hourGrain === HourGrain.plannedDuration &&
            (preferencesError || !durationHoursPerDay)
          ) {
            return (
              <FormattedMessage
                id={MessageKeys.customerDurationPreferenceError}
              />
            );
          }

          if (error || !project) {
            return <FormattedMessage id={MessageKeys.error} />;
          }

          return (
            <>
              {burndownData.length > 0 && (
                <BurndownA11yCharts
                  burndownData={burndownData}
                  projectName={projectName}
                  hourGrain={hourGrain}
                  dateRange={range}
                />
              )}
              <ConnectedChart
                burndownData={burndownData}
                project={project}
                selectedDate={clickedDate}
                hourGrain={hourGrain}
                onDateClick={setClickedDate}
                dateRange={dateRange}
                zoomRange={zoomRange}
                {...rest}
              />
            </>
          );
        };

        const getData = () =>
          shapeBurndownDataForXLSX(
            formatMessage(
              { id: MessageKeys.burndownExportXlsx },
              {
                startDate: range.start,
                endDate: range.end,
              }
            ),
            {
              date: formatMessage({
                id: MessageKeys.date,
              }),
              projectID: formatMessage({
                id: MessageKeys.projectID,
              }),
              projectName: formatMessage({
                id: MessageKeys.projectName,
              }),
              plannedStartDate: formatMessage({
                id: MessageKeys.plannedStartDate,
              }),
              plannedCompletionDate: formatMessage({
                id: MessageKeys.plannedCompletionDate,
              }),
              projectCondition: formatMessage({
                id: MessageKeys.projectCondition,
              }),
              conditionLabel: formatMessage({
                id: MessageKeys.customCondition,
              }),
              tasksAddedDaily: formatMessage({
                id: MessageKeys.tasksAddedDaily,
              }),
              tasksCompletedDaily: formatMessage({
                id: MessageKeys.tasksCompletedDaily,
              }),
              plannedDurationDaily: formatMessage({
                id: MessageKeys.plannedDurationDaily,
              }),
              completedPlannedDurationDaily: formatMessage({
                id: MessageKeys.completedPlannedDurationDaily,
              }),
              plannedHoursDaily: formatMessage({
                id: MessageKeys.plannedHoursDaily,
              }),
              completedPlannedHoursDaily: formatMessage({
                id: MessageKeys.completedPlannedHoursDaily,
              }),
              plannedDuration: formatMessage({
                id: MessageKeys.plannedDurationDays,
              }),
              remainingDuration: formatMessage({
                id: MessageKeys.remainingDuration,
              }),
              plannedHours: formatMessage({
                id: MessageKeys.plannedHoursPlural,
              }),
              remainingHours: formatMessage({
                id: MessageKeys.remainingHours,
              }),
            },
            {
              burndownData,
              projectName,
            }
          );

        return (
          <BurndownWrapper>
            <CardLayout
              title={ChartType.Burndown}
              header={
                <BurndownHeader
                  projectId={projectGuid}
                  projectName={projectName}
                  hourGrain={hourGrain}
                  onHourGrainChange={onHourGrainChange}
                />
              }
              label={`${projectName} ${formatMessage({
                id: MessageKeys.burndown,
              })}`}
              legend={<Legend />}
              chart={chart()}
              getData={getData}
              disableExport={!(burndownData.length > 0) || loading || !!error}
            />
          </BurndownWrapper>
        );
      }}
    </GetBurndown>
  );
};

export const BurndownWrapper = styled.div`
  margin-bottom: 20px;
`;

export default Burndown;
