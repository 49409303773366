import React, { FC } from 'react';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { useProjects } from '@insights/clients-nwe';
import { Font } from '@phoenix/all';
import { css } from 'react-emotion';

export const WorkProjectCount: FC = () => {
  const { data } = useProjects();

  return (
    <Font type="text" className={projectCountText} data-testid="pagination-txt">
      <FormattedMessage
        id={MessageKeys.totalProjectCount}
        values={{
          projectCount: data.totalCount,
        }}
      />
    </Font>
  );
};

const projectCountText = css`
  color: primary.gray(700);
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-right: 11px;
`;
