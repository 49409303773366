import React, { FC, createContext } from 'react';
import { Page, SortByFields, SortByDirection } from '@insights/constants-nwe';
import { Filter } from '@insights/models-nwe';
import {
  RootState,
  getDateRange,
  getFilters,
  getPaginationOffset,
  getSortByField,
  getSortByDirection,
  getPaginationResultsLimit,
  connect,
} from '@insights/store-nwe';

import { useProjectsForDateRangeQuery } from './use-projects-query';
import { ProjectsForDateRangeState } from './models';

export const DEFAULT_STATE: ProjectsForDateRangeState = {
  loading: true,
  error: undefined,
  data: undefined,
};

export const ProjectsContext = createContext<ProjectsForDateRangeState>(
  DEFAULT_STATE
);

export interface ChildData {
  projectguids: string[];
  projectCount: number;
}

export interface Props {
  filters: Filter[];
  startDate: Date;
  endDate: Date;
  limit: number;
  offset: number;
  sortByField: SortByFields;
  sortByDirection: SortByDirection;
}

export const GetProjects: FC<Props> = ({
  startDate,
  endDate,
  children,
  filters,
  limit,
  offset,
  sortByField,
  sortByDirection,
}) => {
  const state = useProjectsForDateRangeQuery(
    startDate,
    endDate,
    filters,
    limit,
    offset,
    sortByField,
    sortByDirection
  );

  return (
    <ProjectsContext.Provider value={state}>
      {children}
    </ProjectsContext.Provider>
  );
};

type StateProps = Pick<
  Props,
  | 'startDate'
  | 'endDate'
  | 'filters'
  | 'limit'
  | 'offset'
  | 'sortByField'
  | 'sortByDirection'
>;

export default connect(
  (state: RootState): StateProps => {
    const { start: startDate, end: endDate } = getDateRange(state);
    const limit = getPaginationResultsLimit(state);
    const offset = getPaginationOffset(state);
    const sortByField = getSortByField(state);
    const sortByDirection = getSortByDirection(state);
    return {
      startDate,
      endDate,
      filters: getFilters(state, Page.work),
      limit,
      offset,
      sortByField,
      sortByDirection,
    };
  }
)(GetProjects);
