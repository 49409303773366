import React from 'react';
import { Moment } from 'moment';
import { addYears } from 'date-fns';
import { DateRangePicker } from '@phoenix/all';
import { MessageKeys, useIntl } from '@insights/i18n-nwe';

import { PopOutCalendar } from './styles';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FOUNDED = new Date(2001, 2, 15);

interface DatePickerChange {
  startDate: Moment;
  endDate: Moment;
}

interface DateRangeInputPickerProps {
  endDate: Date;
  onChange: Function;
  startDate: Date;
  id: string;
}

export const DateRangeInputPicker = (props: DateRangeInputPickerProps) => {
  const { startDate, endDate, onChange, id } = props;
  const { formatMessage } = useIntl();

  const datePickerChange = ({
    startDate: start,
    endDate: end,
  }: DatePickerChange) => {
    onChange(start.toDate(), end.toDate());
  };

  return (
    <PopOutCalendar
      aria-label={formatMessage({ id: MessageKeys.calendarSelector })}
      id={id}
    >
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        format={DATE_FORMAT}
        onChange={datePickerChange}
        mode={DateRangePicker.MODE_RANGE}
        maxValue={addYears(new Date(), 2)}
        minValue={DATE_FOUNDED}
      />
    </PopOutCalendar>
  );
};
