import React, { FC } from 'react';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

import ResourceCapacityChart, { Props as ChartProps } from '../chart';
import { ResourceCapacityChartA11y } from '../chart/chart-parts/chart-a11y';

export interface OwnProps {
  startDate: Date;
  endDate: Date;
}

export type Props = OwnProps & ChartProps;

export const ResourceCapacityChartWithTable: FC<Props> = ({
  startDate,
  endDate,
  data,
  teams,
  selectedTeam,
  setSelectedTeam,
  width,
}) => {
  const caption = (
    <FormattedMessage
      id={MessageKeys.resourceCapacityCaption}
      values={{ startDate, endDate }}
    />
  );

  return (
    <>
      <ResourceCapacityChart
        caption={caption}
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam}
        data={data}
        teams={teams}
        width={width}
      />
      {data !== undefined && data.length > 0 ? (
        <ResourceCapacityChartA11y
          caption={caption}
          data={data}
          teams={teams}
        />
      ) : null}
    </>
  );
};
