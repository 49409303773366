import React, { MouseEvent, useReducer, Reducer, useCallback } from 'react';
import { ActionType } from 'typesafe-actions';
import { constrainToRegion } from '@vx/brush';
import { localPoint } from '@vx/event';

import { DateRange, Extent, Margin, Point } from '@insights/models-nwe';
import { getExtent, transformCoords } from '../../services/chart';

import reducer, { INITIAL_STATE } from './reducer';
import * as Actions from './actions';
import { shouldZoom } from '../../services/zoom-check';

export interface InitialState {
  start: undefined;
  end: undefined;
  isBrushing: false;
}

export interface StartState {
  start: Point;
  end: undefined;
  isBrushing: true;
}

export interface MoveState {
  start: Point;
  end: Point;
  isBrushing: true;
}

export interface EndState {
  start: Point;
  end: Point;
  isBrushing: false;
}

export type ZoomState = InitialState | StartState | MoveState | EndState;

export interface EventHandlers {
  onBrushStart(e: MouseEvent): void;
  onBrushDrag(e: MouseEvent): void;
  onBrushEnd(e: MouseEvent): void;
  onBrushReset(e: MouseEvent): void;
}

const domain = (point1: Point, point2: Point) => {
  return {
    x0: Math.min(point1.x, point2.x),
    x1: Math.max(point1.x, point2.x),
    y0: Math.min(point1.y, point2.y),
    y1: Math.max(point1.y, point2.y),
  };
};

// Once we can access redux from hooks,
// we probably don't need to pass in the callback
// or the current zoom filter
type Hook = (
  onBrushEnd: (domain: Extent) => void,
  width: number,
  height: number,
  margin?: Margin,
  zoomRange?: DateRange
) => [ZoomState, EventHandlers];

const getConstrainedCoordinates = (
  width: number,
  height: number,
  margin: Margin,
  e: MouseEvent
) => {
  const region = getExtent(width, height, margin);
  const { x, y } = transformCoords(localPoint(e), margin);

  return constrainToRegion({ region, x, y });
};

export const useZoom: Hook = (
  onBrushEnd,
  width,
  height,
  margin = { top: 0, left: 0, bottom: 0, right: 0 },
  zoomRange
) => {
  const [state, dispatch] = useReducer<
    Reducer<ZoomState, ActionType<typeof Actions>>
  >(reducer, INITIAL_STATE);
  const getCoordinates = useCallback(
    (e: MouseEvent) => getConstrainedCoordinates(width, height, margin, e),
    [width, height, margin]
  );

  return [
    state,
    {
      onBrushStart: (e: MouseEvent) =>
        dispatch(Actions.startBrush(getCoordinates(e))),
      onBrushDrag: (e: MouseEvent) => {
        if (state.isBrushing) dispatch(Actions.dragBrush(getCoordinates(e)));
      },
      onBrushEnd: (e: MouseEvent) => {
        if (
          state.start &&
          state.end &&
          shouldZoom(state.start.x, state.end.x, zoomRange)
        ) {
          onBrushEnd(domain(state.start, state.end));
        }
        dispatch(Actions.resetBrush());
      },
      onBrushReset: () => dispatch(Actions.resetBrush()),
    },
  ];
};
