import React, { FC, ReactElement } from 'react';
import { ChartA11y } from '@insights/shared-components-nwe';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';
import { ProjectFragment, DateRange } from '@insights/models-nwe';

import { formatHistoricalProjectData } from '../../../utils/chart-reader';
import { ConditionFragment } from '../../../queries/get-conditions/models';

export interface Props {
  projects: ProjectFragment[] | undefined;
  historicalProjects: ConditionFragment[] | undefined;
  dateRange: DateRange;
}

const flightPlanHeaders = [
  {
    key: 'name' as const,
    label: <FormattedMessage id={MessageKeys.projectName} />,
    isRowHeader: true,
  },
  {
    key: 'plannedstartdate' as const,
    label: <FormattedMessage id={MessageKeys.plannedStartDate} />,
  },
  {
    key: 'plannedcompletiondate' as const,
    label: <FormattedMessage id={MessageKeys.plannedCompletionDate} />,
  },
  {
    key: 'actualstartdate' as const,
    label: <FormattedMessage id={MessageKeys.actualStartDate} />,
  },
  {
    key: 'actualcompletiondate' as const,
    label: <FormattedMessage id={MessageKeys.actualCompletionDate} />,
  },
  {
    key: 'conditionlabel' as const,
    label: <FormattedMessage id={MessageKeys.customCondition} />,
  },
  {
    key: 'condition' as const,
    label: <FormattedMessage id={MessageKeys.condition} />,
  },
];

const historicalFlightPlanHeaders = [
  {
    key: 'name' as const,
    label: <FormattedMessage id={MessageKeys.projectName} />,
    isRowHeader: true,
  },
  {
    key: 'conditionlabel' as const,
    label: <FormattedMessage id={MessageKeys.customCondition} />,
  },
  {
    key: 'condition' as const,
    label: <FormattedMessage id={MessageKeys.condition} />,
  },
  {
    key: 'conditionchangetimestamp' as const,
    label: <FormattedMessage id={MessageKeys.conditionChangeTimeStamp} />,
  },
];

const FlightPlanA11yChart: FC<Props> = ({
  projects = [],
  historicalProjects = [],
  dateRange,
}): ReactElement => {
  const { formatDate } = useIntl();

  const getDatum = <
    T extends { [key: string]: string | Date | null | undefined }
  >(
    row: T,
    key: string
  ): string | null | undefined => {
    const value = row[key];
    if (value instanceof Date)
      return formatDate(value, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    return value;
  };

  return (
    <>
      <ChartA11y
        caption={
          <FormattedMessage
            id={MessageKeys.currentProjectConditionsCaption}
            values={{
              startDate: dateRange.start,
              endDate: dateRange.end,
            }}
          />
        }
        data={projects}
        headers={flightPlanHeaders}
        getDatum={getDatum}
      />
      <ChartA11y
        caption={
          <FormattedMessage id={MessageKeys.historicalProjectConditions} />
        }
        data={formatHistoricalProjectData(historicalProjects, projects)}
        headers={historicalFlightPlanHeaders}
        getDatum={getDatum}
      />
    </>
  );
};

export default FlightPlanA11yChart;
