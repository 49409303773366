import React, { FC, ReactNode } from 'react';
import styled from 'react-emotion';
import { primary } from '@phoenix/all';

export interface Props {
  footer?: ReactNode;
}

const CardComponent: FC<Props> = ({ footer, children }) => (
  <Card>
    {children}
    {footer && <Footer>{footer}</Footer>}
  </Card>
);

export default CardComponent;

const Card = styled.div`
  overflow: visible;
  background-color: ${primary.white()};
  border-radius: 2px;
  margin-bottom: 16px;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  padding: 0 16px;
  border-bottom: 1px solid ${primary.gray(200)};
  border-top: 1px solid ${primary.gray(200)};
`;
