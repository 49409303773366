import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import {
  TeamActivitySummaryDatum,
  RawTeamActivitySummaryDatum,
} from '@insights/models-nwe';
import { formatDate } from '@insights/shared-components-nwe';

import { processGroupedActivities } from '../../../services/activity';

export const NO_DATA: TeamActivitySummaryDatum = {
  MAX_LOGIN: 0,
  MAX_TASKS_CHANGES: 0,
  MAX_TASKS_COMPLETED: 0,
  TEAMS: [],
};

export const GET_TEAM_ACTIVITY = gql`
  query TeamActivity(
    $projectIds: [Guid!]!
    $teamIds: [Guid!]!
    $startDate: Date!
    $endDate: Date!
  ) {
    teamActivityForDateRange(
      projectIDs: $projectIds
      teamIDs: $teamIds
      queryStartDate: $startDate
      queryEndDate: $endDate
    ) {
      MAX_LOGIN
      MAX_TASKS_COMPLETED
      MAX_TASKS_CHANGES
      TEAMS {
        guid
        name
        users
        activities {
          login {
            date
            count
          }
          taskStatusChange {
            date
            count
          }
          tasksCompleted {
            date
            count
          }
        }
      }
    }
  }
`;

/**
 * This is where the props feeding into the component
 * are defined
 * Note: leave the children prop there!!!
 */
export interface Props {
  projectIds: string[];
  teamIds: string[];
  startDate: Date;
  endDate: Date;
  children(result: {
    data: TeamActivitySummaryDatum;
    loading: boolean;
    error: ApolloError | undefined;
  }): JSX.Element | null;
}

/**
 * This is where the shape of the response is defined. It should match
 * the shape of your query
 */
export interface Response {
  teamActivityForDateRange: RawTeamActivitySummaryDatum;
}

/**
 * This is where the variables that are used in the actual query
 * are defined
 */
export interface Variables {
  projectIds: string[];
  teamIds: string[];
  startDate: string;
  endDate: string;
}

const GetTeamActivity: FC<Props> = ({
  projectIds,
  teamIds,
  startDate,
  endDate,
  children,
}) => {
  const hasIds = teamIds.length;
  const { data, loading, error } = useQuery<Response, Variables>(
    GET_TEAM_ACTIVITY,
    {
      variables: {
        projectIds,
        teamIds,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      },
      skip: !hasIds,
    }
  );

  if (!hasIds)
    return children({ data: NO_DATA, loading: false, error: undefined });

  return children({
    data: data
      ? {
          ...data.teamActivityForDateRange,
          TEAMS: processGroupedActivities(data.teamActivityForDateRange.TEAMS),
        }
      : NO_DATA,
    loading,
    error,
  });
};

export default GetTeamActivity;
