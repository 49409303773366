import React, { FC } from 'react';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';
import {
  KpiCardWrapper,
  KPICardHeader,
  CardFooter,
  CardBody,
} from '../../card-layout';
import useProjectsCompleted from '../../queries/use-projects-completed';
import { KPICardProps } from '../models';

export const TEST_ID = 'projects-completed';

const CardHeader: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <KPICardHeader
      testID={`${TEST_ID}-header`}
      title={formatMessage({ id: MessageKeys.kpiProjectsCompleted })}
      info={{
        header: <FormattedMessage id={MessageKeys.kpiProjectsCompleted} />,
        body: (
          <>
            <div>
              <FormattedMessage id={MessageKeys.kpiProjectsCompletedInfo1} />
            </div>
            <br />
            <div>
              <FormattedMessage id={MessageKeys.kpiProjectsCompletedInfo2} />
            </div>
          </>
        ),
      }}
      buttonDescription={
        <FormattedMessage
          id={MessageKeys.tooltipButtonDescriptionProjectsCompleted}
        />
      }
    />
  );
};

export const ProjectsCompleted: FC<KPICardProps> = ({ filter, dateRange }) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useProjectsCompleted(
    filter,
    dateRange.start,
    dateRange.end
  );

  if (loading) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.loading} />}
      />
    );
  }

  if (error || !data) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.error} />}
      />
    );
  }

  return (
    <KpiCardWrapper
      testID={TEST_ID}
      header={<CardHeader />}
      body={
        <CardBody
          testID={`${TEST_ID}-body`}
          value={data.projectsCompleted.actual}
          previousValue={data.projectsCompleted.historical}
          bodyAriaLabel={formatMessage(
            { id: MessageKeys.kpiAriaLabelProjectsCompletedBody },
            {
              value: data.projectsCompleted.actual,
              startDate: dateRange.start,
              endDate: dateRange.end,
            }
          )}
          changeAriaLabel={MessageKeys.kpiAriaLabelProjectsCompletedBodyChange}
          dateRange={{ start: dateRange.start, end: dateRange.end }}
        />
      }
      footer={
        <CardFooter
          testID={`${TEST_ID}-footer`}
          previousValue={data.projectsCompleted.historical}
          startDate={dateRange.start}
          endDate={dateRange.end}
          ariaLabel={(totalDays) =>
            formatMessage(
              { id: MessageKeys.kpiAriaLabelProjectsCompletedFooter },
              { value: data.projectsCompleted.historical, totalDays }
            )
          }
        />
      }
    />
  );
};
