import React, { FC } from 'react';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';
import {
  KpiCardWrapper,
  KPICardHeader,
  CardFooter,
  CardBody,
} from '../../card-layout';
import useAvgTasksPerProject from '../../queries/use-avg-tasks-per-project';
import { KPICardProps } from '../models';

export const TEST_ID = 'avg-tasks-per-project';

const CardHeader: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <KPICardHeader
      ariaLabel={formatMessage({
        id: MessageKeys.kpiAriaLabelAverageTasksPerProjectHeader,
      })}
      testID={`${TEST_ID}-header`}
      title={formatMessage({ id: MessageKeys.kpiAverageTasksPerProject })}
      info={{
        header: <FormattedMessage id={MessageKeys.kpiAverageTasksPerProject} />,
        body: (
          <>
            <div>
              <FormattedMessage
                id={MessageKeys.kpiAverageTasksPerProjectInfo1}
              />
            </div>
            <br />
            <div>
              <FormattedMessage
                id={MessageKeys.kpiAverageTasksPerProjectInfo2}
              />
            </div>
          </>
        ),
      }}
      buttonDescription={
        <FormattedMessage
          id={MessageKeys.tooltipButtonDescriptionAverageTasksPerProject}
        />
      }
    />
  );
};

export const AvgTasksPerProject: FC<KPICardProps> = ({ filter, dateRange }) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useAvgTasksPerProject(
    filter,
    dateRange.start,
    dateRange.end
  );

  if (loading) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.loading} />}
      />
    );
  }

  if (error || !data) {
    return (
      <KpiCardWrapper
        testID={TEST_ID}
        header={<CardHeader />}
        body={<FormattedMessage id={MessageKeys.error} />}
      />
    );
  }

  return (
    <KpiCardWrapper
      testID={TEST_ID}
      header={<CardHeader />}
      body={
        <CardBody
          testID={`${TEST_ID}-body`}
          value={data.averageTasksPerProject.actual}
          previousValue={data.averageTasksPerProject.historical}
          bodyAriaLabel={formatMessage(
            { id: MessageKeys.kpiAriaLabelAverageTasksPerProjectBody },
            {
              value: data.averageTasksPerProject.actual,
              startDate: dateRange.start,
              endDate: dateRange.end,
            }
          )}
          changeAriaLabel={
            MessageKeys.kpiAriaLabelAverageTasksPerProjectBodyChange
          }
          dateRange={{ start: dateRange.start, end: dateRange.end }}
        />
      }
      footer={
        <CardFooter
          testID={`${TEST_ID}-footer`}
          previousValue={data.averageTasksPerProject.historical}
          startDate={dateRange.start}
          endDate={dateRange.end}
          ariaLabel={(totalDays) =>
            formatMessage(
              { id: MessageKeys.kpiAriaLabelAverageTasksPerProjectFooter },
              { value: data.averageTasksPerProject.historical, totalDays }
            )
          }
        />
      }
    />
  );
};
