import { css } from 'emotion';

export const headerLink = css`
  margin-left: 6px;
`;

export const root = css`
  width: 100%;
  display: flex;
  align-items: baseline;
`;

export const margin = css`
  margin-right: 6px;
`;

export const burndowHeaderContainer = css`
  max-width: 50%;
  overflow: hidden;
`;

export const burndownHeader = css`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
