export enum ChartType {
  FlightPlan = 'FlightPlan',
  Burndown = 'Burndown',
  ProjectActivity = 'ProjectActivity',
  PeopleActivity = 'PeopleActivity',
  ProjectTreemap = 'ProjectTreemap',
  TasksInFlight = 'TasksInFlight',
  TeamCapacity = 'TeamCapacity',
  ResourceCapacity = 'ResourceCapacity',
}
