import { MessageKeys } from '@insights/i18n-nwe';

export enum HourGrain {
  plannedHours = 'plannedHours',
  plannedDuration = 'plannedDuration',
}

export const HOUR_GRAIN_TO_MESSAGE_KEY: { [key in HourGrain]: MessageKeys } = {
  [HourGrain.plannedHours]: MessageKeys.plannedHours,
  [HourGrain.plannedDuration]: MessageKeys.plannedDuration,
};
