import { differenceInMilliseconds } from 'date-fns';

export const getSlope = (
  startDate: Date,
  completionDate: Date,
  y: number | undefined
) => (date: Date) => {
  if (date > completionDate || y === undefined) {
    return 0;
  }

  const x = differenceInMilliseconds(completionDate, startDate);
  const m = -y / x;

  return Math.min(m * differenceInMilliseconds(date, startDate) + y, y);
};
