import { getType } from 'typesafe-actions';
import { HourGrain } from '@insights/constants-nwe';

import Actions from '../../actions';
import { setHourGrain } from '../../actions/burndown';

export type State = Readonly<{
  hourGrain: HourGrain;
}>;

export const INITIAL_STATE: State = { hourGrain: HourGrain.plannedHours };

export default (state: State = INITIAL_STATE, action: Actions): State => {
  switch (action.type) {
    case getType(setHourGrain):
      return { ...state, hourGrain: action.payload };
    default:
      return state;
  }
};

export const getHourGrain = (state: State) => state.hourGrain;
