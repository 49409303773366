import { Dispatch, bindActionCreators } from 'redux';
import { Page, ChartType } from '@insights/constants-nwe';
import { DateRange } from '@insights/models-nwe';
import {
  RootState,
  getZoomDateRange,
  getDateRange,
  setZoomFilter,
  connect,
} from '@insights/store-nwe';

import PeopleActivity, { Props } from './people-activity/index';

type StateProps = Pick<Props, 'dateRange' | 'zoomRange'>;
type DispatchProps = Pick<Props, 'onZoom'>;

export default connect<StateProps, DispatchProps, Props>(
  (state: RootState): StateProps => ({
    dateRange: getDateRange(state),
    zoomRange: getZoomDateRange(state, Page.people),
  }),
  (dispatch: Dispatch): DispatchProps =>
    bindActionCreators(
      {
        onZoom: (dateRange: DateRange) =>
          setZoomFilter(dateRange, Page.people, ChartType.PeopleActivity),
      },
      dispatch
    )
)(PeopleActivity);
