import React, { FC, ReactNode } from 'react';
import { differenceInDays } from 'date-fns';
import {
  FormattedMessage,
  MessageKeys,
  useFormattedNumber,
} from '@insights/i18n-nwe';
import { Value, PreviousPeriod } from './styles';

export interface Props {
  previousValue: number;
  startDate: Date;
  endDate: Date;
  testID?: string;
  label?: (value: number) => ReactNode;
  ariaLabel: (value: number) => string;
}

const labelDefault = (value: number): ReactNode =>
  useFormattedNumber(value, { format: 'oneDec' });

export const CardFooter: FC<Props> = ({
  previousValue,
  startDate,
  endDate,
  testID,
  label = labelDefault,
  ariaLabel,
}) => {
  const totalDays = differenceInDays(endDate, startDate);

  return (
    <div data-testid={testID} tabIndex={0} aria-label={ariaLabel(totalDays)}>
      <Value id={`${testID}-value`}>{label(previousValue)}</Value>&nbsp;
      <PreviousPeriod id={`${testID}-previousPeriod`}>
        <FormattedMessage
          id={MessageKeys.kpiPreviousDays}
          values={{ totalDays }}
        />
      </PreviousPeriod>
    </div>
  );
};
