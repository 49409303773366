import React, { FC } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { H4, primary } from '@phoenix/all';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

interface Props {
  messageKey?: MessageKeys;
}

const HourGrainTrigger: FC<Props> = ({ messageKey }) => (
  <Trigger>
    <H4 className={triggerText}>
      <FormattedMessage id={messageKey} />
    </H4>
    <DownCaratIcon
      data-html2canvas-ignore
      key="arrow"
      width={16}
      height={16}
      color={primary.blue()}
      css="margin-left: 4px;"
    />
  </Trigger>
);

export default HourGrainTrigger;

const Trigger = styled.div`
  display: flex;
  align-items: center;
`;

export const triggerText = css`
  color: ${primary.blue()};
  text-transform: lowercase;
`;
