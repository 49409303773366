import { getType } from 'typesafe-actions';
import { subDays, startOfDay, endOfDay, addDays } from 'date-fns';
import { createSelector } from 'reselect';
import { DateRangeType } from '@insights/constants-nwe';
import { DateRange } from '@insights/models-nwe';

import Actions from '../../actions';
import { setDateRange } from '../../actions/app';

export type State = Readonly<{
  startDate: Date;
  endDate: Date;
  type: DateRangeType;
}>;

const now = new Date();

export const INITIAL_STATE: State = {
  type: DateRangeType.during,
  endDate: endOfDay(addDays(now, 15)),
  startDate: startOfDay(subDays(now, 60)),
};

export default (state: State = INITIAL_STATE, action: Actions): State => {
  switch (action.type) {
    case getType(setDateRange): {
      const { startDate, endDate, type } = action.payload;
      return { startDate, endDate, type };
    }
    default:
      return state;
  }
};

export const getStartDate = (state: State): Date => state.startDate;
export const getEndDate = (state: State): Date => state.endDate;

export const getDateRange = createSelector(
  [getStartDate, getEndDate],
  (start: Date, end: Date): DateRange => ({ start, end })
);

export const getDateRangeWithType = (state: State) => state;
