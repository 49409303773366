import { css } from 'emotion';

const cellText = css`
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  width: 143px;
  height: 16px;
  vertical-align: bottom;
`;

export const textLeft = css`
  ${cellText} text-align: left;
  color: #21262f;
  border-left: 1px solid #b4b4b4;
  padding-left: 15px;
`;

export const textRight = css`
  ${cellText} text-align: right;
  color: #999999;
  padding-right: 15px;
`;
