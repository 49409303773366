import { getType } from 'typesafe-actions';

import Actions from '../../actions';
import {
  notifySplitLoading,
  notifySplitReady,
  notifySplitTimeout,
} from '../../actions/app';

export type State = number;

export const INITIAL_STATE: State = 0;

export default (state: State = INITIAL_STATE, action: Actions): State => {
  switch (action.type) {
    case getType(notifySplitLoading):
      return state + 1;
    case getType(notifySplitReady):
    case getType(notifySplitTimeout):
      return state > 0 ? state - 1 : 0;
    default:
      return state;
  }
};

export const isLoading = (state: State): boolean => state > 0;
