import React, { FC, useCallback } from 'react';
import { Group } from '@vx/group';
import { Bar } from '@vx/shape';
import { LegendColors } from '@insights/constants-nwe';
import { ScaleLinear } from 'd3-scale';
import { primary } from '@phoenix/all';
import { HoverState, TeamCapacity } from '@insights/models-nwe';

import CapacityLine from '../capacity-line';
import CapacityCircle from '../capacity-circle';

export interface Props {
  xScale: ScaleLinear<number, number>;
  team: TeamCapacity;
  availableHours: number;
  scheduledHours: number;
  height: number;
  top: number | undefined;
  hoverState: HoverState;
  onMouseOver(): void;
  onMouseOut(): void;
  handleTooltip(e: React.MouseEvent<any>, team: TeamCapacity): void;
  hideTooltip(): void;
}

const CapacityBarbell: FC<Props> = ({
  xScale,
  team,
  availableHours,
  scheduledHours,
  height,
  top,
  hoverState,
  onMouseOver,
  onMouseOut,
  handleTooltip,
  hideTooltip,
}) => {
  const capacity = scheduledHours - availableHours;
  const isActive = hoverState === HoverState.active;
  const isInactive = hoverState === HoverState.inactive;

  const onMouseMove = useCallback(
    (e: React.MouseEvent<DOMRect>) => {
      handleTooltip(e, team);
    },
    [team]
  );

  const onMouseLeave = (): void => {
    hideTooltip();
    onMouseOut();
  };

  return (
    <Group
      top={top}
      onMouseOver={onMouseOver}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      data-testid="capacity-barbell"
    >
      <Bar
        height={height}
        width={xScale.range()[1]}
        opacity={0.1}
        fill={isActive ? primary.blue() : undefined}
      />
      <CapacityLine
        height={height}
        scheduledHours={scheduledHours}
        availableHours={availableHours}
        xScale={xScale}
        color={LegendColors.gray}
        opacity={isInactive ? 0.5 : 1}
      />
      <CapacityCircle
        capacity={capacity}
        scheduledHours={scheduledHours}
        xScale={xScale}
        lineHeight={height}
        color={isInactive ? LegendColors.blue100 : LegendColors.blue}
        opacity={isInactive ? 0.5 : 1}
      />
    </Group>
  );
};

export default CapacityBarbell;
