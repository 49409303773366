import React, { createContext, FC, useContext } from 'react';
import { CustomerPreferencesState } from './models';
import {
  DEFAULT_STATE,
  useCustomerPreferencesQuery,
} from './use-customer-preferences-query';

export const CustomerPreferencesContext = createContext<
  CustomerPreferencesState
>(DEFAULT_STATE);

export interface Props {
  fields: string[];
}

export const CustomerPreferencesProvider: FC<Props> = ({
  children,
  fields,
}) => {
  const state = useCustomerPreferencesQuery(fields);

  return (
    <CustomerPreferencesContext.Provider value={state}>
      {children}
    </CustomerPreferencesContext.Provider>
  );
};

export const useCustomerPreferences = (): CustomerPreferencesState => {
  return useContext(CustomerPreferencesContext);
};
