import React, { FC, CSSProperties } from 'react';
import { TruncateText } from '@insights/chart-parts-nwe';
import { useFormattedNumber } from '@insights/i18n-nwe';

import { calcLengthOfString } from '@insights/utils-nwe';
import { LINE_HEIGHT, projectInfo } from './inline-styles';

const MIN_PARENT_WIDTH = calcLengthOfString('ABCD', projectInfo); // Just gets the number of pixels for four characters.
const MIN_PARENT_HEIGHT = 30;

export interface Props {
  projectName: string;
  hours: number;
  fontColor: string;
  width: number;
  height: number;
  x?: number;
  y?: number;
  labelStyle?: CSSProperties;
}

const checkLineHeight = (style: CSSProperties) => {
  if (typeof style.lineHeight === 'number') return style.lineHeight;
  if (typeof style.lineHeight === 'string')
    return parseInt(style.lineHeight, 10);
  return LINE_HEIGHT;
};

const ProjectInfo: FC<Props> = ({
  projectName,
  hours,
  fontColor,
  width,
  height,
  x = 4,
  y = 12,
  labelStyle = projectInfo,
}) => {
  if (width <= MIN_PARENT_WIDTH || height < MIN_PARENT_HEIGHT) return null;
  const formattedNumber = useFormattedNumber(hours, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <TruncateText
        width={width}
        labelStyle={labelStyle}
        x={x}
        y={y}
        fill={fontColor}
        data-testid="truncated-project-name"
      >
        {projectName}
      </TruncateText>
      <TruncateText
        width={width}
        labelStyle={labelStyle}
        x={x}
        y={y + checkLineHeight(labelStyle)}
        fill={fontColor}
        data-testid="planned-time"
      >
        {formattedNumber}
      </TruncateText>
    </>
  );
};

export default ProjectInfo;
