import { FilterOption } from '@insights/models-nwe';

export const SELECT_ALL_FILTER_OPTION: FilterOption = {
  value: 'select-all-option',
  label: 'Select All',
};

export const includeSelectAllOption = (
  options: FilterOption[]
): FilterOption[] => {
  if (options.length === 0) return [];

  return [SELECT_ALL_FILTER_OPTION, ...options];
};
