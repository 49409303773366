import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import styled from 'react-emotion';
import { FilterType, CustomFormOperator, Filter } from '@insights/models-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { logError } from '@insights/utils-nwe';
import { useSearch } from '../../hooks/useSearch';
import {
  Header,
  Popover,
  SearchInput,
  MultiSelectContainer,
  FilterInProgress,
  ComboBoxContainer,
} from '../../components';
import { useFieldOptionValuesQuery } from './use-field-option-values-query';
import { FieldOption } from '../custom-form-field-options';
import { DisplayType } from '../../constants/field-properties';
import { useComboBox, useMultiSelect } from '../../hooks';
import { comboBoxWidth } from '../../constants/combo-box';

export const LIMIT = 50;

export interface Props {
  onAddFilter(d: Filter): void;
  toggleFilter(): void;
}

export const determineOperator = (displayType: string): CustomFormOperator => {
  return displayType === DisplayType.checkbox
    ? CustomFormOperator.allof
    : CustomFormOperator.in;
};

export const CustomFieldMultiSelect: FC<Props> = ({
  onAddFilter,
  toggleFilter,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name: fieldName, displayType } = location.state;
  const { data, loading, error } = useFieldOptionValuesQuery(fieldName);
  const { searchText, updateSearchText, filteredOptions } = useSearch('', data);
  const filteredAndLimitOptions = filteredOptions.slice(0, LIMIT);
  const { options, selectedOptions, onSelectOption } = useMultiSelect(
    filteredAndLimitOptions
  );

  const {
    ariaComboboxProps,
    ariaInputProps,
    ariaMenuProps,
    ariaOptionProps,
  } = useComboBox(options, onSelectOption);

  const operator = determineOperator(displayType);

  const onApply = (): void => {
    if (fieldName) {
      onAddFilter({
        filterType: FilterType.Custom,
        column: fieldName,
        operator,
        values: selectedOptions,
      });
      toggleFilter();
    } else {
      logError(
        'A fieldName was not provided to the Custom Field Multi Select picker'
      );
    }
  };

  return (
    <>
      <FilterInProgress
        column={fieldName}
        operator={operator}
        values={selectedOptions}
      />
      <ComboBoxContainer ariaComboboxProps={ariaComboboxProps}>
        <SearchInput
          value={searchText}
          onChange={updateSearchText}
          searchInputLabel={
            <FormattedMessage
              id={MessageKeys.searchFilterOptionsByFilterType}
              values={{
                filterColumn: fieldName,
              }}
            />
          }
          ariaInputProps={ariaInputProps}
        />
        <Popover>
          <InnerPopoverContainer>
            <Header onBack={() => navigate(-1)} header={fieldName} />
            <MultiSelectContainer
              options={options}
              loading={loading}
              error={!!error}
              selectedOptions={selectedOptions}
              onApply={onApply}
              ariaMenuProps={ariaMenuProps}
              ariaOptionProps={ariaOptionProps}
            />
          </InnerPopoverContainer>
        </Popover>
      </ComboBoxContainer>
    </>
  );
};

const InnerPopoverContainer = styled.div`
  width: ${comboBoxWidth};
`;
