export enum FilterRoutes {
  default = '/',
  standard = '/standard',
  customForm = '/custom',
  customFields = '/custom/fields',
  date = '/custom/fields/date',
  text = '/custom/fields/text',
  number = '/custom/fields/number',
  options = '/custom/fields/options',
  user = '/custom/fields/user',
  unsupported = '/custom/fields/unsupported',
}
