import React, { FC } from 'react';
import styled from 'react-emotion';
import { Omit } from 'utility-types';
import { ParentSize } from '@vx/responsive';

import { HourGrain } from '@insights/constants-nwe';

import Chart, { PropsWithDefaults as ChartProps } from './chart';
import { BurndownFragment } from './queries/get-burndown';
import TaskHourTable from './task-hour-table';
import { getTaskHourData } from './services/burndown';
import { accessors } from './accessors';

export const CHART_MARGINS = { top: 21, left: 150, bottom: 38, right: 29 };

export type Props = Omit<ChartProps, 'data'> & {
  hourGrain: HourGrain;
  burndownData: BurndownFragment[];
};

const ConnectedChart: FC<Props> = ({
  burndownData,
  selectedDate,
  project,
  hourGrain,
  ...rest
}) => {
  return (
    <>
      <ParentSizeStyled>
        {({ width }: { width: number; height: number }) => {
          return (
            <Chart
              {...rest}
              {...accessors[hourGrain]}
              hourGrain={hourGrain}
              data={burndownData}
              project={project}
              width={width}
              selectedDate={selectedDate}
            />
          );
        }}
      </ParentSizeStyled>
      {burndownData.length > 0 && (
        <TaskHourTableWrapper margin={CHART_MARGINS}>
          <TaskHourTable
            hourGrain={hourGrain}
            data={
              selectedDate &&
              getTaskHourData(burndownData, selectedDate, hourGrain)
            }
          />
        </TaskHourTableWrapper>
      )}
    </>
  );
};

// @TODO: Update @vx/responsive package to use parentSizeStyles prop for override instead of !important
const ParentSizeStyled = styled(ParentSize)`
  height: 235px !important;
`;

const TaskHourTableWrapper = styled('div')(
  (props: { margin: Record<'top' | 'left' | 'right' | 'bottom', number> }) => ({
    marginLeft: `${props.margin.left}px`,
    marginRight: `${props.margin.right}px`,
    marginTop: '10px',
  })
);

export default ConnectedChart;
