import React, { FC } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { MessageKeys } from '../message-keys';
import { formats } from './constants/formats';
import { TEMP_MESSAGE_OVERRIDES } from '../message-overrides';
import { useFetchMessages, Messages } from '../hooks/use-fetch-messages';

export interface Props {
  messageKeys?: string[];
  locale?: string;
  messages?: Messages; // this is used mainly for testing to provide default messages
}

export const IntlProvider: FC<Props> = ({
  messageKeys = Object.values(MessageKeys),
  locale = window.navigator.language,
  messages = TEMP_MESSAGE_OVERRIDES as Messages,
  children,
}) => {
  const { done, messages: fetchedMessages } = useFetchMessages(
    messageKeys,
    messages
  );

  return (
    <MessageProvider locale={locale} messages={fetchedMessages}>
      {done ? children : null}
    </MessageProvider>
  );
};

const MessageProvider: FC<Pick<Props, 'locale' | 'messages'>> = ({
  locale = window.navigator.language,
  messages,
  children,
}) => {
  return (
    <ReactIntlProvider
      messages={messages}
      locale={locale}
      formats={formats}
      children={children}
    />
  );
};
