import React, { FC } from 'react';
import { useIntl, FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import { HourGrain } from '@insights/constants-nwe';
import { ChartA11y } from '@insights/shared-components-nwe';
import { DateRange } from '@insights/models-nwe';
import { BurndownFragment } from '../../../queries/get-burndown';

export interface Props {
  burndownData?: BurndownFragment[];
  projectName?: string;
  hourGrain: HourGrain;
  dateRange: DateRange;
}

const BurndownA11yCharts: FC<Props> = ({
  burndownData = [],
  projectName,
  hourGrain,
  dateRange,
}) => {
  const { formatDate, formatNumber } = useIntl();

  const burndownTasksHeaders = [
    {
      key: 'currentdate' as const,
      label: <FormattedMessage id={MessageKeys.date} />,
      isRowHeader: true,
    },
    {
      key: 'taskaddeddaily' as const,
      label: <FormattedMessage id={MessageKeys.tasksAddedDaily} />,
    },
    {
      key: 'taskcompleteddaily' as const,
      label: <FormattedMessage id={MessageKeys.plannedHoursRemaining} />,
    },
  ];

  const burndownPlannedHoursHeaders = [
    {
      key: 'currentdate' as const,
      label: <FormattedMessage id={MessageKeys.date} />,
      isRowHeader: true,
    },
    {
      key: 'plannedHoursDaily' as const,
      label: <FormattedMessage id={MessageKeys.plannedHoursAdded} />,
    },
    {
      key: 'completedPlannedHours' as const,
      label: <FormattedMessage id={MessageKeys.plannedHoursCompleted} />,
    },
    {
      key: 'hoursRemaining' as const,
      label: <FormattedMessage id={MessageKeys.plannedHoursRemaining} />,
    },
    {
      key: 'projectcond' as const,
      label: <FormattedMessage id={MessageKeys.condition} />,
    },
    {
      key: 'conditionlabel' as const,
      label: <FormattedMessage id={MessageKeys.customCondition} />,
    },
  ];

  const burndownDurationHeaders = [
    {
      key: 'currentdate' as const,
      label: <FormattedMessage id={MessageKeys.date} />,
      isRowHeader: true,
    },
    {
      key: 'plannedDurationDaysDaily' as const,
      label: <FormattedMessage id={MessageKeys.durationAdded} />,
    },
    {
      key: 'completedDurationDays' as const,
      label: <FormattedMessage id={MessageKeys.durationCompleted} />,
    },
    {
      key: 'durationRemaining' as const,
      label: <FormattedMessage id={MessageKeys.remainingDuration} />,
    },
    {
      key: 'projectcond' as const,
      label: <FormattedMessage id={MessageKeys.condition} />,
    },
    {
      key: 'conditionlabel' as const,
      label: <FormattedMessage id={MessageKeys.customCondition} />,
    },
  ];

  const getDatum = <
    T extends { [key: string]: string | Date | null | undefined | number }
  >(
    row: T,
    key: string
  ): string | null | undefined => {
    const value = row[key];
    if (value instanceof Date) {
      return formatDate(value, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    } else if (typeof value === 'number') {
      return formatNumber(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    }

    return value;
  };

  return (
    <>
      <ChartA11y
        data={burndownData}
        headers={burndownTasksHeaders}
        caption={
          <FormattedMessage
            id={MessageKeys.taskBurndownA11yCaption}
            values={{
              projectName,
              startDate: dateRange.start,
              endDate: dateRange.end,
            }}
          />
        }
        getDatum={getDatum}
        testID="task-burndown"
      />
      {hourGrain === HourGrain.plannedDuration ? (
        <ChartA11y
          data={burndownData}
          headers={burndownDurationHeaders}
          caption={
            <FormattedMessage
              id={MessageKeys.durationBurndownA11yCaption}
              values={{
                projectName,
                startDate: dateRange.start,
                endDate: dateRange.end,
              }}
            />
          }
          noDataMessage={<FormattedMessage id={MessageKeys.notAvailable} />}
          getDatum={getDatum}
          testID="duration"
        />
      ) : (
        <ChartA11y
          data={burndownData}
          headers={burndownPlannedHoursHeaders}
          caption={
            <FormattedMessage
              id={MessageKeys.plannedHoursBurndownA11yCaption}
              values={{
                projectName,
                startDate: dateRange.start,
                endDate: dateRange.end,
              }}
            />
          }
          getDatum={getDatum}
          testID="planned-hours"
        />
      )}
    </>
  );
};

export default BurndownA11yCharts;
