import React, { FC } from 'react';
import { ScaleBand } from 'd3-scale';
import { GridRows as VxGridRows } from '@vx/grid';

export interface Props {
  width: number;
  scale: ScaleBand<string>;
}

const GridRows: FC<Props> = (props) => (
  <VxGridRows stroke="#EBEBEB" strokeDasharray="4 1" {...props} />
);

export default GridRows;
