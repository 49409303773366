import React, { FC } from 'react';
import { Text } from '@phoenix/all';

import LegendItem from '../legend-item';
import Dot from './dot';

export interface Props {
  color: string;
  label: React.ReactNode;
  testID?: string;
}

const LegendDotTypeItem: FC<Props> = ({ color, label, testID }) => (
  <LegendItem>
    <Dot color={color} data-testid="dot" />
    <Text.Small data-testid={testID}>{label}</Text.Small>
  </LegendItem>
);

export default LegendDotTypeItem;
