import React, { FC } from 'react';
import { Text } from '@vx/text';
import { useFormattedNumber } from '@insights/i18n-nwe';
import { MouseEventProps } from '../models';

export interface Props {
  offset: number;
  scale: number;
  capacity: number;
  textAnchor: string;
  lineHeight: number;
  opacity: number;
}

const CapacityText: FC<Props & MouseEventProps> = ({
  capacity,
  scale,
  offset,
  textAnchor,
  lineHeight,
  opacity,
  onMouseMove,
  onMouseLeave,
}) => {
  const capacityFormatted = useFormattedNumber(capacity, {
    maximumFractionDigits: 2,
  });

  return (
    <Text
      y={lineHeight - 12}
      x={scale}
      dx={offset}
      verticalAnchor="end"
      textAnchor={textAnchor}
      fill="#222730"
      opacity={opacity}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {`${capacity > 0 ? `+` : ``}${capacityFormatted}`}
    </Text>
  );
};

export default CapacityText;
