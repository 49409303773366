import { differenceInHours } from 'date-fns';
import { DateRange } from '@insights/models-nwe';

export const shouldZoom = (
  start: number,
  end: number,
  zoomRange?: DateRange
) => {
  const zoom = end - start >= 10 || end - start <= -10;
  const dateCheck =
    zoomRange === undefined
      ? true
      : differenceInHours(zoomRange.end, zoomRange.start) >= 3;

  return zoom && dateCheck;
};
