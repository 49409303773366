/* eslint-disable no-restricted-syntax */
import React, { FC, memo, useCallback } from 'react';
import { Axis } from '@vx/axis';
import { ScaleTime, ScaleBand } from 'd3-scale';
import { timeDay, timeMonth } from 'd3-time';
import { differenceInDays } from 'date-fns';
import { Orientation } from '@insights/constants-nwe';
import { useIntl } from '@insights/i18n-nwe';

import * as styles from '../styles';

const AXIS_HEIGHT = 20;

export interface Props {
  scale: ScaleTime<number, number> | ScaleBand<Date>;
  orientation?: Orientation;
  top?: number;
  left?: number;
}

export const getDateRange = (
  scale: ScaleTime<number, number> | ScaleBand<Date>
): [Date, Date] => {
  const domain = scale.domain();

  const start = domain[0];
  const end = domain[domain.length - 1];

  return [start, end];
};

export const calculateDayInterval = (start: Date, end: Date): number => {
  const numDays = differenceInDays(end, start);
  if (numDays < 31) {
    return 3;
  }
  if (numDays >= 31 && numDays < 124) {
    return 12;
  }
  return 31;
};

const DateAxis: FC<Props> = (props) => {
  const [start, end] = getDateRange(props.scale);
  const dayInterval = timeDay.every(calculateDayInterval(start, end));
  const monthInterval = timeMonth.every(1);
  const intl = useIntl();
  const getNumericDay = useCallback(
    (date: Date) => intl.formatDate(date, { day: 'numeric' }),
    [intl]
  );
  const getShortMonth = useCallback(
    (date: Date) => intl.formatDate(date, { month: 'short' }),
    [intl]
  );
  return (
    <>
      <Axis
        hideTicks
        tickLabelProps={() => ({
          textAnchor: 'middle',
          className: styles.tickLabel,
          fill: '#82878E',
          fontSize: 12,
        })}
        stroke="#B4B4B4"
        strokeWidth={2}
        tickValues={dayInterval ? dayInterval.range(start, end) : undefined}
        tickFormat={getNumericDay}
        {...props}
      />
      <Axis
        hideTicks
        tickLabelProps={() => ({
          textAnchor: 'middle',
          className: styles.tickLabel,
          fill: '#82878E',
          fontSize: 12,
        })}
        strokeWidth={0}
        tickValues={monthInterval ? monthInterval.range(start, end) : undefined}
        tickFormat={getShortMonth}
        {...props}
        top={props.top ? props.top + AXIS_HEIGHT : AXIS_HEIGHT}
      />
    </>
  );
};

DateAxis.defaultProps = {
  orientation: Orientation.bottom,
};

export default memo(DateAxis);
