export enum MessageKeys {
  A_Z = 'analytics.A_Z',
  activeFilter = 'analytics.activeFilter',
  activityCaption = 'analytics.activity.a11y.caption',
  activityHeaderLogins = 'analytics.activity.header.logins',
  activityHeaderStatusChanges = 'analytics.activity.header.statusChanges',
  activityHeaderTasksCompleted = 'analytics.activity.header.tasksCompleted',
  activityLoginText = 'analytics.activityLoginText',
  activityTasksCompleted = 'analytics.activityTasksCompleted',
  activityTasksChanges = 'analytics.activityTasksChanges',
  activityType = 'analytics.activityType',
  actualCapacity = 'analytics.actualCapacity',
  actualCompletionDate = 'actualcompletiondate',
  actualStartDate = 'actualstartdate',
  alphabetically = 'analytics.alphabetically',
  analyticsNoAccess = 'analytics.error.insufficientRights',
  and = 'and',
  applyFilter = 'analytics.apply.filter',
  atCapacity = 'analytics.atCapacity',
  availableHours = 'analytics.hours.available.plural',
  back = 'action.back',
  balance = 'analytics.balance',
  between = 'analytics.between',
  burndown = 'analytics.burndown',
  burndownActualVelocity = 'analytics.burndownActualVelocity',
  burndownBy = 'analytics.burndownBy',
  burndownExportPng = 'analytics.burndown.export.png',
  burndownExportXlsx = 'analytics.burndown.export.xlsx',
  burndownHourTableAdded = 'analytics.burndownHourTable.added',
  burndownHourTableCompleted = 'analytics.burndownHourTable.completed',
  burndownHourTableHoursHeader = 'analytics.burndownHourTable.header.hours',
  burndownHourTableTasksHeader = 'analytics.burndownHourTable.header.tasks',
  burndownHourTableTotal = 'analytics.burndownHourTable.total',
  burndownHourTableZeroState = 'analytics.zeroState.burndownHourTable',
  averageHours = 'analytics.averageHours',
  burndownInfoTooltipOne = 'analytics.burndownInfoTooltipOne',
  burndownInfoTooltipTwo = 'analytics.burndownInfoTooltipTwo',
  burndownInfoTooltipThree = 'analytics.burndownInfoTooltipThree',
  burndownInfoTooltipFour = 'analytics.burndownInfoTooltipFour',
  burndownInfoTooltipFive = 'analytics.burndownInfoTooltipFive',
  burndownPlannedVelocity = 'analytics.burndownPlannedVelocity',
  burndownProjectLink = 'analytics.burndownProjectLink',
  burnout = 'analytics.burnout',
  calendarFilter = 'analytics.calendarFilter',
  calendarSelector = 'analytics.calendarSelector',
  capacity = 'capacity',
  capacityTeam = 'analytics.capacityTeam',
  capacityAmount = 'analytics.capacityAmount',
  capacityPercentage = 'analytics.capacityPercentage',
  capacityType = 'analytics.capacityType',
  completedHours = 'analytics.completedHours',
  completed = 'completed',
  notcompleted = 'notcompleted',
  condition = 'analytics.condition',
  conditionChangeTimeStamp = 'analytics.conditionChangeTimeStamp',
  completedDurationHours = 'analytics.completedDurationHours',
  completedPlannedHoursDaily = 'analytics.completedPlannedHoursDaily',
  completedPlannedMinutesDaily = 'analytics.completedPlannedMinutesDaily',
  completedPlannedDurationDaily = 'analytics.completedPlannedDurationDaily',
  completedPlannedDurationMinutesDaily = 'analytics.completedPlannedDurationMinutesDaily',
  completedPlannedHours = 'analytics.completedPlannedHours',
  completionDate = 'analytics.completionDate',
  conditionLabel = 'analytics.condition',
  contains = 'analytics.contains',
  currentProjectConditions = 'analytics.currentProjectConditions',
  currentProjectConditionsCaption = 'analytics.currentProjectConditionsCaption',
  customCondition = 'analytics.customCondition',
  customDateField = 'analytics.customDateField',
  customerDurationPreferenceError = 'analytics.customerDurationPreferenceError',
  customForm = 'analytics.customForm',
  customForms = 'customforms',
  customFormNumberFieldInputLabel = 'analytics.customFormNumberFieldInputLabel',
  customFormTextFieldInputLabel = 'analytics.customFormTextFieldInputLabel',
  date = 'date',
  dateRangeReadable = 'analytics.dateRangeReadable',
  daysWorked = 'analytics.daysWorked',
  days = 'day.plural',
  doesNotEqual = 'analytics.doesNotEqual',
  due = 'duedate.abbr',
  durationAdded = 'analytics.durationAdded',
  durationBurndownA11yCaption = 'analytics.burndownA11yDuration',
  durationCompleted = 'analytics.durationCompleted',
  durationDays = 'analytics.durationDays',
  enterText = 'analytics.enterText',
  enterNumber = 'analytics.enterNumber',
  error = 'analytics.error',
  export = 'export',
  exportPng = 'analytics.export.png',
  exportXlsx = 'analytics.export.xlsx',
  equals = 'analytics.equals',
  feedback = 'feedback',
  filterAdd = 'analytics.filterAdd',
  filterRemoveAriaLabel = 'analytics.filterRemoveAriaLabel',
  filterHoverText = 'analytics.filterHoverText',
  filtering = 'analytics.filtering',
  filteringThreeCharLimit = 'analytics.filtering.threeCharLimit',
  filtersLength = 'analytics.filtersLength',
  filterUnsupportedError = 'analytics.filterUnsupportedError',
  flightPlan = 'analytics.flightPlan',
  flightPlanExportPng = 'analytics.flightPlan.export.png',
  flightPlanExportXlsx = 'analytics.flightPlan.export.xlsx',
  flightPlanInfoTooltipOne = 'analytics.flightPlanInfoTooltipOne',
  flightPlanInfoTooltipTwo = 'analytics.flightPlanInfoTooltipTwo',
  flightPlanLegendPlan = 'analytics.flightPlanLegendPlan',
  flightPlanPlannedTimeframe = 'analytics.flightPlanPlannedTimeframe',
  gotit = 'gotit',
  header = 'analytics.header',
  historicalProjectConditions = 'analytics.historicalProjectConditions',
  hours = 'hour.plural',
  hoursCompleted = 'analytics.hoursCompleted',
  ID = 'id',
  kpiCharts = 'analytics.kpiCharts',
  kpiAriaLabelAverageProjectDurationBodyDays = 'analytics.kpi.ariaLabel.averageProjectDuration.bodyDays',
  kpiAriaLabelAverageProjectDurationBodyWeeks = 'analytics.kpi.ariaLabel.averageProjectDuration.bodyWeeks',
  kpiAriaLabelAverageProjectDurationBodyYears = 'analytics.kpi.ariaLabel.averageProjectDuration.bodyYears',
  kpiAriaLabelAverageProjectDurationFooterDays = 'analytics.kpi.ariaLabel.averageProjectDuration.footerDays',
  kpiAriaLabelAverageProjectDurationFooterWeeks = 'analytics.kpi.ariaLabel.averageProjectDuration.footerWeeks',
  kpiAriaLabelAverageProjectDurationFooterYears = 'analytics.kpi.ariaLabel.averageProjectDuration.footerYears',
  kpiAriaLabelAverageProjectDurationHeader = 'analytics.kpi.ariaLabel.averageProjectDuration.header',
  kpiAriaLabelAverageProjectDurationBody = 'analytics.kpi.ariaLabel.averageProjectDuration.body',
  kpiAriaLabelAverageProjectDurationBodyChange = 'analytics.kpi.ariaLabel.averageProjectDuration.bodyChange',
  kpiAriaLabelAverageProjectDurationFooter = 'analytics.kpi.ariaLabel.averageProjectDuration.footer',
  kpiAriaLabelAverageTasksPerProjectHeader = 'analytics.kpi.ariaLabel.AverageTasksPerProject.header',
  kpiAriaLabelAverageTasksPerProjectBody = 'analytics.kpi.ariaLabel.averageTasksPerProject.body',
  kpiAriaLabelAverageTasksPerProjectBodyChange = 'analytics.kpi.ariaLabel.averageTasksPerProject.bodyChange',
  kpiAriaLabelAverageTasksPerProjectFooter = 'analytics.kpi.ariaLabel.averageTaskPerProject.footer',
  kpiAriaLabelProjectsCompletedBody = 'analytics.kpi.ariaLabel.ProjectsCompleted.body',
  kpiAriaLabelProjectsCompletedBodyChange = 'analytics.kpi.ariaLabel.projectsCompleted.bodyChange',
  kpiAriaLabelProjectsCompletedFooter = 'analytics.kpi.ariaLabel.projectsCompleted.footer',
  kpiAriaLabelProjectsCompletedOnTimeBody = 'analytics.kpi.ariaLabel.projectsCompletedOnTim.fody',
  kpiAriaLabelProjectsCompletedOnTimeBodyChange = 'analytics.kpi.ariaLabel.projectsCompletedOnTime.bodyChange',
  kpiAriaLabelProjectsCompletedOnTimeFooter = 'analytics.kpi.ariaLabel.projectsCompletedOnTime.footer',
  kpiAverageProjectDuration = 'analytics.kpi.averageProjectDuration',
  kpiAverageProjectDurationDays = 'analytics.kpi.averageProjectDurationDays',
  kpiAverageProjectDurationWeeks = 'analytics.kpi.averageProjectDurationWeeks',
  kpiAverageProjectDurationYears = 'analytics.kpi.averageProjectDurationYears',
  kpiAverageProjectDurationInfo1 = 'analytics.kpi.averageProjectDuration.info1',
  kpiAverageProjectDurationInfo2 = 'analytics.kpi.averageProjectDuration.info2',
  kpiAverageTasksPerProject = 'analytics.kpi.averageTasksPerProject',
  kpiAverageTasksPerProjectInfo1 = 'analytics.kpi.averageTasksPerProject.info1',
  kpiAverageTasksPerProjectInfo2 = 'analytics.kpi.averageTasksPerProject.info2',
  kpiPreviousDays = 'analytics.kpi.previousDays',
  kpiProjectsCompleted = 'analytics.kpi.projectsCompleted',
  kpiProjectsCompletedInfo1 = 'analytics.kpi.projectsCompleted.info1',
  kpiProjectsCompletedInfo2 = 'analytics.kpi.projectsCompleted.info2',
  kpiProjectsCompletedOnTime = 'analytics.kpi.projectsCompletedOnTime',
  kpiProjectsCompletedOnTimeInfo1 = 'analytics.kpi.projectsCompletedOnTime.info1',
  kpiProjectsCompletedOnTimeInfo2 = 'analytics.kpi.projectsCompletedOnTime.info2',
  learnMore = 'analytics.learnMore',
  loading = 'analytics.loading',
  name = 'name',
  ninetyTwoDayMax = 'analytics.zeroState.ninetyTwoDayMax',
  noActivity = 'analytics.zeroState.noActivity',
  noCapacity = 'analytics.noCapacity',
  noDateText = 'analytics.noDateText',
  noInfo = 'analytics.zeroState.noInfo',
  noTasksInfo = 'analytics.zeroState.noTasksInfo',
  noTasks = 'analytics.zeroState.noTasks',
  none = 'none',
  noName = 'analytics.noName',
  notAvailable = 'notavailable',
  notRetrieved = 'analytics.zeroState.notRetrieved',
  people = 'people',
  overCapcity = 'analytics.overCapacity',
  paginationGoToPageNumber = 'analytics.paginationGoToPageNumber',
  paginationNextPage = 'analytics.paginationNextPage',
  paginationPageNumber = 'analytics.paginationPageNumber',
  paginationCurrentPageOfTotal = 'analytics.paginationCurrentPageOfTotal',
  paginationPreviousPage = 'analytics.paginationPreviousPage',
  paginationText = 'analytics.paginationText',
  paginationWheelText = 'analytics.paginationWheelText',
  peopleActivity = 'analytics.peopleActivity',
  peopleActivityCaption = 'analytics.peopleActivityCaption',
  peopleActivityExportPng = 'analytics.peopleActivity.export.png',
  peopleActivityExportXlsx = 'analytics.peopleActivity.export.xlsx',
  percentComplete = 'percentcomplete',
  plannedCapacity = 'analytics.plannedCapacity',
  plannedDuration = 'analytics.plannedDuration',
  plannedCompletionDate = 'plannedcompletiondate',
  plannedDurationDaily = 'analytics.plannedDurationDaily',
  plannedDurationDays = 'analytics.plannedDurationDays',
  plannedDurationMinutes = 'planneddurationminutes',
  plannedDurationMinutesDaily = 'analytics.plannedDurationMinutesDaily',
  plannedHours = 'analytics.plannedHours',
  plannedHoursAdded = 'analytics.plannedHoursAdded',
  plannedHoursBurndownA11yCaption = 'analytics.burndownA11yHours',
  plannedHoursCompleted = 'analytics.plannedHoursCompleted',
  plannedHoursDaily = 'analytics.plannedHoursDaily',
  plannedHoursPlural = 'plannedhour.plural',
  plannedHoursRemaining = 'analytics.plannedHoursRemaining',
  plannedMinutes = 'analytics.plannedMinutes',
  plannedMinutesDaily = 'analytics.plannedMinutesDaily',
  plannedStartDate = 'plannedstartdate',
  portfolio = 'portfolio',
  progress = 'progress',
  program = 'program',
  project = 'project',
  projectActivity = 'analytics.projectActivity',
  projectActivityCaption = 'analytics.projectActivityCaption',
  projectActivityExportPng = 'analytics.projectActivity.export.png',
  projectActivityExportXlsx = 'analytics.projectActivity.export.xlsx',
  plannedTimeframe = 'analytics.plannedTimeframe',
  projectCondition = 'analytics.projectCondition',
  projectConditionAtRisk = 'analytics.projectConditionAtRisk',
  projectConditionInTrouble = 'analytics.projectConditionInTrouble',
  projectConditionOnTarget = 'analytics.projectConditionOnTarget',
  projectCount = 'analytics.projectCount',
  projectActivityInfoTooltipOne = 'analytics.projectActivityInfoTooltipOne',
  projectActivityInfoTooltipTwo = 'analytics.projectActivityInfoTooltipTwo',
  projectID = 'projectid',
  projectMagnitudeExportXlsx = 'analytics.projectMagnitude.export.xlsx',
  projectMagnitudeHeader = 'analytics.projectMagnitudeHeader',
  projectMagnitudeTableCaption = 'analytics.projectMagnitude.a11y.caption',
  projectMagnitudesInfoTooltipOne = 'analytics.projectMagnitudesInfoTooltipOne',
  projectMagnitudesInfoTooltipTwo = 'analytics.projectMagnitudesInfoTooltipTwo',
  projectName = 'projectname',
  projectNames = 'analytics.projectNames',
  projectowner = 'analytics.projectowner',
  projectPagination = 'analytics.projectPagination',
  projectTreemap = 'analytics.projectTreemap',
  projectTreemapExportPng = 'analytics.projectTreemap.export.png',
  remainingDuration = 'analytics.remainingDuration',
  remainingDurationMinutes = 'remainingdurationminutes',
  remainingHours = 'analytics.remainingHours',
  remainingMinutes = 'analytics.remainingMinutes',
  resourceCapacity = 'analytics.resourceCapacity',
  resourceCapacityCaption = 'analytics.resourceCapacity.a11y.caption',
  resourceCapacityExportPng = 'analytics.resourceCapacity.export.png',
  resourceCapacityExportXlsx = 'analytics.resourceCapacity.export.xlsx',
  resourceCapacityLowercase = 'analytics.resourceCapacity.lowercase',
  resourceCapacityInfoTooltipOne = 'analytics.resourceCapacityInfoTooltipOne',
  resourceCapacityInfoTooltipTwo = 'analytics.resourceCapacityInfoTooltipTwo',
  resourceCapacityToolTipAvailableCapacity = 'analytics.resourceCapacity.tooltip.availableCapacity',
  resourceCapacityTooltipHours = 'analytics.resourceCapacity.tooltip.hoursLabel',
  resourceCapacityTooltipOverCapacity = 'analytics.resourceCapacity.tooltip.overCapacity',
  resourceCapacityToolTipPlannedCapacity = 'analytics.resourceCapacity.tooltip.plannedCapacity',
  resourceCapacityTooltipUnderCapacity = 'analytics.resourceCapacity.tooltip.underCapacity',
  schedulingError = 'analytics.schedulingError',
  scheduledHours = 'scheduledhours',
  search = 'search',
  searchAvailableCustomFormOptions = 'analytics.searchAvailableCustomFormOptions',
  searchAvailableCustomFieldOptions = 'analytics.searchAvailableCustomFieldOptions',
  searchAvailableFilterOptions = 'analytics.searchAvailableFilterOptions',
  searchFilterOptionsByFilterType = 'analytics.searchFilterOptionsByFilterType',
  selectAll = 'action.selectall',
  sortBy = 'sortby',
  sortByActualEndDate = 'analytics.actualEndDate',
  sortByActualStartDate = 'analytics.actualStartDate',
  sortByPlannedCompletionDate = 'analytics.plannedCompletionDate',
  sortByPlannedStartDate = 'analytics.plannedStartDate',
  sponsor = 'sponsor',
  status = 'status',
  taskBurndownA11yCaption = 'analytics.burndownA11yTask',
  taskNames = 'analytics.taskNames',
  tasksAddedDaily = 'analytics.tasksAddedDaily',
  tasksCompletedDaily = 'analytics.tasksCompletedDaily',
  tasksInFlightCaption = 'analytics.tasksInFlightCaption',
  tasksInFlightHeader = 'analytics.tasksInFlightHeader',
  tasksInFlightTooltipHeader = 'analytics.tasksInFlight.tooltip.header',
  tasksInFlightTooltipBody1 = 'analytics.tasksInFlight.tooltip.body1',
  tasksInFlightTooltipBody2 = 'analytics.tasksInFlight.tooltip.body2',
  tasksInFlightTooltipBody3 = 'analytics.tasksInFlight.tooltip.body3',
  tasksInFlightExportPng = 'analytics.tasksInFlight.export.png',
  tasksInFlightExportXlsx = 'analytics.tasksInFlight.export.xlsx',
  tasksInFlightRemainingTasksCount = 'analytics.tasksInFlight.remainingTasksCount',
  teamActivityInfoTooltipOne = 'analytics.teamActivityInfoTooltipOne',
  teamActivityInfoTooltipTwo = 'analytics.teamActivityInfoTooltipTwo',
  teamCapacity = 'analytics.teamCapacity',
  teamCapacityCaption = 'analytics.teamCapacity.a11y.caption',
  teamCapacityExportPng = 'analytics.teamCapacity.export.png',
  teamCapacityExportXlsx = 'analytics.teamCapacity.export.xlsx',
  teamCapacityInfoTooltipOne = 'analytics.teamCapacityInfoTooltipOne',
  teamCapacityInfoTooltipTwo = 'analytics.teamCapacityInfoTooltipTwo',
  teamActivity = 'analytics.teamActivity',
  TeamCapacity = 'analytics.zeroState.teamCapacity',
  TeamFilter = 'analytics.zeroState.teamFilter',
  teamID = 'teamid',
  teamName = 'team.name',
  teamNames = 'analytics.teamNames',
  team = 'team',
  teams = 'teams',
  timeframe = 'analytics.timeframe',
  today = 'today',
  tooltip = 'analytics.tooltip',
  tooltipButtonDescriptionActivityByTeam = 'analytics.tooltipButtonDescriptionActivityByTeam',
  tooltipButtonDescriptionAverageProjectDuration = 'analytics.tooltipButtonDescriptionAverageProjectDuration',
  tooltipButtonDescriptionAverageTasksPerProject = 'analytics.tooltipButtonDescriptionAverageTasksPerProject',
  tooltipButtonDescriptionBurndown = 'analytics.tooltipButtonDescriptionBurndown',
  tooltipButtonDescriptionFilters = 'analytics.tooltipButtonDescriptionFilters',
  tooltipButtonDescriptionFlightPlan = 'analytics.tooltipButtonDescriptionFlightPlan',
  tooltipButtonDescriptionProjectActivity = 'analytics.tooltipButtonDescriptionProjectActivity',
  tooltipButtonDescriptionProjectsCompleted = 'analytics.tooltipButtonDescriptionProjectsCompleted',
  tooltipButtonDescriptionProjectsCompletedOnTime = 'analytics.tooltipButtonDescriptionProjectsCompletedOnTime',
  tooltipButtonDescriptionProjectTreemap = 'analytics.tooltipButtonDescriptionProjectTreemap',
  tooltipButtonDescriptionResourceCapacity = 'analytics.tooltipButtonDescriptionResourceCapacity',
  tooltipButtonDescriptionTasksInFlight = 'analytics.tooltipButtonDescriptionTasksInFlight',
  tooltipButtonDescriptionTeamCapacity = 'analytics.tooltipButtonDescriptionTeamCapacity',
  total = 'total',
  totalProjectCount = 'analytics.totalProjectCount',
  underCapacity = 'analytics.underCapacity',
  unchallenged = 'analytics.unchallenged',
  values = 'reporting.values',
  velocity = 'analytics.velocity',
  wbs = 'wbs',
  work = 'work',
  workBreakdownStucture = 'analytics.workBreakdownStucture',
  workRequired = 'analytics.workRequired',
  Z_A = 'analytics.Z_A',
  ZeroFilters = 'analytics.zeroState.zeroFilters',
  ZeroMessage = 'analytics.zeroState.zeroMessage',
}
