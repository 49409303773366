import { useIntl, FormatDateOptions } from 'react-intl';

export function useFormattedDate(
  date: Date,
  options?: FormatDateOptions
): string {
  const intl = useIntl();

  return intl.formatDate(date, options);
}
