import { css } from 'emotion';

export const root = css`
  align-items: center;
  display: flex;
  line-height: 15px;
  margin: 4px 0 3px 0;
`;

export const conditionDot = (props: { conditionColor: string }) => css`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props.conditionColor};
  line-height: 20px;
`;

export const conditionName = css`
  margin-left: 8px;
  font-size: 12px;
  line-height: 12px;
`;
