import React, { FC, ReactElement } from 'react';

import { Collapse, DelayUnmount } from '@insights/shared-components-nwe';

export interface Props {
  children: ReactElement<any> | ReactElement<any>[];
  projectId?: string;
}

export const ANIMATION_DURATION = 500;

export const CollapsableContainer: FC<Props> = ({ children, projectId }) => {
  const expand = projectId != null;
  return (
    <Collapse expanded={expand} maxHeight={735} duration={ANIMATION_DURATION}>
      <DelayUnmount isMounted={expand} duration={ANIMATION_DURATION}>
        {children}
      </DelayUnmount>
    </Collapse>
  );
};
