import React, { FC, ReactText, ReactElement } from 'react';

interface Props {
  value: ReactText | ReactElement;
  ariaLabel: string;
  onClick(): void;
  ariaDescribedBy?: string;
  className?: string;
  pageSelected?: boolean;
  disabled?: boolean;
  testID?: string;
}

export const PaginationButton: FC<Props> = ({
  value,
  ariaLabel,
  onClick,
  ariaDescribedBy,
  className,
  pageSelected,
  disabled,
  testID,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={className}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
      aria-current={pageSelected}
      disabled={disabled}
      data-testid={testID}
    >
      {value}
    </button>
  );
};
