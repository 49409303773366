import {
  Filter,
  isGraphQLFilter,
  ProjectsFilterInput,
  FilterType,
  GraphQLFilter,
} from '@insights/models-nwe';
import { GraphqlFilterKey } from '@insights/constants-nwe';

export function graphQLShape(filters: Filter[]): ProjectsFilterInput {
  return filters.reduce<ProjectsFilterInput>((acc, filter: GraphQLFilter) => {
    if (isGraphQLFilter(filter) && filter.values.length > 0) {
      if (filter.filterType === FilterType.Custom) {
        // suppressing for now until we have filters hooked up
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // because it won't allow an enum as an index sig
        acc[GraphqlFilterKey.customfields] =
          acc[GraphqlFilterKey.customfields] || [];

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        acc[GraphqlFilterKey.customfields].push({
          name: filter.column,
          operator: filter.operator,
          value: filter.values.map((value) => value.value),
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        acc[filter.column] = {
          operator: filter.operator,
          value: filter.values.map((value) => value.value),
        };
      }
    }

    return acc;
  }, {});
}
