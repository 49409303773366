import React, { FC, ReactNode } from 'react';
import { useFormattedNumber, MessageKeys } from '@insights/i18n-nwe';
import { DateRange } from '@insights/models-nwe';
import { ChangeArrow } from './change-arrow';
import { CardBodyContainer, DataPoint } from './styles';

export interface Props {
  value: number;
  previousValue: number;
  testID?: string;
  label?: (value: number) => ReactNode;
  bodyAriaLabel: string;
  changeAriaLabel: MessageKeys;
  dateRange: DateRange;
}

const labelDefault = (value: number): ReactNode => {
  return useFormattedNumber(value, { format: 'oneDec' });
};

export const CardBody: FC<Props> = ({
  value,
  previousValue,
  testID,
  label = labelDefault,
  bodyAriaLabel,
  changeAriaLabel,
  dateRange,
}) => {
  return (
    <CardBodyContainer data-testid={testID}>
      <DataPoint tabIndex={0} aria-label={bodyAriaLabel}>
        {label(value)}
      </DataPoint>
      <ChangeArrow
        ariaLabel={changeAriaLabel}
        currentValue={value}
        previousValue={previousValue}
        dateRange={dateRange}
      />
    </CardBodyContainer>
  );
};
