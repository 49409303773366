import { css } from 'emotion';
import { primary } from '@phoenix/all';

export const tooltipBox = css`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  color: ${primary.gray(900)};
  display: flex;
  flex-direction: column;
  font-family: 'Proxima Nova';
  padding: 16px;
  min-width: 256px;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.9);
`;

export const condition = css`
  display: flex;
  align-items: center;
  margin-top: 1px;
  font-size: 12px;
  line-height: 15px;
`;

export const display = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
`;
