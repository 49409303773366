import React, { FC, useState, useMemo } from 'react';
import { Group } from '@vx/group';
import { Treemap } from '@vx/hierarchy';
import { treemapSquarify, HierarchyNode } from 'd3-hierarchy';
import { ScaleQuantile } from 'd3-scale';
import { localPoint } from '@vx/event';
import { withTooltip } from '@vx/tooltip';
import {
  DateRange,
  ProjectMagnitudeChartDatum,
  TooltipProps,
} from '@insights/models-nwe';
import { HourGrain, ChartType } from '@insights/constants-nwe';
import { ChartMargins } from '@insights/styles-nwe';
import { getReadableFontColor } from '@insights/utils-nwe';
import { ZeroState } from '@insights/shared-components-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';

import { projectMagnitudeScale } from './chart-parts/ProjectMagnitudeScale';
import * as styles from './styles';
import ProjectInfo from './chart-parts/project-info/index';
import ProjectMagnitudeTooltip from './chart-parts/tooltip';

import { getStrokeColor, getRenderOrder, treeify } from '../../services';

export interface OwnProps {
  data: ProjectMagnitudeChartDatum[];
  width?: number;
  height?: number;
  margin?: Record<'top' | 'left' | 'right' | 'bottom', number>;
  boxColors?: Record<'color1' | 'color2', string>;
  dateRange: DateRange;
  hourGrain: HourGrain;
  selectedProject?: string;
  getCompleted?(d: ProjectMagnitudeChartDatum): number;
  getGuid?(d: ProjectMagnitudeChartDatum): string;
  getCondition?(d: ProjectMagnitudeChartDatum): string;
  getProjectName?(d: ProjectMagnitudeChartDatum): string;
  onProjectClick(guid: string, name: string): void;
  scale?: ScaleQuantile<string>;
}

export type Props = OwnProps &
  TooltipProps<{ data?: ProjectMagnitudeChartDatum }>;

const ProjectMagnitudeChart: FC<Props> = ({
  data,
  width = 956,
  height = 302,
  margin = ChartMargins,
  getGuid = (d) => d.projectguid,
  getCompleted = (d) => d.completedplannedhours,
  getProjectName = (d) => d.name,
  tooltipData,
  tooltipOpen,
  hideTooltip,
  tooltipLeft,
  tooltipTop,
  showTooltip,
  dateRange,
  hourGrain,
  onProjectClick,
  selectedProject,
  scale,
}) => {
  if (!data || data.length === 0)
    return (
      <ZeroState testID="project-magnitude-chart-">
        <FormattedMessage id={MessageKeys.ZeroMessage} />
      </ZeroState>
    );

  const [hoveredProjectId, setHoveredProjectId] = useState<string | undefined>(
    undefined
  );

  const yMax = height - margin.top - margin.bottom;

  const root = treeify(data, getGuid, getCompleted);

  const handleOnMouseMove = (
    event: React.MouseEvent,
    d: ProjectMagnitudeChartDatum
  ) => {
    const { x, y } = localPoint(event);
    setHoveredProjectId(getGuid(d));

    showTooltip({
      tooltipData: { data: d },
      tooltipLeft: x,
      tooltipTop: y,
    });
  };

  const handleMouseLeave = () => setHoveredProjectId(undefined);

  const getClassName = (projectGuid: string) => {
    if (!hoveredProjectId) return styles.initialStyle;

    if (projectGuid === hoveredProjectId || projectGuid === selectedProject)
      return styles.initialStyle;
    return styles.opaqueStyle;
  };

  const colorScale = useMemo(() => {
    return projectMagnitudeScale(data, hourGrain);
  }, [data]);

  return (
    <>
      <svg width={width} height={height} id={ChartType.ProjectTreemap}>
        <rect width={width} height={height} rx={14} fill="transparent" />{' '}
        <Treemap
          root={root}
          size={[width, yMax]}
          tile={treemapSquarify}
          round={false}
        >
          {(
            treemap: HierarchyNode<HierarchyNode<ProjectMagnitudeChartDatum>>
          ) => {
            const rawNodes = treemap.descendants();
            const nodes = getRenderOrder(rawNodes, selectedProject);
            return (
              <Group data-testid="project-magnitude-overlay" top={margin.top}>
                {nodes.map((n: any) => {
                  const rectWidth = n.x1 - n.x0;
                  const rectHeight = n.y1 - n.y0;

                  if (Number.isNaN(rectWidth) || Number.isNaN(rectHeight)) {
                    return null;
                  }

                  const fill = scale ? scale(n.value) : colorScale(n.value);
                  const className = getClassName(getGuid(n.data.data));
                  const projectName = getProjectName(n.data.data);
                  return (
                    <Group
                      data-testid={`${projectName} tile`}
                      key={getGuid(n.data.data)}
                      top={n.y0}
                      left={n.x0}
                      onMouseMove={(event: React.MouseEvent) =>
                        handleOnMouseMove(event, n.data.data)
                      }
                      onMouseOut={hideTooltip}
                    >
                      {n.depth === 1 && n.value > 0 && (
                        <>
                          <rect
                            onMouseOut={handleMouseLeave}
                            width={rectWidth}
                            height={rectHeight}
                            stroke={getStrokeColor(
                              getGuid(n.data.data),
                              selectedProject,
                              hoveredProjectId
                            )}
                            strokeWidth={2}
                            fill={fill}
                            onClick={() =>
                              onProjectClick(
                                getGuid(n.data.data),
                                getProjectName(n.data.data)
                              )
                            }
                            className={className}
                          />

                          <ProjectInfo
                            projectName={projectName}
                            hours={getCompleted(n.data.data)}
                            width={rectWidth}
                            height={rectHeight}
                            fontColor={getReadableFontColor(fill)}
                          />
                        </>
                      )}
                    </Group>
                  );
                })}
              </Group>
            );
          }}
        </Treemap>
      </svg>
      {tooltipOpen && tooltipData.data && (
        <ProjectMagnitudeTooltip
          project={tooltipData.data}
          dateRange={dateRange}
          hourGrain={hourGrain}
          top={tooltipTop}
          left={tooltipLeft}
          offsetLeft={0}
        />
      )}
    </>
  );
};

export default withTooltip(ProjectMagnitudeChart);
