import React, { FC, ReactNode, useRef } from 'react';
import styled from 'react-emotion';
import { primary } from '@phoenix/all';
import { SpreadSheetData } from '@insights/models-nwe';
import { ChartType } from '@insights/constants-nwe';

import VisualExport from '../visual-export';

export interface Props {
  header: ReactNode;
  chart: JSX.Element | string;
  label: string;
  legend?: JSX.Element;
  disableExport: boolean;
  title: ChartType;
  getData?: () => SpreadSheetData;
}

const CardLayout: FC<Props> = ({
  header,
  chart,
  label,
  legend,
  title,
  getData,
  disableExport,
}) => {
  const ref = useRef(null);

  return (
    <Layout innerRef={ref} tabIndex={0} aria-label={label}>
      <Head>
        <Header>{header}</Header>
        <VisualExport
          getNode={() => ref.current}
          title={title}
          getData={getData}
          disableExport={disableExport}
        />
      </Head>
      <Chart>{chart}</Chart>
      <Legend>{legend}</Legend>
    </Layout>
  );
};

const columnGap = '20px';
const rowGap = '20px';

const Layout = styled.figure`
  margin: 1em 0px;
  padding: 0 0 16px 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-column-gap: ${columnGap};
  grid-row-gap: ${rowGap};
  grid-template-columns: minmax(0, 1fr) 144px;
  grid-template-areas:
    'head head'
    'chart legend';
`;

const Legend = styled.aside`
  grid-area: legend;
`;

const Head = styled.div`
  grid-area: head;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${primary.gray(200)};
  border-top: 1px solid ${primary.gray(200)};
  height: 48px;
  padding: 0 16px;
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  justify-content: left;
  align-items: center;
  width: calc(100% - 52px);
  overflow: hidden;
`;

const Chart = styled.section`
  width: 100%;
  grid-area: chart;
  padding-left: 20px;
`;

export default CardLayout;
