import React, { FC, SVGProps } from 'react';
import { Bar } from '@vx/shape';
import { Motion, spring } from 'react-motion';

export interface Props extends SVGProps<SVGRect> {
  startDate: Date;
  endDate: Date;
  dateScale(date: Date): number;
}

const GanttBar: FC<Props> = ({ startDate, endDate, dateScale, ...rest }) => {
  const x = dateScale(startDate);
  const width = Math.max(dateScale(endDate) - x, 0);

  return (
    <Motion
      defaultStyle={{ x, width }}
      style={{
        x: spring(x),
        width: spring(width),
      }}
    >
      {({ x: newX, width: newWidth }) => (
        <Bar {...rest} x={newX} width={newWidth} />
      )}
    </Motion>
  );
};

export default GanttBar;
