import { ChartType } from '@insights/constants-nwe';

import { ZOOM_PENDO_EVENT } from './constants';

export const trackEvent = (
  name: string,
  data?: { [key: string]: any }
): void => {
  const { pendo } = window;
  if (pendo && pendo.isReady && pendo.isReady()) return pendo.track(name, data);
  else return console.warn(`Pendo was not ready to track event: ${name}`, data);
};

export const trackZoom = (chart: ChartType): void => {
  trackEvent(ZOOM_PENDO_EVENT[chart]);
};
