import { Page } from '@insights/constants-nwe';
import {
  RootState,
  getFilters,
  getDateRange,
  connect,
  getZoomDateRange,
} from '@insights/store-nwe';
import KPIContainer from './KpiContainer';
import { KPICardProps } from './kpi-cards/models';

export default connect<KPICardProps, {}, KPICardProps>(
  (state: RootState): KPICardProps => ({
    filter: getFilters(state, Page.work),
    dateRange: getZoomDateRange(state, Page.work) || getDateRange(state),
  })
)(KPIContainer);
