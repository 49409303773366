import React, { FC } from 'react';
import { Text } from '@vx/text';
import { Tooltip } from '@insights/tooltip-nwe';
import { truncate } from '@insights/utils-nwe';

import { TickProps } from '../models';

const TooltipTick: FC<TickProps> = ({ formattedValue, ...rest }) => (
  <Tooltip wrapperTag="g" content={formattedValue}>
    <Text data-testid="chart-item" {...rest}>
      {truncate(formattedValue, 18, 0)}
    </Text>
  </Tooltip>
);

export default TooltipTick;
