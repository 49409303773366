import { createAction } from 'typesafe-actions';

export const RESULTS_LIMIT = 50;

export const setOffsetByPageNumber = createAction(
  'Pagination/SET_PAGE_OFFSET',
  (pageNumber: number): number => {
    return (pageNumber - 1) * RESULTS_LIMIT;
  }
)<number>();
