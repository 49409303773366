import React, { FC } from 'react';
import { TeamCapacityChartDatum } from '@insights/models-nwe';
import { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { formatDate } from '@insights/shared-components-nwe';
import gql from 'graphql-tag';

export const NO_DATA: TeamCapacityChartDatum = {
  capacityByTeamForDateRange: [],
  teams: [],
};

export const GET_CAPACITY_TEAMS = gql`
  query GetTeamCapacities(
    $teamIDs: [Guid!]!
    $queryStartDate: Date!
    $queryEndDate: Date!
  ) {
    teams(
      filter: { teamguid: { operator: in, value: $teamIDs } }
      orderBy: { key: name, direction: ASC }
    ) {
      name
      guid
    }

    capacityByTeamForDateRange(
      teamIDs: $teamIDs
      queryStartDate: $queryStartDate
      queryEndDate: $queryEndDate
    ) {
      guid
      scheduledHours
      availableHours
    }
  }
`;

export interface Props {
  teamIds: string[];
  startDate: Date;
  endDate: Date;
  children(result: {
    data: TeamCapacityChartDatum;
    loading: boolean;
    error: ApolloError | undefined;
  }): JSX.Element | null;
}

export interface Variables {
  teamIDs: string[];
  queryStartDate: string;
  queryEndDate: string;
}

export const GetTeamCapacities: FC<Props> = ({
  children,
  teamIds,
  startDate,
  endDate,
}) => {
  const hasNoTeams = teamIds.length === 0;
  const { data, loading, error } = useQuery<TeamCapacityChartDatum, Variables>(
    GET_CAPACITY_TEAMS,
    {
      variables: {
        teamIDs: teamIds,
        queryStartDate: formatDate(startDate),
        queryEndDate: formatDate(endDate),
      },
      skip: hasNoTeams,
    }
  );

  if (hasNoTeams)
    return children({ data: NO_DATA, loading: false, error: undefined });

  return children({ data: data ?? NO_DATA, loading, error });
};
