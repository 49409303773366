/* eslint-disable @typescript-eslint/camelcase */
import { wfetch, encodeAsFormData } from '@wf-mfe/api';
import { Project } from 'workfront-objcodes';
import { Filter } from '@insights/models-nwe';
import { useCancelableEffect } from '@insights/utils-nwe';
import { SortByFields, SortByDirection } from '@insights/constants-nwe';

import { ProjectState, ProjectsForDateRangeState } from '../models';
import { useProjectConditions } from '../../project-conditions';
import { getProjectsPage } from './getProjectsPage';
import { transformProjectFilters, processProjectData } from './utils';

type FilterBody = {
  [key: string]: string | number | Date | string[];
};

export const useProjectsForDateRangeQuery = (
  startDate: Date,
  endDate: Date,
  filters: Filter[],
  limit: number,
  offset: number,
  sortByField: SortByFields,
  sortByDirection: SortByDirection
): ProjectsForDateRangeState => {
  const {
    data,
    loading: projectConditionsLoading,
    error: projectConditionsError,
  } = useProjectConditions();

  const state = useCancelableEffect((): Promise<ProjectState> | null => {
    if (projectConditionsLoading) return null;
    if (projectConditionsError)
      return Promise.reject(
        new Error(
          `Error thrown from useProjectConditions dependency: ${projectConditionsError.message}`
        )
      );

    const filterBody: FilterBody = {
      name_Sort: 'asc',
      plannedCompletionDate: startDate,
      plannedCompletionDate_Mod: 'gte',
      plannedStartDate: endDate,
      plannedStartDate_Mod: 'lte',
      ...transformProjectFilters(filters, data),
    };

    return Promise.all([
      getProjectsPage(filterBody, limit, offset, sortByField, sortByDirection),
      getTotalProjectCount(filterBody),
    ]).then(([projects, { count: totalCount }]) => ({
      totalCount,
      projects: processProjectData(projects),
    }));
  }, [
    startDate,
    endDate,
    filters,
    limit,
    offset,
    sortByField,
    sortByDirection,
    data,
    projectConditionsLoading,
    projectConditionsError,
  ]);

  return state;
};

const getTotalProjectCount = (
  filterBody: FilterBody
): Promise<{ count: number }> =>
  wfetch(`/attask/api-internal/${Project}/count`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: encodeAsFormData({
      method: 'get',
      filters: filterBody,
    }),
  });
