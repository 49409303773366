import {
  RawGroupedActivity,
  GroupedActivity,
  RawActivity,
  Activity,
  MaxActivitiesByType,
  Project,
  MaxActivities,
  TopLevelGroupedActivity,
  TopLevelRawGroupedActivity,
} from '@insights/models-nwe';
import { ActivityType } from '@insights/constants-nwe';
import { parseDate } from '@insights/utils-nwe';

import { ProjectFragment } from '../../project-activity/queries/get-project-activity';

export const processActivity = (
  data: RawGroupedActivity['activities']
): GroupedActivity['activities'] => {
  return Object.values(ActivityType).reduce(
    (
      acc: GroupedActivity['activities'],
      a: ActivityType
    ): GroupedActivity['activities'] => {
      const parsedAct = data[a].map(
        (l: RawActivity): Activity => ({
          count: l.count,
          date: parseDate(l.date),
        })
      );
      acc[a] = parsedAct;
      return acc;
    },
    {} as GroupedActivity['activities']
  );
};

export function generateProjectMap<T extends Pick<Project, 'guid'>>(
  projects: T[]
): { [guid: string]: T } {
  return projects.reduce((map, obj): { [guid: string]: T } => {
    map[obj.guid] = obj; // eslint-disable-line no-param-reassign
    return map;
  }, {} as { [guid: string]: T });
}

export const processProjectActivity = (
  data: TopLevelRawGroupedActivity[],
  projects: ProjectFragment[] = []
): TopLevelGroupedActivity[] => {
  const projectMap = generateProjectMap(projects);

  return data
    .filter((group) => projectMap[group.guid])
    .map((group) => {
      const projectInfo = projectMap[group.guid];

      return {
        ...group,
        name: projectInfo && projectInfo.name,
        activities: processActivity(group.activities),
      };
    });
};

export function processGroupedActivities<T extends RawGroupedActivity>(
  data: T[]
) {
  return data.map((group) => ({
    ...group,
    activities: processActivity(group.activities),
  }));
}

export const mapMaxActivities = (data: MaxActivities): MaxActivitiesByType => ({
  [ActivityType.login]: data.MAX_LOGIN,
  [ActivityType.taskStatusChange]: data.MAX_TASKS_CHANGES,
  [ActivityType.tasksCompleted]: data.MAX_TASKS_COMPLETED,
});
