import React, { FC, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import styled from 'react-emotion';
import {
  FilterOption,
  Filter,
  isGraphQLFilter,
  FilterType,
} from '@insights/models-nwe';
import { MessageKeys, FormattedMessage } from '@insights/i18n-nwe';

import { useSearch } from '../../hooks/useSearch';
import { useComboBox } from '../../hooks/useComboBox';
import {
  Popover,
  SearchInput,
  Header,
  SingleSelect,
  ComboBoxContainer,
} from '../../components';
import { FilterRoutes } from '../../constants/filter-routes';
import { DataType, DisplayType } from '../../constants/field-properties';

import useCustomFieldsForFormQuery, {
  Option,
} from './use-custom-fields-for-forms-query';
import { comboBoxWidth } from '../../constants/combo-box';

export const LIMIT = 50;

export interface FormState {
  formId: string;
  label: string;
}

export interface Props {
  filters?: Filter[];
}

export type FieldOption = Option & FilterOption;
export type TextField = Option & { dataType: DataType.text };

export const CustomFormFieldOptions: FC<Props> = ({ filters = [] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, loading, error } = useCustomFieldsForFormQuery(
    location?.state?.formId ?? ''
  );
  const options: FieldOption[] = useMemo(
    () =>
      data
        ? generateFieldOptions(excludeFieldsAlreadySelected(data, filters))
        : [],
    [data, filters]
  );
  const { searchText, updateSearchText, filteredOptions } = useSearch(
    '',
    options
  );
  const filteredAndLimitOptions = filteredOptions.slice(0, LIMIT);

  const onClick = (option: FieldOption): void => {
    navigate(getRoute(option), { state: option });
  };

  const {
    ariaComboboxProps,
    ariaInputProps,
    ariaMenuProps,
    ariaOptionProps,
  } = useComboBox(filteredAndLimitOptions, onClick);

  return (
    <ComboBoxContainer ariaComboboxProps={ariaComboboxProps}>
      <SearchInput
        value={searchText}
        onChange={updateSearchText}
        searchInputLabel={
          <FormattedMessage
            id={MessageKeys.searchAvailableCustomFieldOptions}
          />
        }
        ariaInputProps={ariaInputProps}
      />
      <Popover>
        <Container>
          <Header onBack={() => navigate(-1)} header={location?.state?.label} />
          <OptionsContainer>
            <SingleSelect<FieldOption>
              options={filteredAndLimitOptions}
              loading={loading}
              error={!!error}
              ariaMenuProps={ariaMenuProps}
              ariaOptionProps={ariaOptionProps}
            />
          </OptionsContainer>
        </Container>
      </Popover>
    </ComboBoxContainer>
  );
};

const Container = styled.div`
  width: ${comboBoxWidth};
`;

const OptionsContainer = styled.div`
  height: auto;
  min-height: 32px;
  max-height: 193px;
  overflow-y: auto;
`;

const getRoute = (option: Option): FilterRoutes => {
  switch (option.dataType) {
    case DataType.date:
      return FilterRoutes.date;
    case DataType.number:
    case DataType.text:
      return getTextRoute(option as TextField);
    default:
      return FilterRoutes.unsupported;
  }
};

const getTextRoute = (option: TextField): FilterRoutes => {
  switch (option.displayType) {
    case DisplayType.checkbox:
    case DisplayType.radio:
    case DisplayType.select:
      return FilterRoutes.options;
    case DisplayType.typeahead:
      return FilterRoutes.unsupported;
    default:
      return DataType.text === option.dataType
        ? FilterRoutes.text
        : FilterRoutes.number;
  }
};

export const generateFieldOptions = (options: Option[]): FieldOption[] => {
  return options.map((d) => ({ ...d, label: d.name, value: d.name }));
};

export const excludeFieldsAlreadySelected = (
  options: Option[],
  filters: Filter[] = []
): Option[] => {
  const usedCustomFields = new Set(
    filters.reduce<string[]>((acc, filter) => {
      if (isGraphQLFilter(filter) && filter.filterType === FilterType.Custom) {
        acc.push(filter.column);
      }

      return acc;
    }, [])
  );

  return options.filter((option) => !usedCustomFields.has(option.name));
};
