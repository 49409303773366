import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { SummaryPanel } from './SummaryPanel';
import { ContentWrapper } from './styles';

interface SummaryPanelDetails {
  ID: string;
  objCode: string;
}

export interface SummaryPanelState {
  details?: SummaryPanelDetails;
  setSummaryDetails(details?: SummaryPanelDetails): void;
}

const SummaryPanelContext = createContext<SummaryPanelState>({
  setSummaryDetails: () => {},
});

export const SummaryPanelProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [details, setDetails] = useState<SummaryPanelDetails>();

  return (
    <SummaryPanelContext.Provider
      value={{ details, setSummaryDetails: setDetails }}
    >
      <ContentWrapper>{children}</ContentWrapper>
      <SummaryPanel details={details} setSummaryDetails={setDetails} />
    </SummaryPanelContext.Provider>
  );
};

export const useSummaryPanel = () => useContext(SummaryPanelContext);
