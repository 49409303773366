import React, { Component, SVGProps, createRef } from 'react';
import { Group } from '@vx/group';

import Overlay from '../overlay';

export interface Props extends SVGProps<SVGGElement> {
  scrollHeight: number;
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface State {
  top: number;
}

// Sonarqube requires the intentionally empty comment
const emptyArrow = () => {
  // intentionally empty
};

export default class ScrollYOverlay extends Component<Props, State> {
  state = { top: 0 };

  overlay = createRef<SVGGElement>();

  static defaultProps = {
    y: 0,
    x: 0,
  };

  componentDidMount() {
    // Safari's event triggering needs some help. Adding the window listener fixes the scrolling behavior.
    window.addEventListener('wheel', emptyArrow, { passive: false });
    // Reasoning for using traditional addEventListener - https://developers.google.com/web/updates/2019/02/scrolling-intervention
    // React's synthetic event does not support passive flag - https://github.com/facebook/react/issues/14856
    if (this.overlay.current !== null) {
      this.overlay.current.addEventListener(
        'wheel',
        (e) => this.handleWheel(e),
        {
          passive: false,
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', emptyArrow);
    if (this.overlay.current !== null) {
      this.overlay.current.removeEventListener('wheel', (e) =>
        this.handleWheel(e)
      );
    }
  }

  private getTop({ top: prevTop }: State, deltaY: number) {
    const maxScroll = this.getMaxScroll();
    const top = prevTop - deltaY;

    if (top > 0) return 0;
    else if (top < maxScroll) return maxScroll;
    else return top;
  }

  private getMaxScroll() {
    const { height, y, scrollHeight } = this.props;
    const yMax = height - y;

    return scrollHeight > yMax ? -(scrollHeight - yMax) : 0;
  }

  handleWheel = (e: WheelEvent) => {
    const { deltaY } = e;
    this.setState((prevState) => ({ top: this.getTop(prevState, deltaY) }));
    e.preventDefault();
  };

  render() {
    const {
      width,
      height,
      children,
      x,
      y,
      ref,
      scrollHeight,
      ...rest
    } = this.props;
    const { top } = this.state;

    return (
      <Overlay
        width={width}
        height={height}
        x={x}
        y={y}
        data-testid="overlay"
        ref={this.overlay}
        {...rest}
      >
        <Group top={top} data-testid="group">
          {children}
        </Group>
      </Overlay>
    );
  }
}
