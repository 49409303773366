import React, { FC } from 'react';
import { Circle } from '@vx/shape';
import CapacityText from '../text/index';
import { MouseEventProps } from '../models';

interface Props {
  capacity: number;
  scheduledHours: number;
  xScale(scheduledHours: number): number;
  lineHeight: number;
  color: string;
  opacity: number;
}

const CapacityCircle: FC<Props & MouseEventProps> = ({
  capacity,
  scheduledHours,
  xScale,
  lineHeight,
  color,
  opacity,
  onMouseMove,
  onMouseLeave,
}) => {
  const textProps =
    capacity < 0
      ? { offset: -10, textAnchor: 'end' }
      : { offset: 10, textAnchor: 'start' };
  return (
    <>
      <CapacityText
        {...textProps}
        scale={xScale(scheduledHours)}
        capacity={capacity}
        lineHeight={lineHeight}
        opacity={opacity}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <Circle
        className="dot"
        cx={xScale(scheduledHours)}
        cy={lineHeight / 2}
        r={4.5}
        fill={color}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    </>
  );
};

export default CapacityCircle;
