import React, { FC, memo } from 'react';
import { primary } from '@phoenix/all';
import { Group } from '@vx/group';
import { ScaleTime } from 'd3-scale';
import { isEqual } from 'date-fns';
import { Project } from '@insights/models-nwe';

import GanttBar from '../gantt-bar';
import StartMarker from './start-marker';
import EndMarker from './end-marker';

const PLANNED_LINE_HEIGHT = 2;

type ProjectFragment = Pick<
  Project,
  'plannedcompletiondate' | 'plannedstartdate'
> & { [key: string]: unknown };

export interface Props extends React.SVGProps<SVGRect> {
  project: ProjectFragment;
  dateScale: ScaleTime<number, number>;
  height: number;
  top: number;
}

const PlannedTimeline: FC<Props> = ({
  project,
  dateScale,
  height,
  ...rest
}) => {
  if (
    !project.plannedstartdate ||
    !project.plannedcompletiondate ||
    isEqual(project.plannedstartdate, project.plannedcompletiondate)
  )
    return null;
  return (
    <Group {...rest} data-testid="planned-timeline">
      <GanttBar
        height={PLANNED_LINE_HEIGHT}
        fill={primary.blue()}
        startDate={project.plannedstartdate}
        endDate={project.plannedcompletiondate}
        dateScale={dateScale}
      />
      <StartMarker
        fill={primary.blue()}
        startDate={project.plannedstartdate}
        dateScale={dateScale}
        height={height}
      />
      <EndMarker
        fill={primary.blue()}
        endDate={project.plannedcompletiondate}
        dateScale={dateScale}
        height={height}
      />
    </Group>
  );
};

export default memo(PlannedTimeline);
