import { AnyAction } from 'redux';

export function createFilteredReducer<State, Action = AnyAction>(
  reducerFunction: (state: State | undefined, action: Action) => State,
  reducerPredicate: (action: Action) => boolean
): (state: State, action: Action) => State {
  return (state: State, action: Action): State => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer =
      reducerPredicate(action) || isInitializationCall;
    return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
  };
}
