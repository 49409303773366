import { ScaleQuantile } from 'd3-scale';
import { scaleQuantile } from '@vx/scale';
import { ProjectMagnitudeChartDatum } from '@insights/models-nwe';
import { staticPMColors, HourGrain } from '@insights/constants-nwe';

export const findMax = (
  dataArr: ProjectMagnitudeChartDatum[],
  hourGrain: HourGrain
) => {
  const x = dataArr.reduce((accum, d: ProjectMagnitudeChartDatum) => {
    if (hourGrain === HourGrain.plannedDuration) {
      if (d.completeddurationdays) {
        return [...accum, d.completeddurationdays];
      }
    } else {
      return [...accum, d.completedplannedhours];
    }
    return accum;
  }, []);

  return x.length === 0 ? 0 : Math.max(...x);
};

export const projectMagnitudeScale = (
  data: ProjectMagnitudeChartDatum[],
  hourGrain: HourGrain
): ScaleQuantile<string> => {
  return scaleQuantile({
    domain: [0, findMax(data, hourGrain)],
    range: staticPMColors,
  });
};
