import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { FilterType, CustomFormOperator, Filter } from '@insights/models-nwe';
import { MessageKeys, useIntl, FormattedMessage } from '@insights/i18n-nwe';
import { logError } from '@insights/utils-nwe';
import { CustomFieldInput } from '../custom-field-input';
import { FieldOption } from '../custom-form-field-options';

export interface Props {
  onAddFilter(d: Filter): void;
  toggleFilter(): void;
}

export const MINIMUM_SEARCH_LENGTH = 1;
export const TEST_ID_SEARCH_INPUT = 'custom-field-number-search-input';

export const CustomFieldNumberInput: FC<Props> = ({
  onAddFilter,
  toggleFilter,
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { name: fieldName } = location.state;
  const [value, updateSearch] = useState('');
  const disabledApplyButton = value.length < MINIMUM_SEARCH_LENGTH;
  const operator = CustomFormOperator.allof;

  const onApply = (): void => {
    if (fieldName) {
      onAddFilter({
        filterType: FilterType.Custom,
        column: fieldName,
        operator,
        values: [
          {
            label: value,
            value,
          },
        ],
      });
      toggleFilter();
    } else {
      logError('A fieldName was not provided to Custom Field Number Input');
    }
  };

  return (
    <CustomFieldInput
      fieldName={fieldName}
      value={value}
      onApply={onApply}
      onChange={updateSearch}
      operator={operator}
      disabledApplyButton={disabledApplyButton}
      placeholder={formatMessage({ id: MessageKeys.enterNumber })}
      inputType="number"
      testID={TEST_ID_SEARCH_INPUT}
      searchInputLabel={
        <FormattedMessage
          id={MessageKeys.customFormNumberFieldInputLabel}
          values={{ fieldName }}
        />
      }
    />
  );
};
