export const dateFormats = {
  shortDateDash: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  readable: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
};

export const numberFormats = {
  oneDec: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  },
  twoDec: {
    maximumFractionDigits: 2,
  },
  a11y: {
    minimumFranctionDigits: 0,
    maximumFractionDigits: 2,
  },
  percent: {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
    style: 'percent',
  },
};

export const formats = {
  date: dateFormats,
  number: numberFormats,
};
