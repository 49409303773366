import React, { FC } from 'react';
import styled from 'react-emotion';
import { Text } from '@phoenix/all';

export interface Props {
  testID?: string;
}

const ZeroState: FC<Props> = ({ children, testID }) => {
  return (
    <Root tabIndex={0} role="dialog" aria-live="off">
      <p>
        <MessageText data-testid={`${testID}zero-state-msg`}>
          {children}
        </MessageText>
      </p>
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 2;
  opacity: 0.8;
`;

const MessageText = styled(Text)`
  color: #21262f;
  font-weight: bold;
`;

export default ZeroState;
