export const MAX_PAGINATION_OPTIONS = 7;
export const TRUNCATION_THRESHOLD = 5;
export const TRUNCATION_DOTS = '...';

const generateOptionsArray = (
  start: number,
  totalElements: number
): number[] => {
  return Array.from(new Array(totalElements), (_, index) => start + index);
};

export const generatePaginationOptions = (
  selectedPage: number,
  totalPages: number
): (number | string)[] => {
  if (totalPages === 0) return [1];

  if (totalPages <= MAX_PAGINATION_OPTIONS)
    return generateOptionsArray(1, totalPages);

  if (selectedPage < TRUNCATION_THRESHOLD) {
    return [
      ...generateOptionsArray(1, TRUNCATION_THRESHOLD),
      TRUNCATION_DOTS,
      totalPages,
    ];
  } else if (selectedPage > totalPages - TRUNCATION_THRESHOLD + 1) {
    return [
      1,
      TRUNCATION_DOTS,
      ...generateOptionsArray(totalPages - 4, TRUNCATION_THRESHOLD),
    ];
  } else {
    return [
      1,
      TRUNCATION_DOTS,
      ...generateOptionsArray(selectedPage - 1, 3),
      TRUNCATION_DOTS,
      totalPages,
    ];
  }
};
