import React, { FC, ReactNode } from 'react';

import * as styles from './styles';

export interface Props {
  labelText: React.ReactNode;
  displayValue: ReactNode;
  testID?: string;
}

const DataItem: FC<Props> = ({ labelText, displayValue, testID }) => {
  return (
    <div className={styles.container} data-testid={testID}>
      <h4 className={styles.label}>{labelText}</h4>
      <span className={styles.display}>{displayValue}</span>
    </div>
  );
};

export default DataItem;
