import { MessageKeys } from '@insights/i18n-nwe';

export interface SortByOption {
  messageKey: MessageKeys;
  value: SortByFields;
  direction: SortByDirection;
}

export enum SortByDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum SortByFields {
  name = 'name',
  plannedStartDate = 'plannedStartDate',
  plannedCompletionDate = 'plannedCompletionDate',
  actualStartDate = 'actualStartDate',
  actualEndDate = 'actualCompletionDate',
}

export const sortByOptions: SortByOption[] = [
  {
    messageKey: MessageKeys.A_Z,
    value: SortByFields.name,
    direction: SortByDirection.asc,
  },
  {
    messageKey: MessageKeys.Z_A,
    value: SortByFields.name,
    direction: SortByDirection.desc,
  },
  {
    messageKey: MessageKeys.sortByPlannedStartDate,
    value: SortByFields.plannedStartDate,
    direction: SortByDirection.desc,
  },
  {
    messageKey: MessageKeys.sortByPlannedCompletionDate,
    value: SortByFields.plannedCompletionDate,
    direction: SortByDirection.desc,
  },
];
