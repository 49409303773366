import React, { FC, SVGProps, memo } from 'react';

import { ProjectHistoricalCondition } from '@insights/models-nwe';
import { conditionColorScale as colorScale } from '@insights/utils-nwe';

import GanttBar from '../gantt-bar';

type ConditionFragment = Pick<
  ProjectHistoricalCondition,
  'conditionchangetimestamp' | 'condition' | 'projectguid'
> & { [key: string]: unknown; conditionChangeEnd: Date };

export interface Props extends SVGProps<SVGRect> {
  conditions: ConditionFragment[];
  dateScale(date: Date): number;
  height: number;
  y: number;
}

const ConditionTimeline: FC<Props> = ({
  conditions,
  dateScale,
  ...rectProps
}) => (
  <>
    {conditions.map((condition, i) => {
      const {
        conditionchangetimestamp,
        conditionChangeEnd,
        projectguid,
      } = condition;
      if (!conditionchangetimestamp || !conditionChangeEnd) return null;
      return (
        <GanttBar
          {...rectProps}
          key={`${projectguid}-${conditionchangetimestamp}`}
          data-testid={`${projectguid}-${conditionchangetimestamp}`}
          dateScale={dateScale}
          startDate={conditionchangetimestamp}
          endDate={conditionChangeEnd}
          fill={colorScale(condition.condition)}
        />
      );
    })}
  </>
);

export default memo(ConditionTimeline);
