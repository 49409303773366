import React, { FC, SVGProps, memo } from 'react';
import { Text } from '@vx/text';
import { Orientation } from '@insights/constants-nwe';
import { useIntl, MessageKeys } from '@insights/i18n-nwe';

import * as styles from '../styles';

export interface Props {
  label: string | MessageKeys;
  // TODO: updated all other charts axis's to pass message keys
  orientation: Orientation;
  range: number[];
  labelProps?: SVGProps<SVGTextElement>;
  labelOffset?: number;
  tickLabelFontSize?: number;
  tickLength?: number;
}

export function labelTransform({
  labelOffset,
  labelProps,
  orientation,
  range,
  tickLabelFontSize,
  tickLength,
}: LabelTransformArgs) {
  const sign =
    orientation === Orientation.left || orientation === Orientation.top
      ? -1
      : 1;

  let x;
  let y;
  let transform = null;

  if (orientation === Orientation.top || orientation === Orientation.bottom) {
    x = (range[0] + range[range.length - 1]) / 2;
    y =
      sign *
      (tickLength +
        labelOffset +
        tickLabelFontSize +
        (orientation === Orientation.bottom ? Number(labelProps.fontSize) : 0));
  } else {
    x = sign * ((range[0] + range[range.length - 1]) / 2);
    y = -(tickLength + labelOffset);
    transform = `rotate(${sign * 90})`;
  }

  return { x, y, transform };
}

const AxisLabel: FC<Props> = ({
  label,
  orientation,
  range,
  labelProps = {
    fill: '#999',
    fontSize: 12,
    textAnchor: 'middle',
  },
  labelOffset = 14,
  tickLabelFontSize = 10,
  tickLength = 8,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Text
      className={styles.tickLabel}
      {...labelTransform({
        labelOffset,
        labelProps,
        range,
        orientation,
        tickLabelFontSize,
        tickLength,
      })}
      {...labelProps}
    >
      {formatMessage({ id: label })}
    </Text>
  );
};

export default memo(AxisLabel);

type LabelTransformArgs = Required<
  Pick<
    Props,
    | 'labelProps'
    | 'labelOffset'
    | 'orientation'
    | 'range'
    | 'tickLabelFontSize'
    | 'tickLength'
  >
>;
