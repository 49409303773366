export const saveAs = (uri: string, filename: string) => {
  const link = document.createElement('a');
  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;

    //  Firefox requires the link to be in the body
    document.body.appendChild(link);

    //  simulate click
    link.click();

    //  remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};
