import React, { FC, useMemo } from 'react';
import { scaleLinear } from '@vx/scale';
import uniqueId from 'lodash.uniqueid';
import {
  CardLayout,
  Collapse,
  DelayUnmount,
} from '@insights/shared-components-nwe';
import { ParentSize } from '@vx/responsive';
import { DateRange } from '@insights/models-nwe';
import { useIntl, MessageKeys, FormattedMessage } from '@insights/i18n-nwe';
import { ChartType } from '@insights/constants-nwe';
import { ChartMargins } from '@insights/styles-nwe';

import { GetTeamCapacity } from '../chart/query/getTeamCapacity';
import { TeamCapacityGradient } from '../chart/gradient';
import { shapeTeamCapacityDataForXLSX } from '../utils/team-capacity';
import TeamCapacityLegend from '../legend';
import TeamHeader from '../header';
import { TeamCapacityChartWithTable } from './chart-with-table';
import { getY, calculateYDomain } from '../utils/accessors';

const ANIMATION_DURATION = 500;
const chartHeight = 250;
const yMax = chartHeight - ChartMargins.top - ChartMargins.bottom;

export type PropsFromChart = Pick<Props, 'dateRange' | 'zoomRange'>;
export interface Props {
  teamId: string | null;
  teamName: string | null;
  dateRange: DateRange;
  onZoom: any;
  zoomRange: any;
}

const TeamCapacity: FC<Props> = ({
  teamId,
  teamName,
  dateRange,
  onZoom,
  zoomRange,
}) => {
  const { formatMessage } = useIntl();
  const gradientId = useMemo(() => uniqueId('team-capacity'), []);
  const expand = !!(teamId && teamName);

  return (
    <Collapse expanded={expand} maxHeight={350} duration={ANIMATION_DURATION}>
      <DelayUnmount isMounted={expand} duration={ANIMATION_DURATION}>
        {() => {
          return (
            <GetTeamCapacity
              teamId={teamId}
              startDate={dateRange.start}
              endDate={dateRange.end}
            >
              {({ data, loading, error }) => {
                if (loading)
                  return <FormattedMessage id={MessageKeys.loading} />;
                if (error) return <FormattedMessage id={MessageKeys.error} />;

                const headerTitle = formatMessage(
                  { id: MessageKeys.capacityTeam },
                  { teamName }
                );

                const yScale = scaleLinear({
                  range: [yMax, 0],
                  domain: calculateYDomain(data, getY),
                  clamp: true,
                });

                const gradientOffsetTop = yScale(1.75) / yMax;
                const gradientOffsetMiddle = yScale(1) / yMax;
                const gradientOffsetBottom = yScale(0.25) / yMax;

                const chart = () => {
                  return (
                    <ParentSize>
                      {({ width }: { width: number; height: number }) => (
                        <TeamCapacityChartWithTable
                          data={data}
                          width={width}
                          height={chartHeight}
                          yMax={yMax}
                          yScale={yScale}
                          gradientId={gradientId}
                          dateRange={dateRange}
                          teamName={teamName}
                          zoomRange={zoomRange}
                          onZoom={onZoom}
                        />
                      )}
                    </ParentSize>
                  );
                };

                const getData = () =>
                  shapeTeamCapacityDataForXLSX(
                    formatMessage(
                      { id: MessageKeys.teamCapacityExportXlsx },
                      {
                        teamName,
                        startDate: dateRange.start,
                        endDate: dateRange.end,
                      }
                    ),
                    {
                      date: formatMessage({ id: MessageKeys.date }),
                      availableHours: formatMessage({
                        id: MessageKeys.availableHours,
                      }),
                      scheduledHours: formatMessage({
                        id: MessageKeys.scheduledHours,
                      }),
                    },
                    data
                  );

                return (
                  <>
                    <CardLayout
                      title={ChartType.TeamCapacity}
                      header={<TeamHeader title={headerTitle} />}
                      label={headerTitle}
                      legend={
                        <TeamCapacityLegend
                          balanceLabelOffset={gradientOffsetMiddle}
                          legendHeight={yMax}
                          gradientId={gradientId}
                        />
                      }
                      chart={chart()}
                      getData={getData}
                      disableExport={!(data.length > 0) || loading || !!error}
                    />
                    <TeamCapacityGradient
                      gradientId={gradientId}
                      yMax={yMax}
                      gradientOffsetTop={gradientOffsetTop}
                      gradientOffsetMiddle={gradientOffsetMiddle}
                      gradientOffsetBottom={gradientOffsetBottom}
                    />
                  </>
                );
              }}
            </GetTeamCapacity>
          );
        }}
      </DelayUnmount>
    </Collapse>
  );
};

export default TeamCapacity;
