import React, { FC } from 'react';

import { MessageKeys, useIntl } from '@insights/i18n-nwe';
import { KPICardProps } from './kpi-cards/models';
import { AverageProjectDuration } from './kpi-cards/AvgProjectDuration';
import { AvgTasksPerProject } from './kpi-cards/AvgTasksPerProject';
import { ProjectsCompletedOnTime } from './kpi-cards/ProjectsCompletedOnTime';
import { ProjectsCompleted } from './kpi-cards/ProjectsCompleted';
import * as styles from './styles';

const KPIContainer: FC<KPICardProps> = ({ filter, dateRange }) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={styles.KPIWrapper}
      tabIndex={0}
      aria-label={formatMessage({ id: MessageKeys.kpiCharts })}
    >
      <ProjectsCompleted filter={filter} dateRange={dateRange} />
      <ProjectsCompletedOnTime filter={filter} dateRange={dateRange} />
      <AverageProjectDuration filter={filter} dateRange={dateRange} />
      <AvgTasksPerProject filter={filter} dateRange={dateRange} />
    </div>
  );
};

export default KPIContainer;
