import React, {
  createContext,
  useContext,
  FC,
  useEffect,
  useReducer,
} from 'react';
import { useCurrentUser } from '@wf-mfe/auth';
import { Action as AnyAction } from 'redux';
import { CurrentUser } from '@insights/models-nwe';
import { Page } from '@insights/constants-nwe';
import { Subtract } from 'utility-types';
import { getStorageUtil } from '@workfront/storage';
import rootReducer, { RootState } from './reducers';
import configureStore from './configure-store';
import { ZOOM_COLUMN } from './constants';

type Action = AnyAction | Function;
type Dispatch = (action: Action) => {};

const initialState = configureStore().getState();

const storageUtil = getStorageUtil();

export const StoreContext = createContext<{
  state: RootState;
  dispatch: React.Dispatch<AnyAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export function connect<
  StateProps extends object,
  DispatchProps extends object,
  BaseProps extends StateProps & DispatchProps
>(
  mapStateToProps: (
    state: RootState,
    props: Subtract<BaseProps, StateProps & DispatchProps>
  ) => StateProps,
  mapDispatchToProps?: (
    handleThunk: Dispatch,
    props: Subtract<BaseProps, StateProps & DispatchProps>
  ) => DispatchProps
): (
  _BaseComponent: React.ComponentType<BaseProps>
) => React.ComponentType<Subtract<BaseProps, StateProps & DispatchProps>> {
  type OwnProps = Subtract<BaseProps, StateProps & DispatchProps>;

  return function generateComponent(
    _BaseComponent: React.ComponentType<BaseProps>
  ): React.ComponentType<OwnProps> {
    const BaseComponent = _BaseComponent as React.ComponentType<
      StateProps & DispatchProps
    >;

    const ConnectedComponent: FC<OwnProps> = (componentProps) => {
      const { state, dispatch } = useContext(StoreContext);

      const getState = () => {
        return state;
      };

      const handleThunk = (action: Action) => {
        if (typeof action === 'function') {
          return action(dispatch, getState);
        }
        return dispatch(action);
      };

      const stateToProps = mapStateToProps(state, componentProps);
      const dispatchToProps =
        mapDispatchToProps && mapDispatchToProps(handleThunk, componentProps);
      const props = { ...componentProps, ...stateToProps, ...dispatchToProps };

      return <BaseComponent {...props} />;
    };
    return ConnectedComponent;
  };
}

function useLocalStorageReducer<State extends {}>(
  key: string,
  rootReducerFunction: React.Reducer<State, AnyAction>,
  initialStateObject: State,
  hydrate: (stateInLocalStorage: State) => State
): [State, React.Dispatch<Action>] {
  const [state, dispatch] = useReducer(
    rootReducerFunction,
    initialStateObject,
    (initialStateArg: State): State => {
      let initState = initialStateArg;
      const localStorageState: State | null = storageUtil.get(key);

      if (
        localStorageState &&
        Object.prototype.toString.call(localStorageState) === '[object Object]'
      ) {
        initState = {
          ...initState,
          ...hydrate(localStorageState),
        };
      }

      return initState;
    }
  );

  useEffect((): void => {
    storageUtil.set(key, state);
  }, [state]);

  return [state, dispatch];
}

export const Store = ({ children }: { children: JSX.Element }) => {
  const user: CurrentUser = useCurrentUser();

  const USER = user?.ID || 'USER';
  const [state, dispatch] = useLocalStorageReducer<RootState>(
    `insights/filters/${USER}`,
    rootReducer,
    initialState,
    (arg) => {
      return {
        ...arg,
        dateRange: initialState.dateRange,
        filters: {
          ...arg.filters,
          [Page.work]: arg.filters[Page.work].filter(
            (filter) => filter.column !== ZOOM_COLUMN
          ),
          [Page.people]: arg.filters[Page.people].filter(
            (filter) => filter.column !== ZOOM_COLUMN
          ),
        },
        pagination: initialState.pagination,
      };
    }
  );

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};
