import { createReducer, ActionType } from 'typesafe-actions';
import { SortByFields, SortByDirection } from '@insights/constants-nwe';
import { setSortByAndDirection } from '../../actions/sort-by';

export type State = Readonly<{
  sortBy: SortByFields;
  sortDirection: SortByDirection;
}>;

export const INITIAL_STATE = {
  sortBy: SortByFields.name,
  sortDirection: SortByDirection.asc,
};

export default createReducer<State, ActionType<typeof setSortByAndDirection>>(
  INITIAL_STATE
).handleAction(setSortByAndDirection, (_, action) => {
  return {
    sortBy: action.payload.sortBy,
    sortDirection: action.payload.sortDirection,
  };
});

export const getSortByField = (state: State): SortByFields => state.sortBy;

export const getSortByDirection = (state: State): SortByDirection =>
  state.sortDirection;
