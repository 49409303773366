import { MessageKeys } from '@insights/i18n-nwe';

export enum TimeframeType {
  days = 'days',
  weeks = 'weeks',
  years = 'years',
}

export type UnitObj = {
  value: number;
  unit: TimeframeType;
};

export const TIMEFRAME_ARIALABEL_TO_MESSAGEKEY: {
  [key in TimeframeType]: MessageKeys;
} = {
  [TimeframeType.days]: MessageKeys.kpiAriaLabelAverageProjectDurationBodyDays,
  [TimeframeType.weeks]:
    MessageKeys.kpiAriaLabelAverageProjectDurationBodyWeeks,
  [TimeframeType.years]:
    MessageKeys.kpiAriaLabelAverageProjectDurationBodyYears,
};

export const TIMEFRAME_ARIALABEL_FOOTER_TO_MESSAGEKEY: {
  [key in TimeframeType]: MessageKeys;
} = {
  [TimeframeType.days]:
    MessageKeys.kpiAriaLabelAverageProjectDurationFooterDays,
  [TimeframeType.weeks]:
    MessageKeys.kpiAriaLabelAverageProjectDurationFooterWeeks,
  [TimeframeType.years]:
    MessageKeys.kpiAriaLabelAverageProjectDurationFooterYears,
};

export const TIMEFRAME_LABEL_TO_MESSAGEKEY: {
  [key in TimeframeType]: MessageKeys;
} = {
  [TimeframeType.days]: MessageKeys.kpiAverageProjectDurationDays,
  [TimeframeType.weeks]: MessageKeys.kpiAverageProjectDurationWeeks,
  [TimeframeType.years]: MessageKeys.kpiAverageProjectDurationYears,
};

export const getValue = (value: number): UnitObj => {
  if (value <= 30) {
    return { value, unit: TimeframeType.days };
  } else if (value >= 31 && value <= 364) {
    return { value: value / 7, unit: TimeframeType.weeks };
  } else {
    return { value: value / 365, unit: TimeframeType.years };
  }
};
