import React, { FC, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'react-emotion';
import { css } from 'emotion';
import { CustomFormOperator } from '@insights/models-nwe';
import { FormattedMessage, MessageKeys, useIntl } from '@insights/i18n-nwe';
import { ClickableIcon, primary } from '@phoenix/all';
import LeftCaratIcon from 'phoenix-icons/dist/LeftCaratIcon.js';
import { HiddenAriaElement } from '@insights/shared-components-nwe';
import { FilterInput, FilterInProgress } from '../../components';
import { FieldOption } from '../custom-form-field-options';

export const TEST_ID_BACK_BTN = 'filter-back-btn';

export interface Props {
  fieldName: string;
  value: string;
  onApply(): void;
  onChange(value: string): void;
  operator: CustomFormOperator;
  disabledApplyButton: boolean;
  placeholder: string;
  inputType: string;
  testID: string;
  searchInputLabel?: React.ReactNode;
}

export const TEST_ID_APPLY_FILTER = 'apply-filter';

export const CustomFieldInput: FC<Props> = ({
  fieldName,
  value,
  onApply,
  onChange,
  operator,
  disabledApplyButton,
  placeholder,
  inputType,
  testID,
  searchInputLabel,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const onKeyDown = (e: KeyboardEvent): void => {
    if (value.length === 0 && e.key === 'Backspace') {
      navigate(-1);
    }
    if (!disabledApplyButton && e.key === 'Enter') {
      onApply();
    }
  };

  const onClick = () => {
    if (!disabledApplyButton) {
      onApply();
    }
  };

  return (
    <Container>
      <FilterInProgress column={fieldName} operator={operator} />
      <FilterInput
        autoFocus
        className={search}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        data-testid={testID}
        onKeyDown={onKeyDown}
        id={testID}
        placeholder={placeholder}
        type={inputType}
        autoComplete="off"
      />
      {searchInputLabel && (
        <HiddenAriaElement tag="label" htmlFor={testID}>
          {searchInputLabel}
        </HiddenAriaElement>
      )}
      <BackButton>
        <ClickableIcon
          onClick={() => navigate(-1)}
          testID={TEST_ID_BACK_BTN}
          icon={LeftCaratIcon}
          title={<FormattedMessage id={MessageKeys.back} />}
          className={backIcon}
          color={primary.white()}
        />
      </BackButton>
      <ApplyButton
        onClick={onClick}
        isDisabled={disabledApplyButton}
        data-testid={TEST_ID_APPLY_FILTER}
        aria-disabled={disabledApplyButton}
        aria-label={formatMessage({ id: MessageKeys.applyFilter })}
      >
        <FormattedMessage id={MessageKeys.applyFilter} />
      </ApplyButton>
    </Container>
  );
};

const ApplyButton = styled.button`
  background-color: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? '#ADAFB1' : primary.blue()};
  cursor: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? 'not-allowed' : 'pointer'};
  text-align: center;
  color: ${primary.white()};
  font-family: 'Proxima Nova';
  font-size: 12px;
  font-weight: 500;
  border: none;
  margin-left: 6px;
  border-radius: 2px;
  height: 21px;
`;

const BackButton = styled.div`
  background-color: ${primary.blue()};
  cursor: pointer;
  margin-left: 6px;
  border-radius: 2px;
  height: 21px;
  width: 25px;
`;

const backIcon = css`
  width: 19px;
  height: 17px;
  margin-right: 2px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.25;
`;

const search = css`
  width: 200px;
`;
