import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  RefObject,
} from 'react';
import { FilterOption } from '@insights/models-nwe';
import uniqueId from 'lodash.uniqueid';
import { formatItemTestId } from '@insights/utils-nwe';

export interface AriaComboboxProps {
  'aria-owns': string;
}

export interface AriaInputProps {
  'aria-activedescendant': string;
  'aria-controls': string;
  innerRef: RefObject<HTMLElement>;
}

export interface AriaMenuProps {
  id: string;
  innerRef: RefObject<HTMLElement>;
}

export interface AriaOptionProps {
  'aria-current': boolean;
  id: string;
  key: string;
  onClick(e: React.MouseEvent): void;
}

export interface UseComboBoxReturnValue {
  ariaComboboxProps: AriaComboboxProps;
  ariaInputProps: AriaInputProps;
  ariaMenuProps: AriaMenuProps;
  ariaOptionProps(item: FilterOption): AriaOptionProps;
}

export const useComboBox = (
  options: FilterOption[],
  onSelection: (item: FilterOption) => void
): UseComboBoxReturnValue => {
  const [focusedItem, updateFocusedItem] = useState<FilterOption | null>(null);
  const listboxRef = useRef<HTMLElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const id = useMemo(() => uniqueId('filters-'), []);
  const focusedItemValue = focusedItem?.value;
  const focusedItemIndex = options.findIndex(
    (option) => option.value === focusedItemValue
  );
  const itemIdPrefix = 'item-';
  const focusedItemId =
    focusedItemValue !== undefined
      ? `${itemIdPrefix}${formatItemTestId(focusedItemValue)}`
      : null;

  /* If focusedItem is no longer in the list of options reset focus */
  useEffect(() => {
    if (focusedItemIndex === -1) {
      updateFocusedItem(null);
    }
  }, [options, focusedItemIndex, updateFocusedItem]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown' || e.key === 'Down') {
        e.preventDefault();
        e.stopPropagation();

        if (focusedItemIndex < options.length - 1) {
          updateFocusedItem(options[focusedItemIndex + 1]);
        }
      } else if (e.key === 'ArrowUp' || e.key === 'Up') {
        e.preventDefault();
        e.stopPropagation();

        if (focusedItemIndex > 0) {
          updateFocusedItem(options[focusedItemIndex - 1]);
        }
      } else if (e.key === 'Enter') {
        if (inputRef.current === document.activeElement) {
          const item = options[focusedItemIndex];
          if (item) onSelection(item);
        }
      }
    },
    [focusedItemIndex, options, updateFocusedItem, onSelection, inputRef]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, [handleKeyDown]);

  /* Function For Controlling Scroll As User Traverses List */
  const scroll = useCallback(() => {
    if (listboxRef.current === null) return null;
    if (focusedItemId === null) return null;
    const { clientHeight, scrollHeight, scrollTop } = listboxRef.current;

    const focusedOption: HTMLLIElement | null = listboxRef.current?.querySelector(
      `#${focusedItemId}`
    );

    if (focusedOption === null) return null;

    const itemOffsetTop = focusedOption?.offsetTop;
    const offsetHeight = focusedOption?.offsetHeight;

    if (focusedItemIndex > -1 && scrollHeight > clientHeight) {
      const bottomOfListBoxInView = clientHeight + scrollTop;
      const focusedOptionBottom = itemOffsetTop + offsetHeight;

      if (focusedOptionBottom > bottomOfListBoxInView) {
        listboxRef.current.scrollTop = focusedOptionBottom - clientHeight;
      } else if (itemOffsetTop < scrollTop) {
        listboxRef.current.scrollTop = itemOffsetTop;
      }
    }
    return null;
  }, [listboxRef, focusedItemId]);

  /* Scroll */
  useEffect(() => {
    scroll();
  }, [scroll, listboxRef, focusedItemId]);

  /* If focusedItem changes update focus to input field if it isn't already */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, focusedItem]);

  const ariaComboboxProps = {
    'aria-owns': id,
  };

  const ariaInputProps = {
    'aria-activedescendant': focusedItemId ?? '',
    'aria-controls': id,
    innerRef: inputRef,
  };

  const ariaMenuProps = {
    id,
    innerRef: listboxRef,
  };

  const ariaOptionProps = (item: FilterOption): AriaOptionProps => ({
    'aria-current': focusedItemValue === item.value,
    id: `${itemIdPrefix}${formatItemTestId(item.value)}`,
    key: `${itemIdPrefix}${formatItemTestId(item.value)}`,
    onClick: (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onSelection(item);
    },
  });

  return {
    ariaComboboxProps,
    ariaInputProps,
    ariaMenuProps,
    ariaOptionProps,
  };
};
