import React, { FC, SVGProps, useMemo } from 'react';
import { Group } from '@vx/group';
import { CircleClipPath } from '@vx/clip-path';
import uniqueId from 'lodash.uniqueid';

export enum AvatarSize {
  small = 24,
  medium = 32,
  large = 68,
}

export interface Props extends SVGProps<SVGImageElement> {
  id: string;
  size?: AvatarSize;
}

const Avatar: FC<Props> = ({
  id,
  size = AvatarSize.medium,
  x,
  y,
  ...props
}) => {
  const clipPathId = useMemo(() => uniqueId('circle'), []);
  const radius = size / 2;

  return (
    <Group top={y} left={x}>
      <CircleClipPath id={clipPathId} r={radius} cy={radius} cx={radius} />
      <image
        data-testid="image"
        clipPath={`url(#${clipPathId})`}
        xlinkHref={`/internal/user/avatar?ID=${id}`}
        width={size}
        height={size}
        {...props}
      />
    </Group>
  );
};

export default Avatar;
