import { FilterOption } from '@insights/models-nwe';

export const selectOptionMultiSelect = (
  option: FilterOption,
  selectedOptions: FilterOption<string>[],
  onChange: (d: FilterOption[]) => void
): void => {
  const optionIndex = selectedOptions.findIndex(
    (element) => element.value === option.value
  );

  if (optionIndex !== -1) {
    const newValues = [
      ...selectedOptions.slice(0, optionIndex),
      ...selectedOptions.slice(optionIndex + 1),
    ];
    onChange(newValues);
  } else onChange([...selectedOptions, option]);
};
