export enum GraphqlFilterKey {
  portfolioguid = 'portfolioguid',
  programguid = 'programguid',
  projectguid = 'projectguid',
  condition = 'condition',
  status = 'status',
  teamguid = 'teamguid',
  sponsorguid = 'sponsorguid',
  ownerguid = 'ownerguid',
  customfields = 'customfields',
}
