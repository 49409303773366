import styled from 'react-emotion';
import { primary } from '@phoenix/all';

export const CardBodyContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DataPoint = styled.span`
  color: ${primary.gray(900)};
  font-size: 32px;
  font-weight: 600;
  margin-right: 16px;
  line-height: 38px;
`;
