import React, { FC, SVGProps } from 'react';
import { isBefore, isEqual } from 'date-fns';
import { ScaleTime } from 'd3-scale';
import { Motion, spring } from 'react-motion';

export interface Props extends SVGProps<SVGPolygonElement> {
  endDate: Date;
  dateScale: ScaleTime<number, number>;
  height: number;
}

const EndMarker: FC<Props> = ({ endDate, dateScale, height, ...rest }) => {
  const rangeStartDate = dateScale.domain()[0];
  const rangeEndDate = dateScale.domain()[1];
  if (
    isEqual(endDate, rangeStartDate) ||
    isBefore(endDate, rangeStartDate) ||
    isBefore(rangeEndDate, endDate)
  )
    return null;
  const x = dateScale(endDate);

  return (
    <Motion
      defaultStyle={{ x }}
      style={{
        x: spring(x),
      }}
    >
      {({ x: newX }) => (
        <polygon
          {...rest}
          transform={`translate(${newX} 0)`}
          points={`0,0 0,${height} ${-height},0`}
          data-testid="planned-timeline-end-marker"
        />
      )}
    </Motion>
  );
};

export default EndMarker;
