import React, { FC } from 'react';

import {
  MessageKeys,
  FormattedNumber,
  FormattedMessage,
} from '@insights/i18n-nwe';
import { DateRange, ProjectMagnitudeChartDatum } from '@insights/models-nwe';
import {
  ChartTooltip as Tooltip,
  TooltipWithInsightsBounds,
  TooltipWithInsightsBoundsProps,
} from '@insights/tooltip-nwe';

export interface OwnProps {
  project: ProjectMagnitudeChartDatum;
  dateRange: DateRange;
  hourGrain: string;
}

type Props = OwnProps & TooltipWithInsightsBoundsProps;

const MINUTES_IN_HOURS = 60;

const ProjectMagnitudeTooltip: FC<Props> = ({
  project,
  dateRange,
  hourGrain,
  ...rest
}) => {
  if (!project) return null;

  const d = project;

  const PlannedHoursTooltipData = () => (
    <>
      <Tooltip.DataItem
        labelText={<FormattedMessage id={MessageKeys.plannedHours} />}
        displayValue={
          <FormattedNumber
            value={d.workrequired / MINUTES_IN_HOURS}
            maximumFractionDigits={2}
            minimumFractionDigits={0}
          />
        }
      />
      <Tooltip.DataItem
        labelText={<FormattedMessage id={MessageKeys.completedHours} />}
        displayValue={
          <FormattedNumber
            value={d.completedplannedhours}
            maximumFractionDigits={2}
            minimumFractionDigits={0}
          />
        }
      />
      <Tooltip.DataItem
        labelText={<FormattedMessage id={MessageKeys.averageHours} />}
        displayValue={
          <Tooltip.AvgHoursPerDay
            startDate={dateRange.start}
            endDate={dateRange.end}
            hours={d.completedplannedhours}
          />
        }
      />
    </>
  );

  const DurationTooltipData = () => (
    <>
      <Tooltip.DataItem
        labelText={<FormattedMessage id={MessageKeys.daysWorked} />}
        displayValue={
          <FormattedMessage
            id={MessageKeys.durationDays}
            values={{
              numberOfDays: d.completeddurationdays,
            }}
          />
        }
      />
      <Tooltip.PlannedWithDurationDays
        startDate={d.plannedstartdate}
        endDate={d.plannedcompletiondate}
      />
    </>
  );

  return (
    <TooltipWithInsightsBounds {...rest}>
      <Tooltip.TooltipBaseComponent
        header={<Tooltip.Header label={d.name} />}
        content={
          <>
            <Tooltip.ConditionDot projectCondition={d.condition} />
            {d.conditionlabel && (
              <Tooltip.DataItem
                testID="custom-condition-label"
                labelText={
                  <FormattedMessage id={MessageKeys.customCondition} />
                }
                displayValue={d.conditionlabel}
              />
            )}
            {hourGrain === 'plannedHours' && <PlannedHoursTooltipData />}
            {hourGrain === 'plannedDuration' && <DurationTooltipData />}
          </>
        }
      />
    </TooltipWithInsightsBounds>
  );
};
export default ProjectMagnitudeTooltip;
