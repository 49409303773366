import { css } from 'emotion';
import { primary } from '@phoenix/all';

export const container = css`
  padding: 5px 0px;
`;

export const label = css`
  color: ${primary.gray(300)};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-transform: uppercase;
`;

export const display = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
`;
