export enum FeatureSwitch {
  'demo' = 'demo',
}

function stringToBoolean(string: string): boolean {
  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
}

export function isFlagEnabled(feature: FeatureSwitch) {
  const name = feature.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null
    ? false
    : stringToBoolean(decodeURIComponent(results[1].replace(/\+/g, ' ')));
}

export function getFeatureString() {
  const flags = Object.values(FeatureSwitch).reduce<string[]>(
    (acc, feature) => {
      if (isFlagEnabled(feature)) acc.push(`${feature}=true`);

      return acc;
    },
    []
  );

  if (flags.length === 0) return '';

  return `?${flags.join('&')}`;
}
