import React, { FC } from 'react';
import { FlightPlan } from '@insights/flight-plan-nwe';
import { Burndown } from '@insights/burndown-nwe';
import { useProjects } from '@insights/clients-nwe';
import TasksInFlight from '../connected-charts/tasks-in-flight';

import { CollapsableContainer, useSelectedProject } from '../../collapsing';

export const FlightPlanCharts: FC = () => {
  const { selectedProject, setSelectedProject } = useSelectedProject();
  const {
    data: { projects },
  } = useProjects();
  const projectId = selectedProject && selectedProject.guid;
  const projectName = selectedProject && selectedProject.name;

  return (
    <>
      <FlightPlan
        projectIds={Array.from(projects.keys())}
        onProjectClick={setSelectedProject}
        selectedProject={projectId}
      />
      <CollapsableContainer projectId={projectId}>
        <Burndown projectId={projectId} />
        <TasksInFlight projectId={projectId} projectName={projectName} />
      </CollapsableContainer>
    </>
  );
};
