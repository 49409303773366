import React, { FC, SVGProps, ReactNode, memo } from 'react';
import { ScaleTime } from 'd3-scale';
import { FormattedDate } from '@insights/i18n-nwe';

import * as styles from './styles';
import Marker from './index';

export interface Props extends SVGProps<SVGLineElement> {
  dateScale: ScaleTime<number, number>;
  height: number;
  offset?: number;
  color: string;
  dateX: Date;
  labelText: ReactNode;
}

const DateMarker: FC<Props> = ({
  dateScale,
  height,
  offset = 5,
  color,
  dateX,
  labelText,
}) => {
  const markerX = dateScale(dateX);

  return (
    <Marker
      startPosition={{ x: markerX, y: 0 }}
      endPosition={{ x: markerX, y: height + offset }}
      color={color}
      content={(x: number, y: number) => (
        <>
          <rect
            x={x - 27}
            y={y}
            width="54"
            height="32"
            style={{ fill: color }}
          />
          <text
            className={styles.lineMarker}
            x={x}
            y={y}
            textAnchor="middle"
            fontSize={12}
          >
            <tspan x={x} y={y} dx={0} dy={13}>
              <FormattedDate day="numeric" month="short" value={dateX} />
            </tspan>
            <tspan x={x} y={y} dx={0} dy={26}>
              {labelText}
            </tspan>
          </text>
        </>
      )}
    />
  );
};

export default memo(DateMarker);
