import { css } from 'emotion';
import styled from 'react-emotion';

interface StyledComponentProps {
  isFocused: boolean;
}

const borderStyling = (props: StyledComponentProps): string => {
  return props.isFocused ? '1px solid #0078D7' : '1px solid #E0E1E3';
};

const iconContainerBackground = (props: StyledComponentProps): string => {
  return props.isFocused ? '#0078D7' : '#F4F4F4';
};

const DateRangeSpan = styled.span`
  color: ${(props: StyledComponentProps): string =>
    props.isFocused ? '#313745' : '#82878E'};
  cursor: pointer;
  font-family: Proxima Nova;
  font-size: 14px;
  outline: none;
  width: 176px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: StyledComponentProps): string =>
    iconContainerBackground(props)};
  width: 32px;
  height: 100%;
`;

const CalendarInputContainer = styled.button`
  display: flex;
  outline: ${(props: StyledComponentProps): string | undefined =>
    props.isFocused ? 'none' : undefined};
  border: ${(props: StyledComponentProps): string => borderStyling(props)};
  border-radius: 2px;
  background-color: white;
  align-items: center;
  padding: 0;
  height: 32px;
`;

const parentContainer = css`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 208px;
`;

const PopOutCalendar = styled('div')`
  position: absolute;
  right: 0px;
  top: 40px;
  z-index: 9;
`;

export {
  DateRangeSpan,
  IconContainer,
  CalendarInputContainer,
  parentContainer,
  PopOutCalendar,
};
