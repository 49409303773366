import { useEffect, useState } from 'react';
import { useProjects } from '@insights/clients-nwe';

type Project = {
  guid: string;
  name: string;
};

export const useSelectedProject = () => {
  const { data: projectsData } = useProjects();
  const [selectedProject, setSelectedProject] = useState<Project | undefined>(
    undefined
  );

  useEffect(() => {
    if (selectedProject && !projectsData.projects.has(selectedProject.guid)) {
      setSelectedProject(undefined);
    }
  }, [projectsData.projects]);

  const handleSelectProject = (guid: string, name: string): void => {
    if (selectedProject && guid === selectedProject.guid) {
      setSelectedProject(undefined);
    } else {
      setSelectedProject({ guid, name });
    }
  };

  return { selectedProject, setSelectedProject: handleSelectProject };
};
