import { HierarchyNode, stratify, hierarchy } from 'd3-hierarchy';
import { primary } from '@phoenix/all';
import { ProjectMagnitudeChartDatum } from '@insights/models-nwe';

export const BASE_BACKGROUND_COLOR = primary.white();
export const HOVERED_BACKGROUND_COLOR = primary.gray(300);
export const SELECTED_BACKGROUND_COLOR = primary.gray(800);

export const treeify = (
  data: ProjectMagnitudeChartDatum[],
  getGuid = (d: ProjectMagnitudeChartDatum) => d.projectguid,
  getCompleted = (d: ProjectMagnitudeChartDatum) => d.completedplannedhours
) => {
  const getRoot = (d: ProjectMagnitudeChartDatum) =>
    d.projectguid === 'root' ? null : 'root';

  const stratifiedData = stratify<ProjectMagnitudeChartDatum>()
    .id(getGuid)
    .parentId(getRoot)([
      { projectguid: 'root' } as ProjectMagnitudeChartDatum,
      ...data,
    ])
    .sum(getCompleted);

  return hierarchy(stratifiedData).sort(
    (a, b) => getCompleted(b.data.data) - getCompleted(a.data.data)
  );
};

export const getRenderOrder = (
  d: HierarchyNode<HierarchyNode<ProjectMagnitudeChartDatum>>[],
  selectedProject: string | undefined
) => {
  if (!selectedProject) return d;
  return d.sort(
    (
      a: HierarchyNode<HierarchyNode<ProjectMagnitudeChartDatum>>,
      b: HierarchyNode<HierarchyNode<ProjectMagnitudeChartDatum>>
    ) => {
      if (a.data.id === selectedProject && a.depth === 1) {
        return 1;
      }
      if (b.data.id === selectedProject && b.depth === 1) {
        return -1;
      }
      return 0;
    }
  );
};

export const getStrokeColor = (
  projectGuid: string,
  selectedId?: string,
  hoveredId?: string
) => {
  if (!selectedId && !hoveredId) return BASE_BACKGROUND_COLOR;

  if (projectGuid === hoveredId) return HOVERED_BACKGROUND_COLOR;
  if (projectGuid === selectedId) return SELECTED_BACKGROUND_COLOR;
  return BASE_BACKGROUND_COLOR;
};
