import React, { FC } from 'react';

import styled from 'react-emotion';
import {
  PROJECT_CONDITION_TO_MESSAGE_KEY,
  isConditionType,
} from '@insights/constants-nwe';
import { conditionColorScale as colorScale } from '@insights/utils-nwe';
import { FormattedMessage, MessageKeys } from '@insights/i18n-nwe';
import * as styles from './styles';

export interface Props {
  projectCondition: string | undefined;
  testID?: string;
}

const ConditionDot: FC<Props> = ({ projectCondition = '', testID }) => (
  <div className={styles.root} data-testid={testID}>
    <CondDot conditionColor={colorScale(projectCondition)} />
    <span className={styles.conditionName}>
      <FormattedMessage id={getMessageKey(projectCondition)} />
    </span>
  </div>
);

export default ConditionDot;

const CondDot = styled.span`
  ${styles.conditionDot}
`;

const getMessageKey = (condition: string): MessageKeys => {
  return isConditionType(condition)
    ? PROJECT_CONDITION_TO_MESSAGE_KEY[condition]
    : MessageKeys.notAvailable;
};
